import { useState, useEffect } from "react";
import "./styles.css";
import banner from "../../assets/pictures/bannerHotel.png";
import Find from "../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import HotelSearchEngine from "./HotelSearchEngine/HotelSearchEngine";
import { useTranslation } from "react-i18next";
import { get, keys } from "../../localStorage";
import UseHotels from "../../viewModels/hotels/UseHotels";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";

const Hotel = () => {
  const [t] = useTranslation("global");
  const [isShowingFind, setIsShowingFind] = useState(false);

  const {
    cardRef,
    openHotelSearch,
    paceholders,
    search,
    setSearch,
    destiny,
    dateEntry,
    setDateEntry,
    datesSelected,
    setDatesSelected,
    cardRooms,
    setCardRooms,
    roomsSelected,
    setRoomsSelected,
    quantityPersons,
    openHotelSearchFuct,
    destinationSelectionFunc,
    searchHotelSelected,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    loadingSearch,
    validationForm,
    errApi,
    searchHotelNewApi,
  } = UseHotels();

  async function isWidgetLS() {
    try {
      const response = await get(keys?.isWidget);
      setIsShowingFind(response?.enable);
    } catch (error) {
      console.log("Error in isWidgetLS(): ", error);
    }
  }

  useEffect(() => {
    isWidgetLS();
  }, []);

  return (
    <div>
      {!isShowingFind && <Find />}
      <CarouselSevices current={t("carousel_services.hotel")} />
      <header className="header-hotel">
        <HotelSearchEngine
          titleSize={"30px"}
          iconTitleSize={"50"}
          containDateFlexDirection={"row"}
          containDateJustify={"space-between"}
          layoutCampoWidth={"47%"}
          containFinishFlexDirection={"row"}
          containFinishJustify={"space-between"}
          layoutCampoVWidth={"47%"}
          cardRef={cardRef}
          cardRooms={cardRooms}
          codeNacionality={codeNacionality}
          dataNacionality={dataNacionality}
          dateEntry={dateEntry}
          datesSelectedEntry={datesSelected.entryDate}
          datesSelectedExit={datesSelected.exitDate}
          destinationSelectionFunc={destinationSelectionFunc}
          destiny={destiny}
          errApi={errApi}
          findCountryClient={findCountryClient}
          loading={loading}
          loadingSearch={loadingSearch}
          nacionality={nacionality}
          nacionalityDropdown={nacionalityDropdown}
          openHotelSearch={openHotelSearch}
          openHotelSearchFuct={openHotelSearchFuct}
          paceholders={paceholders}
          quantityPersons={quantityPersons}
          roomsSelected={roomsSelected}
          search={search}
          searchHotelSelected={searchHotelSelected}
          selectedNacionality={selectedNacionality}
          setCardRooms={setCardRooms}
          setDateEntry={setDateEntry}
          setDatesSelected={setDatesSelected}
          setNacionalityDropdown={setNacionalityDropdown}
          setRoomsSelected={setRoomsSelected}
          setSearch={setSearch}
          validationForm={validationForm}
          searchHotelNewApi={searchHotelNewApi}
        />

        <div className="contain-banner-hotel">
          <img className="banner-img" src={banner} alt="" />
        </div>
      </header>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Hotel;
