import React, { useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from '@mui/material'
import img1 from "../../../assets/images/medellin.png"
import img2 from "../../../assets/images/BuenosAires.png"
import img3 from "../../../assets/images/Bogota.png"
import img4 from "../../../assets/images/chile.png"
import { useNavigate } from 'react-router-dom'

const OutstandingPlaces = () => {

    const constraintsRef = useRef(null);
    const MotionConstraints = styled(motion.div)`overflow-x: hidden;`;
    const MotionBox = styled(motion.div)`width: 100vw;`;
    const navigate = useNavigate()

    const imgs = [
        {
            img: img1,
            text: "Medellin"
        },
        {
            img: img2,
            text: "Buenos Aires, Argentina"
        },
        {
            img: img3,
            text: "Bogota"
        },
        {
            img: img4,
            text: "Santiago de chile, Chile"
        }
    ]

    const goToOption = (keywork) => {
        if(keywork === ""){
            navigate(`/TourismHealthPack/all`, {
                state: {
                    keyWork: keywork
                }})
        }else{
            navigate(`/TourismHealthPack/${keywork}`, {
            state: {
                keyWork: keywork
            }
        })
        }
        
    }


    return (
        <div className='content-best-experiences'>
            <h2>Lugares Destacados</h2>
            <div className='header-experiences'>
                <p>Conoce los lugares mas destacados y más seleccionados para procedimientos médicos</p>
                <button onClick={() => {
                    goToOption("")
                }}>
                    Ver todas los lugares destacados
                </button>
            </div>
            <section >
                <MotionConstraints className='slider_container' ref={constraintsRef}>
                    <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}>
                        {
                            imgs.map((item, key) => {
                                return (
                                    <motion.div className='div_homeTurism' key={key}>
                                        <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                            <div className='card' onClick={() => {
                                                goToOption(item.text)
                                            }}>
                                                <div className='content-img-best-experience'>
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <h3>{item.text}</h3>
                                            </div>
                                        </Grid>
                                    </motion.div>
                                )
                            })
                        }

                    </MotionBox>
                </MotionConstraints>


            </section>

        </div>
    )
}

export default OutstandingPlaces