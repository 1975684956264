import React from 'react'

const About = () => {
  return (
    <div style={{backgroundColor: '#00DEDF', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h1>About</h1>
    </div>
  )
}

export default About