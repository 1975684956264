import { useState, useEffect, useContext } from "react";
import { wellezy_api } from "../../../../infra/api";
import { doctorURL } from "../../../../infra/urls";
import UserContext from "../../../../Context/user/userProvider";
import { iconList } from "../../../views/share/components/iconSVG";

function useMyCalendarViewModel() {
  const { userDetail } = useContext(UserContext);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalCardInfo, setIsShowModalCardInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentSelected, setAppointmentSelected] = useState(null);

  const [filterByMonth, setFilterByMonth] = useState({ id: 0, name: "" });
  const [isShowMonthList, setIsShowMonthList] = useState(false);
  const [monthList, setMonthList] = useState([
    { id: 1, name: "Enero" },
    { id: 2, name: "Febrero" },
    { id: 3, name: "Marzo" },
    { id: 4, name: "Abril" },
    { id: 5, name: "Mayo" },
    { id: 6, name: "Junio" },
    { id: 7, name: "Julio" },
    { id: 8, name: "Agosto" },
    { id: 9, name: "Septiembre" },
    { id: 10, name: "Octubre" },
    { id: 11, name: "Noviembre" },
    { id: 12, name: "Diciembre" },
  ]);

  const [filterByYear, setFilterByYear] = useState(0);
  const [isShowYearList, setIsShowYearList] = useState(false);
  const [yearList, setYearList] = useState([
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ]);

  function chooseFilterByMonth(item) {
    setFilterByMonth(item);
    setIsShowMonthList(false);
  }

  function cooseFilterByYear(value) {
    setFilterByYear(value);
    setIsShowYearList(false);
  }

  function createAppointment() {
    setAppointmentSelected(null);
    setIsShowModal(true);
  }

  function managementAppointment(value) {
    setAppointmentSelected(value);
    setIsShowModalCardInfo(true);
  }

  const tabletHeader = [
    { key: "date", value: "Fecha" },
    { key: "hour", value: "Hora" },
    { key: "procedure", value: "Procedimiento" },
    { key: "actions", value: "actions" },
  ];

  const tabletData = appointmentList;

  const tabletActions = [
    {
      color: "#004274",
      size: 25,
      icon: iconList.IconCalendar,
      label: "Agendar",
      action: managementAppointment,
    },
  ];

  async function getAppointmentsFiltered() {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const url = doctorURL.get.appointmentsFilter;
      const data = {
        user_id: userDetail?.id,
        year: filterByYear,
        month: filterByMonth?.id,
      };
      const result = await wellezy_api.get(url, {
        params: data,
      });
      const newData = result?.data?.appointments;
      setAppointmentList(newData);
    } catch (error) {
      console.log("Error in getAppointmentsFiltered(): ", error);
    } finally {
      setIsLoading(false);
    }
  }

  function getCurrentTime() {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const monthSelected = monthList.find((obj) => obj.id === monthIndex);
    setFilterByMonth(monthSelected);
    setFilterByYear(currentYear);
  }

  useEffect(() => {
    if (filterByYear !== 0 && filterByMonth.id !== 0) {
      getAppointmentsFiltered();
    }
  }, [filterByYear, filterByMonth]);

  useEffect(() => {
    getCurrentTime();
    if (filterByYear !== 0 && filterByMonth.id !== 0) {
      getAppointmentsFiltered();
    }
  }, []);

  return {
    isLoading,
    isShowModal,
    setIsShowModal,
    getAppointmentsFiltered,

    filterByMonth,
    isShowMonthList,
    setIsShowMonthList,
    monthList,
    chooseFilterByMonth,

    filterByYear,
    isShowYearList,
    setIsShowYearList,
    yearList,
    cooseFilterByYear,

    tabletHeader,
    tabletData,
    tabletActions,
    appointmentSelected,
    createAppointment,

    isShowModalCardInfo,
    setIsShowModalCardInfo,
  };
}

export default useMyCalendarViewModel;

//   const [listMonth, setListMonth] = useState([]);
//

//   const months_es = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
//   const months_en = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//   const daysOfWeek_es = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
//   const daysOfWeek_en = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

//   const banners = [
//     { id: 0, img: 'https://www.xtrafondos.com/wallpapers/montanas-en-lago-al-atardecer-4450.jpg' },
//     { id: 1, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 2, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 3, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 4, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 5, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 6, img: 'https://img.freepik.com/premium-photo/bunch-colorful-tropical-leaves-black-background-generative-ai_797840-7790.jpg' },
//     { id: 7, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 8, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 9, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 10, img: 'https://w0.peakpx.com/wallpaper/778/607/HD-wallpaper-16k-waterfall-waterfalls.jpg' },
//     { id: 11, img: 'https://c4.wallpaperflare.com/wallpaper/339/964/49/christmas-night-landscape-christmas-tree-wallpaper-preview.jpg' },
//   ]

//   const countDaysPerMonth = [{ 'id': 0, days: 31 }, { 'id': 1, days: 28 }, { 'id': 2, days: 31 }, { 'id': 3, days: 30 }, { 'id': 4, days: 31 }, { 'id': 5, days: 30 }, { 'id': 6, days: 31 }, { 'id': 7, days: 31 }, { 'id': 8, days: 30 }, { 'id': 9, days: 31 }, { 'id': 10, days: 30 }, { 'id': 11, days: 31 }];

//   function getyearLeaper(y) {
//     let status
//     if (Number.isInteger(y / 4)) { status = true }
//     else {
//       if (Number.isInteger(y / 100)) { status = true }
//       else {
//         if (Number.isInteger(y / 400)) { status = true }
//         else {
//           status = false
//         }
//       }
//     }
//     return status
//   }

//   function fillDateFormat(fecha) {
//     const partesFecha = fecha.split('-');
//     const año = partesFecha[0];
//     const mes = partesFecha[1].padStart(2, '0');
//     const dia = partesFecha[2].padStart(2, '0');
//     return `${año}-${mes}-${dia}`;
//   }

//   function getNameDay(day) {
//     const [year, month, date] = day.split('-');
//     const fecha = new Date(Date.UTC(year, month - 1, date));
//     const numeroDia = fecha.getUTCDay();
//     const nombreDia = daysOfWeek_es[numeroDia];
//     return nombreDia;
//   }

//   function getValuesMonth(y = false, m = false, d = false) {
//     let currentDate;
//     if (y && m && d) {
//       currentDate = new Date(y, m, d)
//     }
//     else {
//       currentDate = new Date();
//     }

//     const yearNumb = currentDate.getFullYear();
//     const monthNumb = currentDate.getMonth() + 1;
//     const indiceMouth = currentDate.getMonth();
//     const monthName = months_es[indiceMouth];
//     const isLeapYear = getyearLeaper(yearNumb);
//     const _daysList = [];

//     const res = {
//       isCurrentMonth: new Date().getMonth() === indiceMouth,
//       year: yearNumb,
//       isLeapYear: isLeapYear,
//       monthNumb: monthNumb,
//       indiceMouth: indiceMouth,
//       monthName: monthName,
//       totalDays: countDaysPerMonth.find((obj) => obj.id === indiceMouth)?.days,
//       banner: banners.find((obj) => obj.id === indiceMouth)?.img,
//     }

//     for (let i = 0; i < res.totalDays; i++) {
//       const _fillDateFormat = fillDateFormat(`${res.year}-${res.monthNumb}-${i + 1}`);
//       const _getName = getNameDay(_fillDateFormat);
//       const numberDay = `${i + 1}`;
//       _daysList.push({
//         name: _getName,
//         number: numberDay.padStart(2, '0'),
//       })
//     }

//     if (isLeapYear && indiceMouth === 1) {
//       res.totalDays = res.totalDays + 1
//     }
//     res.daysList = _daysList;
//     return res;
//   }

//   function getMonths() {

//     const list = [];
//     let month;
//     for (let i = 0; i < 4; i++) {
//       if (list.length === 0) {
//         month = getValuesMonth();
//       }
//       else {
//         const lastMonth = list[list.length - 1];
//         const newYear = lastMonth?.year;
//         const newMonth = lastMonth?.indiceMouth + 1;
//         const newDat = 1;
//         month = getValuesMonth(newYear, newMonth, newDat);
//       }
//       list.push(month);
//     }
//     setListMonth(list)

//   }

//   async function getAppointments() {
//     try {
//       const url = 'https://staging.aiop.com.co/api/appointments/pending';
//       const response = await axios.get(url, {
//         params: {
//           "user_id": 1
//         }
//       });

//       console.log("response: ", response?.data?.appointments)

//     } catch (error) {
//       console.log("Error in getAppointments():", error)
//     }
//   }

// async function getFilteredAppointments() {
//   if (isLoading) return;
//   try {
//     setIsLoading(true)
//     console.log("getFilteredAppointments")
//   } catch (error) {
//     console.log("Error in getFilteredAppointments(): ", error)
//   }
//   finally {
//     setIsLoading(false)
//   }
// }

// try {
//
//

//   if (result) {
//     setUserSpecialtiesList(result?.data?.user)
//   }
// }
// wellezy_api. doctorURL
