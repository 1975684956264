import React, { useEffect, useRef, useState } from 'react';
import { CloseCircle } from 'iconsax-react';
import CircularProgress from '@mui/material/CircularProgress';
// import '../../../../../Components/FlightsDesinitySearcher/Assets/styles.css';
import axios from 'axios';
import "./styles.css"
import arrowDownRight from "../../../../assets/icons/arrow-down-right.png"


const DesinitySearcher = ({
  type,
  change,
  inputFocused
}) => {
  const [search, setSearch] = useState('');
  const [origins, setOrigins] = useState([]);
  const [error, setError] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false)



  // const filterCity = async (e) => {
  //   const text = e.target.value
  //   setSearch(text)
  // }




  function IconToShow() {
    if (error) {
      return <div style={{ cursor: "pointer" }} onClick={() => { setSearch('') }}><CloseCircle size="30" color="#FF0000" /></div>
    }
    else {

      if (origins.length === 0) {
        return <CircularProgress size={25} />
      }
      else {
        return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none"><path d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10Z" stroke="#2ECC71" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="m7.75 12 2.83 2.83 5.67-5.66" stroke="#2ECC71" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
      }

    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    const searchCity = async () => {

      try {
        if (error) {
          setError(false)
        } else if (search === "") {
          setLoadingSearch(false)
          setOrigins([])
          return
        } else {
          const url = `${process.env.REACT_APP_URL_API_FLY}airports/v2`;
          setLoadingSearch(true)
          const result = await axios.post(url, { "code": `${search}` }, { cancelToken: source.token });
          if (result?.data?.airports?.length === 0 && result?.data?.cities?.length === 0) {
            // setError(true)
            setLoadingSearch(false)
            // setSearch(`Invalido: ${search}`)
            setOrigins({ airports: [{ codeIataCity: "Ciudad no encontrada" }], cities: [{ codeIataAirport: "Aeropuerto no encontrado" }] })
          }
          else {
            setError(false)
            setLoadingSearch(false)
            console.log(result.data)
            setOrigins(result.data)
          }
        };
        
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("Error in searchCity: ", error);
          setLoadingSearch(false);
          setSearch(`api error: ${search}`);
          setError(true);
        }
      }
    };

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const debouncedSearchCity = debounce(searchCity, 2000);

    debouncedSearchCity();

    return () => {
      source.cancel("Componente desmontado. Cancelar solicitudes pendientes.");
    };

  }, [search, error]);


  const inputRef = useRef(null);






  return (
    <>
      <div className='flight-searchDestiny_input'>
        <input ref={inputRef} type="text" placeholder={type.placeholder} onChange={(e) => {
          setSearch(e.target.value)
        }} required value={search} />
        {
          error ?
            <div style={{ cursor: "pointer" }} onClick={() => [setSearch(''), setError(false)]}><CloseCircle size="30" color="#FF0000" /></div>
            :
            (
              loadingSearch ?
                <CircularProgress size={20} style={{ position: "absolute", borderRadius: "50%", backgroundColor: "white", right: "0", top: "13px", zIndex: "9" }} />
                :
                ""
            )
        }
      </div>
      {
        inputFocused && origins?.airports?.length > 0 == true || origins?.cities?.length > 0  == true ?

        <div className='flight-search-cities-list'>
          <div className='header-list-cities'><p>Ciudades encontradas {origins?.cities[0]?.codeIataCity === "Ciudad no encontrada" ? origins?.cities?.length - 1 : origins?.cities?.length}</p></div>
          <div className='content-result-cities-flight'>
            {origins?.cities.map((cityData, key) => {
              return (
                <>
                  {
                    cityData?.new_airports?.length > 1 &&
                    <div key={key} className='cityData-result'>
                      <div className='city-data'>
                        <div className='city-data-text' style={{ cursor: "auto", background: "white" }}>
                          <div className='city-country'>
                            <p>{cityData?.nameCity}</p>
                          </div>
                          <div className='sub-airports-city' key={key}>
                            {
                              cityData?.new_airports.map((airport, key) => {
                                return (
                                  <div onClick={() => {
                                    change(airport)
                                    setOrigins([])
                                  }} className='' key={key}>
                                    <img src={arrowDownRight} alt="arrow" />
                                    <p>{airport?.nameAirport} - {cityData?.new_country?.nameCountry}</p>
                                  </div>
                                )
                              })
                            }
                          </div>
                          {/* <div className='city-iata'>
                        <p>{cityData?.nameCountry}</p>
                      </div> */}
                        </div>
                      </div>
                    </div>

                  }
                  {
                    cityData?.new_airports?.length === 1 &&
                    <div key={key} className='cityData-result'>
                      <div onClick={() => {
                        change(cityData?.new_airports[0])
                        setOrigins([])
                      }} className='city-data'>
                        <div className='city-data-text'>
                          <div className='city-country'>
                            <p>{cityData?.nameCity} - {cityData?.new_country?.nameCountry}</p>
                          </div>
                          {/* <div className='city-iata'>
                        <p>{cityData?.nameCountry}</p>
                      </div> */}
                        </div>
                      </div>

                    </div>
                  }

                </>


              )
            })}
          </div>

          <div className='header-list-cities' style={{marginTop:"1rem"}}><p>Aeropuertos encontrados {origins?.airports[0]?.codeIataAirport === "Aeropuerto no encontrado" ? origins?.airports?.length - 1 : origins?.airports?.length}</p></div>
          <div className='content-result-cities-flight'>
            {origins?.airports?.map((cityData, key) => {
              return (
                <div key={key} className='cityData-result'>
                  <div onClick={() => {
                    change(cityData)
                    setOrigins([])
                  }} className='city-data'>
                    <div className='city-data-text'>
                      <div className='city-country'>
                        <p>{cityData?.nameAirport}  - {cityData?.new_country?.nameCountry}</p>
                      </div>
                      {/* <div className='city-iata'>
                        <p>{cityData?.nameCountry}</p>
                      </div> */}
                    </div>
                  </div>

                </div>
              )
            })}
          </div>

        </div>
        :
        ""
      }

    </>
  )
}
export default DesinitySearcher;



//http://localhost:3000/flight/search?&dateOfDeparture=&dateOfReturn=2023-09-29T05%3A00%3A00.000Z&iataDestinationCode=BOG&iataOriginCode=MDE&destinationCity=Eldorado%20-%20BOG&originCity=Jose%20Marie%20Cordova%20-%20MDE&adultPassengers=1&boyPassengers=0&babyPassengers=0&isRoundTrip=false

//http://localhost:3000/flight/search?&dateOfDeparture=&dateOfReturn=2023-09-29T05:00:00.000Z&iataDestinationCode=BOG&iataOriginCode=MDE&destinationCity=Eldorado%20-%20BOG&originCity=Jose%20Marie%20Cordova%20-%20MDE&adultPassengers=1&boyPassengers=0&babyPassengers=0&isRoundTrip=false

// recibo esta url http://localhost:3000/flight/search?&dateOfDeparture=&dateOfReturn=2023-09-29T05:00:00.000Z&iataDestinationCode=BOG&iataOriginCode=MDE&destinationCity=Eldorado%20-%20BOG&originCity=Jose%20Marie%20Cordova%20-%20MDE&adultPassengers=1&boyPassengers=0&babyPassengers=0&isRoundTrip=false y necesito estructurarla de la siguiente manera {

//   searchs: 50,

//   class: false,

//   qtyPassengers: 1,

//   adult: 1,

//   child: 0,

//   baby: 0,

// itinerary: [

//   {

//       departureCity: 'BOG',

//       arrivalCity: 'MDE',

//       hour: '2023-09-29T05:00:00.000Z'

//     }

//   ]

// } los datos de searchs y class van por default hazlo en js