import React from 'react';
import './styles.css';
import { global } from '../../../../global';
import { CloseCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

const {
  iconLuggageBlue
} = global.img.icons;

function ModalWishToInclude({
  show,
  close,
  continueBuy,
  chooseUpsell,
}) {

  const [t,i18n] = useTranslation("global")

  return (
    <>
      {show &&
        <div className='modalWishToIncludeContainer'>
          <div className='modalWishToIncludeCard'>
            <div onClick={() => close(false)} className='modalWishToIncludeCardClose'>
              <CloseCircle size="30" color="#FFFFFF" />
            </div>
            
          <div className='modalWishToIncludeCenter'>
            <div className='modalWishToIncludeCardHead'>
              <div className='modalWishToIncludeCardHeadIconWrap'>
                <img src={iconLuggageBlue} alt='icon Luggage' className='modalWishToIncludeCardHeadIcon'/>
              </div>
            </div>

            <div className='modalWishToIncludeCardBody'>
              <p className='modalWishToIncludeCardBodyTitle'>{t("fligth.modal_to_include.include_luggage")}</p>
              <p className='modalWishToIncludeCardBodysubTitle'>{t("fligth.modal_to_include.select_fare_ajust")}</p>
            </div>

            <div className='modalWishToIncludeCardFoot'>
              <div className='modalWishToIncludeCardFootBtn' onClick={chooseUpsell}>
                <p className='modalWishToIncludeCardFootBtnText'>{t("fligth.modal_to_include.select_fare")}</p>
              </div>
              <div className='modalWishToIncludeCardFootBtn' onClick={()=>continueBuy(false)}>
                <p className='modalWishToIncludeCardFootBtnText'>{t("fligth.search_fligth.continue")}</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ModalWishToInclude;