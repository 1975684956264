import React, { forwardRef } from "react";
import Check from "../../../assets/images/Check.png";
import viaja from "../../../assets/images/viaja.png";
import DatePicker from "react-datepicker";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import InputCustom from "./InputCustom";

import "./styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ArrowDown2 } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ReactComponent as IconSearch } from "./Assets/svg/IconSearch.svg";
import { ReactComponent as IconPlus } from "./Assets/svg/IconPlus.svg";
import { ReactComponent as IconLess } from "./Assets/svg/IconLess.svg";
import { CircularProgress } from "@mui/material";

const SearchTransport = ({
  adult,
  baby,
  setTypeT,
  ciudadDropdown,
  setCiudadDropdown,
  aeropuertoDropdown,
  setAeropuertoDropdown,
  formValidate,
  passengersCal,
  cityList,
  citySelected,
  setcitySelected,
  findOrigin,
  originList,
  originSelected,
  setOriginSelected,
  findDestiny,
  destinyList,
  destinySelected,
  setDestinySelected,
  destinyDropdown,
  setDestinyDropdown,
  findServices,
  selectedDate,
  handleDateChange,
  width,
  buscadorMenu,
  resetFilter,
  loader,
  setCityList,
  loadingCity,
  loadingDestiny,
  loadingOrigin,
  setCity,
  setOriginList,
  setDestinyList
}) => {
  const [t, i18n] = useTranslation("global");

  const { pathname } = useLocation();

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    function convertirFecha(fecha) {
      // Dividir la fecha en sus componentes
      const components = fecha.split("-");

      // Reorganizar los componentes para obtener el formato deseado
      const nuevaFecha = `${components[2]}-${components[1]}-${components[0]}`;

      return nuevaFecha;
    }
    function obtenerHora(fecha) {
      // Dividir la fecha en sus componentes
      const components = fecha.split("-");

      // Obtener la hora y los minutos
      const hora = components[3];
      const minutos = components[4];

      // Formatear la hora en "HH:MM"
      const horaFormateada = `${hora}:${minutos}`;

      return horaFormateada;
    }
    return (
      <div
        className="input-cat-date"
        style={{
          justifyContent: "space-around",
          flexWrap: "wrap",
          alignItems: "center",
        }}
        ref={ref}
        onClick={onClick}
      >
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          <span style={{ marginRight: "15px" }}>
            <SvgIcon
              name={iconList.IconCalendar2}
              size={21}
              color={"#004274"}
            />
          </span>
          {convertirFecha(value)}
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          <span style={{ marginRight: "15px" }}>
            <SvgIcon 
              name={iconList.IconReloj2} 
              size={21} 
              color={"#004274"} 
            />
          </span>
          {obtenerHora(value)}
        </p>
      </div>
    );
  });

  return (
    <>
      <div className="container-search-taxicar" style={{ width: `${width}` }}>
        <div className="check-title">
          <SvgIcon name={iconList.IconTaxi} color={"#004274"} size={50} />
          <div>
            <h4>{t("transfers.title")}</h4>
            <p>{t("transfers.text_title")}</p>
          </div>
        </div>
        <div className="contain-form">
          <form action="" onSubmit={(e) => findServices(e)}>
            <div className="campo-t">
              <label htmlFor="">{t("transfers.transfers_type")}:</label>
              <select
                className="input-cat"
                name=""
                id=""
                onChange={(e) => setTypeT(e.target.value)}
              >
                <option value="">{t("transfers.select_transfer_type")}</option>
                <option value="ONLY_TO_AIRPORT">
                  {t("transfers.one_way_airport")}
                </option>
                <option value="ONLY_FROM_AIRPORT">
                  {t("transfers.one_way_hotel")}
                </option>
                <option value="ROUND_TRIP">{t("transfers.round_trip")}</option>
              </select>
            </div>
            <div className="campo-t">
              <label htmlFor="">{t("transfers.city")}:</label>
              <div
                className="input-cat"
              >
                {
                  citySelected !== "" ?
                    <p onClick={() => {
                      setcitySelected("")
                    }}>
                      {citySelected?.name ? citySelected?.name : citySelected}
                    </p>
                    :
                    <div className="contend-input-loader">
                      <input
                        className="input-dropdown"
                        onChange={(e) => setCity(e.target.value)}
                        type="text"
                        placeholder={t("transfers.city")}
                      />
                      {
                        loadingCity &&
                        <CircularProgress size={20} style={{}} />
                      }
                    </div>

                }

              </div>

              {cityList?.length > 0 && (
                <div className="dropdown-ciudad shadow">
                  <div className="header-list">
                    <p>Ciudades Encontradas {cityList.length}</p>
                  </div>

                  <ul className="city-result">
                    {
                      cityList?.map((item) => {

                        return (
                          <li
                            onClick={() => {
                              setcitySelected(item);
                              setCityList([])
                            }}
                            key={item.id}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>
            <div className="campo-t">
              <label htmlFor="">{t("transfers.origin")}:</label>
              <div
                className="input-cat"

              >
                {
                  originSelected !== "" ?
                    <p onClick={() => {
                      setOriginSelected("");
                    }}>{originSelected.name}</p>
                    :
                    <div onClick={() => {
                      findOrigin()
                      
                    }} style={{display:"flex", justifyContent:"space-between",alignItems:"center",width:"97%"}}>
                      <p>
                        {t("transfers.select_airport")}
                      </p>
                      {
                        loadingOrigin &&
                        <CircularProgress size={20} style={{}} />
                      }
                    </div>

                }
              </div>
              {originList?.length > 0 && (
                <div className="dropdown-ciudad shadow">
                  <div className="header-list">
                    <p>Origenes Encontrados {originList?.length}</p>
                  </div>
                  <ul className="city-result">
                    {
                      originList?.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              setOriginSelected(item);
                              setOriginList([])
                            }}
                            key={item.id}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>
            <div className="campo-t">
              <label htmlFor="">{t("transfers.select_destination")}:</label>
              <div
                className="input-cat"
              >
                 {
                  destinySelected !== "" ?
                    <p onClick={() => {
                      setDestinySelected("");
                    }}>{destinySelected.name}</p>
                    :
                    <div onClick={() => {
                      findDestiny();          
                    }} style={{display:"flex", justifyContent:"space-between",alignItems:"center",width:"97%"}}>
                      <p>
                        {t("transfers.select_destination")}
                      </p>
                      {
                        loadingDestiny &&
                        <CircularProgress size={20} style={{}} />
                      }
                    </div>

                }
              </div>
              {destinyList?.length > 0 && (
                <div className="dropdown-ciudad shadow">
                  <div className="header-list">
                    <p>Destinos Encontrados {destinyList?.length}</p>
                  </div>
                  <ul className="city-result">
                    {
                      destinyList?.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              setDestinySelected(item);
                              setDestinyList([])
                            }}
                            key={item.id}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>

            <div className="campo-t">
              <label htmlFor="">{t("transfers.select_date_time")}:</label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                customInput={<CustomInput />}
                showTimeSelect
                dateFormat="yyyy-MM-dd-HH-mm"
              />
            </div>
            <div className="campo-three">
              <div>
                <label htmlFor="">{t("transfers.adults")} +18</label>
                <div className="btn-passengers">
                  <button onClick={(e) => passengersCal(e, "ad", 1)}>
                    <IconPlus />
                  </button>
                  <span>{adult}</span>
                  <button onClick={(e) => passengersCal(e, "ad", -1)}>
                    <IconLess />
                  </button>
                </div>
              </div>

              <div>
                <label htmlFor="">{t("transfers.babies")} -2</label>
                <div className="btn-passengers">
                  <button onClick={(e) => passengersCal(e, "bb", 1)}>
                    <IconPlus />
                  </button>
                  <span>{baby}</span>
                  <button onClick={(e) => passengersCal(e, "bb", -1)}>
                    <IconLess />
                  </button>
                </div>
              </div>
            </div>
            {formValidate === "" ? (
              ""
            ) : (
              <div className="error-form">
                <p>{formValidate}</p>
              </div>
            )}
            {pathname !== "/packages" && (
              <div className="contain-btn-t">
                <button onClick={resetFilter}>
                  {
                    loader ?
                      <CircularProgress size={20} />
                      :
                      <IconSearch />
                  }

                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchTransport;
