import { useState, useEffect } from 'react';

function usePqrsPrimaryScreen() {
  const [activatedTab, setActivatedTab] = useState(0);
  const [isEstablishedSession, setIsEstablishedSession] = useState(false);
  const [dataSelected, setDataSelected] = useState(null)

  const dataPqrs =
    [
      {
        id: 'person',
        name: 'persona',
        isActive: true,
        videos: [
          {name:'video 1', url:'https://www.youtube.com/watch?v=DbLUdJcv0uk'},
          {name:'', url:'https://www.youtube.com/watch?v=NNu7jyCwnD8'},
          {name:'video videovideo f video 3', url:'https://www.youtube.com/watch?v=Vua0Skln52A'},
          {name:'video 4', url:'https://www.youtube.com/watch?v=DbLUdJcv0uk'},
          {name:'video 5', url:'https://www.youtube.com/watch?v=NNu7jyCwnD8'},
          {name:'video 6', url:'https://www.youtube.com/watch?v=Vua0Skln52A'},
          {name:'video 7', url:'https://www.youtube.com/watch?v=DbLUdJcv0uk'},
          {name:'video 8', url:'https://www.youtube.com/watch?v=NNu7jyCwnD8'},
          {name:'video 9', url:'https://www.youtube.com/watch?v=Vua0Skln52A'},
          {name:'video 10', url:'https://www.youtube.com/watch?v=DbLUdJcv0uk'},
          {name:'video 11', url:'https://www.youtube.com/watch?v=NNu7jyCwnD8'},
          {name:'video 12', url:'https://www.youtube.com/watch?v=Vua0Skln52A'},
          {name:'video 13', url:'https://www.youtube.com/watch?v=DbLUdJcv0uk'},
          {name:'video 14', url:'https://www.youtube.com/watch?v=NNu7jyCwnD8'},
          {name:'video 15', url:'https://www.youtube.com/watch?v=Vua0Skln52A'},
        ],
        faq: {
          en: [
            { title: "title Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.", content: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen." },
            { title: "title Faq", content: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen." },
            { title: "title Faq", content: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen." },
            { title: "title Faq", content: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen." },
            { title: "title Faq", content: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen." },
          ],
          es: [
            { title: "title Faq", content: "lorem insup" },
            { title: "title Faq", content: "lorem insup" },
            { title: "title Faq", content: "lorem insup" },
            { title: "title Faq", content: "lorem insup" },
            { title: "title Faq", content: "lorem insup" },
          ]
        }
      },
    ]


  useEffect(() => {
    if (dataPqrs && dataPqrs.length > 0) {
      for (const i in dataPqrs) {
        if (dataPqrs[i]?.isActive) {
          setActivatedTab(dataPqrs[i].id)
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (activatedTab) {
      const pqrsSelected = dataPqrs.find((obj) => obj.id === activatedTab)
      if (pqrsSelected)
        setDataSelected({ ...pqrsSelected });
    }
  }, [activatedTab]);

  return {
    activatedTab,
    setActivatedTab,
    dataPqrs,
    isEstablishedSession,
    dataSelected,
  }

}

export default usePqrsPrimaryScreen;






/*
  "Persona": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Doctor": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Clinica": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Turismo": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  },
  "Salud": {
      "videos":[
          "urivideo1", "urivideo2", "urivideo3", "urivideo4"
      ],
      "faq": [
          {
              "title": "title Faq",
           "content": "lorem insup"
          },
          {
              "title": "title Faq",
           "content": "lorem insup"
          }
      ]
  }*/
