import React from 'react'
import "./styles.css"
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import { useLocation } from 'react-router-dom'

const DetailBuy = () => {
    const pathname = localStorage.getItem("urlHotel")
    const hab = JSON.parse(localStorage.getItem("hab-selected"))
    console.log(JSON.parse(localStorage.getItem("flightSelected")))
    console.log(JSON.parse(localStorage.getItem("hab-selected")))
    const fligth = JSON.parse(localStorage.getItem("flightSelected"))
    function formatearFecha(fechaStr) {
        const fecha = new Date(fechaStr);
        const opcionesDeFormato = { day: 'numeric', month: 'short', year: 'numeric' };
        return fecha.toLocaleDateString('es-ES', opcionesDeFormato);
    }
    function obtenerHora(fechaStr) {
        const fecha = new Date(fechaStr);
        const hora = fecha.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
        return hora;
    }

    const extract = (stringt) => {
        const startIndex = pathname.indexOf(stringt);
        if (startIndex !== -1) {
            // Encontrado, buscar el índice del ampersand siguiente
            const endIndex = pathname.indexOf("&", startIndex);

            if (endIndex !== -1) {
                // Extraer el valor de dateOfDeparture
                const dateOfDeparture = pathname.slice(startIndex + stringt.length, endIndex);

                return dateOfDeparture;
            }
        }
    }

    return (
        <>
            <div className='content-detail-buy'>
                <SvgIcon name={iconList.IconPlane} color={"#004274"} size={30} />
                <h2>
                    {
                        `${fligth.itinerary[0].from} - ${fligth.itinerary[0].to}`
                    }
                </h2>
                <p className='p-fligth-buy'>
                    {
                        fligth.itinerary.length === 1 ? `Solo ida, ${fligth.passengersSelected.adult} adultos, ${fligth.passengersSelected.boy === 0 ? "" : `${fligth.passengersSelected.boy} niños`},${fligth.passengersSelected.baby === 0 ? "" : `${fligth.passengersSelected.baby} bebes`}` : `Ida y vuelta, ${fligth.passengersSelected.adult} adultos, ${fligth.passengersSelected.boy === 0 ? "" : `${fligth.passengersSelected.boy} niños`},${fligth.passengersSelected.baby === 0 ? "" : `${fligth.passengersSelected.baby} bebes`}`
                    }
                </p>
                <section className='contain-round-trip-buy-pack'>
                    <p>Ida</p>
                    <h3 className='date-departure-buy'>{formatearFecha(fligth.itinerary[0].dateDeparture)}</h3>
                    <div className='content-depar-arr'>
                        <section>
                            <p>{fligth.itinerary[0].from}</p>
                            <h3>{obtenerHora(fligth.itinerary[0].dateDeparture)}</h3>
                        </section>
                        <section>
                            <p>{fligth.itinerary[0].to}</p>
                            <h3>{obtenerHora(fligth.itinerary[0].dateArrival)}</h3>
                        </section>
                    </div>
                </section>
                {
                    fligth.itinerary.length >= 2 &&

                    <section className='contain-round-trip-buy-pack' style={{ borderTop: "1px solid rgb(176, 176, 176)", paddingTop: "1rem" }}>
                        <p>Vuelta</p>
                        <h3 className='date-departure-buy'>{formatearFecha(fligth.itinerary[1].dateDeparture)}</h3>
                        <div className='content-depar-arr'>
                            <section>
                                <p>{fligth.itinerary[1].from}</p>
                                <h3>{obtenerHora(fligth.itinerary[1].dateDeparture)}</h3>
                            </section>
                            <section>
                                <p>{fligth.itinerary[1].to}</p>
                                <h3>{obtenerHora(fligth.itinerary[1].dateArrival)}</h3>
                            </section>
                        </div>
                    </section>
                }
            </div>
            <div className='content-detail-buy' style={{ borderTop: "1px solid rgb(176, 176, 176)", paddingTop: "1rem" }}>
                <SvgIcon name={iconList.IconHotel} color={"#004274"} size={30} />
                {
                    hab.map((item,index) => {
                        return (
                            <h2 key={index}>
                                {item?.name}
                            </h2>
                        )
                    })
                }

                <div className='content-depar-arr'>
                    <section>
                        <p>Check in</p>
                        <h3>{extract("departure=")}</h3>
                    </section>
                    <section>
                        <p>Check out</p>
                        <h3>{extract("arrival=")}</h3>
                    </section>
                </div>
            </div>
        </>

    )
}

export default DetailBuy