import React, { useState } from 'react'
import RoomCard from './RoomCard/RoomCard'

import './Assets/styles.css'
import { useTranslation } from 'react-i18next'

const HotelRoomsCard = ({ cardRef, roomsSelected, setRoomsSelected, setCardRooms }) => {

  const [t, i18n] = useTranslation("global")
  const [activeFunctionRemove, setactiveFunctionRemove] = useState(false)
  const [activeFunctionAdd, setactiveFunctionAdd] = useState(false)

  const deletRoom = (index) => {
    const value = [...roomsSelected]
    value.splice(index, 1)
    setRoomsSelected(value)
  }

  const addAdult = (index) => {
    setRoomsSelected(roomsSelected.map((room, newIndex) => {
      if(newIndex === index) {
        return {...room, adult: room.adult + 1}
      } else {
        return room
      }
    }))
  }
  const subAdult = (index) => {
    setRoomsSelected(roomsSelected.map((room, newIndex) => {
      if(newIndex === index) {
        if(room.adult === 1) {
          return {...room, adult: 1}
        }
        return {...room, adult: room.adult - 1}
      } else {
        return room
      }
    }))
  }

  

 


  return (
    <div className='hotelRoomsCard' ref={cardRef}>
      {roomsSelected.map((element, index) => {
        return (
          <RoomCard key={index} index={index} deletRoom={deletRoom} addAdult={addAdult} {...element}  subAdult={subAdult}  data={element} roomSelected={roomsSelected} setRoomSelected={setRoomsSelected} />
        )
      })}
      <div className='hotelRoomsCard_'>
        <div className='hotelRoomsCard_addRoom'> 
          <p onClick={() => setRoomsSelected([...roomsSelected, { adult: 1, child: 0, age:[]}])}>{t('hotel.search_engine.add_room')}</p>
          <button className='hotelRoomsCard_addRoomButton' onClick={() => setCardRooms(false)}>{t('fligth.search_fligth.continue')}</button>
        </div>
      </div>
    </div>
  )
}

export default HotelRoomsCard