import React, { useEffect, useState } from "react";
import axios from "axios";
import Find from "../../ui/views/share/components/Find/Find";
import CarouselSevices, { servicesTravel } from "../../ui/views/share/components/CarouselServices/CarouselSevices";

import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import "./Assets/styles.css";
import PackageTourismPassenger from "./PackageTourismPassenger/PackageTourismPassenger";
import AnyQuestions from "../../ui/views/home/components/anyQuestion";

const PackageDataPassenger = () => {
    const location = useLocation();
    const dataString = decodeURIComponent(location.pathname.split("/")[4]);
    const data = JSON.parse(dataString);

    const [packaged, setPackaged] = useState({});
    const [loading, setLoading] = useState(true);
    const [sendData, setSendData] = useState(false);
    const [dataForm, setDataForm]   = useState([]);
    const [formData, setFormData] = useState([]);
    const [formValidations, setFormValidations] = useState(Array(data.passengerCount).fill(false));

    const navigate = useNavigate();

    const findServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL_API_AIOP_PRT}api/packages/${data.packId}`);
            console.log(response.data)
            setPackaged(response.data);
            const imageUrls = response.data.pack.images.map(image => image.url);
        } catch (error) {
            console.error("Error en la llamada a la API de planes", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        findServices();
        window.scrollTo(0, 0);
    }, []);

    const handleFormChange = (index, data) => {
        const values = [...formData];
        values[index] = { ...values[index], ...data };
        setFormData(values);
    };

    function replicateForms() {
        let forms = [];
        for (let i = 0; i < data.passengerCount; i++) {
            forms.push(
                <PackageTourismPassenger
                    key={i}
                    index={i}
                    setSend={setSendData}
                    handleFormChange={handleFormChange} 
                    long={data.passengerCount}
                    setFormValidations={setFormValidations}
                    formValidations={formValidations}
                />
            );
        }
        return forms;
    }

    const SendDataForm = async () => {
        if (formValidations.some(isValid => !isValid)) {
            return;
        }

        const dataId = {
            idResponse: data.idResponse,
            packId: data.packId,
            passengerCount: data.passengerCount
            
        }

        const dataIdEncode = encodeURIComponent(JSON.stringify(dataId))

        try{
            const response = await axios.post(`${process.env.REACT_APP_URL_API_AIOP_STG}api/pack/form/passengers/${data.idResponse}`, formData);

            console.log(response.data);

            if(response.status === 200){
                navigate(`/widget/packages/pay/${dataIdEncode}`)
            }
        }catch(error){
            console.error("Error en la llamada a la API de planes", error);
        }
    }

    return (
        <div>
            {
                loading ? (
                    <div className='container-loading-packages'>
                        <CircularProgress />
                    </div>
                ):(
                    <div>
                        <div>
                            <Find />
                            <CarouselSevices current={servicesTravel[12].title}/>
                        </div>
                        <div className="container-all-passengers-tourism">
                            <div className='container-header-package-tourism'>
                                <p>{ packaged.pack.name }</p>
                            </div>
                            <div className='container-data-passengers'>
                                <div className='container-form-passengers-sura'>
                                    <div>
                                        <p style={{ color: "#004274", fontWeight: "700", fontSize: "18px" }}>Información de los viajeros</p>
                                    </div>
                                    { replicateForms() }
                                </div>
                                <div className='container-data-description-tourism'>
                                    <div className="list-package-tourism">
                                        <div>
                                            <p>Fecha de salida:</p>
                                        </div>
                                        <div>
                                            <p>{ packaged.pack.start_date }</p>
                                        </div>
                                    </div>
                                    <div className="list-package-tourism">
                                        <div>
                                            <p>Destino:</p>
                                        </div>
                                        <div>
                                            <p>{ packaged.pack.destination }</p>
                                        </div>
                                    </div>
                                    <div className="list-package-tourism">
                                        <div>
                                            <p>Precio:</p>
                                        </div>
                                        <div>
                                            <p>{ parseInt(packaged.pack.price).toLocaleString() } { packaged.pack.currency }</p>
                                        </div>
                                    </div>
                                    <div className="list-package-tourism">
                                        <div>
                                            <p>Viajeros:</p>
                                        </div>
                                        <div>
                                            <p>{ data.passengerCount }</p>
                                        </div>
                                    </div>
                                    <div className="btn-pay-package-tourism">
                                        <button onClick={SendDataForm}>Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <AnyQuestions title="¿Tienes alguna duda?"/>   
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default PackageDataPassenger;