
import './assets/styles.css'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { motion, useDragControls } from 'framer-motion'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;

const MotionBox = styled(motion.div)`
    width: 400vw;
  `



const NewsWellezy = () => {

    const constraintsRef = useRef(null)
    const [t,i18n] = useTranslation("global")

    const news = [
        {
            img: "https://www.semana.com/resizer/8tovOrQy5xi5LilM3LrmslxUpQo=/1280x720/smart/filters:format(jpg):quality(80)/cloudfront-us-east-1.images.arcpublishing.com/semana/7T25MNDXIBANXKU6MUAHX6CLQM.jpg",
            fecha: "11:46 AM",
            text: t("browse_home.news_wellezy.firts_new"),
            link: "https://www.semana.com/economia/empresas/articulo/la-aplicacion-que-busca-impulsar-el-turismo-en-salud-dentro-de-colombia/202339/"
        },
        {
            img: "https://i0.wp.com/folou.co/wp-content/uploads/2023/02/Odolontologia.jpg?w=1200&ssl=1",
            fecha: "11:46 AM",
            text: t("browse_home.news_wellezy.second_new"),
            link: "https://folou.co/mundo/la-plataforma-que-impulsa-el-turismo-de-salud-en-colombia/"
        },
        {
            img: "https://www.portafolio.co/files/article_content/uploads/2023/03/11/640c0c1c3523a.jpeg",
            fecha: "12:07 PM",
            text: t("browse_home.news_wellezy.three_new"),
            link: "https://www.portafolio.co/amp/negocios/empresas/wezelly-la-empresa-que-apuesta-por-ser-agencia-de-viajes-de-salud-579756"
        },
        {
            img: "https://www.portafolio.co/files/article_content/uploads/2023/03/11/640ce8ae01886.jpeg",
            fecha: "03:57 PM",
            text: t("browse_home.news_wellezy.for_new"),
            link: "https://www.portafolio.co/amp/economia/finanzas/turismo-colombia-recibio-a-mas-de-cuatro-millones-de-turistas-en-2022-579764"
        }
    ]




    return (
        <div className='contain-new-wellezy'>
            <div className='content-btn-news-see'>
                 <h3 className='title-find-wellezy'>{t("browse_home.news_wellezy.last_news")}</h3>
                 {/* <NavLink to={"/wellezy-news"}>Ver mas</NavLink> */}
            </div>
           
            <p className='subtitle-find-wellezy'>{t("browse_home.news_wellezy.subtitle_new")}</p>
            <div className="contain-carousel-news">
                <MotionConstraints className='slider_container' ref={constraintsRef}>
                    <MotionBox className='slider_' style={{justifyContent:"flex-start",gap:"2rem"}} drag='x' dragConstraints={constraintsRef}  >
                        {
                            news.map((topic,key) => {
                                return (
                                    <motion.div className='div_homeTurism' key={key}>
                                        <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism' > 
                                            <a href={topic.link}>
                                                <div className='contain-new' >
                                                    <img src={topic.img} alt={topic.text} />
                                                    <p className='date-new-wellezy'><span>Medellin</span> - {topic.fecha}</p>
                                                    <p className='text-new-wellezy'>{topic.text}</p>
                                                </div>
                                            </a>
                                        </Grid>
                                    </motion.div>
                                )
                            })
                        }

                    </MotionBox>
                </MotionConstraints>
            </div>
        </div>
    )
}

export default NewsWellezy