import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import './styles.css';
import ModalWishToInclude from '../../../modalWishToInclude';
import UpsellChoose from '../../../upsellChoose';
import ListFlights from '../listFlights';
import ContainerPriceDinamic from '../../../containerPriceDinamic';
function ContainerFlights(
  {
    nameOrigin,
    nameDestiny,
    isRoundTrip,
    Seg1,
    Seg2,
    recommendation,
    allPassengersSelected,
  }
) {
  const navigate = useNavigate();
  const optionsRef = useRef(null);
  const urlpath = useLocation().pathname
  const [outwardJourney, setOutwardJourney] = useState(null);//hora viaje de ida
  const [returnJourney, setReturnJourney] = useState(null);//hora viaje retorno
  const [openModalWish, setOpenModalWish] = useState(false);
  const [dataCabin, setDataCabin] = useState();
  const [dataComponents, setDataComponents] = useState();
  const [getUpsell, setGetUpsell] = useState(false);
  const [priceRecommendation, setPriceRecommendation] = useState({ fareAmount: 0, taxAmount: 0, totalAmount: 0 });
  const [priceUpsell, setPriceUpsell] = useState({ fareAmount: 0, taxAmount: 0, totalAmount: 0 });



  function getPrice() {
    const totalWithTax = parseInt(recommendation?.recPriceInfo?.monetaryDetail[0].amount);
    const total = parseInt(recommendation?.recPriceInfo?.monetaryDetail[1].amount);
    const onlyTax = totalWithTax - total;
    setPriceRecommendation({ fareAmount: total, taxAmount: onlyTax, totalAmount: totalWithTax });
  }


  function getPricesperPAssengerType() {
    const all = recommendation?.paxFareProduct;
    const response = []
    for (const a in all) {
      const obj = all[a]
      const data = {
        type: obj?.paxReference?.ptc,
        price: parseInt(obj?.paxFareDetail?.totalFareAmount),
        priceTaxt: parseInt(obj?.paxFareDetail?.totalTaxAmount),
        priceWithoutTaxt: parseInt(obj?.paxFareDetail?.totalFareAmount - obj?.paxFareDetail?.totalTaxAmount)
      }
      response.push(data)
    }
    return response;
  }


  function getItinerary(isFromUpsell) {

    let intinerary;
    const response = [];
    const scales = []
    const bigArray = [outwardJourney];
    if (isRoundTrip) {
      bigArray.push(returnJourney);
    }

    function getIntinerary(data, number, pF = 0, pS = 0) {
      const dateDeparture = data?.productDateTime.dateOfDeparture;
      const timeDeparture = data?.productDateTime.timeOfDeparture;
      const dateArrival = data?.productDateTime.dateOfArrival;
      const timeArrival = data?.productDateTime.timeOfArrival;
      const obj = {
        group: parseInt(number),
        from: data?.location[0]?.locationId,
        to: data?.location[1]?.locationId,
        bookingClass: recommendation?.paxFareProduct[0]?.fareDetails[pF]?.groupOfFares[pS]?.productInformation?.cabinProduct?.rbd,
        dateDeparture: `${dateDeparture}T${timeDeparture}:00+0000`,
        dateArrival: `${dateArrival}T${timeArrival}:00+0000`,
        company: data?.companyId?.marketingCarrier,
        flightNumber: data?.flightOrtrainNumber,
      }
      if (isFromUpsell) {
        obj.family = dataComponents[pF]?.fareFamilyDetails?.fareFamilyname;
      }
      return obj;
    }


    for (let i = 0; i < bigArray.length; i++) {
      const vuelo = bigArray[i];
      if (vuelo?.segments.length > 1) {
        intinerary = getIntinerary(vuelo?.segments[0], i + 1, i, 0);
        const scalesChild = vuelo?.segments;
        for (const s in scalesChild) {
          const child = scalesChild[s]
          if (s > 0) {
            const number = (parseInt(i) + parseInt(s)) + 1
            const childItinerary = getIntinerary(child, number, i, i)
            scales.push(childItinerary)
          }
        }
        intinerary.scale = scales;
        response.push(intinerary);
      } else {
        intinerary = getIntinerary(vuelo?.segments[0], i + 1, i, 0)
        response.push(intinerary);
      }
    }



    return response;
  }


  function getCompany() {
    let response
    const paxFareDetail = recommendation?.paxFareProduct[0]?.paxFareDetail;
    console.log(paxFareDetail)
    if (Array.isArray(paxFareDetail?.codeShareDetails)) {
      response = paxFareDetail?.codeShareDetails[0]?.company;
    }
    else {
      response = paxFareDetail?.codeShareDetails?.company;
    }
    console.log(response)
    return response;
  }


  function continueBuy(type = false) {
    
    const isFromUpsell = type;
    setOpenModalWish(false);
    const body = document.querySelector('body');
    body.style.overflow = '';

    const priceFrom = isFromUpsell ? priceUpsell : priceRecommendation;
    const stateProps = {
      passengersSelected: allPassengersSelected,
      company: getCompany(),
      priceTotal: priceFrom?.totalAmount,
      priceTaxes: priceFrom?.taxAmount,
      priceWithOutTaxes: priceFrom?.fareAmount,
      prices: getPricesperPAssengerType(),
      bag: false,
      fare: "RP",
      itinerary: getItinerary(isFromUpsell),
    }

    console.log("-------> stateProps: ", stateProps)

    const pathname = window.location.pathname.split('/')
    const isIframe = pathname.includes('iframe')
    const route = isIframe ? '/iframe/flightPassengersForm' : '/flightPassengersForm'
    if(urlpath.includes("/packages")){
      localStorage.setItem("flightSelected",JSON.stringify(stateProps))
      navigate("/packages/pay")
    }else{
      navigate(`${route}`, { state: stateProps });
    }
    
    // flightsCheckout
  }







  function chooseUpsell() {
    setOpenModalWish(false);
    setGetUpsell(true)
  }


  const closeGetandOpen = () => {
    setGetUpsell(false)
  }


  function toBuy(isFromUpsell) {
    if (!isFromUpsell) {
      setOpenModalWish(true);
    }
    else {
      continueBuy(true)
    }
  }

  const checkOptionsVisibility = () => {
    const rect = optionsRef.current.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
    if (!isVisible) {
      optionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    openModalWish ? body.style.overflow = 'hidden' : body.style.overflow = '';
  }, [openModalWish])

  useEffect(() => {
    if (getUpsell) {
      optionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      checkOptionsVisibility();
    };
  }, [getUpsell]);

  useEffect(() => {
    getPrice();
  }, []);

  return (
    <div className='cardFather'>
      <ModalWishToInclude
        show={openModalWish}
        close={setOpenModalWish}
        continueBuy={continueBuy}
        chooseUpsell={chooseUpsell}
      />
      <div className='column'>
        <div className='row'>
          <ListFlights
            isRoundTrip={isRoundTrip}
            Seg1={Seg1}
            Seg2={Seg2}
            nameOrigin={nameOrigin}
            nameDestiny={nameDestiny}
            recommendation={recommendation}
            outwardJourney={outwardJourney}
            returnJourney={returnJourney}
            setOutwardJourney={setOutwardJourney}
            setReturnJourney={setReturnJourney}

          />
          <ContainerPriceDinamic
            price={priceRecommendation}
            toBuy={toBuy}
          />
        </div>

        <div ref={optionsRef}>
          <UpsellChoose
            getandOpen={getUpsell}
            closeGetandOpen={closeGetandOpen}
            isRoundTrip={isRoundTrip}
            outwardJourney={outwardJourney}
            returnJourney={returnJourney}
            recommendation={recommendation}
            toBuy={toBuy}
            setDataCabin={setDataCabin}
            setDataComponents={setDataComponents}
            allPassengersSelected={allPassengersSelected}
            setPriceUpsell={setPriceUpsell}
          />
        </div>
      </div>
    </div>
  )
}
export default ContainerFlights;
