import { useRef, useState, useEffect } from 'react'
import './styles.css'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from '@mui/material'
import { useTranslation } from "react-i18next"
import { iconList } from '../iconSVG';
import ModelCarousel from './ModelCarousel.jsx';
import SvgIcon from '../iconSVG';
import { get, keys } from '../../../../localStorage';
import { widgetsList } from '../../../../../infra/constants/app';

const MotionConstraints = styled(motion.div)`overflow-x: hidden;`;
const MotionBox = styled(motion.div)`width: 100vw;`;

export const { servicesTravel } = ModelCarousel();

const CarouselSevices = ({ current = "" }) => {
  const [t] = useTranslation("global");
  const [showOnlyThis, setShowOnlyThis] = useState([])

  function addWidgetURL(url) {
    const isWidget = widgetsList.find((w) => w.path === window.location.pathname);
    return isWidget ? `/widget${url}` : url;
  }

  const servicesTravel = [
    {
      id: 'fligths',
      title: t("carousel_services.fligth"),
      img: iconList.IconPlane,
      route: addWidgetURL('/fligths'),
    },
    {
      id: 'hotel',
      title: t("carousel_services.hotel"),
      img: iconList.IconHotel,
      route: addWidgetURL('/hotel'),
    },
    {
      id: 'tours',
      title: t("carousel_services.tours"),
      img: iconList.IconBag,
      route: addWidgetURL('/tours'),
    },
    {
      id: 'transport',
      title: t("carousel_services.transfers"),
      img: iconList.IconTaxi,
      route: addWidgetURL('/transport'),
    },
    {
      id: 'carRentals',
      title: t("carousel_services.car_rental"),
      img: iconList.IconRentaCar,
      route: addWidgetURL('/carRentals'),
    },
    {
      id: 'packages',
      title: t("carousel_services.packages"),
      img: iconList.IconTravel,
      route: addWidgetURL('/packages'),
    },
    {
      id: 'travelSecure',
      title: t("carousel_services.travel_secure"),
      img: iconList.IconSecureTravel,
      route: addWidgetURL('/travelSecure'),
    },
    {
      id: 'Specialists',
      title: t("carousel_services.specialist"),
      img: iconList.IconDoctor,
      route: addWidgetURL('/Specialists'),
    },
    {
      id: 'clinics',
      title: t("carousel_services.clinics"),
      img: iconList.IconHospital,
      route: addWidgetURL('/Clinics'),
    },
    {
      id: 'healthWellness',
      title: t("carousel_services.health_wellness"),
      img: iconList.IconBeautiful,
      route: addWidgetURL('/healthWellness'),
    },
    {
      id: 'ambulance',
      title: t("carousel_services.ambulance"),
      img: iconList.IconAmbulance,
      route: addWidgetURL('/ambulance'),
    },
    {
      id: 'pharmacy',
      title: t("carousel_services.pharmacy"),
      img: iconList.IconMedicaments,
      route: addWidgetURL('/Pharmacy'),
    },
    {
      id: 'cosmetics',
      title: t("carousel_services.cosmetics"),
      img: iconList.IconDoctor,
      route: addWidgetURL('/Cosmetics'),
    },
    {
      id: 'recoveryHouse',
      title: t("carousel_services.recovery_house"),
      img: iconList.IconRestHouse,
      route: addWidgetURL('/RecoveryHouse'),
    },
  ]

  const constraintsRef = useRef(null);

  const servicesTravelList = showOnlyThis && showOnlyThis.length > 0
    ? servicesTravel.filter((route) => showOnlyThis.includes(route.id))
    : servicesTravel;

  async function getServicesList() {
    try {
      const response = await get(keys?.servicesMenu);
      setShowOnlyThis(response)
    } catch (error) {
      console.log("Error in getServicesList(): ", error)
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getServicesList();
    }, 100);
  }, [])

  return (
    <section className='services-travel'>
      <MotionConstraints className='slider_container' ref={constraintsRef}>
        <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}>
          {
            servicesTravelList.map((item, key) => {
              const color = current === item.title ? "#5BAFEE" : '#004274';
              return (
                <motion.div className='div_homeTurism' key={key}>
                  <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                    <ul >
                      <li className='list-carrousel-verticales'>
                        <Link to={item.route} >
                          <SvgIcon color={color} size={70} name={item?.img} />
                          <p >{item.title}</p>
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                </motion.div>
              )
            })
          }

        </MotionBox>
      </MotionConstraints>


    </section>
  )
}


export default CarouselSevices