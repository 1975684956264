import useHeaderSession from '../../../../viewModels/session/header.session.viewModel';
import SvgIcon, { iconList } from '../iconSVG';
import './styles.css';
import {useTranslation} from "react-i18next"

function HeaderSession() {
  const [t,i18n] = useTranslation("global")
  const {
    isOpen,
    setIsOpen,
    cardRef,
    optionList,
    userDetail,
  } = useHeaderSession();

  return (
    <div className='headerSessionContainer' ref={cardRef}>
      <div className={`headerSessionIcon ${isOpen && 'headerSessionIconOpen'}`} onClick={() => setIsOpen(!isOpen)}>
        {userDetail && userDetail?.profile_photo_url &&
          <img src={userDetail?.profile_photo_url} />
        }
        {!userDetail &&
          <SvgIcon name={iconList.IconUserCircleOutLine} size={40} color={isOpen ? '#104172' : '#FFFFFF'} />
        }
      </div>
      {isOpen &&
        <div className='headerSessionMenu' >
          {
            userDetail && userDetail?.name &&
            <div className='greetingContainer'>
              <p className='greetingText'><span className='greeting'>{t("browse_home.nav_bar.join_club.hello")},</span> {userDetail?.name}!</p>
            </div>
          }
          {optionList.map((index, key) => {
            return (
              <div key={key} className={`headerSessionMenuOpt`}
                onClick={() => index.action && index?.action()}
              >
                <a href={index?.url && index?.url}>
                  <p className='headerSessionMenuOptText'>{index.text}</p>
                </a>
              </div>

            )
          })}
        </div>
      }
    </div>
  )
}

export default HeaderSession;