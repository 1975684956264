import SvgIcon from '../../../share/components/iconSVG';
import './styles.css';
const DinamicTablet = ({ header = [], datos = [], actions = [] }) => {
  const renderActions = (data) => {
    return (
      <div className='wrapperActions'>{actions && actions.map((act, key) => {
        return (
          <div key={key} onClick={() => act.action(data)}>
            <SvgIcon name={act?.icon} color={act?.color} size={act?.size} />
            {act?.label !== '' && <span>{act?.label}</span>}
          </div>
        )
      })
      }
      </div>
    )
  }
  return (
    <>
      <table className='dinamicTablet'>
        <thead>
          <tr>
            {header && header.map((h) => {
              return (
                <th key={h.key}>{h.value}</th>
              )
            })}
          </tr>
        </thead>


        <tbody>
          {datos && datos.map((fila, index) => (
            <tr key={index}>
              {header.map((c) => {
                return (
                  <td key={c?.key}>
                    {c.key === 'actions' ? renderActions(fila) : fila[c.key]}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {datos.length === 0 &&
        <div className='emptyWrap'>
          <p className='emptyText'>Empty!</p>
        </div>
      }

    </>
  )
}

export default DinamicTablet