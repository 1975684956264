import React, { useEffect, useRef, useState } from "react";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import Find from "../../share/components/Find/Find";
import CarouselSevices from "../../share/components/CarouselServices/CarouselSevices";
import currencyFormatter from "../../../../helpers";
import FormSuggestions from "../formAndSuggestions/form/FormSuggestions";
import styled from "styled-components";
import BestExperiences from "../BestExperiences/BestExperiences";
import AnyQuestions from "../../home/components/anyQuestion";
import imgSmall from "../../../assets/pictures/smallImageDetail.png";
import largeImg from "../../../assets/pictures/largeImageDetail.png";

import "./styles.css";

import { motion } from "framer-motion";
import { CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Draggable } from "leaflet";
import { ReactComponent as IconCalendar } from "./asset/svg/IconCalendar.svg";
import { ReactComponent as IconWhatsApp } from "./asset/svg/IconWhatsApp.svg";
import { ReactComponent as IconClock } from "./asset/svg/IconClock.svg";
import { ReactComponent as IconMoney } from "./asset/svg/IconMoney.svg";
import { ReactComponent as IconDoctor } from "./asset/svg/IconDoctor.svg";
import { ReactComponent as IconPlane } from "./asset/svg/IconPlane.svg";
import { ReactComponent as IconStructure } from "./asset/svg/IconStructure.svg";
import { ReactComponent as IconCar } from "./asset/svg/IconCar.svg";
import { ReactComponent as IconAvaliableUntis } from "./asset/svg/IconAvaliableUntis.svg";
import axios from "axios";

const DetailPackhealth = () => {
  const constraintsRef = useRef(null);
  const motionBoxRef = useRef(null);
  const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;
  const MotionBox = styled(motion.div)`
    width: 100vw;
  `;
  const [t] = useTranslation("global");

  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedButtonTravel, setSelectedButtonTravel] = useState(1);
  const [packageMedic, setPackageMedic] = useState();

  const location = useLocation();
  const data = location.state ? location.state.data : null;

  const navigate = useNavigate();

  const dataString = decodeURIComponent(location.pathname.split("/")[3]);

  useEffect(() => {
    {
      getPackageMedic();
    }
  }, []);

  const getPackageMedic = () => {
    axios
      .get(`${process.env.REACT_APP_URL_API_AIOP_PRT}api/medical/${dataString}`)
      .then((response) => {
        console.log(response.data.city);
        setPackageMedic(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goReservation = () => {
    navigate(`/TourismHealth/reservation/${data?.name}`, {
      state: {
        name: data?.name,
        categories: data?.supplier,
        urlImage: data?.image,
        stars: null,
        id: data?.id,
      },
    });
  };

  const allIcons = [
    {
      icon: <IconWhatsApp />,
    },
    {
      icon: <SvgIcon color={"#004274"} name={iconList.IconEmail} size={50} />,
    },
    {
      icon: <SvgIcon color={"#004274"} name={iconList.IconCadena} size={50} />,
    },
    {
      icon: <SvgIcon color={"#004274"} name={iconList.IconTwitter} size={50} />,
    },
  ];

  const allButtons = [
    {
      id: 1,
      text: "Ventajas",
    },
    {
      id: 2,
      text: "Descripción",
    },
    {
      id: 3,
      text: "Inclusiones",
    },
    {
      id: 4,
      text: "Exclusiones",
    },
    {
      id: 5,
      text: "¿Porque Elegirnos?",
    },
    {
      id: 6,
      text: "Resumen",
    },
  ];

  const allButtonsSections = [
    data?.flight_reserves?.length > 0 && {
      id: 1,
      text: "Vuelos",
    },
    data?.hotel_reserves?.length > 0 && {
      id: 2,
      text: "Hoteles",
    },
    data?.traslade_reserves?.length > 0 && {
      // Cambia 'transfer_reserves' a 'traslade_reserves'
      id: 3,
      text: "Traslados",
    },
    data?.tour_reserves?.length > 0 && {
      id: 4,
      text: "Tours",
    },
    data?.assistance_reserves?.length > 0 && {
      // Cambia 'medical_assistances' a 'assistance_reserves'
      id: 5,
      text: "Asistencia medica",
    },
  ].filter(Boolean);

  return (
    <div>
      <Find />
      <CarouselSevices />
      <section className="content-detail-pack-health">
        <section className="layout-result-filter">
          <div className="all-result-search-tourism" style={{ width: "70%" }}>
            <div className="content-info-detail-health">
              <section className="first-section-detail-health">
                <img
                  src={`${process.env.REACT_APP_URL_API_AIOP_PRT}storage/medic_pack/${packageMedic?.image}`}
                  alt=""
                />
                <h2>{packageMedic?.name}</h2>
              </section>
              <section className="second-section-detail-health">
                <div style={{ display: "flex" }}>
                  <ul style={{ width: "50%" }}>
                    <li className="data-list">
                      <SvgIcon
                        color={"#004274"}
                        name={iconList.IconBariatica}
                        size={20}
                      />
                      <p>
                        Recomendacion de edad: {packageMedic?.suitable_ages}
                      </p>
                    </li>
                    <li className="data-list">
                      <SvgIcon
                        color={"#004274"}
                        name={iconList.IconBariatica}
                        size={20}
                      />
                      <p>Procedimiento: {data?.subcategory?.name}</p>
                    </li>
                    <li className="data-list">
                      <SvgIcon
                        color={"#004274"}
                        name={iconList.IconPinMap2}
                        size={20}
                      />
                      <p>
                        Lugar de salida:{" "}
                        {packageMedic?.departure_place === "medellin update" ||
                        packageMedic?.departure_place == "Medellin update"
                          ? "Medellín"
                          : packageMedic?.departure_place}
                      </p>
                    </li>
                    {/* <li className="data-list">
                      <SvgIcon
                        color={"#004274"}
                        name={iconList.IconPinMap2}
                        size={20}
                      />
                      <p>Ubicación: {data?.localization}</p>
                    </li> */}
                    <li className="data-list">
                      <IconDoctor />
                      <p>Doctor: {data?.user?.name}</p>
                    </li>
                    <li className="data-list">
                      <SvgIcon
                        color={"#004274"}
                        name={iconList.IconPinMap2}
                        size={20}
                      />
                      <p>Ciudad: {data?.city?.name}</p>
                    </li>
                    <li className="data-list">
                      <IconDoctor />
                      <p>Agencia: {data?.admin?.name}</p>
                    </li>
                  </ul>
                  <ul style={{ width: "50%" }}>
                    <li className="data-list">
                      <IconMoney />
                      <p>
                        Precio del paquete desde:{" "}
                        {packageMedic?.price.toLocaleString("de-DE")}{" "}
                        {packageMedic?.currency}
                      </p>
                    </li>
                    <li className="data-list">
                      <IconStructure />
                      <p>Centro asociado: {packageMedic?.supplier}</p>
                    </li>
                    <li className="data-list">
                      <IconCalendar />
                      <p>
                        Desde: {packageMedic?.trip_from} hasta{" "}
                        {packageMedic?.trip_up}
                      </p>
                    </li>
                    <li className="data-list">
                      <IconCalendar />
                      <p>Duración del paquete: {packageMedic?.day} días</p>
                    </li>
                    <li className="data-list">
                      <IconCalendar />
                      <p>Paquete valido hasta: {packageMedic?.date_validity}</p>
                    </li>
                    <li className="data-list">
                      <IconAvaliableUntis />
                      <p>
                        Unidades disponibles: {packageMedic?.availables_units}
                      </p>
                    </li>
                  </ul>
                </div>
                <p
                  style={{
                    color: "#004274",
                    fontWeight: "700",
                    fontSize: "14px",
                    marginTop: "30px",
                  }}
                >
                  Procedimiento de {data?.subcategory?.name} a un costo
                  asequible
                </p>
                <div>
                  <img
                    src={`${process.env.REACT_APP_URL_API_AIOP_PRT}storage/medic_pack/${packageMedic?.image}`}
                    alt=""
                  />
                </div>
                {/* <div>
                  <div
                    style={{
                      color: "#004274",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Resumen
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.extract }}
                    style={{ marginBlock: "10px", fontSize: "12px" }}
                  ></div>
                  <p
                    style={{
                      color: "#004274",
                      fontWeight: "700",
                      fontSize: "14px",
                      marginTop: "30px",
                    }}
                  >
                    Descripción
                  </p>
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                    style={{ marginBlock: "10px", fontSize: "12px" }}
                  ></div>
                </div> */}
              </section>
              <section className="three-section-detail-health">
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "900",
                    color: "#004274",
                    textAlign: "center",
                    marginBlock: "20px",
                  }}
                >
                  TABLA DE CONTENIDOS
                </p>
                <div
                  style={{
                    overflowX: "scroll",
                    display: "flex",
                    width: "100%",
                    display: "flex",
                    borderBottom: "5px solid #004274",
                    justifyContent: "space-between",
                    alignItems: "end",
                    padding: "0",
                    marginTop: "20px",
                  }}
                >
                  {allButtons?.map((item, index) => {
                    return (
                      <div className="div_homeTurism" key={index}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={3}
                          lg={2}
                          style={{ paddingInline: "10px" }}
                        >
                          <button
                            style={
                              selectedButton === item?.id
                                ? {
                                    backgroundColor: "#004274",
                                    color: "white",
                                    border: "1px solid #004274",
                                    height: "40px",
                                    paddingInline: "20px",
                                    borderRadius: "6px 6px 0px 0px",
                                  }
                                : {
                                    backgroundColor: "white",
                                    color: "#004274",
                                    border: "1px solid #D9D9D9",
                                    height: "35px",
                                    paddingInline: "20px",
                                    borderRadius: "6px",
                                    marginBottom: "5px",
                                  }
                            }
                            onClick={() => {
                              setSelectedButton(item?.id);
                            }}
                          >
                            {item?.text}
                          </button>
                        </Grid>
                      </div>
                    );
                  })}
                </div>
                <div className="content-info-selected-health">
                  {selectedButton === 1 && (
                    <>
                      <p
                        style={{
                          color: "#004274",
                          fontSize: "14px",
                          fontWeight: "900",
                        }}
                      >
                        {`Ventajas del paquete`}
                      </p>
                      <div
                        style={{ marginBlock: "10px", fontSize: "12px" }}
                        dangerouslySetInnerHTML={{
                          __html: packageMedic?.advantages,
                        }}
                      ></div>
                    </>
                  )}
                  {selectedButton === 2 && (
                    <>
                      <p
                        style={{
                          color: "#004274",
                          fontSize: "14px",
                          fontWeight: "900",
                        }}
                      >
                        {`Descripción del paquete`}
                      </p>
                      <div
                        style={{ marginBlock: "10px", fontSize: "12px" }}
                        dangerouslySetInnerHTML={{
                          __html: packageMedic?.description,
                        }}
                      ></div>
                    </>
                  )}
                  {selectedButton === 3 && (
                    <>
                      <p
                        style={{
                          color: "#004274",
                          fontSize: "14px",
                          fontWeight: "900",
                        }}
                      >
                        {`Inclusiones del paquete`}
                      </p>
                      <div
                        style={{ marginBlock: "10px", fontSize: "12px" }}
                        dangerouslySetInnerHTML={{
                          __html: packageMedic?.inclusions,
                        }}
                      ></div>
                    </>
                  )}
                  {selectedButton === 4 && (
                    <>
                      <p
                        style={{
                          color: "#004274",
                          fontSize: "14px",
                          fontWeight: "900",
                        }}
                      >
                        {`Exclusiones del paquete`}
                      </p>
                      <div
                        style={{ marginBlock: "10px", fontSize: "12px" }}
                        dangerouslySetInnerHTML={{
                          __html: packageMedic?.exclusions,
                        }}
                      ></div>
                    </>
                  )}
                  {selectedButton === 5 && (
                    <>
                      <p
                        style={{
                          color: "#004274",
                          fontSize: "14px",
                          fontWeight: "900",
                        }}
                      >
                        ¿Porque operarse con nosotros?
                      </p>
                      <div
                        style={{ marginBlock: "10px", fontSize: "12px" }}
                        dangerouslySetInnerHTML={{
                          __html: packageMedic?.why_operate,
                        }}
                      ></div>
                    </>
                  )}
                  {selectedButton === 6 && (
                    <>
                      <p
                        style={{
                          color: "#004274",
                          fontSize: "14px",
                          fontWeight: "900",
                        }}
                      >
                        {`Resumen del paquete`}
                      </p>
                      <div
                        style={{ marginBlock: "10px", fontSize: "12px" }}
                        dangerouslySetInnerHTML={{
                          __html: packageMedic?.extract,
                        }}
                      ></div>
                    </>
                  )}
                </div>
              </section>
              {data?.gallery_package_medics?.length > 0 && (
                <div style={{ padding: "2rem" }}>
                  <div style={{ marginBlock: "30px" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "900",
                        color: "#004274",
                      }}
                    >
                      Galeria
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        width: "100%",
                        gap: "15px",
                      }}
                    >
                      {packageMedic?.gallery_package_medics?.map(
                        (item, index) => (
                          <div style={{ width: "100%" }}>
                            <img
                              src={`${process.env.REACT_APP_URL_API_AIOP_PRT}storage/${item?.path}`}
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "13px",
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div
                    style={
                      packageMedic?.flight_reserves?.length < 1
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <div>
                      <div style={{ marginBlock: "50px" }}>
                        <p
                          style={{
                            fontSize: "24px",
                            fontWeight: "900",
                            color: "#004274",
                            textAlign: "center",
                          }}
                        >
                          DETALLES DEL PAQUETE
                        </p>
                      </div>
                      <div
                        style={{
                          overflowX: "scroll",
                          display: "flex",
                          width: "100%",
                          display: "flex",
                          borderBottom: "5px solid #004274",
                          alignItems: "end",
                          padding: "0",
                          marginTop: "20px",
                        }}
                      >
                        {allButtonsSections?.map((item, index) => {
                          return (
                            <div className="div_homeTurism" key={index}>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={3}
                                lg={2}
                                style={{ paddingInline: "10px" }}
                              >
                                <button
                                  style={
                                    selectedButtonTravel === item?.id
                                      ? {
                                          backgroundColor: "#004274",
                                          color: "white",
                                          border: "1px solid #004274",
                                          height: "40px",
                                          paddingInline: "20px",
                                          borderRadius: "6px 6px 0px 0px",
                                        }
                                      : {
                                          backgroundColor: "white",
                                          color: "#004274",
                                          border: "1px solid #D9D9D9",
                                          height: "35px",
                                          paddingInline: "20px",
                                          borderRadius: "6px",
                                          marginBottom: "5px",
                                        }
                                  }
                                  onClick={() => {
                                    setSelectedButtonTravel(item?.id);
                                  }}
                                >
                                  {item?.text}
                                </button>
                              </Grid>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="content-info-selected-health">
                      {selectedButtonTravel === 1 && (
                        <>
                          <div>
                            <p
                              style={{
                                color: "#004274",
                                fontSize: "14px",
                                fontWeight: "900",
                              }}
                            >
                              Detalle de vuelo
                            </p>
                          </div>
                          <div style={{ display: "flex" }}>
                            <ul
                              style={{
                                listStyleType: "none",
                                padding: 0,
                                width: "50%",
                              }}
                            >
                              <li className="data-list">
                                <IconPlane />
                                <p>
                                  Tipo de vuelo:{" "}
                                  {packageMedic?.flight_reserves[0]
                                    ?.flight_type === "one_way"
                                    ? "Solo ida"
                                    : "Ida y regreso"}
                                </p>
                              </li>
                              <li className="data-list">
                                <SvgIcon
                                  color={"#004274"}
                                  name={iconList.IconPinMap2}
                                  size={20}
                                />
                                <p>
                                  Ciudad de salida:{" "}
                                  {packageMedic?.flight_reserves[0]?.city_dep}
                                </p>
                              </li>
                              <li className="data-list">
                                <SvgIcon
                                  color={"#004274"}
                                  name={iconList.IconPinMap2}
                                  size={20}
                                />
                                <p>
                                  Ciudad de llegada:{" "}
                                  {packageMedic?.flight_reserves[0]?.city_arr}
                                </p>
                              </li>
                            </ul>
                            <ul style={{ width: "50%" }}>
                              <li className="data-list">
                                <IconPlane />
                                <p>
                                  Aerolinea:{" "}
                                  {
                                    packageMedic?.flight_reserves[0]
                                      ?.code_airline
                                  }
                                </p>
                              </li>
                              {packageMedic?.flight_reserves[0]?.flight_type ===
                              "one_way" ? (
                                <li className="data-list">
                                  <IconCalendar />
                                  <p>
                                    Fecha de ida:{" "}
                                    {
                                      packageMedic?.flight_reserves[0]
                                        ?.departure
                                    }
                                  </p>
                                </li>
                              ) : (
                                <>
                                  <li className="data-list">
                                    <IconCalendar />
                                    <p>
                                      Fecha de ida:{" "}
                                      {
                                        packageMedic?.flight_reserves[0]
                                          ?.departure
                                      }
                                    </p>
                                  </li>
                                  <li className="data-list">
                                    <IconCalendar />
                                    <p>
                                      Fecha de regreso:{" "}
                                      {
                                        packageMedic?.flight_reserves[0]
                                          ?.arrival
                                      }
                                    </p>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        </>
                      )}
                      {selectedButtonTravel === 2 && (
                        <>
                          <p
                            style={{
                              color: "#004274",
                              fontSize: "14px",
                              fontWeight: "900",
                            }}
                          >
                            Detalle de hoteles
                          </p>
                          <div style={{ display: "flex" }}>
                            <ul style={{ width: "50%" }}>
                              <li className="data-list">
                                <IconStructure />
                                <p>
                                  Nombre de hotel:{" "}
                                  {packageMedic?.hotel_reserves[0]?.hotel_name}
                                </p>
                              </li>
                              <li className="data-list">
                                <SvgIcon
                                  color={"#004274"}
                                  name={iconList.IconPinMap2}
                                  size={20}
                                />
                                <p>
                                  Ciudad de llegada:{" "}
                                  {packageMedic?.hotel_reserves[0]?.type_room}
                                </p>
                              </li>
                            </ul>
                            <ul style={{ width: "50%" }}>
                              <li className="data-list">
                                <IconCalendar />
                                <p>
                                  Fecha de llegada:{" "}
                                  {
                                    packageMedic?.hotel_reserves[0]
                                      ?.departure_hotel
                                  }
                                </p>
                              </li>
                              <li className="data-list">
                                <IconCalendar />
                                <p>
                                  Fecha de salida:{" "}
                                  {
                                    packageMedic?.hotel_reserves[0]
                                      ?.arrival_hotel
                                  }
                                </p>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                      {selectedButtonTravel === 3 && (
                        <>
                          <p
                            style={{
                              color: "#004274",
                              fontSize: "14px",
                              fontWeight: "900",
                            }}
                          >
                            Detalle de traslados
                          </p>
                          <div>
                            <ul>
                              <li className="data-list">
                                <IconCar />
                                {(() => {
                                  switch (
                                    packageMedic?.traslade_reserves[0]
                                      ?.service_type
                                  ) {
                                    case "origin_destination":
                                      return (
                                        <p>
                                          Tipo de traslado: Origen - destino
                                        </p>
                                      );
                                    case "from_the_airport":
                                      return (
                                        <p>
                                          Tipo de traslados: Desde el aeropuerto
                                        </p>
                                      );
                                    case "to_the_airport":
                                      return (
                                        <p>
                                          Tipo de traslados: Hacia el aeropuerto
                                        </p>
                                      );
                                    case "inter_hotel":
                                      return (
                                        <p>Tipo de traslados: Inter-hotel</p>
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                      {selectedButtonTravel === 4 && (
                        <>
                          <p
                            style={{
                              color: "#004274",
                              fontSize: "14px",
                              fontWeight: "900",
                            }}
                          >
                            Detalle del tour
                          </p>
                          <div>
                            <ul style={{ width: "50%" }}>
                              <li className="data-list">
                                <IconStructure />
                                <p>
                                  Nombre del tour:{" "}
                                  {packageMedic?.tour_reserves[0]?.name_tour}
                                </p>
                              </li>
                            </ul>
                            <div style={{ marginTop: "30px" }}>
                              <p
                                style={{
                                  color: "#004274",
                                  fontSize: "14px",
                                  fontWeight: "900",
                                }}
                              >
                                Descripción
                              </p>
                              <p style={{ marginTop: "30px" }}>
                                {
                                  packageMedic?.tour_reserves[0]
                                    ?.description_tour
                                }
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {selectedButtonTravel === 5 && (
                        <>
                          <p
                            style={{
                              color: "#004274",
                              fontSize: "14px",
                              fontWeight: "900",
                            }}
                          >
                            Detalle de la asistencia medica
                          </p>
                          <div>
                            <ul style={{ width: "50%" }}>
                              <li className="data-list">
                                <IconStructure />
                                <p>
                                  Nombre de la asistencia:{" "}
                                  {
                                    packageMedic?.assistance_reserves[0]
                                      ?.name_assistance
                                  }
                                </p>
                              </li>
                            </ul>
                            <div style={{ marginTop: "30px" }}>
                              <p
                                style={{
                                  color: "#004274",
                                  fontSize: "14px",
                                  fontWeight: "900",
                                }}
                              >
                                Observación
                              </p>
                              <p style={{ marginTop: "30px" }}>
                                {
                                  packageMedic?.assistance_reserves[0]
                                    ?.observations_assistance
                                }
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="content-form-detail-health">
            <div className="content-icons-share-health">
              <h2>Compartir</h2>
              <div>
                <MotionConstraints
                  className="slider_container"
                  ref={constraintsRef}
                >
                  <MotionBox
                    className="slider_"
                    drag="x"
                    dragConstraints={constraintsRef}
                  >
                    {allIcons?.map((item, index) => {
                      return (
                        <motion.div className="div_homeTurism" key={index}>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={3}
                            lg={2}
                            className="grid_cardHomeTurism"
                          >
                            {item.icon}
                          </Grid>
                        </motion.div>
                      );
                    })}
                  </MotionBox>
                </MotionConstraints>
              </div>
            </div>
            <div className="btns-detail-health">
              <button
                onClick={goReservation}
              >{`Reservar por  ${packageMedic?.price.toLocaleString("de-DE")} ${
                packageMedic?.currency
              }`}</button>
              <button>Contactar</button>
            </div>
          </div>
        </section>
      </section>
      <BestExperiences />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default DetailPackhealth;
