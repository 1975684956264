import React from 'react'
import './styles.css'
import HotelSearchEngine from '../../HotelSearchEngine/HotelSearchEngine'
import FilterAsideHotels from './FilterAsideHotels/FilterAsideHotels'
import UseHotels from '../../../../viewModels/hotels/UseHotels'

const AsideHotelResult = ({ setFirstRange, setSecondRange, activeFilterPrice, setActiveFilterPrice, erroFilter, setResetResult, resetResult }) => {
  const {
    cardRef,
    openHotelSearch,
    paceholders,
    search,
    setSearch,
    destiny,
    dateEntry,
    setDateEntry,
    datesSelected,
    setDatesSelected,
    cardRooms,
    setCardRooms,
    roomsSelected,
    setRoomsSelected,
    quantityPersons,
    openHotelSearchFuct,
    destinationSelectionFunc,
    searchHotelSelected,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    loadingSearch,
    validationForm,
    errApi}=UseHotels()

  return (
    <div className='contain-aside-hotel-result'>
      <HotelSearchEngine titleSize={'20px'} iconTitleSize={'50'} containDateFlexDirection={'column'} containDateJustify={'space-between'} layoutCampoWidth={'100%'} containFinishFlexDirection={'column'} containFinishJustify={'space-between'} layoutCampoVWidth={'100%'} widthContain={'100'} cardRef={cardRef} cardRooms={cardRooms} codeNacionality={codeNacionality} dataNacionality={dataNacionality} dateEntry={dateEntry} datesSelectedEntry={datesSelected.entryDate}
          datesSelectedExit={datesSelected.exitDate} destinationSelectionFunc={destinationSelectionFunc} destiny={destiny} errApi={errApi} findCountryClient={findCountryClient} loading={loading} loadingSearch={loadingSearch} nacionality={nacionality} nacionalityDropdown={nacionalityDropdown} openHotelSearch={openHotelSearch} openHotelSearchFuct={openHotelSearchFuct} paceholders={paceholders} quantityPersons={quantityPersons} roomsSelected={roomsSelected} search={search} searchHotelSelected={searchHotelSelected} selectedNacionality={selectedNacionality} setCardRooms={setCardRooms} setDateEntry={setDateEntry} setDatesSelected={setDatesSelected} setNacionalityDropdown={setNacionalityDropdown} setRoomsSelected={setRoomsSelected} setSearch={setSearch} validationForm={validationForm}/>

      <FilterAsideHotels
        widthContain={'100%'}
        setSecondRange={setSecondRange}
        activeFilterPrice={activeFilterPrice}
        errorFilter={erroFilter}
        setActiveFilterPrice={setActiveFilterPrice}
        setFirstRange={setFirstRange} 
         setResetResult={setResetResult}
        resetResult={resetResult}
        />
     
    </div>
  )
}

export default AsideHotelResult