import React, { useEffect } from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../iconSVG'
import { useNavigate } from 'react-router-dom'
import {  Star1 } from 'iconsax-react'

const CardGlobal = ({ id, img, title, price, stars,address, descripcion, DetailPackages, imgType, currency }) => {
    const navigate = useNavigate();
    
    useEffect(() => {
        console.log(id)
    },[])    

    const handleClick = () => {
        navigate(`/packages/${id}`)
    }

    return (
        <div className='Contain-Card-Global' onClick={handleClick}>
            <img src={`${imgType === true ? img : `https://aiop.com.co/storage/pack/${img}`}`} alt={title} />
            <div className='content-info-card-global'>
                <div className='column-a'>
                    <p className='column-a-price'>{price === "" ? "Precio no disponible" : `$ ${Number(price).toLocaleString('de-DE')}`} <span>{currency}</span></p>
                    <h3>{title === "" ? "Titulo no disponible" : title}</h3>    
                </div>
            </div>
        </div>
    )
}

export default CardGlobal