import React, { useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import useUpsellChoose from '../../../../viewModels/flights/upsell.choose.viewModel';
import './styles.css';
import { global } from '../../../../global';
import SvgIcon, { iconList } from '../../../share/components/iconSVG';
import { AddCircle, TickCircle, ArrowCircleDown, ArrowCircleUp, ArrowRight2, Warning2, CloseCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

const { iconLuggage } = global.img.icons;


function UpsellChoose({
  getandOpen,
  closeGetandOpen,
  isRoundTrip,
  outwardJourney,
  returnJourney,
  recommendation,
  toBuy,
  setDataCabin,
  setDataComponents,
  allPassengersSelected,
  setPriceUpsell
}
) {
  const {
    isLoading,
    isEmpty,
    openUpsell,
    setOpenUpsell,
    getUpsell,
    clearError,
    currencyFormat,
    farePrice,
    selectedFare,
    cleanData,
    familiesCombinationsCode,
    chooseFare,
    showDetails,
    textToLowerCase,
    selectedFareModal,
    openModal,
    closeModal,
  } = useUpsellChoose(
    getandOpen,
    closeGetandOpen,
    isRoundTrip,
    outwardJourney,
    returnJourney,
    recommendation,
    setDataCabin,
    setDataComponents,
    allPassengersSelected,
    setPriceUpsell,
  );

  const [t] = useTranslation("global")

  return (
    <>
      {!isEmpty &&
        <UpsellQuery
          isLoading={isLoading}
          isOpen={openUpsell}
          open={getUpsell}
          close={setOpenUpsell}
        />
      }

      {
        isLoading &&
        <LinearProgress />
      }



      {
        !isLoading && openUpsell && isEmpty &&
        <UpsellQueryEmpty clear={clearError} />
      }


      <ComponentModal isOpen={openModal} data={selectedFareModal} close={closeModal} />

      {!isLoading && openUpsell && !isEmpty &&
        <div className='upsellFooterBody'>
          <div className='row'>
            <Tariffa
              name={t("fligth.search_fligth.going")}
              isRoundTrip={isRoundTrip}
              selectedFare={selectedFare}
              cleanData={cleanData}
              combinations={familiesCombinationsCode}
              choose={chooseFare}
              textToLowerCase={textToLowerCase}
              showDetails={showDetails}
            />
            {isRoundTrip &&
              <Tariffa
                name={t("fligth.search_fligth.return")}
                isRoundTrip={isRoundTrip}
                selectedFare={selectedFare}
                cleanData={cleanData}
                combinations={familiesCombinationsCode}
                choose={chooseFare}
                textToLowerCase={textToLowerCase}
                showDetails={showDetails}
              />
            }

          </div>
          <div className='upsellFooter'>
            <div className='upsellFooterPrice'>
              <p className='upsellFooterText'>{t("fligth.upsell_choose.total")}:</p>
              <p className='upsellFooterText'>{currencyFormat(farePrice)}</p></div>
            <div className='upsellFooterPay' onClick={()=>toBuy(true)}>
              <p className='upsellFooterText'>{t("fligth.list_flights.buy")}</p>
            </div>
          </div>
        </div>
      }

    </>
  )
}
export default UpsellChoose;


const Tariffa = ({
  name,
  isRoundTrip,
  selectedFare,
  cleanData,
  combinations,
  choose,
  textToLowerCase,
  showDetails,
}) => {
  const [t] = useTranslation("global")
  const position = name === t("fligth.search_fligth.going") ? 0 : 1;
  const data = cleanData(selectedFare, name);
  const nameFare = selectedFare?.components[position]?.fareFamilyDetails?.fareFamilyname
  const [isOpenList, setIsOpenList] = useState(false);

  function removeDuplicates(arr) {
    const uniqueObjects = arr.reduce((unique, item) => {
      if (!unique[item.oneWay]) {
        unique[item.oneWay] = item;
      }
      return unique;
    }, {});
    return Object.values(uniqueObjects);
  }

  const getChoose = (value) => {
    setIsOpenList(false)
    choose(name, value)
  }
  
  const optionsCombinationsOneWay = removeDuplicates(combinations)
  const optionsCombinationsRoundTrip = combinations.filter((obj) => obj?.oneWay === nameFare);
  const optionsCombinations = name === t("fligth.search_fligth.going") ? optionsCombinationsOneWay : optionsCombinationsRoundTrip;
  const defaultBag = { description: "personal items", status: "INC", type: "BG" };
  const newServicesLuggage = data.servicesLuggage;
  const regexCarryOn = { min: "carry on", upper: "CARRY ON", regex: /\bcarry on\b/i };
  const regexLaptop = { min: "laptop", upper: "LAPTOP", regex: /\blaptop\b/i };
  const regexPItems = { min: "personal items", upper: "PERSONAL ITEMS", regex: /\bpersonal items\b/i };
  
  const bagsLis = [
    iconList.IconBagSmall,
    iconList.IconBagMedium,
    iconList.IconBagBig,
  ]


  const validatingBags = (text, regex) => {
    return text.toLowerCase().includes(regex.min) || text.toUpperCase().includes(regex.upper) || text.match(regex.regex);
  }





  return (
    <div className='upsellCard' style={{ width: isRoundTrip ? '49%' : '100%' }}>
      <div className='upsellCardHead'>
        <div className='upsellCardHeadName'>
          <p className='upsellCardHeadNameText'>{t("fligth.upsell_choose.fare")} {name}</p>
        </div>
        <div className='upsellCardHeadList'>
          <p className='upsellCardHeadListText'>{t("fligth.upsell_choose.fare")}:  {nameFare}</p>
          <div className='containerArrowCircle' onClick={() => setIsOpenList(!isOpenList)}>
            {isOpenList ? <ArrowCircleUp size="28" color="#FFFFFF" /> : <ArrowCircleDown size="28" color="#FFFFFF" />}
          </div>
        </div>
        {isOpenList &&
          <div className='upsellCardHeadListContaine'>
            {optionsCombinations.length > 0 && optionsCombinations.map((index, key) => {
              const value = name === t("fligth.search_fligth.going") ? index?.oneWay : index?.roundtrip;
              return (
                <p key={key}
                  onClick={() => getChoose(value)}
                  className='upsellCardHeadListContaineOpt'>{value}</p>
              )
            })}
          </div>
        }
      </div>
      <div className='upsellCardBody'>
        <div className='upsellCardBodySection'>
          <p className='upsellCardBodySectionText'>{t("fligth.upsell_choose.luggage")}</p>
          <div className='upsellCardBodySectionContainer'>
            <div className='upsellCardBodySectionContainerBag'>
              <div className='upsellCardBodySectionContainerBagContainer'>
                <div className='upsellCardBodySectionContainerBagContainerIcon'>
                <SvgIcon name={bagsLis[0]} color={'#104172'} size={60} />
                </div>
              </div>
              <p className='upsellCardBodySectionContainerBagText'>{textToLowerCase(defaultBag.description)}</p>
            </div>
            {
              newServicesLuggage.map((index, key) => {
                const isAvailable = index.status === 'INC'
                const isCarry = validatingBags(index?.description, regexCarryOn)
                const isLaptop = validatingBags(index?.description, regexLaptop)
                const isItems = validatingBags(index?.description, regexLaptop)
                const typeBag = isCarry ? iconList.IconBagMedium : iconList.IconBagBig;
                const maxCount = isLaptop ? 2:3;
                if (key < maxCount && !isLaptop)
                  return (
                    <div key={key} className='upsellCardBodySectionContainerBag'>
                      <div className='upsellCardBodySectionContainerBagContainer'>
                        <div className='upsellCardBodySectionContainerBagContainerIcon'>
                        <SvgIcon name={typeBag} color={isAvailable ? '#104172':'#BDBDBD'} size={60} />
                        </div>
                      </div>
                      <p className='upsellCardBodySectionContainerBagText'>{textToLowerCase(index?.description)}</p>
                    </div>
                  )
              })}
          </div>
        </div>



        {
          data.servicesIncluded.length > 0 &&
          <div className='upsellCardBodySection'>
            <p className='upsellCardBodySectionText'>{t("fligth.upsell_choose.services_include")}</p>
            <div className='upsellCardBodySectionContainerList'>{
              data.servicesIncluded.map((index, key) => {
                if (key < 6)
                  return (
                    <div className='upsellCardBodySectionContainerListGroup'>
                      <div className='upsellCardBodySectionContainerListGroupIcon'>
                        <TickCircle size="15" color="#17AEE6" />
                      </div>
                      <p key={key} className='upsellCardBodySectionContainerListGroupText'>{textToLowerCase(index.description)}</p>
                    </div>
                  )
              })
            }</div>
          </div>}



        <div className='showMoreDetail'>
          <p className='showMoreDetailText' onClick={() => showDetails(selectedFare, name)}>{t("tours.more_detail")}</p>
        </div>

      </div>
    </div>
  )
}




const UpsellQueryEmpty = ({ clear }) => {

  const [t] = useTranslation("global")
  return (
    <div className='upsellSectionEmpty'>
      <div className='upsellSectionEmptyHead'>
        <div className='upsellSectionEmptyHeadLeft'>
          <Warning2 size="40" color="#FFFFFF" />
          <h3 className='upsellSectionEmptyHeadTitle'>{t("fligth.upsell_choose.sorry_moment")}</h3>
        </div>
        <div className='upsellSectionEmptyHeadRigth' onClick={clear}>
          <CloseCircle size="40" color="#FFFFFF" />
        </div>
      </div>
      <div className='upsellSectionEmptyBody'>
        <div className={'upsellSectionEmptyBodyGroup'}>
          <div className={'upsellSectionEmptyBodyGroupVisible'}>
            <div className='upsellSectionEmptyBodyGroupVisibleIcon'>
              <ArrowRight2 size="25" color="#104172" />
            </div>
            <p className='upsellSectionEmptyBodyGroupVisibleText'>{t("fligth.upsell_choose.select_other_fare")}</p>
          </div>
          <div className='upsellSectionEmptyBodyGroupHidden'>
            <p className='upsellSectionEmptyBodyGroupHiddenText'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
            </p>
          </div>
        </div>
        <div className={'upsellSectionEmptyBodyGroup'}>
          <div className={'upsellSectionEmptyBodyGroupVisible'}>
            <div className='upsellSectionEmptyBodyGroupVisibleIcon'>
              <ArrowRight2 size="25" color="#104172" />
            </div>
            <p className='upsellSectionEmptyBodyGroupVisibleText'>{t("fligth.upsell_choose.call_services_client")}</p>
          </div>
          <div className='upsellSectionEmptyBodyGroupHidden'>
            <p className='upsellSectionEmptyBodyGroupHiddenText'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
            </p>
          </div>
        </div>
        <div className={'upsellSectionEmptyBodyGroup'}>
          <div className={'upsellSectionEmptyBodyGroupVisible'}>
            <div className='upsellSectionEmptyBodyGroupVisibleIcon'>
              <ArrowRight2 size="25" color="#104172" />
            </div>
            <p className='upsellSectionEmptyBodyGroupVisibleText'>{t("fligth.upsell_choose.continue_same_fare")}</p>
          </div>
          <div className='upsellSectionEmptyBodyGroupHidden'>
            <p className='upsellSectionEmptyBodyGroupHiddenText'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}



const UpsellQuery = ({ isLoading, isOpen, open, close }) => {
  const [t,i18n] = useTranslation("global")
  return (
    <div className='chooseUpsell'>
      <div className='iconWrap'>
        <img src={iconLuggage} alt="agregar equipaje" className='icon' />
      </div>
      <div className='column'>
        <p className='chooseUpsellTextBig'>{t("fligth.upsell_choose.you_choose_fare")}</p>
        <p className='chooseUpsellTextSmall'> {t("fligth.upsell_choose.class_luggage")}</p>
      </div>
      
      {isOpen ?
        <button className='btn iconWrapSax' onClick={() => close(false)}>
          <ArrowCircleUp size="40" color="#FFFFFF" />
        </button>
        :
        <button className='btn iconWrapSax' onClick={open} disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} >
          <ArrowCircleDown size="40" color="#FFFFFF" />
        </button>
      }
    </div>
  )
}




const ComponentModal = ({ isOpen, data, close }) => {
  let dataInGroups = []

  if (isOpen && data) {
    dataInGroups = data.reduce((group, obj) => {
      const { status } = obj;
      if (!group[status]) {
        group[status] = [];
      }
      group[status].push(obj);
      return group
    }, {});
  }

  const [t] = useTranslation("global")

  return (
    <>
      {isOpen &&
        <div className='upsellModal'>
          <div className='upsellModalCard2'>

            <div className='upsellModalClose' onClick={close}>
              <CloseCircle size="25" color="#FFFFFF" />
            </div>

            <div className='upsellModalCard'>



              <div className='modalSection'>
                <div className='modalSectionTitle'>
                  <p className='modalSectionTitleText'>{t("fligth.upsell_choose.services_include")}</p>
                </div>
                <div className='modalSectionBody'>
                  {dataInGroups?.INC && dataInGroups?.INC.map((index, key) => {
                    return (
                      <div className='serviceGroup' key={key}>
                        <div className='serviceGroupIcon'>
                          <TickCircle size="20" color="#17AEE6" />
                        </div>
                        <p className='serviceGroopDescription'>{index.description}</p>
                      </div>
                    )
                  })
                  }
                </div>
              </div>



              <div className='modalSection'>
                <div className='modalSectionTitle'>
                  <p className='modalSectionTitleText'>{t("fligth.upsell_choose.services_extra_pay")}</p>
                  <p className='modalSectionSubTitle'>{t("fligth.upsell_choose.call_assesor")}</p>
                </div>
                <div className='modalSectionBody'>{
                  dataInGroups?.CHA && dataInGroups?.CHA.map((index, key) => {
                    return (
                      <div className='serviceGroup' key={key}>
                        <div className='serviceGroupIcon'>
                          <AddCircle size="20" color="#17AEE6" />
                        </div>
                        <p className='serviceGroopDescription'>{index.description}</p>
                      </div>
                    )
                  })
                }
                </div>
              </div>



              <div className='modalSection'>
                <div className='modalSectionTitle'>
                  <p className='modalSectionTitleText'>{t("fligth.upsell_choose.services_no_include")}</p>
                  <p className='modalSectionSubTitle'>{t("fligth.upsell_choose.services_validate_other")}</p>
                </div>
                <div className='modalSectionBody'>
                  {
                    dataInGroups?.NOF && dataInGroups?.NOF.map((index, key) => {
                      return (
                        <div className='serviceGroup' key={key}>
                          <div className='serviceGroupIcon'>
                            <CloseCircle size="20" color="#a8a8a8" />
                          </div>
                          <p className='serviceGroopDescription'>{index.description}</p>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

