import ActivityIndicator from "../../ui/views/share/components/activityIndicator";
import AnyQuestions from "../../ui/views/home/components/anyQuestion";
import axios from "axios";
import CarouselSevices from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import DetailBuy from "./DetailBuy/DetailBuy";
import FormDataPassenger from "./FormDataPassenger/FormDataPassenger";
import FormEmergencyContact from "./FormEmergencyContact/FormEmergencyContact";
import Find from "../../ui/views/share/components/Find/Find";
import React, { useEffect, useState } from "react";

import "./Assets/styles.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TerrawindVaoucherDetail() {

    const location = useLocation();
    const dataString = decodeURIComponent(location.pathname.split('/')[4]);
    const data = JSON.parse(dataString);
    const priceWithoutCommas = data.price.replace(/,/g, '');
    const priceFormat = parseFloat(priceWithoutCommas);
    const env = process.env.REACT_APP_URL_ENV

    const initialFormData = Array(data.passengers).fill({
        voucher_date_from: data.dateOrigin,
        voucher_date_to: data.dateDestination,
        voucher_product_id: parseInt(data.productId),
        country_id: 9999
    });

    const [typeDocument, setTypeDocument] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(initialFormData);
    const [emergencyData, setEmergencyData] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [backDropLoading, setBackDropLoading] = useState(false);
    const [send, setSend] = useState(false);
    const [sendEmergency, setSendEmergency] = useState(false);

    const [t] = useTranslation("global");

    const navigate = useNavigate();

    let url;

    switch (env) {
        case "local":
          url = process.env.REACT_APP_URL_API_AIOP_LCH;
          break;
        case "staging":
          url = process.env.REACT_APP_URL_API_AIOP_STG;
          break;
        case "production":
          url = process.env.REACT_APP_URL_API_AIOP_PRT;
          break;
      }

    const handleEmergencyChange = (data) => {
        setEmergencyData(data);
        const values = formData.map(item => ({ ...item, ...data }));
        setFormData(values);
    };

    const handleFormChange = (index, data) => {
        const values = [...formData];
        values[index] = { ...values[index], ...data, ...emergencyData };
        setFormData(values);
    };

    const renderForms = (typeDocument) => {
        let forms = [];
        for(let i = 0; i < data.passengers; i++){
            forms.push(
                <FormDataPassenger 
                    setSend={setSend}
                    typeDocument={typeDocument} 
                    passengers={i+1} 
                    long={data.passengers}
                    key={i} 
                    handleFormChange={handleFormChange} 
                    index={i}
                />
            );
        }
        return forms;
    };

    const details = [
        { title: "Plan", value: data.plan },
        { title: "Total", value:`COP $ ${priceFormat.toLocaleString()}` },
        { title: "Origen", value: data.origin },
        { title: "Destino", value: data.destination },
        { title: "Salida", value: data.dateOrigin },
        { title: "Regreso", value: data.dateDestination },
        { title: "Vieajero/s", value: data.passengers }
    ];

    useEffect(() => {
        axios.get(`${url}api/terrawind/documents`)
            .then((response) => {
                setTypeDocument(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false);
            })
            
    },[])

    const sendForm = async () => {
        let check = true;

        if (!send || !sendEmergency) {
            return;
        }
        
        setBackDropLoading(true);
        setErrorMessage("");

        try{
            const response = await axios.post(`${url}api/terrawind/check-voucher`, {
                vouchers: formData
            });
            console.log("response", response);
            response.data.forEach((item) => {
             if(item.voucher_status !== "OK"){
                check = false;
                console.log("error", item.voucher_status);
             }
            })
            if(check){
                const dataPay = [{formData}, {data}]
                const dataJson = encodeURIComponent(JSON.stringify(dataPay));
                navigate(`/travelSecure/terrawind/methodPay/${dataJson}`)
            }
        }catch(error){
            console.error("Error en la llamada a la API de planes", error)
        }finally{
            setBackDropLoading(false);
        }

    }

    return(
        <div>
            <div>
                <div>
                    <Find/>
                    <CarouselSevices current={t("carousel_services.travel_secure")}/>
                </div>
                <div className="header-voucher-container">
                    <div>
                        <p>Plan {data.plan}</p>
                        <p className="title-pay">Información de pago</p>
                    </div>
                </div>
                <div className="form-voucher-container">
                    <div className="voucher-container">
                        {
                            loading ? (
                                <div className="loading-form-passengers">
                                    <ActivityIndicator colorFill='#0072B9' size={50} />
                                </div>
                            ):(
                                <div className="form-passangers-container">
                                    <div className="form-passangers">
                                        <div className="title-form">
                                            <p>Datos de los viajeros</p>
                                        </div>
                                        <div>
                                            {
                                                renderForms(typeDocument)
                                            }
                                        </div>
                                        <hr className="div-emergency-cotact"/>
                                        <div>
                                            <FormEmergencyContact typeDocument={typeDocument} setSendEmergency={setSendEmergency} handleEmergencyChange={handleEmergencyChange} />
                                        </div>
                                    </div>
                                    <div className="btns-vouche">
                                        <div className="btn-frequent-questions">
                                            <button style={{ cursor: "not-allowed" }} disabled={true}>PREGUNTAS FRECUENTES</button>
                                        </div>
                                        <div className="btn-pay">
                                            <button onClick={sendForm}>PAGAR</button>
                                        </div>
                                    </div>
                                    <div className="container-validate">
                                        {errorMessage && (
                                            <div className="validate-data-passengers" style={{border: '1px solid red', margin: '10px', padding: '10px'}}>
                                                <p>{errorMessage}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        <DetailBuy colorTitles={true} details={details} />
                    </div>
                </div>
                <div>
                    <AnyQuestions title="¿Tienes alguna duda?" />
                </div>
                {
                    backDropLoading ? (
                        <div className="containe-check-voucher">
                            <div className="box-circle-progress">
                                <ActivityIndicator colorFill='#0072B9' size={50} />
                            </div>
                        </div>
                    ):(
                        null
                    )
                }
            </div>
        </div>
    )
}

export default TerrawindVaoucherDetail;