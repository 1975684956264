import React, { useState } from 'react'
import { DateRange } from 'react-date-range'
import './Assets/styles.css'
import { Calendar } from 'iconsax-react'
import { useTranslation } from 'react-i18next'


const HotelDateCard = ({ cardRef, setDatesSelected, setDateEntry, left}) => {

  const [t, i18n] = useTranslation("global")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [totalDate, setTotalDate] = useState(0)

  const [dateMark, setDateMark] = useState(false)

  const date = new Date()
  date.setDate(date.getDate() + 1)

  const dateMax = new Date()
  dateMax.setDate(date.getDate() + 360)

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    dateMark: dateMark,
    key: 'selection'
  }

  const handleSelect = (range) => {
    setStartDate(range.selection.startDate)

    setDateMark(!range.selection.dateMark)

    setEndDate(range.selection.endDate)

    setTotalDate(calculateDates(range.selection.startDate.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" }), range.selection.endDate.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })))
  }

  const calculateDates = (fechaInicio, fechaFin) => {
    const unDiaEnMilisegundos = 1000 * 60 * 60 * 24; // 1 día en milisegundos
    const fechaInicioObj = new Date(fechaInicio)
    const fechaFinObj = new Date(fechaFin)
    const diferenciaEnMilisegundos = fechaFinObj - fechaInicioObj
    const diasDeDiferencia = Math.round(diferenciaEnMilisegundos / unDiaEnMilisegundos)
    return diasDeDiferencia;
  }

  const selectDates = () => {
    setDatesSelected({
      entryDate: startDate.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" }),
      exitDate: endDate.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })
    })

    setDateEntry(false)
  }

  return (
    <div className='hotelDateCard' ref={cardRef} style={{left:`${left}`}}>
      <div className='hotelDateCard_'>
        {dateMark ?
          <div className='layout-campo' style={{backgroundColor: '#004274', color: 'white'}}>
            <div className='destiny-hotel'>
              <span style={{color: 'white'}}>{t("fligth.search_fligth.date")}</span>
              <p style={{color: 'white'}}>{startDate.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
            </div>
            <div className='destiny-hotel-icon'>
              <Calendar size="32" color="white" />
            </div>
          </div>
          :
          <div className='layout-campo'>
            <div className='destiny-hotel'>
              <span>{t("fligth.search_fligth.date")}</span>
              <p>{startDate.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
            </div>
            <div className='destiny-hotel-icon'>
              <Calendar size="32" color="#004274" />
            </div>
          </div>
        }
        {!dateMark ?
          <div className='layout-campo' style={{backgroundColor: '#004274', color: 'white'}}>
            <div className='destiny-hotel'>
              <span style={{color: 'white'}}>{t("fligth.search_fligth.date")}</span>
              <p style={{color: 'white'}}>{endDate.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
            </div>
            <div className='destiny-hotel-icon'>
              <Calendar size="32" color="white" />
            </div>
          </div>
          :
          <div className='layout-campo'>
            <div className='destiny-hotel'>
              <span>{t("fligth.search_fligth.date")}</span>
              <p>{endDate.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
            </div>
            <div className='destiny-hotel-icon'>
              <Calendar size="32" color="#004274" />
            </div>
          </div>
        }
      </div>
      <DateRange minDate={date} maxDate={dateMax} editableDateInputs={true} months={2} direction='horizontal' moveRangeOnFirstSelection={false} onChange={handleSelect} ranges={[selectionRange]} />
      <div className='hotelDateCard_button'>
        <button onClick={selectDates}> {t("hotel.search_engine.accommodation_of")} {totalDate} {t('hotel.search_engine.day_accommodation')} </button>
      </div>
    </div>
  )
}

export default HotelDateCard