import { useContext } from 'react'
import './styles.css'
import useResultSearch from '../../../viewModels/transport/ResultSearch_Model'
import TransportContext from '../../../../Context/transport/TransportProvider'
import { ArrowDown2, HambergerMenu, People, Timer } from 'iconsax-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useTransport from '../../../viewModels/transport/TransportC'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices';
import Loading from '../../../views/share/components/loading';
import Find from '../../share/components/Find/Find';
import { Link, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import SearchTransport from '../primaryView/SearchTransport';


const ResultSearch = () => {

  const {
    menuHambur,
    buscadorMenu,
    filtersMenu,
    passengerFilter,
    setPassengerFilter,
    filtrado,
    activeFilterP,
    setActiveFilterP,
    resetFilter,
    setActiveFilterPrice,
    activeFilterPrice,
    setFirstRange,
    setSecondRange,
    errorFilter,
    resultados,
    filterPerPassenger,
    filterPerPrice, 
    loader,
    goToReservation
  } = useResultSearch()

  const {
    adult,
    baby,
    setTypeT,
    ciudadDropdown,
    setCiudadDropdown,
    aeropuertoDropdown,
    setAeropuertoDropdown,
    passengersCal,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginSelected,
    findDestiny,
    destinyList,
    destinySelected,
    setDestinySelected,
    destinyDropdown,
    setDestinyDropdown,
    findServices,
    selectedDate,
    handleDateChange,
    formValidate,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    setCity,
    city,
    setOriginList,
    setDestinyList
  } = useTransport()


  return (
    <div>

      <Find />
      <CarouselSevices
        current={servicesTravel[3].title}
      />
      <section className='route-page'>
        <h3>
          Travel {'>'} <Link to={'/transport'}>Traslados</Link>  {'>'} <span>Buscador</span>
        </h3>
        <div className='filter-order'>
          <p>Ordenar por:</p>
          <div>
            <span>Mas Elegido</span>
            <ArrowDown2 size="20" color="#004274" />
          </div>
        </div>
      </section>
      <main className='contenedor-principal-resultados'>
        <div className='hamburger-menu-filters'>
          <span onClick={() => { menuHambur(false) }}><HambergerMenu size="20" color="#004274" /> Buscar</span>
          <span onClick={() => { menuHambur(true) }}><HambergerMenu size="20" color="#004274" /> Filtrar</span>
        </div>
        <aside className={'filters-container buscador-mobile'}>
          
          <SearchTransport adult={adult} aeropuertoDropdown={aeropuertoDropdown} baby={baby} cityList={cityList} citySelected={citySelected} ciudadDropdown={ciudadDropdown} destinyDropdown={destinyDropdown} destinyList={destinyList} destinySelected={destinySelected}  findDestiny={findDestiny} findOrigin={findOrigin} findServices={findServices} formValidate={formValidate} handleDateChange={handleDateChange} originList={originList} originSelected={originSelected} passengersCal={passengersCal} selectedDate={selectedDate} setAeropuertoDropdown={setAeropuertoDropdown} setCiudadDropdown={setCiudadDropdown} setDestinyDropdown={setDestinyDropdown} setDestinySelected={setDestinySelected} setOriginSelected={setOriginSelected} setTypeT={setTypeT} setcitySelected={setcitySelected} buscadorMenu={buscadorMenu} resetFilter={resetFilter} width={"100% !important"} setCityList={setCityList} loadingCity={loadingCity} loadingDestiny={loadingDestiny} loadingOrigin={loadingOrigin} setCity={setCity} setDestinyList={setDestinyList} setOriginList={setOriginList}/>
          <div className={filtersMenu === true ? 'filters-box' : 'display-none-t filters-box'}>
            <section className="container-filter-price">
              <h3>
                Precios
              </h3>
              <div className='input-filtrado-price'>
                <div>
                  <label htmlFor="">Desde</label>
                  <input onChange={(e) => { setFirstRange(e.target.value) }} type="number" />
                </div>
                <div>
                  <label htmlFor="">Hasta</label>
                  <input onChange={(e) => { setSecondRange(e.target.value) }} type="number" />
                </div>
                {
                  errorFilter === '' ?
                    ''

                    :
                    <div className='error-form top-m'>
                      <p>{errorFilter}</p>
                    </div>

                }
                <div className='aplicar-filtro'>
                  <button onClick={() => { filterPerPrice() }}>Aplicar</button>
                </div>
              </div>

            </section>
            <section className="container-filter-passenger">
              <h3>Pasajeros</h3>
              <div className='input-filtrado-passenger'>
                <div>
                  <label htmlFor="">Nro Pasajeros</label>
                  <input id='range-passenger' onChange={e => { setPassengerFilter(e.target.value) }} className='range-passenger' type="range" max={12} min={1} />
                </div>
                <div className='content-number-passenger'>
                  <input type="number" readOnly="readOnly" value={passengerFilter} />
                </div>
                <div className='aplicar-filtro'>
                  <button onClick={() => filterPerPassenger()}>Aplicar</button>
                </div>
              </div>
            </section>
          </div>
        </aside>

        <section className='content-all-card-t'>
          {
            loader === true ?
              <Loading size={100}></Loading>
              :

              (
                resultados.length <= 0 ?
                <div>
                <h2 style={{ textAlign: 'center' }}>
                  No se encontraron traslados disponibles
                </h2>
              </div>
                :

                resultados.map((item,index) => {
                return (
                  <div className='card-traslado' key={index}>
                    <div className="layout-card-traslado content-img">
                      <img src={item?.vehicleHDImage} alt={item?.vehicle} />
                    </div>
                    <div className='layout-card-traslado-second'>
                      <div className="content-info information-car">
                        <h3>{item?.vehicle}</h3>
                        <p>{item?.vehicleType} {item?.meetAndGreet === true ? ' - Meet And Greet' : ''}</p>
                        <div className='content-information-p'>
                          <div className='cuantity-p'>
                            <div className='icons-cuantity'>
                              <People className='icon-card-t' size="30" color="#004274" />
                              <span>{item?.passengerCapacity}</span>
                            </div>
                            <div className='icons-cuantity'>
                              <Timer className='icon-card-t' size="30" color="#004274" />
                              <span>{item?.duracionInMinutes} Min</span>
                            </div>
                          </div>
                          <div className='cancelacion-service'>
                            <p style={{ color: "green" }}>Cancelacion Gratuita</p>
                            <p>hasta el </p>
                          </div>
                        </div>

                      </div>
                      <div className=" price-card">
                        <div className='price-content'>
                          <span>${(item?.totalPriceUSD + (item?.totalPriceUSD * 0.20))?.toFixed(1)}</span>
                          <h3>${item?.totalPriceUSD?.toFixed(1)} USD</h3>
                        </div>
                        <div className='content-reserva'>
                          <button onClick={()=>{goToReservation(item)}}>Reservar Ahora</button>
                        </div>
                        <div className='more-details'>
                          <p>Mas detalles...</p>
                        </div>

                      </div>
                    </div>

                  </div>
                )
              })
              )

              
          }

        </section>
      </main>

    </div>
  )
}

export default ResultSearch