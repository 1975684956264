import React from 'react'
import "./styles.css"
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import especialista from "../../../../assets/images/tourismSpecialist.png"
import clinica from "../../../../assets/images/tourismClinics.png"
import { useNavigate } from 'react-router-dom'

const Suggestions = () => {

    const navigate = useNavigate()

    const goToOption = (procedure) => {
        
            navigate(`/TourismHealth/${procedure}`, {
            state: {
                city: "",
                procedure:procedure
            }
        })
        
        
    }
    const surgery = [
        {
            icon: iconList.IconSurgeryEstectic,
            name: "Cirugia estetica"
        },
        {
            icon: iconList.IconBariatica,
            name: "Bariatica/Obesidad"
        },
        {
            icon: iconList.IconFertilidad,
            name: "Fertilidad"
        },
        {
            icon: iconList.IconUrologia,
            name: "Urologia"
        },
        {
            icon: iconList.IconOdontologia,
            name: "Odontologia"
        },
        {
            icon: iconList.IconCelulas,
            name: "Celula Madres"
        },
        {
            icon: iconList.Ortopedia,
            name: "Ortopedia"
        },
        {
            icon: iconList.IconCancer,
            name: "Cancer"
        },
        {
            icon: iconList.IconEnvejecimiento,
            name: "Antienvejecimiento"
        },
        {
            icon: iconList.IconProtectEyes,
            name: "Cuidado de ojos"
        },
        {
            icon: iconList.IconGinecologia,
            name: "Ginecologia"
        }
    ]

    const goToScreen = (route)=>{
        navigate(route)
    }

    return (
        <div className='content-suggestions'>
            <section className='categories'>
                {
                    surgery.map((item, key) => {
                        return (
                            <div className='btn-surgery' key={key} onClick={()=>{
                                goToOption(item.name)
                            }}>
                                <SvgIcon color={"#004274"} name={item.icon} size={30} />
                                <span>{item.name}</span>
                            </div>
                        )
                    })
                }
            </section>
            <section className='content-card'>
                <div className='card-suggestions'>
                    <div className='contain-img-card-suggestions'>
                        <img src={especialista} alt="" />
                    </div>
                    <div className='contain-info-card-suggestions'>
                        <h3>Especialistas Destacados</h3>
                        <p>Acepto los Términos de uso, Términos de SMS, Política de privacidad y Política de cookies</p>
                        <button onClick={()=>{
                            goToScreen("/specialist")
                        }}>Ver todos</button>
                    </div>
                </div>
                <div className='card-suggestions'>
                    <div className='contain-img-card-suggestions'>
                        <img src={clinica} alt="" />
                    </div>
                    <div className='contain-info-card-suggestions'>
                        <h3>Clinicas Destacadas</h3>
                        <p>Acepto los Términos de uso, Términos de SMS, Política de privacidad y Política de cookies</p>
                        <button onClick={()=>{
                            goToScreen("/clinics")
                        }}>Ver todos</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Suggestions