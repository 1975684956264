import React, { useState } from 'react';
import './styles.css';
function FrequentQuestions({ data }) {
  const [isShowingContentKey, setIsShowingContentKey] = useState(false);


  const listFaq = data?.es || [];

  return (
    <div className='pqrsFrequentQuestions'>
      <p className='pqrsFrequentQuestionsTitle'>
        Preguntas frecuentes
      </p>

      <div className='pqrsFrequentQuestionsWrapper'>
        {
          listFaq.map((faq, key) => {
            const isOpen = isShowingContentKey === key
            return (
              <div key={key} className='pqrsFaq'
                onClick={() =>
                  isOpen
                    ? setIsShowingContentKey(false)
                    : setIsShowingContentKey(key)
                }>
                <div className='pqrsFaqRow'>
                  <div className='pqrsIcon'> A </div>
                  <div className='pqrsTitle'>{faq.title}</div>
                </div>
                {isOpen && <p className='pqrsContent'>{faq.content}</p>}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default FrequentQuestions;
