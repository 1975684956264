import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Find from '../../ui/views/share/components/Find/Find';
import CarouselSevices from '../../ui/views/share/components/CarouselServices/CarouselSevices';
import { useTranslation } from 'react-i18next';
import InputSimple from '../TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple';
import InputDocument from '../TerrawindPaymentCard/InputDocument/InputDocument';
import AnyQuestions from '../../ui/views/home/components/anyQuestion';
import ActivityIndicator from '../../ui/views/share/components/activityIndicator';
import { CircularProgress } from '@mui/material';

const PayPackageTourism = () => {
    const location = useLocation();
    const dataString = decodeURIComponent(location.pathname.split("/")[4]);
    const data = JSON.parse(dataString);
    const env = process.env.REACT_APP_URL_ENV;

    let urlWellezy;
    let url;
    
    const [validateCard, setValidateCard] = useState(false);
    const [packaged, setPackaged] = useState({});
    const [loading, setLoading] = useState(true);
    const [names, setNames] = useState("");
    const [lastNames, setLastNames] = useState("");
    const [document, setDocument] = useState("");
    const [documentTypeId, setDocumentTypeId] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [validate, setValidate] = useState("");
    const [modal, setModal] = useState(false);
    const [errorPay, setErrorPay] = useState(false);

    const [t] = useTranslation("global");

    switch (env) {
        case "local":
          urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_LCH;
          url = process.env.REACT_APP_URL_API_AIOP_LCH;
          break;
        case "staging":
          urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_PRT;
          url = process.env.REACT_APP_URL_API_AIOP_STG;
          break;
        case "production":
          urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_PRT;
          url = process.env.REACT_APP_URL_API_AIOP_PRT;
          break;
        default:
          urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_LCH;
          url = process.env.REACT_APP_URL_API_AIOP_LCH;
          break;
      }

      const handleClickOutside = (event) => {
        setNames("");
        setLastNames("");
        setDocument("");
        setDocumentTypeId("");
        setPhone("");
        setEmail("");
        event.stopPropagation();
        setModal(false);
      };
    
      const handleClickedInside = (event) => {
        event.stopPropagation();
      };

    const findServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL_API_AIOP_PRT}api/packages/${data.packId}`);
            console.log(response.data)
            setPackaged(response.data);
            const imageUrls = response.data.pack.images.map(image => image.url);
        } catch (error) {
            console.error("Error en la llamada a la API de planes", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        findServices();
    }, []);

    async function payPSE() {
    
        if (document === "" || email === "") {
          setValidateCard(true);
          return;
        }
    
        if(!validateData()){
          return;
        } 
    
        setModal(true);
        setLoading(true);
    
        
            let mount = parseInt(packaged.pack.price);
          
            const dataToPay = {
              "name": names,
              "lastname": lastNames,
              "typeDocument": documentTypeId,
              "document": document,
              "email": email,
              "cel": phone,
              "localizator": `${data.idResponse}`,
              "type": "Pack",
              "price": 1000000,
              "url": `${urlWellezy}packages/voucher`,
            };
    
            try{
              const responseDataPay = await axios.post(`${url}api/place-to-pay`,dataToPay);
        
              console.log(responseDataPay);
              
                localStorage.setItem("requestId", responseDataPay.data.requestId);
                localStorage.setItem("price", packaged.pack.price);
                localStorage.setItem("currency", packaged.pack.currency);
                localStorage.setItem("namePackage", packaged.pack.name);
        
               if(responseDataPay.status === 200){
                 window.location.href = responseDataPay.data.processUrl
               }else{
                 setLoading(false);
                 setErrorPay(true);
               }
            }catch{
              setLoading(false);
              setErrorPay(true);
            }
          
      }

    const validateData = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;


        if(names === "" || names.length < 3 || /\d/.test(names)){
          setValidate("names");
          return false;
        }else if(lastNames === "" || lastNames.length < 3 || /\d/.test(lastNames)){
          setValidate("lastNames");
          return false;
        }else if (documentTypeId === "3" && isNaN(document)) {
          setValidate("document");
          return false;
        }else if(/[\s\W]/.test(document) || document.trim() === "" || document.length < 5 || documentTypeId.trim() === ""){
          setValidate("document");
          return false;
        }else if(!emailRegex.test(email) || email.trim() === "" || /[\s]/.test(email)){
          setValidate("email");
          return false;
        }else if(isNaN(phone)){
          setValidate("phoneText");
          return false;
        }else if(phone === "" || phone.length < 8){
          setValidate("phone");
          return false
        }else{
          setValidate("")
        }
    
        return true;
      }
    
      useEffect(() => {
        validateData();
      }, [names, lastNames, document, documentTypeId, phone, email]);

    return (
        <div>
            {
                loading ? (
                    <div className='container-loading-packages'>
                        <CircularProgress />
                    </div>
                ):(
                    <div>
                        <Find />
                        <CarouselSevices current={t("carousel_services.travel_secure")} />
                        <div className='container-payment-card'>
                            <div className='credit-cards'>
                                <div className="title-method-pay">
                                    <p>Responsable del pago</p>
                                </div>
                                <div className="text-description">
                                    <p>Ingresa la información para tu metodo de pago</p>
                                </div>
                                <div className="container-payment-person">
                                    <div className="row-data-payment-person">
                                        <div className={ validate === "names" ? "validate-input-voucher": null}>
                                            <InputSimple label="Nombres *" onInputChange={setNames}/>
                                            { validate === "names" ? <p className="span-simple-error">El campo es requerido</p> : null}
                                        </div>
                                        <div className={validate === "lastNames" ? "validate-input-voucher": null}>
                                            <InputSimple label="Apellidos *" onInputChange={setLastNames}/>
                                            { validate === "lastNames" ? <p className="span-simple-error">El campo es requerido</p> : null}
                                        </div>
                                        <div>
                                            <InputDocument  onInputChange={setDocument} validate={validate === "document"? true : false} setDocumentTypeId={setDocumentTypeId}/>
                                        </div>
                                    </div>
                                    <div className="row-data-payment-person-second">
                                        <div className={validate === "email" ? "validate-input-voucher": null}>
                                            <InputSimple label="Correo *" onInputChange={setEmail}/>
                                            { validate === "email" ? <p className="span-simple-error">El campo es requerido</p> : null}
                                        </div>
                                        <div className={validate === "phone" || validate === "phoneText"? "validate-input-voucher": null}>
                                            <InputSimple label="Telefono *" onInputChange={setPhone}/>
                                            { validate === "phone"  || validate === "phoneText"? <p className="span-simple-error">{validate === "phone" ? "El campo es requerido": validate === "phoneText" ? "El campo debe ser numerico": null}</p> : null}
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="btn-confirm-buy">
                                    {/* <button>Confirmar compra y continuar</button> */}
                                    <button onClick={payPSE}>Pagar con PSE</button>
                                </div>
                            </div>
                            <div className='container-data-description-tourism'>
                                <div className="list-package-tourism">
                                    <div>
                                        <p>Fecha de salida:</p>
                                    </div>
                                    <div>
                                        <p>{ packaged.pack.start_date }</p>
                                    </div>
                                </div>
                                <div className="list-package-tourism">
                                    <div>
                                        <p>Destino:</p>
                                    </div>
                                    <div>
                                        <p>{ packaged.pack.destination }</p>
                                    </div>
                                </div>
                                <div className="list-package-tourism">
                                    <div>
                                        <p>Precio:</p>
                                    </div>
                                    <div>
                                        <p>{ parseInt(packaged.pack.price).toLocaleString() } { packaged.pack.currency }</p>
                                    </div>
                                </div>
                                <div className="list-package-tourism">
                                    <div>
                                        <p>Viajeros:</p>
                                    </div>
                                    <div>
                                        <p>{ data.passengerCount }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AnyQuestions title={t("browse_home.have_doubt")} />
                        {modal ? (
                            <div
                            className="container-modal-method-pay"
                            >
                            {loading ? (
                                <div
                                className="modal-loading-method-pay"
                                >
                                <ActivityIndicator colorFill="#004274" size={40} />
                                </div>
                            ) : errorPay ? (
                                <div
                                className="modal-loading-method-pay"
                                onClick={handleClickedInside}
                                style={{ padding: "20px" }}
                                >
                                <p>Error al hacer el pago</p>
                                <p style={{ fontWeight: "400", fontSize: "12px", textAlign: "center" }}>Hubo un error al intentar ir a pagar, intentelo de nuevo.</p>
                                <button
                                    className="btn-button-accept-error-method-pay"
                                    onClick={handleClickOutside}
                                >
                                    Aceptar
                                </button>
                                </div>
                            ) : null}
                            </div>
                        ) : null}
                    </div>
                )
            }
        </div>
    );
}

export default PayPackageTourism;