import useRegisterUser from '../../../viewModels/session/register.user.viewModel';
import './styles.css';
import SvgIcon, { iconList } from '../../share/components/iconSVG';
import InputRadio, { typesRadio } from '../../share/components/inputs/inputRadio';
import { global } from '../../../global'
import SelectListDirecion from '../../share/components/selectListDirecion';
import ActivityIndicator from '../../share/components/activityIndicator';

function UserRegister() {
  const {
    screensList,
    step,
    setStep,
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    errorPasswordStatus,
    // isShowModal,
    // setIsShowModal,
    formRegister,
    // inputPhone,
    // getInputPhone,
    // addPhone,
    // editPhone,
    // deletePhone,
    // setInputsForm,
    // avataresList,
    // addAddress,
    // editAddress,
    // deleteAddress,
    // onRegister,
    loading,
    getInputError,
    containerRef,
    onRegister,
    showValidationPassword,
  } = useRegisterUser()

  const {
    BannerTours
  } = global.img.pictures;

  return (
    <div className='userRegister'>
      <div className='userRegisterContainer'>
        <section className='sectionLeft'>
          <div className='userRegisterFormContainer'>
            <div className='userRegisterFormContainerHead'>
              <p className='userRegisterFormTitle'>Formulario de registo</p>
              <p className='userRegisterFormSubTitle'>Los campos marcados con (*) son obligatorios.</p>
            </div>
            {step === 1 &&
              <>
                <div className='userRegisterFormContainerBody' ref={containerRef}>
                  {inputsList.length > 0 && inputsList.map((index, key) => {
                    const isError = getInputError(index);
                    const showBtn = ['password', 'confirmPassword'].includes(index?.id);
                    return (
                      <div key={key} className='userRegisterInputContainer'>
                        {isError ?
                          <p className='userRegisterInputErrorLabel'>{isError?.message}</p>
                          :
                          <label className='userRegisterInputLabel'>
                            <b>{index.require ? '* ' : ''}</b>
                            {index?.label}
                          </label>
                        }
                        <div className={`userRegisterInput ${isError && 'userRegisterInputError'}`}>
                          <div className='userRegisterInputIcon'>
                            <SvgIcon name={index?.icono} color={'#757575'} size={25} />
                          </div>
                          <input
                            type={index?.type}
                            name={index?.name}
                            id={index?.id}
                            value={index?.value}
                            placeholder={index?.placeHolder}
                            onChange={index?.func}
                            className='userRegisterInputInput'
                            onFocus = {index?.focus}
                          />
                          {showBtn &&
                            <div className='userRegisterInputShowPassword' onClick={() => setShowPassword(!showPassword)}>
                              <SvgIcon name={showPassword ? iconList.IconEyeOn : iconList.IconEyeOff
                              } color={'#757575'} size={25} />
                            </div>
                          }
                        </div>
                      </div>
                    )
                  })
                  }
                  {showValidationPassword &&
                    <div className='containerPassword'>
                      {errorPasswordStatus.map((index, key) => {
                        const colorText = index.comply ? '#2ECC71' : '#E74C3C';
                        return (
                          <div className='containerPasswordRow' key={key}>
                            <div className='containerPasswordRowIcon'>
                              <SvgIcon name={
                                index.comply ? iconList.IconCheckSucces : iconList.IconCloseCircleOutLine} color={colorText} size={25} />
                            </div>
                            <p className='containerPasswordRowText' style={{ color: colorText }}>{index?.text}</p>
                          </div>
                        )
                      })}
                    </div>
                  }


                  {/* <div className='formRadio'>
                    <label className='userRegisterInputLabel'>Te identificas como:  <b>*</b></label>
                    <div className='formRadioBody'>
                      <InputRadio
                        display={'row'}
                        value={formRegister.gender}
                        list={[
                          { id: 1, value: 'Hombre' },
                          { id: 2, value: 'Mujer' },
                          { id: 3, value: 'Prefiero no decirlo' },
                        ]}
                        change={(value) => setInputsForm(value, 'gender')}
                        type={typesRadio.default}
                      />
                    </div>
                  </div> */}


                  {/* <div className='formRadio'>
                    <label className='userRegisterInputLabel'>Tipo de usuario:  <b>*</b></label>
                    <div className='formRadioBody'>
                      <InputRadio
                        display={'row'}
                        value={formRegister.type}
                        list={[
                          { id: 'customer', value: 'Por defecto' },
                          { id: 'serviceProvider', value: 'Quiero brindar servicios' },
                        ]}
                        change={(value) => setInputsForm(value, 'type')}
                        type={typesRadio.default}
                      />
                    </div>
                  </div> */}




                </div>
                <div className='userRegisterFormContainerFoot'>
                  <div onClick={() => !btnDisable && onRegister()}
                    className={`formBtnSend big ${btnDisable ? 'formBtnSendDisable' : 'formBtnSendAvaliable'}`}>
                    {loading
                      ? <ActivityIndicator size={30} colorFill={'white'}
                      />
                      : <p className='formBtnSendText'>Registrar</p>
                    }
                  </div>
                </div>
              </>
            }
            {/*

            {step === 2 &&
              <>
                <div className='userRegisterFormContainerBody'>
                  <div className='formPhone'>
                    <label className='formPhoneLabel'>Agrega tu número de contacto</label>
                    {formRegister.phones.length < 3 &&
                      <div className='formPhoneForm'>
                        {inputsList.length > 0 && inputsList.map((index, key) => {
                          return (
                            <input
                              key={key}
                              onChange={index.func}
                              id={index.id}
                              type={index.type}
                              placeholder={index.placeHolder}
                              value={index.value}
                              className={index.className}
                            />
                          )
                        })}
                        <div onClick={addPhone} className='formPhoneFormBtn'>
                          <SvgIcon name={iconList.IconPlusCircleOutLine} color={'#757575'} size={25} />
                        </div>
                      </div>}
                    <div className='formPhoneList'>
                      {formRegister.phones.map((index, key) => {
                        return (
                          <div key={key} className='formPhoneListGroup'>
                            <div className='formPhoneListGroupInfo'>
                              <p>{index?.codePhone}</p>-
                              <p>{index?.phoneNumber}</p>
                            </div>
                            <div className='formPhoneListGroupActions'>
                              <div onClick={() => editPhone(index)} className='formPhoneListGroupActionsBtn'><SvgIcon name={iconList.IconPencilOutLine} color={'#757575'} size={25} /></div>
                              <div onClick={() => deletePhone(index)} className='formPhoneListGroupActionsBtn'><SvgIcon name={iconList.IconTrashOutLine} color={'#757575'} size={25} /></div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className='formAddrees'>
                    <div onClick={() => setIsShowModal(true)} className='formPhoneLabelBtn'>
                      <label className='formPhoneLabel'>Agregar dirección
                        <SvgIcon name={iconList.IconPlusCircleOutLine} color={'#104172'} size={25} />
                      </label>
                    </div>
                    <div className='formPhoneList'>
                      {formRegister.address.length > 0 && formRegister.address.map((index, key) => {
                        return (
                          <div key={key} className='formPhoneListGroup'>
                            <div className='formAddresListGroupInfo'>
                              <p>{index.countryName}. {index.departmentName}. {index.cityName}.</p>
                              <p>{index.postalCode}{index.description}</p>
                            </div>

                            <div className='formPhoneListGroupActions'>
                              <div className='formPhoneListGroupActionsBtn' onClick={() => editAddress(index)}>
                                <SvgIcon name={iconList.IconPencilOutLine} color={'#757575'} size={25} />
                              </div>
                              <div className='formPhoneListGroupActionsBtn' onClick={() => deleteAddress(index)}>
                                <SvgIcon name={iconList.IconTrashOutLine} color={'#757575'} size={25} />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                </div>
                <div className='userRegisterFormContainerFoot'>
                  <div onClick={() => setStep(1)}
                    className={`formBtnSend small formBtnSendAvaliable`}>
                    <p className='formBtnSendText'>Atrás</p>
                  </div>
                  <div onClick={() => setStep(3)}
                    className={`formBtnSend medium formBtnSendAvaliable`}>
                    <p className='formBtnSendText'>
                      {formRegister.phones.length == 0 && formRegister.address.length == 0 ? 'omitir' : 'siguiente'}
                    </p>
                  </div>
                </div>
              </>
            }



            {step === 3 &&
              <>
                <div className='userRegisterFormContainerBody'>
                  <label className='userRegisterInputLabel'>selecciona un avatar</label>
                  <div className='formAvatar'>
                    {avataresList.length > 0 && avataresList.map((index, key) => {
                      const selected = formRegister?.avatar?.img === index?.img
                      return (
                        <div key={key} className='formAvatarContainer'>
                          <div

                            onClick={() => setInputsForm(index, 'avatar')}

                            className={`formAvatarContainerWrap ${selected ? 'formAvatarSelected' : ''}`}>

                            <img src={index?.img} alt='avatar' className='formAvatarImg' />
                          </div>
                          {selected &&
                            <div className='formAvatarContainerIcon'>
                              <SvgIcon name={iconList.IconCheckSucces} color={'#ffffff'} size={25} />
                            </div>}
                        </div>
                      )
                    })}
                  </div>
                  {/* <input type="image"/> */}
            {/* <div>
                    <label></label>
                    <div>
                    <input type="date"/>
                    </div>
                  </div> *}


                </div>
                <div className='userRegisterFormContainerFoot'>
                  <div onClick={() => setStep(2)}
                    className={`formBtnSend small formBtnSendAvaliable`}>
                    <p className='formBtnSendText'>Atrás</p>
                  </div>
                  <div onClick={() => setStep(4)}
                    className={`formBtnSend medium formBtnSendAvaliable`}>
                    <p className='formBtnSendText'>
                      {formRegister.avatar === null && formRegister.dateOfBirth === null ? 'omitir' : 'siguiente'}
                    </p>
                  </div>
                </div>
              </>
            }



            {step === 4 &&
              <>
                <div className='userRegisterFormContainerBody'>
                  {inputsList.length > 0 && inputsList.map((index, key) => {
                    const isError = inputError && inputError.key === index.id;
                    return (
                      <div key={key} className='userRegisterInputContainer'>
                        {isError ?
                          <p className='userRegisterInputErrorLabel'>{inputError?.message}</p>
                          :
                          <label className='userRegisterInputLabel'>
                            {index?.label}
                            <b>{index.require ? '*' : ''}</b>
                          </label>
                        }
                        <div className={`userRegisterInput ${isError && 'userRegisterInputError'}`}>
                          <div className='userRegisterInputIcon'>
                            <SvgIcon name={index?.icono} color={'#757575'} size={25} />
                          </div>
                          <input
                            type={index?.type}
                            name={index?.name}
                            id={index?.id}
                            value={index?.value}
                            placeholder={index?.placeHolder}
                            onChange={index?.func}
                            className='userRegisterInputInput'
                          />
                          <div className='userRegisterInputShowPassword' onClick={() => setShowPassword(!showPassword)}>
                            <SvgIcon name={showPassword ? iconList.IconEyeOn : iconList.IconEyeOff
                            } color={'#757575'} size={25} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
                <div className='userRegisterFormContainerFoot'>
                  <div onClick={() => setStep(3)}
                    className={`formBtnSend small formBtnSendAvaliable`}>
                    <p className='formBtnSendText'>Atrás</p>
                  </div>
                  <div className={`formBtnSend medium ${btnDisable ? 'formBtnSendDisable' : 'formBtnSendAvaliable'}`} onClick={() => !btnDisable && onRegister()}>
                    <p className='formBtnSendText'>Finalizar</p>
                  </div>
                </div>
              </>
            }
            */}
          </div>



          {/* <div className={`userRegisterModal ${isShowModal ? 'userRegisterModalShow' : 'userRegisterModalHidden'}`}>
            <div className='userRegisterModalContainer'>
              <div onClick={() => setIsShowModal(false)} className='userRegisterModalBtn'>
                <SvgIcon name={iconList.IconCloseCircleOutLine} color={'#757575'} size={25} />
              </div>
              {/* addAddress, *}
              <SelectListDirecion />
            </div>
          </div> */}

        </section>

        <section className='sectionRight'>
          <div className='sectionRightTextContainerHeader'>
            <a href={screensList.home}>
              <div className='goToFromScreen'>
                <p className='goToFromScreenText'>Home</p>
              </div>
            </a>
            <a href={screensList.loging}>
              <div className='goToFromScreen'>
                <p className='goToFromScreenText'>LogIn</p>
              </div>
            </a>
          </div>
          <div className='sectionRightTextContainer'>
            <p className='sectionRightTextContainerText'>
              Agregar aqui un texto que sea funcional.
            </p>
          </div>
          <img src={BannerTours} alt='background' className='background' />

        </section>
      </div>
    </div>
  )
}

export default UserRegister;
