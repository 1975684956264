import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import img1 from "../../../../assets/svg/packages1.png"
import img2 from "../../../../assets/svg/packages2.png"
import CardGlobal from '../../../share/components/cardGlobal'
import styled from 'styled-components'

import "./styles.css"

import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid, Pagination } from '@mui/material'

const MotionConstraints = styled(motion.div)`overflow-x: hidden;`;
const MotionBox = styled(motion.div)`width: 400vw;`

const PackageSection = ({inputValue, selectValue, seker}) => {
    if (!inputValue) {
        inputValue = '';
    }

    //Variables de estado
    const [loading, setLoading] = useState(true);
    const [dataPackage, setDataPackage] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValueSection, setInputValueSection] = useState(inputValue)
    const [selectValueSection, setSelectValueSection] = useState(selectValue)

    //Variables de paginación
    const itemsPerPage = 8;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataPackage.slice(indexOfFirstItem, indexOfLastItem);

    //Contador
    let counter = 0;

    //Traducción
    const [t] = useTranslation("global");

    //Función para cambiar de página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    //Función para buscar servicios
    const findServices = () => {
    
        if (counter === 0) {
          axios.get("https://aiop.com.co/api/packages", {
            params: {
                "destination_type": selectValueSection,
                "search": inputValueSection
            }
          })
          .then(res => {
            counter += 1;
            setDataPackage(res.data.packs);
            setLoading(false);
          })
          .catch(error => {
            counter += 1;
            console.log(error);
            setLoading(false);
          });
        }
      };

    //Efecto para buscar servicios
    useEffect(() => {
        findServices();
    }, [inputValueSection, selectValueSection])

    useEffect(() => {
        setInputValueSection(inputValue)
        setSelectValueSection(selectValue)
    }, [inputValue, selectValue])

    useEffect(() => {
        findServices();
    }, [])

    const constraintsRef = useRef(null)


    return (
        <div className='packages-section'>
            <h2 className='title-packages-section'>{t("packages.packages_home.packages")}</h2>
            <p className='p-pack'>{t("packages.packages_home.best_price")}</p>
        <div>
        <div>
            <div>
                {
                    seker ? (
                        <div>
                            <div>
                                <label className='label-filter-package' htmlFor="">Lugar</label>
                                <input className='input-filter-packages' type="text" onChange={(event) => setInputValueSection(event.target.value)} />
                            </div>
                            <div>
                                <label className='label-filter-package' htmlFor="">Tipo de destino</label>
                                <select className='select-filter-packages' name="" id="" onChange={(event) => setSelectValueSection(event.target.value)}>
                                    <option value="">Todas</option>
                                    <option value="international">Internacional</option>
                                    <option value="national">Nacional</option>
                                    <option value="regional">Regional</option>
                                </select>
                            </div>
                        </div>
                    ):(
                        null
                    )
                }
            </div>
            <div className='layout-packages'>
                {
                    loading ? (
                        <div>
                            <CircularProgress />
                        </div>
                    ) : (
                        currentItems && currentItems.length === 0 ? (
                            <h1>No hay paquetes que coincidan con tu busqueda</h1>
                        ) : (
                            currentItems && currentItems.length > 0
                                ? <Grid container spacing={6} justify="center" alignItems="center" style={{ marginTop: "10px" }}>
                                    {currentItems.map((pack, index) => (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <CardGlobal id={pack.id} imgType={false} descripcion={pack.description} img={pack.image} price={pack.price} stars={pack.stars} title={pack.name} address={pack.address} currency={pack.currency} />
                                        </Grid>
                                    ))}
                                </Grid>
                                : <h1>No hay paquetes que coincidan con tu búsqueda</h1>
                        )
                    )
                }
                {
                    currentItems && currentItems.length === 0 ? (
                        null
                    ):(
                        <Pagination className='pagination-packages' count={Math.ceil(dataPackage.length / itemsPerPage)} page={currentPage} onChange={handlePageChange} />
                    )
                }
            </div>
        </div>
        </div>
            <div className='layout-section-packages' style={{ marginTop: "70px",marginLeft:"-25px",width:"107%"}}>
                <section>
                    <img src={img1} alt={img1} />
                    <div className='contain-letters'>
                        <h2>{t("packages.packages_tourist")}</h2>
                    </div>
                </section>
                <section>
                    <img src={img2} alt={img2} />
                    <div className='contain-letters'>
                        <h2>{t("packages.packages_home.packs_health")}</h2>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PackageSection