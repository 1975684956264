import React from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import "./styles.css"

const DetailBuy = ({ item }) => {
    return (
        <div className='content-detail-buy-hotel'>
            <h2>Detalle de compra</h2>
            <h3>{item?.vehicle}</h3>
            <p style={{ fontSize: "13px" }}>Covid Prepared:{item?.covidPrepared ? " Si" : " No"}</p>
            <p style={{ display: "flex", alignItems: "center" }}><SvgIcon color={"#004274"} name={iconList.IconPinMap} size={25} /> Ver mapa</p>
            <div className='content-check-group'>
                <section className='check-in-detail'>
                    <span>Capacidad </span>
                    <span>{item?.passengerCapacity}</span>
                </section>
                <section className='check-in-detail'>
                    <span>Duracion </span>
                    <span>{item?.duracionInMinutes} Min</span>
                </section>
            </div>
           
            
        </div>
    )
}

export default DetailBuy