import ActivityIndicator from "../../../ui/views/share/components/activityIndicator";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Assets/styles.css";

function BenefitValue({id, isModalOpen, closeModal}){
    const env = process.env.REACT_APP_URL_ENV;

    const [benefitValue, setBenefitValue] = useState([])
    const [loading, setLoading] = useState(true)

    let url;

    switch (env) {
        case "local":
          url = process.env.REACT_APP_URL_API_AIOP_LCH;
          break;
        case "staging":
          url = process.env.REACT_APP_URL_API_AIOP_STG;
          break;
        case "production":
          url = process.env.REACT_APP_URL_API_AIOP_PRT;
          break;
      }
    
    const getBenefitValue = async () => {

        try{
            const response = await axios.get(`${url}api/terrawind/products/${id}/coverages`)
            setBenefitValue(response.data)
        }catch(error){
            console.error("Error en la llamada a la API de planes", error)
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        if(isModalOpen){
            getBenefitValue()
        }
    }, [isModalOpen])
    
    const handleClickOutside = (event) => {
        event.stopPropagation();
        closeModal();
    }

    const handleClickedInside = (event) => {
        event.stopPropagation();
    }

    if(!isModalOpen){
        return null;
    }

    return(
        <div className="benefit-value-container" onClick={handleClickOutside}>
            <div className="view-value-benefit" onClick={handleClickedInside}>
                {
                    loading ? (
                        <div className="loading">
                            <ActivityIndicator colorFill='#004274' size={50} />
                        </div>
                    ) : (
                        <div>
                            <div>
                                <div className="benefit-value">
                                    <div id="benefit-container-benefit">
                                        <div className="title-benefit-value">
                                            <p>Beneficio</p>
                                        </div>
                                    </div>
                                    <div id="benefit-container-value">
                                        <div className="title-benefit-value">
                                            <p>Valor</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        benefitValue.map((valueBenefit, index) => (
                                            <div className="benefit-value" key={index}>
                                                <div id="plan-benefit">
                                                    <p>{valueBenefit.coverage_name}</p>
                                                </div>
                                                <div id="plan-value">
                                                    <p>{valueBenefit.coverage_val}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="btn-close-benefit">
                                <button onClick={closeModal}>CERRAR BENEFICIOS </button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default BenefitValue;