

const CleanText = () => {
    const cleanTextHelper = (texto)=>{
        return (
            texto.replace(/[^\wáéíóúÁÉÍÓÚñÑüÜ\s\d:.]/g, '').replace(/\s+/g, ' ')
        )
    }
    
  return {
    cleanTextHelper
  }
}

export default CleanText
