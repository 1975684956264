import axios from 'axios';
import { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Api from './Api';


const ResultSearch_Model = () => {

  const [menuHamburger, setMenuHamburger] = useState(false)
  const [buscadorMenu, setBuscadorMenu] = useState(false)
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [passengerFilter, setPassengerFilter] = useState(7)
  const [filtrado, setFiltrado] = useState([0])
  const [activeFilterP, setActiveFilterP] = useState(false)
  const [activeFilterPrice, setActiveFilterPrice] = useState(false)
  const { state } = useLocation()
  const [resultados, setResultados] = useState([])
  const [resusltadosFirst, setResultadosFirst] = useState([])

  const [firstRange, setFirstRange] = useState(0)
  const [secondRange, setSecondRange] = useState(0)
  const [errorFilter, setErrorFilter] = useState('')
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()

  const { servicesUrl } = Api()

  const menuHambur = (type) => {
    setMenuHamburger(!menuHamburger)
    type ? setFiltersMenu(!filtersMenu) : setBuscadorMenu(!buscadorMenu)
  }


  const resetFilter = () => {
    setFiltrado(state?.results)
  }

  function reloadPage() {
    window.location.reload(true)
  }


  const filterPerPassenger = () => {
    if (resusltadosFirst.length > 0) {
      const filtrados = resusltadosFirst.filter(item => {
        const number = item.passengerCapacity.split('-')
        return Number(number[0]) <= passengerFilter && Number(number[1]) >= passengerFilter;
      })
      console.log(filtrados)
      setResultados(filtrados)
    }
  }

  const filterPerPrice = () => {
    if (firstRange === 0 || secondRange === 0) {
      setErrorFilter('Es necesario llenar los 2 campos')
    } else {
      if (resusltadosFirst.length > 0) {
        setErrorFilter('')
        const filtrados = resusltadosFirst.filter(item => {
          return item?.totalPriceUSD >= firstRange && item?.totalPriceUSD <= secondRange
        })

        setResultados(filtrados)
      }
    }
  }


  const request = {
    "transfer": state.type,
    "token": localStorage.getItem('politicas'),
    "city": state.cityId,
    "origin": state.originId,
    "destination": state.destinyId,
    "date_time": state.dateApi,
    "passenger": state.adult,
    "user": state.userId,
    "baby": state.baby
  }




  useEffect(() => {
    setLoader(true)
    if (state?.type) {
      console.log(request)
      axios.post(servicesUrl, request)
        .then(res => {
          console.log(res.data)
          if (!res.data.response) {
            setLoader(false)
          } else {
            setLoader(false)
            setResultados(res.data.response)
            setResultadosFirst(res.data.response)
          }

        })
        .catch(error => {
          console.log(error)
          setLoader(false)
        })
    }else{
      setLoader(false)
    }
  }, [state])


  const goToReservation = (item) => {
    navigate("/transport/reserve", {
      state: {
        price: item?.totalPriceUSD,
        dateTime: state.dateApi,
        passengers: state.adult,
        baby: state.baby,
        token: localStorage.getItem('politicas'),
        product: item?.id,
        user: state.userId,
        type: state.type,
        origin: state.originId,
        destination: state.destinyId,
        city: state.cityId,
        service: item
      }
    })
  }




  return {
    menuHambur,
    menuHamburger,
    buscadorMenu,
    filtersMenu,
    passengerFilter,
    setPassengerFilter,
    filtrado,
    activeFilterP,
    setActiveFilterP,
    resetFilter,
    setActiveFilterPrice,
    activeFilterPrice,
    setFirstRange,
    setSecondRange,
    errorFilter,
    resultados,
    filterPerPrice,
    filterPerPassenger,
    loader,
    goToReservation
  }
}

export default ResultSearch_Model