import './styles.css';
import useInitianRoom from "../../../viewModels/videoSdk/initialRoom.viewModel";
import { MeetingProvider } from "@videosdk.live/react-sdk";
import { authToken } from '../../../../infra/constants/tokens.video.sdk';
import JoinScreen from '../joinScreen';
import MeetingView from '../meetingView';

function InitianRoomComponent({
  meetingId,
  getMeetingAndToken,
  onMeetingLeave,
  creatingRoom,
  user,
  isDisabledRoom,
  isValidatingRoom,
}) {
  return (
    <div className='videsdk initianRoomComponent'>
      {
        authToken !== '' && meetingId ?
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: true,
              webcamEnabled: true,
              name: user?.name,
            }}
            token={authToken}
          >
            <MeetingView
              meetingId={meetingId}
              onMeetingLeave={onMeetingLeave}
              user={user}
              isDisabledRoom={isDisabledRoom}
              isValidatingRoom={isValidatingRoom}
            />
          </MeetingProvider>
          :
          <JoinScreen getMeetingAndToken={getMeetingAndToken} meetingId={meetingId} creatingRoom={creatingRoom} />
      }
    </div>
  )
}



function InitialRoom({code}) {

  const {
    meetingId,
    getMeetingAndToken,
    onMeetingLeave,
    creatingRoom,
    user,
    isDisabledRoom,
    isValidatingRoom,
  } = useInitianRoom(code);

  return (
    <InitianRoomComponent
      meetingId={meetingId}
      getMeetingAndToken={getMeetingAndToken}
      onMeetingLeave={onMeetingLeave}
      creatingRoom={creatingRoom}
      user={user}
      isDisabledRoom={isDisabledRoom}
      isValidatingRoom={isValidatingRoom}
    />
  )
}

export default InitialRoom;
