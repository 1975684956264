import useLoginUser from '../../../viewModels/session/login.user.viewModel';
import './styles.css';
import SvgIcon, { iconList } from '../../share/components/iconSVG';
import InputRadio, { typesRadio } from '../../share/components/inputs/inputRadio';
import { global } from '../../../global'
import SelectListDirecion from '../../share/components/selectListDirecion';
import ActivityIndicator from '../../share/components/activityIndicator';

function UserLogin() {
  const {
    screensList,
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    loading,
    getInputError,
    onLogin,
  } = useLoginUser()

  const {
    BannerTours
  } = global.img.pictures;

  return (
    <div className='userRegister'>
      <div className='userRegisterContainer'>
        <section className='sectionLeft'>
          <div className='userRegisterFormContainer'>
            <div className='userRegisterFormContainerHead'>
              <p className='userRegisterFormTitle'>Inicia Sesion</p>
            </div>
            <div className='userRegisterFormContainerBody'>
              {inputsList.length > 0 && inputsList.map((index, key) => {
                const isError = getInputError(index);
                const showBtn = ['password', 'confirmPassword'].includes(index?.id);
                return (
                  <div key={key} className='userRegisterInputContainer'>
                    {isError ?
                      <p className='userRegisterInputErrorLabel'>{isError?.message}</p>
                      :
                      <label className='userRegisterInputLabel'>
                        <b>{index.require ? '* ' : ''}</b>
                        {index?.label}
                      </label>
                    }
                    <div className={`userRegisterInput ${isError && 'userRegisterInputError'}`}>
                      <div className='userRegisterInputIcon'>
                        <SvgIcon name={index?.icono} color={'#757575'} size={25} />
                      </div>
                      <input
                        type={index?.type}
                        name={index?.name}
                        id={index?.id}
                        value={index?.value}
                        placeholder={index?.placeHolder}
                        onChange={index?.func}
                        className='userRegisterInputInput'
                        onFocus={index?.focus}
                      />
                      {showBtn &&
                        <div className='userRegisterInputShowPassword' onClick={() => setShowPassword(!showPassword)}>
                          <SvgIcon name={showPassword ? iconList.IconEyeOn : iconList.IconEyeOff
                          } color={'#757575'} size={25} />
                        </div>
                      }
                    </div>
                  </div>
                )
              })
              }
            </div>

            <div className='userRegisterFormContainerFoot'>
              <div onClick={() => !btnDisable && onLogin()}
                className={`formBtnSend big ${btnDisable ? 'formBtnSendDisable' : 'formBtnSendAvaliable'}`}>
                {loading
                  ? <ActivityIndicator size={30} colorFill={'white'}
                  />
                  : <p className='formBtnSendText'>Entrar</p>
                }
              </div>
            </div>
          </div>
        </section>

        <section className='sectionRight'>
          <div className='sectionRightTextContainerHeader'>
            <a href={screensList.home}>
              <div className='goToFromScreen'>
                <p className='goToFromScreenText'>Home</p>
              </div>
            </a>
            <a href={screensList.register}>
              <div className='goToFromScreen'>
                <p className='goToFromScreenText'>Registarme</p>
              </div>
            </a>
          </div>
          <div className='sectionRightTextContainer'>
            <p className='sectionRightTextContainerText'>
              Todo lo que necesitas a tu alcance en un solo carrito de compras.
            </p>
          </div>
          <img src={BannerTours} alt='background' className='background' />

        </section>
      </div>
    </div>
  )
}

export default UserLogin;
