import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'

const AboutHotel = ({name,information, ubication}) => {
  const [t] = useTranslation("global")
  return (
    <div className='about-hotel-detail'>
        <h3>{t("hotel.about")} {name}</h3>
        <div className='contain-information-about-hotel'>
            <p>
                {information}
            </p>
            <p>
                {ubication}
            </p>
        </div>
    </div>
  )
}

export default AboutHotel