import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'





const BannerDetail = ({name, specialist, urlImage, pathImage, setModal, goReservation}) => {

    const [t] = useTranslation("global")

    
  return (
    <div className='contain-banner-detail-specialist'>
        <section className='background-banner-specialist-detail'>
            <div className='info-banner-detail-specialist'>
                <div>
                    <img src={`https://aiop.com.co/storage/doctors/1/profile/ubWHqMgA7QaBSBr4ecW0iriX3QqSl9fpBjVyVcZD.png`} alt="" />
                </div>
                <div className='info-banner-detail-specialist-second'>
                    <h1>{name}</h1>
                    <p>
                        {
                            specialist.map((item,key)=>{
                                return(
                                    <span key={key}>{item.name}, </span>
                                )
                            })
                        }
                    </p>
                    <div className='contain-buttons-banner-specialist-detail'>
                        <button onClick={()=>setModal(true)}>
                            {t("specialist.detail.make_appointment")}
                        </button>
                        <button onClick={goReservation}>
                            {t("specialist.detail.request_quote")}
                        </button>
                        <button >
                            {t("specialist.detail.contact")}
                        </button>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}

export default BannerDetail