import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import UserContext from '../../../Context/user/userProvider';
import { config } from '@fortawesome/fontawesome-svg-core';

function usePaymentCards() {
  const [cardsRegisteredList, setCardsRegisteredList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { userDetail, userToken } = useContext(UserContext);
  const [isFetchign, setIsFetchign] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSendingCard, setIsSendingCard] = useState(false);
  const [successfull, setSuccessfull] = useState(true);
  const [message, setMessage] = useState('');
  const [idPay, setIdPay] = useState(userDetail === null ? "" : userDetail.id,)

  console.log( "payment",userDetail)
  function addCard() {
    setIsOpenModal(true);
  }

  useEffect(() => {
    getMyMethodpays()
    setIdPay(userDetail === null ? idPay : userDetail.id,)
  }, [userDetail])
  
  async function getResponsePaymentezAdd(data) {
    console.log("recibiendo data: ", data)
    const statusOfCard = {
      valid: 'valid',
      rejected: 'rejected',
      review: 'review',
    }
    if (isSendingCard) { return; }

    try {
      setIsSendingCard(true)
      const card = data?.card;
      const cardError = data?.error;
      console.log("-> cardError: ", cardError)
      console.log(" -> card: ", card)
      if (cardError) {
        setSuccessfull(false);
        setMessage(cardError?.help)
      }

      if (card?.status !== statusOfCard?.rejected) {
        const newData = {
          bank_name: data?.card?.bank_name || "",
          bin: data?.card?.bin || "",
          expiry_month: data?.card?.expiry_month || "",
          expiry_year: data?.card?.expiry_year || "",
          message: data?.card?.message || "",
          number: data?.card?.number || "",
          origin: data?.card?.origin || "",
          status: data?.card?.status || "",
          token: data?.card?.token || "",
          tel: idPay,
          transaction_reference: data?.card?.transaction_reference || "",
          type: data?.card?.type || ""
        }
        const url = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/pays`;
        const response = await axios.post(url, newData);
        if (response) {
          console.log("respuesta de nuestro servidor: ", response)
          setSuccessfull(true);
          setMessage('the inserted card has been registered successfully');
          await getMyMethodpays();
        }
      }
      else {
        setSuccessfull(false);
        setMessage("Sorry! The card inserted has been rejected");
      }
    } catch (error) {
      console.log('Error in getResponsePaymentezAdd()', error)
    }
  }

  async function deleteCard(idCard) {
    if (isDeleting !== false) { return; }
    try {
      setIsDeleting(idCard);
      const newData = {
        user: idPay,
        card: idCard
      }
      console.log(" deleting card: ", newData)
      const url = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/pays/delete`;
      const response = await axios.delete(url, {data: newData});
      console.log("Respuesta tarjeta",response)
      if (response) {
        setIsDeleting(false);
        await getMyMethodpays();
      }
    } catch (error) {
      console.log("Error in deleteCard()", error);
    }
    finally {
      setIsDeleting(false)
    }
  }

  async function getMyMethodpays() {
    setIsFetchign(true)
    try {
      //const url = 'https://aiop.com.co/api/pays';
      const newData = {
        tel: idPay
      }
      const url = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/pays/card`;
      const response = await axios.post(url,newData);
      const list = response?.data?.cards;
      console.log("newData: ", newData)
      console.log(response?.data)
      setCardsRegisteredList(list)
      if(list.length > 0){
        setIsOpenModal(false)
      }
    } catch (error) {
      console.log("Error in getMyMethodpays(): ", error);
    } finally {
      setIsFetchign(false)
    }
  };

  useEffect(() => {
    if (message !== '') {
      setTimeout(() => {
        setMessage('');
        setIsSendingCard(false);
      }, 10000);
    }
  }, [message])

  useEffect(() => {
      getMyMethodpays();
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    isOpenModal ? (body.style.overflow = 'hidden') : (body.style.overflow = '');
  }, [isOpenModal]);

  return {
    cardsRegisteredList,
    isOpenModal,
    setIsOpenModal,
    addCard,
    getResponsePaymentezAdd,
    userDetail,
    isFetchign,
    isDeleting,
    isSendingCard,
    deleteCard,
    message,
    successfull,
    setIdPay,
    idPay,
    getMyMethodpays
  }
}

export default usePaymentCards;