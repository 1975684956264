import { useState, useRef, useEffect,useCallback } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { widgetsList } from '../../../infra/constants/app';

const UseHotels = () => {

  const [t] = useTranslation("global")

  const { pathname } = useLocation()
  const cardRef = useRef(null)
  const [errApi, setErrApi] = useState(false)
  const navigate = useNavigate()
  const [openHotelSearch, setOpenHotelSearch] = useState(false)
  const [paceholders, setPaceholders] = useState('')
  const [search, setSearch] = useState('')
  const [destiny, setDestiny] = useState([])
  const [destinyCopy, setDestinyCopy] = useState([])
  const [destinitySelected, setDestinitySelected] = useState('')
  const [nacionalityDropdown, setNacionalityDropdown] = useState(false)
  const [dataNacionality, setDataNacionality] = useState([])
  const [loadingDestiny, setLoadingDestiny] = useState(true)
  const [dateEntry, setDateEntry] = useState(false)
  const [nacionality, setNacionality] = useState('')
  const [idHotel, setIdHotel] = useState("")
  const [loading, setLoading] = useState(false)
  const [validationForm, setValidationForm] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [codeNacionality, setCodeNacionality] = useState('')
  const [allDataCodeCobol, setAllDataCodeCobol] = useState([])
  const [onlyCodeCobol, setOnlyCodeCobol] = useState([])
  const [datesSelected, setDatesSelected] = useState({
    entryDate: t('hotel.search_engine.entrance'),
    exitDate: t('hotel.search_engine.exit')
  })

  let quantity = 0

  const [cardRooms, setCardRooms] = useState(false)
  const [roomsSelected, setRoomsSelected] = useState([
    {
      adult: 1,
      child: 0,
      age: []
    }
  ])

  const [quantityPersons, setQuantityPersons] = useState(1)

  useEffect(() => {
    let contador = 0
    for (let index = 0; index < roomsSelected.length; index++) {
      contador += roomsSelected[index].adult + roomsSelected[index].child
    }
    setQuantityPersons(contador)
   
  }, [roomsSelected])


  const openHotelSearchFuct = () => {
    setOpenHotelSearch(!openHotelSearch)
  }

  const filterDestinacion = () =>{
      if(search === ""){
        setDestiny(destinyCopy)
      }else{
        const filteredDestiny = destiny.filter((e) =>
        e.Country.toLowerCase().includes(search.toLowerCase())
      );
      setDestiny(filteredDestiny);
      }
  }

  const destinationSelectionFunc = (element) => {
    setPaceholders(element.name)
    setDestinitySelected(element.code)
    // setIdHotel(element.Id)
    setOpenHotelSearch(false)
  }

  const findCountryClient = (country) => {
    setLoading(true)
    const url = `${process.env.REACT_APP_URL_API_FLY}restel/country`
    axios.post(url, {
      'code': country
    })
      .then(res => {
        setDataNacionality(res.data)
        setLoading(false)
        console.log(res.data)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const selectedNacionality = (code, name) => {
    setNacionality(name)
    setCodeNacionality(code)

  }

  function reloadPage() {
    window.location.reload(true)
  }


  function generateUniqueId() {
    return 'xxxxx-xxxx-4xxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const data = {
    "arrival": `${datesSelected.entryDate}`,
    "departure": `${datesSelected.exitDate}`,
    "country": "CO",
    "countryClient": codeNacionality,
    "hotels": onlyCodeCobol,
    "rooms": roomsSelected
  }

  function convertirAFormatoDeseadoC(listaObjetos) {
    const codigosEnFormatoDeseado = listaObjetos.map(objeto => objeto.code);
    return codigosEnFormatoDeseado;
  }
  function convertirAFormatoDeseadoA(listaObjetos) {
    const codigosEnFormatoDeseado = listaObjetos.map(objeto => objeto.adult);
    return codigosEnFormatoDeseado;
  }
  function convertirAFormatoDeseadoCH(listaObjetos) {
    const codigosEnFormatoDeseado = listaObjetos.map(objeto => objeto.child);
    return codigosEnFormatoDeseado;
  }
  
  const codeCobol = convertirAFormatoDeseadoC(onlyCodeCobol);
  const adults = convertirAFormatoDeseadoA(roomsSelected)
  const childs = convertirAFormatoDeseadoCH(roomsSelected)

  const fechaLlegadaFormateada = datesSelected.entryDate.replace(/\//g, '-');
  const fechaSalidaFormateada = datesSelected.exitDate.replace(/\//g, '-');


  // Función para formatear la propiedad 'age'
function formatearAge(objeto) {
  return objeto.age.length > 0 ? objeto.age.join('-') : '0';
}

// Procesar cada objeto y formatear 'age'
const resultados = roomsSelected.map(objeto => `${formatearAge(objeto)}`);

// Unir los resultados con '&' como separador
const resultadoFinal = resultados.join(',');
  
// /hotels/&arrival=9-22-2023&departure=9-25-2023&countryClient=CO&hotels=037840,056554,065586,069095,069627,123884,123887,124435,125929,160523,169309,354819,395524,465239,467266,471105,471110,471129,596076,613502,613503,658599,658612,665564,665598,667569,671417,691840,691841,726153,769485,795605,849610,952224,952229,952885,955158,993195,993688,993769,993785,993857,993866,996124,997020,997047,997448,997659,997704,A1E460,A20472,AB2B91,B89E37,BC5459,C0BF32,C5A052,C5A372,C5B520,C5C268,C5D237,C70C00,C79633,C8C316,C94865,C94A32&adult=1,1&child=2,1&age=1-1,1

//arrival: fecha de entrada
//departure: fecha de salida
//countryClient: nacionalidad del cliente
//hotels: codigos cobol
//adult: cantidad de adultos, las comas separan las habitaciones
//child: cantidad de niños, las comas separan las habitaciones,
//age: edades de los niños, el guion indica los diferentes niños que hay dentro de la habitacion y la coma separa las habitaciones

const searchHotelNewApi = () =>{
  setErrApi(false)
  setLoadingSearch(true)
  if(idHotel === "" || datesSelected.entryDate === 'Entrada' || datesSelected.exitDate === 'Salida' || paceholders === '' ){
    setValidationForm(true)
    setLoadingSearch(false)
  }else{
    axios.get("https://travelflight.pdtcomunicaciones.com/api/hotel-do/hotels",{
      params:{
        "destination":idHotel
      }
    })
        .then(res => {
          setLoadingSearch(false)
          setValidationForm(false)
          const isWidget = widgetsList.find((w) => w.path === window.location.pathname);
          let url = ""
          if(isWidget){
            url = `/widget/hotels/`
          }else if(pathname.includes("/packages")){
            url = "/packages/hotels"
            localStorage.setItem("urlHotel",`/packages/hotels/&arrival=${fechaLlegadaFormateada}&departure=${fechaSalidaFormateada}&countryClient=${codeNacionality}&hotels=${codeCobol.join(",")}&adult=${adults.join(",")}&child=${childs.join(",")}&age=${resultadoFinal}`)
          }else{
            url = "/hotels"
          }
          console.log(res.data)
          navigate(`${url}/idHotel=${idHotel}&arrival=${fechaLlegadaFormateada}&departure=${fechaSalidaFormateada}&adult=${adults.join(",")}&child=${childs.join(",")}&age=${resultadoFinal}`, {
            state: {
              data: res.data.Hotel,
            }
          })
          reloadPage()
        })
        .catch(err => {
          console.log(err)
          setErrApi(true)
          setLoadingSearch(false)
        })
  }
}

  const searchHotelSelected = () => {
    setErrApi(false)
    setLoadingSearch(true)
    const urlApi = `${process.env.REACT_APP_URL_API_FLY}restel/disponibility`
    if (datesSelected.entryDate === 'Entrada' || datesSelected.exitDate === 'Salida' || paceholders === '' || codeNacionality === '' || onlyCodeCobol === '') {
      setValidationForm(true)
      setLoadingSearch(false)
    } else {
      axios.post(urlApi, data)
        .then(res => {
          setLoadingSearch(false)
          setValidationForm(false)
          const isWidget = widgetsList.find((w) => w.path === window.location.pathname);
          let url = ""
          if(isWidget){
            url = `/widget/hotels/`
          }else if(pathname.includes("/packages")){
            url = "/packages/hotels"
            localStorage.setItem("urlHotel",`/packages/hotels/&arrival=${fechaLlegadaFormateada}&departure=${fechaSalidaFormateada}&countryClient=${codeNacionality}&hotels=${codeCobol.join(",")}&adult=${adults.join(",")}&child=${childs.join(",")}&age=${resultadoFinal}`)
          }else{
            url = "/hotels"
          }
          console.log(res.data)
          navigate(`${url}/&arrival=${fechaLlegadaFormateada}&departure=${fechaSalidaFormateada}&countryClient=${codeNacionality}&hotels=${codeCobol.join(",")}&adult=${adults.join(",")}&child=${childs.join(",")}&age=${resultadoFinal}`, {
            state: {
              data: res.data,
              persons: quantityPersons,
              rooms: roomsSelected,
              fen: datesSelected.entryDate,
              fsa: datesSelected.exitDate,
              country_client: codeNacionality
            }
          })
           reloadPage()
        })
        .catch(err => {
          console.log(err)
          setErrApi(true)
          setLoadingSearch(false)
        })
    }
  }

  // useEffect(() => {
  //   const getResults = async () => {
  //     const result = await axios.get('https://travelflight.pdtcomunicaciones.com/api/hotel-do/destination')
  //     setDestiny(result.data.Destination)
  //     setDestinyCopy(result.data.Destination)
  //   }
  //   getResults()
  // }, [])

  useEffect(() => {
    const source = axios.CancelToken.source();
    const url = `${process.env.REACT_APP_URL_API_FLY}restel/cities`
    const getResults = async () => {
      try {
        setDestiny([])
        const res = await axios.post(url, {
          "code": `${search}`
        }, { cancelToken: source.token });
  
        setDestiny(res.data);
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.log("Error en searchCity: ", err);
        }
      }
    };
  
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };
  
    const debouncedSearchCity = debounce(getResults, 2000);
  
    
  
   debouncedSearchCity()
  
    return () => {
      source.cancel("Componente desmontado. Cancelar solicitudes pendientes.");
    };
  }, [search]);


  // useEffect(() => {
  //   filterDestinacion()
  // }, [search])
  

  useEffect(() => {
    if (paceholders !== '') {
      const url = `${process.env.REACT_APP_URL_API_FLY}restel/hotels?page=1`
      axios.post(url, {
        "city": paceholders
      })
        .then(res => {
          setAllDataCodeCobol(res.data[0].data)

          if (res.data[0].last_page === 2) {
            axios.post(res.data[0].last_page_url, {
              "city": paceholders
            })
              .then(res2 => {
                setAllDataCodeCobol(res2.data[0].data)
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }



  }, [paceholders])


  function obtenerCodeCOBOL(arr) {
    let codeCOBOLArray = [];

    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i];
      let codeObj = { "code": obj.code_cobol };
      codeCOBOLArray.push(codeObj);
    }

    setOnlyCodeCobol(onlyCodeCobol => [...onlyCodeCobol, ...codeCOBOLArray])
  }



  useEffect(() => {

    obtenerCodeCOBOL(allDataCodeCobol)
    

  }, [allDataCodeCobol])



  useEffect(() => {
    const handler = (e) => {
      if (!cardRef?.current?.contains(e.target)) {
        setOpenHotelSearch(false)
        setDateEntry(false)
        setCardRooms(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])







  return {
    cardRef,
    openHotelSearch,
    setOpenHotelSearch,
    paceholders,
    setPaceholders,
    search,
    setSearch,
    destiny,
    setDestiny,
    destinitySelected,
    setDestinitySelected,
    dateEntry,
    setDateEntry,
    datesSelected,
    setDatesSelected,
    quantity,
    cardRooms,
    setCardRooms,
    roomsSelected,
    setRoomsSelected,
    quantityPersons,
    setQuantityPersons,
    openHotelSearchFuct,
    destinationSelectionFunc,
    searchHotelSelected,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    loadingSearch,
    validationForm,
    errApi,
    searchHotelNewApi
  }
}

export default UseHotels

