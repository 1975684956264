import { useState, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import './styles.css'
import GalleryImg from './GalleryImg/GalleryImg'
import ServicesInclude from './ServicesInclude/ServicesInclude'
import AboutHotel from './AboutHotel/AboutHotel'
import HabDetail from './HabDetail/HabDetail'
import CartReserve from './CartReserve/CartReserve'
import { useTranslation } from 'react-i18next'

const DetailHotel = () => {
  const [slideShow, setSlideShow] = useState(false)
  const [rooms, setRooms] = useState([])
  const [listReserve, setListReserve] = useState([])

  const {
    dataHab,
    dataHot,
    persons,
    fen,
    fsa,
    country_client
  }
    = useLocation().state

    const navigate = useNavigate()
    let suma = 0
    let line = []
    const [t] = useTranslation("global")
  
  function formatterText(frase) {
    // Dividir la frase en palabras individuales
    let palabras = frase.toLowerCase().split(' ');

    // Capitalizar la primera letra de cada palabra
    for (let i = 0; i < palabras.length; i++) {
      palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
    }

    console.log(dataHab)
    console.log(dataHot)

    // Unir las palabras en una sola frase
    let resultado = palabras.join(' ');

    return resultado;
  }

  useEffect(() => {
    // Función local para crear los nuevos objetos
    
      const newRooms = listReserve.map(item => {
          const [adults, children] = item?.acomodation?.split('-')?.map(Number);
        console.log(item)
    
        return {
          adult: adults,
          child: children,
          age: []
        };
      });
   

setRooms(newRooms)
  

  }, [listReserve]);



  useEffect(() => {
    const body = document.querySelector('body');
    slideShow ? body.style.overflow = 'hidden' : body.style.overflow = '';
  }, [slideShow])

  listReserve.forEach((item, key) => {
    suma += parseInt(item["@attributes"]?.prr);
    item?.lin.map((item,key)=>{
      line.push(item)
    })
})


  
  const navigateReservation = ()=>{

    navigate('/hotels/reservation', {
      state:{
        price: suma,
        code: dataHab.cod,
        hotel: dataHab.nom,
        arrival: fen,
        line: line,
        departure: fsa,
        country: 'CO',
        rooms:rooms,
        country_client: country_client,
        listReserve: listReserve,
        stars: dataHab?.cat,
        dataHot:dataHot,
        dataHab:dataHab
      }
    })
  }



  return (
    <div>
      <CarouselSevices current={servicesTravel[1]?.title} />
      <div className='detail-hotel-route'>
        <p>
          <Link to={'/hotel'}>{t("hotel.hotels")}</Link> {">"} {t("hotel.seacher")} {">"} <span >{dataHot?.nombre_h}</span>
        </p>
      </div>
      <div className='contain-title-hotel'>
        <h1>
          {dataHot?.nombre_h}
        </h1>
        <p>{dataHot?.provincia} {formatterText(dataHab.dir)}</p>
      </div>
      <div className='contain-img-principal-hotel'>
        <section className='section-img-big'>
          <img src={dataHot?.fotos?.foto[0]} alt="" />
          <span onClick={() => { setSlideShow(!slideShow) }}>{t("hotel.see_photos")}</span>
        </section>
        <section className='section-img-layout-for'>
          <img src={dataHot?.fotos?.foto[1]} alt="" />
          <img src={dataHot?.fotos?.foto[2]} alt="" />
          <img src={dataHot?.fotos?.foto[3]} alt="" />
          <img src={dataHot?.fotos?.foto[4]} alt="" />
        </section>
      </div>
      {
        slideShow &&
        <GalleryImg Images={dataHot?.fotos?.foto} setSlideShow={setSlideShow} />
      }
      <ServicesInclude serviciosHot={dataHot?.servicios?.servicio} serviciosHab={dataHot?.servicios_habitacion?.servicio_habitacion} checkin={dataHot?.checkin} checkout={dataHot?.checkout} star={dataHot?.categoria} />

      <AboutHotel information={dataHab?.desc} name={dataHot?.nombre_h} ubication={dataHab?.como_llegar} />

      <CartReserve list={listReserve} setList={setListReserve} navigateMakeReservation={navigateReservation}/>

      {
        dataHab.res.pax.map((item, key) => {
          return (
            <HabDetail Hab={item.hab} key={key} id={key} persons={persons} typeAcomodation={item['@attributes']?.cod} listReserve={listReserve} setListReserve={setListReserve} />
          ) 
        })
      }


    </div>
  )
}

export default DetailHotel