import React from 'react'
import './styles.css'

const Paginado = ({ nextHandler, prevHandler, currentPage, data, mostrarNext }) => {
    return (
        <div className='contain-Paginado-specialist'>
            {
                data?.length - currentPage === data?.length ?
                    ''
                    :
                    <button onClick={prevHandler}>
                        prev
                    </button>
            }

            <button>
                {currentPage + 1}
            </button>
            {
                mostrarNext === false  ?
                    ''
                    :
                    <button onClick={nextHandler}>
                        next
                    </button>
            }

        </div>
    )
}

export default Paginado