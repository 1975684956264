import React, { useEffect, useState } from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import Find from '../../share/components/Find/Find'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import ModalQuo from './ModalQuo/ModalQuo'
import UseDetailPackages from '../../../viewModels/packages/UseDetailPackages'
import axios from 'axios'
import ModalForm from './ModalForm/ModalForm'
import ModalAlert from './ModalAlert/ModalAlert'
import Slider from "react-slick";
import ModalPay from './ModalPay/ModalPay'

import { Link, useLocation } from 'react-router-dom'
import { currencyFormat } from '../../../utils'
import { CircularProgress, Grid } from '@mui/material';
import { ReactComponent as Calendar } from './Svg/Calendar.svg'
import { ReactComponent as ArrowDown } from './Svg/ArrowDown.svg'
import { ReactComponent as People } from './Svg/People.svg'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './styles.css'

const DetailPackages = () => {
    const imageUrls = [];

    const location = useLocation();
    const idPackage = location.pathname.split('/')[2];
    const regex = /(\d+)\snoches,\s(\d+)\sdías/;

    const [selectedImage, setSelectedImage] = useState(imageUrls[0]);
    const [packaged, setPackaged] = useState()
    const [loading, setLoading] = useState(true)
    const [cleanDescription, setCleanDescription] = useState('')
    const [images, setImages] = useState([])
    const [isModalOpenPackage, setIsModalOpenPackage] = useState(false)
    const [isModalOpenPay, setIsModalOpenPay] = useState(false)
    const [isModalOpenAlert, setIsModalOpenAlert] = useState(false)

    function closeModal(){
        setIsModalOpenPackage(false)
        setIsModalOpenPay(false)
        setIsModalOpenAlert(false)
    }

    const findServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL_API_AIOP_PRT}api/packages/${idPackage}`);
            setPackaged(response.data);
            setCleanDescription(response.data.pack.description);
            const imageUrls = response.data.pack.images.map(image => image.url);
            setImages([response.data.pack.image, ...imageUrls]);
        } catch (error) {
            console.error("Error en la llamada a la API de planes", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        findServices();
    }, []);

    useEffect(() => {
        if(packaged !== undefined){
            setSelectedImage(`${packaged.url}/${images[0]}`)
        }
    }, [images]);

    // const {
    //     state
    // } = useLocation()

    // const {
    //     sendInformation,
    //     name,
    //     setName,
    //     email,
    //     setEmail,
    //     error,
    //     succesScreen,
    //     description,
    //     setDescription,
    //     tel,
    //     setTel,
    //     subject,
    //     setSubject,
    //     setModal,
    //     modal,
    //     loadingS
    // } = UseDetailPackages()

    // let stars = []

    // for (let i = 0; i < Number(state.stars); i++) {
    //     stars.push(<SvgIcon key={i} name={iconList.IconStar} color={'#FFD902'} size={20} />);
    // }

    // useEffect(() => {
    //     const body = document.querySelector('body');
    //     modal ? body.style.overflow = 'hidden' : body.style.overflow = '';
    // }, [modal])

    return (
        <div>
            {
                loading ? (
                    <div className='container-loading-packages'>
                        <CircularProgress />
                    </div>
                ):(
                    <div>
                        <Find />
                        <CarouselSevices current={servicesTravel[12].title} />
                        <div className='thumbnails-images-packages' style={{ maxWidth: '100%' }}>
                            {
                                images.length == 0 ? (
                                    <p>No hay imagenes para este paquete</p>
                                ):(
                                    <div style={{ overflow: 'hidden', marginBottom: "50px" }}>
                                        <Slider 
                                            dots={true}
                                            infinite={true}
                                            speed={500}
                                            slidesToShow={1}
                                            slidesToScroll={1}
                                            arrows={true}
                                        >
                                            {images.map((image, index) => (
                                                <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                    <img
                                                        className='section-image'
                                                        src={`${packaged.url}/${image}`}
                                                        onClick={() => setSelectedImage(`${packaged.url}/${image}`)}
                                                        alt={`${index + 1}`}
                                                        style={{ objectFit: 'contain', maxHeight: '100%', margin: 'auto' }}
                                                    />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                )
                            }
                        </div>
                        <div className='detail-package'>
                            <div className='container-price-description'>
                                <div className='colum-description'>
                                    <div className='titles-packages title-main-package'>
                                        <p>{packaged.pack.name}</p>
                                    </div>
                                    <div className='paragraphs-packages'>
                                        <div className='paragraphs-packages' dangerouslySetInnerHTML={{ __html: packaged.pack.description }} />
                                    </div>
                                    <div className='paragraphs-packages'>
                                        <div className='paragraphs-packages' dangerouslySetInnerHTML={{ __html: packaged.pack.conditions }} />
                                    </div>
                                </div>
                                <div className='colum-price'>
                                    <div className='container-price'>
                                        <div className='price-title'>
                                            <p className='p-price-title'>Desde</p>
                                            <p className='p-price-money'>$ {parseInt(packaged.pack.price).toLocaleString()} {packaged.pack.currency}</p>
                                        </div>
                                        <div className='price-title' style={{ marginTop: "20px" }}>
                                            <p className='p-price-title'>Destino</p>
                                            <p className='p-price-money'>{packaged.pack.destination}</p>
                                        </div>
                                        <div>
                                            <div>
                                                <button className='btn-days-package btn-calender-package' onClick={() => setIsModalOpenPackage(true)}>
                                                    <div>
                                                        <p>COTIZAR</p>
                                                    </div>
                                                </button>
                                                <button className='btn-days-package btn-calender-package' onClick={() => setIsModalOpenPay(true)}>
                                                    <div>
                                                        <p>PAGAR</p>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ModalForm isModalOpen={isModalOpenPackage} setIsModalOpenAlert={setIsModalOpenAlert} closeModal={closeModal} packId={packaged.pack.id}/>
                            <ModalAlert isModalOpen={isModalOpenAlert} closeModal={closeModal}/>
                            <ModalPay isModalOpen={isModalOpenPay} closeModal={closeModal} packId={packaged.pack.id}/>
                        </div>
                    </div>
                )
            }
        </div>
        // <div>
        //     <Find />
        //     <div className='detail-hotel-route' style={{ width: "90%" }}>
        //         <p>
        //             <Link to={'/packages'}>Paquetes</Link> {">"} <span>{state?.name}</span>
        //         </p>
        //     </div>
        //     <div className='contain-detail-recovery-house'>
        //         <section className='contain-img-detail-recovery'>
        //             <img src={`${state?.urlImage === "" ? "" : state?.urlImage}${state?.pathImage}`} alt={state?.name} className='img-detail-recovery' />
        //         </section>
        //         <section className='contain-info-detail-recovery'>
        //             <div className='first-section-detail'>
        //                 <h1>{state?.name}</h1>
        //                 <div className='star-hotel-card'>
        //                     <span>
        //                         {Number(state?.stars).toFixed(1)}
        //                     </span>
        //                     <div>
        //                         {stars}
        //                     </div>
        //                     <span className='price-packages-s'>
        //                         {currencyFormat(state?.price).total}
        //                     </span>
        //                 </div>
        //                 <p>
        //                     Direccion:
        //                     <br />
        //                     <span>
        //                         {state?.address}
        //                     </span>
        //                 </p>
        //                 <p>
        //                     Descripcion:
        //                     <br />
        //                     <span>
        //                         {state?.description}
        //                     </span>
        //                 </p>
        //             </div>
        //             <div>
        //                 <button onClick={() => { setModal(true) }}>Realizar Pedido</button>
        //             </div>


        //         </section>
        //     </div>
        //     <CarouselSevices current={servicesTravel[12].title} />
        //     {
        //         modal &&
        //         <ModalQuo
        //             setModal={setModal}
        //             description={description}
        //             setDescription={setDescription}
        //             email={email}
        //             setEmail={setEmail}
        //             tel={tel}
        //             setTel={setTel}
        //             name={name}
        //             setName={setName}
        //             subject={subject}
        //             setSubject={setSubject}
        //             sendInformation={sendInformation}
        //             succesScreen={succesScreen}
        //             error={error}
        //             id={state?.id}
        //             loading={loadingS}
        //         />
        //     }
        // </div>
    )
}

export default DetailPackages