import React, { useState } from 'react';
import './styles.css';
import PrimarySection from './components/primarySection';
import CardRegisterNow from './components/cardRegisterNow';
import AnyQuestions from './components/anyQuestion';
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices';
import CountDownTimer from './components/CountDown/CountDownTime';
import NewsWellezy from '../../../Components/NewsWellezy/NewsWellezy';
import {useTranslation} from "react-i18next"
import ShopWellezy from './components/shopWellezy/ShopWellezy';
import useSearchFlights from '../../viewModels/flights/search.flights.viewModel';
import travelers from "../../assets/svg/travelers.png"
import doctors from "../../assets/svg/doctors.png"
import CarouselVideo from './components/CarouselVideos/CarouselVideo';
import PackageSection from './components/PackagesSection/PackageSection';
import { useLocation } from 'react-router-dom';


  
function Home() {
  const [t] = useTranslation("global")
  const fechaLanzamiento = new Date('2023-08-23T17:32:00');
  const {pathname} = useLocation()

  const fechaActual = new Date()

  const [lanzamiento, setLanzamiento] = useState(fechaLanzamiento > fechaActual ? false : true)

 const types = {
    company: {
      url:'register',
      typeUser:'serviceProvider',
      img: doctors,
      title: t("browse_home.card_register.company.title"),
      description: t("browse_home.card_register.company.description"),
      register: t("browse_home.card_register.company.register"),
      action: () => { }
    },
  
    person: {
      url:'register',
      typeUser:'customer',
      img: travelers,
      title: t("browse_home.card_register.person.title"),
      description: t("browse_home.card_register.person.description"),
      register: t("browse_home.card_register.person.register"),
      action: () => { }
    }
  }




  return (
    <div className='homeContainer'>
      {
        lanzamiento === true ?
          <>
            <PrimarySection pathname={pathname}/>
            <section className='homeSectionCarrousel'>
              <p className='homeSectionCarrouselTitle'>{t("browse_home.find_wellezy")}</p>
              <CarouselSevices />
            </section>
            <ShopWellezy/>
            <section className='homeSectionDoubleCardWrap'>
              <div className='homeSectionDoubleCard'>
                <CardRegisterNow type={types.company} />
                <CardRegisterNow type={types.person} />
              </div>
            </section>
            <PackageSection seker={true}/>
            <CarouselVideo/>
            <NewsWellezy/>
            <AnyQuestions title={t('browse_home.have_doubt')} />
          </>
          :
        <CountDownTimer targetDate={fechaLanzamiento} setLanzamiento={setLanzamiento} lanzamiento={lanzamiento}/>
       
      }

    </div>
  )
}
export default Home;