import React from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import { Location, SearchNormal } from 'iconsax-react'
import { CircularProgress, Grid } from '@mui/material'
import Paquetes from '../../../viewModels/packages/Paquetes'
import { useTranslation } from 'react-i18next'
import SearchFlights from '../../flights/search.flights.view'
import HotelSearchEngine from '../../Hotel/HotelSearchEngine/HotelSearchEngine'
import "./styles.css"
import UseHotels from '../../../viewModels/hotels/UseHotels'
import SearchTransport from '../../transport/primaryView/SearchTransport'
import TransportC from '../../../viewModels/transport/TransportC'
import useSearchFlights from '../../../viewModels/flights/search.flights.viewModel'

const SearchPackages = ({ width }) => {

    const [t] = useTranslation("global")
    const {
        selectTypeResercation,
        background,
        nextStep,
        prevStep,
        step
    } = Paquetes()

    const {
        adult,
        baby,
        setTypeT,
        typeT,
        ciudadDropdown,
        setCiudadDropdown,
        aeropuertoDropdown,
        setAeropuertoDropdown,
        formValidate,
        passengersCal,
        findCity,
        cityList,
        citySelected,
        setcitySelected,
        findOrigin,
        originList,
        originSelected,
        setOriginSelected,
        findDestiny,
        destinyList,
        destinySelected,
        setDestinySelected,
        destinyDropdown,
        setDestinyDropdown,
        findServices,
        selectedDate,
        handleDateChange
      } = TransportC()

    
    const {
        cardRef,
        openHotelSearch,
        paceholders,
        search,
        setSearch,
        destiny,
        dateEntry,
        setDateEntry,
        datesSelected,
        setDatesSelected,
        cardRooms,
        setCardRooms,
        roomsSelected,
        setRoomsSelected,
        quantityPersons,
        openHotelSearchFuct,
        destinationSelectionFunc,
        searchHotelSelected,
        findCountryClient,
        nacionalityDropdown,
        setNacionalityDropdown,
        dataNacionality,
        selectedNacionality,
        nacionality,
        codeNacionality,
        loading,
        loadingSearch,
        validationForm,
        errApi
      } = UseHotels()

      const isIframe = false

      const {
        cardRefPlace,
        cardRefPassenger,
        cardRefDate,
        isRoundTrip,
        setIsRoundTrip,
        buttonDisable,
        searcherFlight,
    
        //ciudades
        //ida
        isCardRefOrigin,
        setIsCardRefOrigin,
        selectedOrigin,
        getSelectedOrigin,
    
        //retorno
        isCardRefDestination,
        setIsCardRefDestination,
        selectedDestination,
        getSelectedDestination,
        //fechas
        dateGoingSelected,
        setDateGoingSelected,
        dateReturnSelected,
        setDateReturnSelected,
        isCardRefDate,
        setIsCardRefDate,
        setDateGoingSelectedApi,
        setDateReturnSelectedApi,
    
        //pasajeros
        isManagingPassengers,
        setIsManagingPassengers,
        passengersSelecteds,
        getPassengersSelecteds,
        finishProcess,
        ellipsisText,
      } = useSearchFlights(isIframe);


    return (
        <div className='layout-one-t' style={{ width: `${width === '' ? '40%' : `${width}%`}`, }}>
            <div className='reserva-p' >
                <div className={background === 1 ? 'select' : 'unselect'} onClick={() => selectTypeResercation(1)}>
                    <p><SvgIcon color={background === 1 ? '#ffffff' : '#004274'} name={iconList.IconPlane} size={30} />{t("packages.packages_medics")} + <SvgIcon color={background === 1 ? '#ffffff' : '#004274'} name={iconList.IconHotel} size={30} /> {t("packages.hotel")}</p>
                </div>

                <div className={background === 2 ? 'select' : 'unselect'} onClick={() => selectTypeResercation(2)}>
                    <p><SvgIcon color={background === 2 ? '#ffffff' : '#004274'} name={iconList.IconPlane} size={30} />{t("packages.packages_medics")} + <SvgIcon color={background === 2 ? '#ffffff' : '#004274'} name={iconList.IconRentaCar} size={30} /> {t("packages.car")}</p>
                </div>
            </div>
            <div className='contain-forms-packages'>
                {
                    step === 1 ?
                        <>
                            <SearchFlights  buttonDisable={buttonDisable} cardRefDate={cardRefDate} cardRefPassenger={cardRefPassenger} cardRefPlace={cardRefPlace} dateGoingSelected={dateGoingSelected} dateReturnSelected={dateReturnSelected} ellipsisText={ellipsisText} finishProcess={finishProcess} getPassengersSelecteds={getPassengersSelecteds} getSelectedDestination={getSelectedDestination} getSelectedOrigin={getSelectedOrigin} isCardRefDate={isCardRefDate} isCardRefDestination={isCardRefDestination} isCardRefOrigin={isCardRefOrigin} isManagingPassengers={isManagingPassengers} isRoundTrip={isRoundTrip} passengersSelecteds={passengersSelecteds} searcherFlight={searcherFlight} selectedDestination={selectedDestination} selectedOrigin={selectedOrigin} setDateGoingSelected={setDateGoingSelected} setDateGoingSelectedApi={setDateGoingSelectedApi} setDateReturnSelected={setDateReturnSelected} setDateReturnSelectedApi={setDateReturnSelectedApi} setIsCardRefDate={setIsCardRefDate} setIsCardRefDestination={setIsCardRefDestination} setIsCardRefOrigin={setIsCardRefOrigin} setIsManagingPassengers={setIsManagingPassengers} setIsRoundTrip={setIsRoundTrip}/> 
                            <button className='btn-next-prev' onClick={() => {
                                searcherFlight()
                                nextStep()
                            }}>
                                Siguiente
                            </button>
                        </>

                        :
                        <>
                            {
                                background === 1 &&
                                <HotelSearchEngine titleSize={'20px'} iconTitleSize={'50'} containDateFlexDirection={'row'} containDateJustify={'space-between'} layoutCampoWidth={'47%'} containFinishFlexDirection={'row'} widthContain={100} containFinishJustify={'space-between'} layoutCampoVWidth={'47%'} cardRef={cardRef} cardRooms={cardRooms} codeNacionality={codeNacionality} dataNacionality={dataNacionality} dateEntry={dateEntry} datesSelectedEntry={datesSelected.entryDate}
                                datesSelectedExit={datesSelected.exitDate} destinationSelectionFunc={destinationSelectionFunc} destiny={destiny} errApi={errApi} findCountryClient={findCountryClient} loading={loading} loadingSearch={loadingSearch} nacionality={nacionality} nacionalityDropdown={nacionalityDropdown} openHotelSearch={openHotelSearch} openHotelSearchFuct={openHotelSearchFuct} paceholders={paceholders} quantityPersons={quantityPersons} roomsSelected={roomsSelected} search={search} searchHotelSelected={searchHotelSelected} selectedNacionality={selectedNacionality} setCardRooms={setCardRooms} setDateEntry={setDateEntry} setDatesSelected={setDatesSelected} setNacionalityDropdown={setNacionalityDropdown} setRoomsSelected={setRoomsSelected} setSearch={setSearch} validationForm={validationForm}/>
                            }
                           {
                            background === 2 &&
                            <SearchTransport adult={adult} aeropuertoDropdown={aeropuertoDropdown} baby={baby} cityList={cityList} citySelected={citySelected} ciudadDropdown={ciudadDropdown} destinyDropdown={destinyDropdown} destinyList={destinyList} destinySelected={destinySelected} findCity={findCity} findDestiny={findDestiny} findOrigin={findOrigin} findServices={findServices} formValidate={formValidate} handleDateChange={handleDateChange} originList={originList} originSelected={originSelected} passengersCal={passengersCal} selectedDate={selectedDate} setAeropuertoDropdown={setAeropuertoDropdown} setCiudadDropdown={setCiudadDropdown} setDestinyDropdown={setDestinyDropdown} setDestinySelected={setDestinySelected} setOriginSelected={setOriginSelected} setTypeT={setTypeT} setcitySelected={setcitySelected} width={100}/>
                           }
                            <button className='btn-next-prev' onClick={() => {
                                prevStep()
                            }}>
                                Anterior
                            </button>
                        </>
                }


            </div>


            <div className='btn-search-p'>
                {
                    step === 2 &&
                     <button onClick={searchHotelSelected}>
                    {
                        loadingSearch === true ?
                            <CircularProgress size={20} />
                            :

                            <SearchNormal size="30" />
                    }
                </button>
                }
               
            </div>

        </div>
    )
}

export default SearchPackages