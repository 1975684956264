import {useState} from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import './styles.css'
import wordSpecialist from '../../Specialist/ButtonsFilters/wordSpecialist'
import useBrowseHome from '../../../viewModels/home/browse.home.viewModel'
import { useTranslation } from 'react-i18next'

const ButtonsFilters = ({personalizerFind,cityFind , filterSpecialist}) => {

    const [t,i18n] = useTranslation("global")
    const [dropdownPersonalizeFind, setDropdownPersonalizeFind] = useState(false)
    const [dropdownCityFind, setDropdownCityFind] = useState(false)
    const [KeyWord, setKeyWord] = useState('')
    const [dropdownSpecialist, setDropdownSpecialist] = useState(false)
    const [wordSpecialistw, setWordSpecialistw] = useState('')
    const {wordSpeialist} = wordSpecialist()
    const {
        proceduresList
    } = useBrowseHome()

  return (
    <div className='contain-btn-filters-specialist'>
            <button>
                <SvgIcon name={iconList.IconListMedicaments} size={60} color={'#004274'} />
                <section onClick={()=>{
                    setDropdownSpecialist(!dropdownSpecialist)
                }} className='layout-btn-specialist'>
                    <h3>
                        {t("clinics.buttons.specialty")}
                    </h3>
                    <p>{wordSpecialistw === '' ? t("clinics.buttons.description") : wordSpecialistw}</p>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </section>
                {
                    dropdownSpecialist &&
                    <section className='dropdown-Specialist'>
                        {
                            proceduresList.map((item,key)=>{
                                return(
                                    <div className='dropdown-Specialist-list' key={key} onClick={()=>{
                                        setDropdownSpecialist(!dropdownSpecialist)
                                        filterSpecialist(item.name)
                                        setWordSpecialistw(item.name)
                                    }}>
                                        <p>{item?.name}</p>
                                    </div>
                                )
                            })
                        }
                    </section>
                }
            </button>
            <button>
                <SvgIcon name={iconList.IconMap} size={60} color={'#004274'} />
                <section onClick={()=>{setDropdownCityFind(!dropdownCityFind)}} className='layout-btn-specialist'>
                    <h3>
                    {t("clinics.buttons.where")}
                    </h3>
                    <p>{KeyWord === '' ? 'Barranquilla, Cartagena' : KeyWord}</p>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </section>
                {
                    dropdownCityFind &&
                    <div className='dropdown-personalize-find-clinics'>
                        <input type="text" onChange={(e)=>{setKeyWord(e.target.value)}}/>
                        <span onClick={()=>{
                            cityFind(KeyWord)
                            setDropdownCityFind(!dropdownCityFind)
                        }}>{t("clinics.buttons.search")}</span>
                   </div>
                }
            </button>
            <button >
                <SvgIcon name={iconList.IconFilters} size={60} color={'#004274'} />
                <section onClick={()=>{setDropdownPersonalizeFind(!dropdownPersonalizeFind)}} className='layout-btn-specialist' >
                    <h3>
                        {t("clinics.buttons.filters")}
                    </h3>
                    <p>{t("clinics.buttons.custom_search")}</p>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </section>
                {
                    dropdownPersonalizeFind &&
                    <div className='dropdown-personalize-find-clinics'>
                        <input type="text" onChange={(e)=>{setKeyWord(e.target.value)}}/>
                        <span onClick={()=>{
                            personalizerFind(KeyWord)
                            setDropdownPersonalizeFind(!dropdownPersonalizeFind)
                        }}>{t("clinics.buttons.search")}</span>
                   </div>
                }
            </button>
        </div>
  )
}

export default ButtonsFilters