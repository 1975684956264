import React from 'react'
import SvgIcon, { iconList } from '../../../../share/components/iconSVG'
import "./styles.css"
import doctor from "../../../../../assets/images/doctorExample.png"
import { useTranslation } from 'react-i18next'

const BannerFlow = ({ name, state, urlImage, stars, modal, setModal }) => {

    const [t] = useTranslation("global")

    return (
        <>
            {
                modal ?
                    <div className='content-banner-flow' style={{ display: `${modal ? "flex" : "none"}` }}>
                        <section className='contain-info-doctor-flow'>
                            <div className='content-img-doctor-flow'>
                                <img src={`https://aiop.com.co/storage/medic_pack/${urlImage}`} height={"200px"} alt="" />
                            </div>
                            <div className='content-info-doctor-detail-flow'>
                                <h2>Pack {name}</h2>
                                <p>{state}</p>
                                <ul>
                                    <li>
                                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                                    </li>
                                    <li>
                                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                                    </li>
                                    <li>
                                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                                    </li>
                                    <li>
                                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                                    </li>
                                    <li>
                                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                                    </li>
                                </ul>
                                <p>(86) - 90% {t("specialist.booking_flow.recomment")}</p>
                            </div>
                        </section>
                        <section className='contain-close-doctor-flow' onClick={() => {
                            setModal(false)
                        }}>
                            <SvgIcon name={iconList.IconCloseCircleOutLine} size={30} color={"#004274"} />
                            <span>{t("specialist.booking_flow.close")}</span>
                        </section>
                    </div>
                    :
                    <div className='content-banner-flow' style={{padding:"1rem", alignItems:"center"}}>
                        <section className='contain-info-doctor-flow'>
                            <div className='content-img-doctor-flow'>
                                <img src={`https://aiop.com.co/storage/medic_pack/${urlImage}`} style={{width:"50px", height:"50px"}} alt="" />
                            </div>
                            <div className='content-info-doctor-detail-flow'>
                                <h2 style={{fontSize:"18px"}}>Pack {name}</h2>
                            </div>
                        </section>
                        <section className='contain-close-doctor-flow' onClick={() => {
                            setModal(true)
                        }}>
                            <SvgIcon name={iconList.IconArrowDown} size={30} color={"#004274"} />
                            <span>Abrir</span>
                        </section>
                    </div>
        }

        </>

    )
}

export default BannerFlow