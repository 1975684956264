import {useRef} from 'react'
import './styles.css'
import { motion} from 'framer-motion'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import img1 from '../../../../assets/pictures/img-1.jpg'
import img2 from '../../../../assets/pictures/img-2.jpg'
import img3 from '../../../../assets/pictures/img-3.webp'

const imgGalery = [
    {
        img: img1
    },
    {
        img: img2
    },
    {
        img: img3
    },
]

const CarouselGalery = ({ data }) => {

    const constraintsRef = useRef(null)

    const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;

    const MotionBox = styled(motion.div)`
    width: 100vw;
  `

    return (
        <>
        <MotionConstraints className='slider_container' ref={constraintsRef}>
                <MotionBox className='slider_' drag='x'  dragConstraints={constraintsRef}>
                    {
                        data.length > 0 ?

                        data.map((item,key) => {
                            return (
                                <motion.div className='div_homeTurism' key={key}>
                                    <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                        <img className='individual-img-galery' src={item.img} alt="" />
                                    </Grid>
                                </motion.div>
                            )
                        })

                        :

                        imgGalery.map((item,key) => {
                            return (
                                <motion.div className='div_homeTurism' key={key}>
                                    <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                        <img className='individual-img-galery' src={item.img} alt="" />
                                    </Grid>
                                </motion.div>
                            )
                        })
                    }

                </MotionBox>
            </MotionConstraints>
        </>
    )
}

export default CarouselGalery