import React from 'react'
import "./styles.css"
import { useTranslation } from 'react-i18next'


const WantQuo = ({ name }) => {

const [t] = useTranslation("global")

    return (
        <div className='content-want-quo'>
            <p>
            {t("specialist.detail.want_info")} Dr.{name} {t("specialist.detail.want_info2")}
            </p>
            <button>
                {t("specialist.detail.make_appointment")}
            </button>
        </div>
    )
}

export default WantQuo