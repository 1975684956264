import React from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import Loading from '../../share/components/loading'
import { useTranslation } from 'react-i18next'

const AllClinics = ({ dataClinics, urlImage, loading, detailClinics}) => {

    const [t,i18n] = useTranslation("global")

    return (
        <div className='contain-all-clinics'>
            {
                loading === true ?

                    <Loading size={100} />
                    :

                    (
                        dataClinics.length === 0 ?
                            <h2>{t('clinics.no_result')}</h2>
                            :

                            dataClinics.map((item, key) => {
                                return (
                                    <div onClick={()=>{detailClinics(item)}} key={key} className='card-clinics'>
                                        <img src={`${urlImage}${item?.image}`} alt="" />
                                        <div className='card-clinic-info'>
                                            <div className='layout-clinics-a'>
                                                <div>
                                                    <h3>{item?.name}</h3>
                                                    <p>{item?.address}</p>
                                                </div>
                                                <div>
                                                    <span>{item?.stars?.toFixed(1)} <SvgIcon name={iconList.IconStar} color={'#ffff00'} size={15} /></span>
                                                </div>
                                            </div>
                                            <div className='layout-clinics-b'>
                                                <p className='p-clinics'>
                                                    {item?.subcategories.map((item1,key)=>{
                                                        return(
                                                            <span key={key} style={{color:'rgb(134, 134, 134)', fontSize:'12px', fontWeight:'200'}} >
                                                               {item?.subcategories?.length > 1 ? `${item1?.name}, ` : item1?.name}
                                                            </span>
                                                            
                                                        )
                                                    })}
                                                    
                                                </p>
                                                <span >{t("clinics.buttons.see_more")}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    )

            }
        </div>
    )
}

export default AllClinics