import React from 'react'
import "./styles.css"
import Suggestions from './suggestions/Suggestions'
import FormSuggestions from './form/FormSuggestions'
import { useNavigate } from 'react-router-dom'

const FormAndSuggestions = () => {

  const navigate = useNavigate()

  const goReservation = () => {
    navigate(`/TourismHealth/reservation`)
}
 
  return (
    <div className='contain-form-suggestions'>
      <Suggestions />
      <div className='content-buttons-cotizar'>
        <div className='btns-detail-health'>
          <button onClick={goReservation}>Reservar</button>
          <button>Contactar</button>
        </div>
      </div>
      {/* <FormSuggestions/> */}
    </div>
  )
}

export default FormAndSuggestions