import React from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import "./styles.css"

const DetailBuy = ({ nom, address,hab,arrival,departure,checkIn,checkOut }) => {
    return (
        <div className='content-detail-buy-hotel'>
            <h2>Detalle de compra</h2>
            <h3>{nom}</h3>
            <p style={{ fontSize: "13px" }}>{address}</p>
            <p style={{ display: "flex", alignItems: "center" }}><SvgIcon color={"#004274"} name={iconList.IconPinMap} size={25} /> Ver mapa</p>
            <div className='content-check-group'>
                <section className='check-in-detail'>
                    <span>Check in</span>
                    <p>{arrival}</p>
                    <span>{checkIn}</span>
                </section>
                <section className='check-in-detail'>
                    <span>Check out</span>
                    <p>{departure}</p>
                    <span>{checkOut}</span>
                </section>
            </div>
            {
                hab?.map((item, index) => {
                    let allAcomodation = item?.acomodation?.split('-')?.map(Number);
                    console.log(item)
                    return (
                        <div className='content-hab-total-paxes' key={index}>
                            <p>{allAcomodation[0]} adultos, {allAcomodation[1]} niños</p>
                            <p style={{ display: "flex", alignItems: "center" }}><SvgIcon color={"#004274"} name={iconList.IconHotel} size={20}/> {item?.name}</p>
                        </div>
                    )
                })
            }
            {
                hab?.map((item, index) => {
                    
                    return (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                        <SvgIcon color={"#004274"} size={25} name={iconList.IconCoupon} />
                        <span>{item["@attributes"]?.cod}</span>
                    </div>
                    )
                })
            }
            
        </div>
    )
}

export default DetailBuy