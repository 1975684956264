import React from 'react'
import './styles.css'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const GalleryImg = ({ Images, setSlideShow }) => {

    
    return (
        <div className='modal-gallery'>
        <div className='modal-content-gallery'>
          <span onClick={() => { setSlideShow(false) }}>x</span>
          <Slide duration={20000} transitionDuration={1500}>
            {Images.map((item, key) => {
              return (
                <div className="each-slide-effect" key={key}>
                  <div style={{ 'backgroundImage': `url(${item})` }}></div>
                </div>
              )
            })}
          </Slide>
        </div>
      </div>
  
    )
}

export default GalleryImg