import { useState, createContext, useEffect, useCallback } from 'react';
import { keys, get as getLocalStorage } from '../../ui/localStorage';

export const UserContext = createContext();

export const UserDetailsProvider = ({ children }) => {
  const [loadingContext, setLoadingContext] = useState(true)
  const [userDetail, setUserDetail] = useState(null);
  const [userToken, setUserToken] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const storedUserSession = await getLocalStorage(keys.authUserSession);
      if (storedUserSession) {
        const { token, user } = storedUserSession;
        setUserToken(token);
        setUserDetail(user);
      }
    } catch (error) {
      console.error("Error al cargar fetchData UserContext:", error);
    } finally {
      setLoadingContext(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <UserContext.Provider value={{ userDetail, userToken, loadingContext, setUserDetail, setUserToken }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext;
