import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import { global } from '../../global';

const {
  img: {
    logos: {
      AviancaLogo,
      LatamLogo,
      VivaLogo,
    }
  }

} = global;


function useFlightsSearch() {
  const { pathname, state } = useLocation()

  const extract = (stringt) => {
    const startIndex = pathname.indexOf(stringt);
    if (startIndex !== -1) {
      // Encontrado, buscar el índice del ampersand siguiente
      const endIndex = pathname.indexOf("&", startIndex);

      if (endIndex !== -1) {
        // Extraer el valor de dateOfDeparture
        const dateOfDeparture = pathname.slice(startIndex + stringt.length, endIndex);

        return dateOfDeparture;
      }
    }
  }


  const [loading, setLoading] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [flightsList, setFlightsList] = useState([]);
  const numberSearchs = 50
  const classFlight = false
  const allPassengers = {
    adult: Number(extract("adult=")),
    boy: Number(extract("boy=")),
    baby: Number(extract("baby="))
  }
  const [isRoundTripB, setIsRoundTripB] = useState(state === null ? extract("isRoundTrip=") === "false" ? false : true : state.roundTrip);
  const [allPassengersSelected, setAllPassengersSelected] = useState(state === null ? allPassengers : state?.passengersSelecteds);
  const [numberOfAdultPassengers, setNumberOfAdultPassengers] = useState(state === null ? Number(extract("adult=")) : allPassengersSelected?.adult);
  const [numberOfBoyPassengers, setNumberOfBoyPassengers] = useState(state === null ? Number(extract("boy=")) : allPassengersSelected?.boy);
  const [numberOfBabyPassengers, setNumberOfBabyPassengers] = useState(state === null ? Number(extract("baby=")) : allPassengersSelected?.baby);
  const errorGetFlight = 'Request failed with status code 500';

  const [nameOrigin, setNameOrigin] = useState(state === null ? decodeURIComponent(extract("originC=")) : state?.buttonOriginFlight);

  const [nameDestiny, setNameDestiny] = useState(state === null ? decodeURIComponent(extract("destinationC=")) : state?.buttonDestinityFlight);

  const [dateGoingSelected, setDateGoingSelected] = useState('')
  const [dateGoingSelectedApi, setDateGoingSelectedApi] = useState(state === null ? extract("departure=") : state?.dateGoingSelectedApi);
  const [dateReturnSelected, setDateReturnSelected] = useState('')
  const [dateReturnSelectedApi, setDateReturnSelectedApi] = useState(state === null ? extract("return=") : state?.dateReturnSelectedApi);

  const codeIataDestiny = state === null ? extract("iataD=") : state?.codeiataDestinity;
  const codeIataOrigin = state === null ? extract("iataO=") : state?.codeIataOrigin;

  const airlinesList = [
    {
      id: 1,
      img: VivaLogo,
      name: 'viva air',
      priceDirect: 1050,
      priceOneScale: 950,
      priceMoreScale: null,
    },
    {
      id: 2,
      img: LatamLogo,
      name: 'latam airlines',
      priceDirect: 1100,
      priceOneScale: 1080,
      priceMoreScale: null,
    },
    {
      id: 3,
      img: AviancaLogo,
      name: 'avianca',
      priceDirect: 1120,
      priceOneScale: 1100,
      priceMoreScale: 1050,
    },
  ]





  const dataToSend = {
    "searchs": numberSearchs,
    "class": classFlight,
    "qtyPassengers": numberOfAdultPassengers + numberOfBoyPassengers,
    "adult": numberOfAdultPassengers,
    "child": numberOfBoyPassengers,
    "baby": numberOfBabyPassengers,
    "itinerary":
      [
        {
          "departureCity": `${codeIataOrigin}`,
          "arrivalCity": `${codeIataDestiny}`,
          "hour": `${dateGoingSelectedApi}`
        },
      ]
  }



  if (isRoundTripB) {
    dataToSend.itinerary.push(
      {
        "departureCity": `${codeIataDestiny}`,
        "arrivalCity": `${codeIataOrigin}`,
        "hour": `${dateReturnSelectedApi}`
      }
    )
  }

  function getFlights() {
    setLoading(true)
    setQueryError(false)
    const url = `${process.env.REACT_APP_URL_API_FLY}flights/v2`;
    axios.post(url, dataToSend)
      .then(res => {
        setFlightsList(res?.data?.data);
        console.log(res.data)
      })
      .catch(err => {
        console.log({ err })
        if (err.message === errorGetFlight) {
          console.log("no hay resultados")
          setQueryError(true)
        }
        console.log("Error in getFlights: ", err);
      })
      .finally(() => {
        setLoading(false)
      })


  }

  useEffect(() => {
    if (state) {
      setNameOrigin(state?.buttonOriginFlight)
      setNameDestiny(state?.buttonDestinityFlight)
      setAllPassengersSelected(state?.passengersSelecteds)
      setIsRoundTripB(state?.roundTrip)
      setDateReturnSelectedApi(state?.dateReturnSelectedApi)
      setDateGoingSelectedApi(state?.dateGoingSelectedApi)
      getFlights();
    } else {
      setNameOrigin(decodeURIComponent(extract("originC=")))
      setNameDestiny(decodeURIComponent(extract("destinationC=")))
      setAllPassengersSelected(allPassengers)
      setIsRoundTripB(extract("isRoundTrip="))
      setDateGoingSelectedApi(extract("departure="))
      setDateReturnSelectedApi(extract("return="))
      getFlights();
    }

  }, [pathname]);

  return {
    loading,
    isRoundTripB, setIsRoundTripB,
    queryError,
    flightsList,
    airlinesList,
    nameOrigin, setNameOrigin,
    nameDestiny, setNameDestiny,
    getFlights,
    allPassengersSelected,
    setAllPassengersSelected,
    setDateGoingSelected,
    dateGoingSelected,
    setDateGoingSelectedApi,
    setDateReturnSelected,
    setDateReturnSelectedApi,
    dateReturnSelected


  }
}

export default useFlightsSearch;