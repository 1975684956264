import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Find from '../../share/components/Find/Find'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import './styles.css'
import FormReservation from './FormReservation/FormReservation'
import axios from 'axios'
import usePassengerInformationForm from '../../../viewModels/flights/passenger.information.form.viewModel'
import ModalContainer from '../../share/components/modalContainer'
import RequiredSession from '../../share/components/requiredSession'
import UseMakeReservation from '../../../viewModels/hotels/UseMakeReservation'
import { ArrowLeft, Happyemoji } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import PersonForm from './PersonForm/PersonForm'
import SendInvoices from './SendInvoce/SendInvoices'
import DetailPay from './detailPay/DetailPay'
import DetailBuy from './detailBuy copy/DetailBuy'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import Loading from '../../share/components/loading'
import {useNavigate} from "react-router-dom"


const MakeReservation = () => {

  const {
    needLogin,
    goToScreen,
    hiddenModalSesion
  } = usePassengerInformationForm()

  const {
    emailTomador,
    setEmailTomador,
    telTomador,
    setTelTomador,
    paxes,
    setPaxes,
    step,
    setStep,
    cantAcomodationAdult,
    body,
    createReservation,
    nombreTomador,
    setNombreTomador,
    paymentCardSelected,
    setPaymentCardSelected,
    state,
    cantAcomodationChild,
    errorReservation,
    loadingReservation,
    setLoadingReservation,
    loadingRespose,
    reservation
  } = UseMakeReservation()

  const [t] = useTranslation("global")

  const persons = []

  const navigate = useNavigate();

  const volverPaginaAnterior = () => {
    navigate(-1);
  };

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  for (let index = 0; index < cantAcomodationAdult; index++) {

    persons?.push(<PersonForm index={index + 1} key={index} type={"adult"} setPaxes={setPaxes} />)
  }

  for (let index = 0; index < cantAcomodationChild; index++) {
    persons?.push(<PersonForm index={index + 1} key={index} type={"child"} setPaxes={setPaxes} />)
  }


  return (
    <div>
      <Find />
      <CarouselSevices current={servicesTravel[1].title} />
      <div className='contain-hotel-pay'>
        <ModalContainer
        show={loadingReservation}
        hidden={setLoadingReservation}
        sizeWidth={60}
        sizeHeight={50}
      >
        {
          loadingRespose ?

          <Loading size={90} />
          
          :

          <div className='detail-reservation-result'>
          <h2>Reserva realizada con exito en el hotel: {reservation?.hotel}</h2>
          <h3>Datos de la reserva</h3>
          <ul>
            <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25}/> <span>Codigo de reserva: </span> {reservation?.localizator}</li>
            <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25}/> <span>Tomador: </span> {reservation?.name}</li>
            <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25}/> <span>Correo: </span> {reservation?.email}</li>
            <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25}/> <span>Precio de la reserva: </span> {reservation?.price}</li>
          </ul>
        </div>
        }
        
      </ModalContainer>
        <p onClick={volverPaginaAnterior} className='return-url'><ArrowLeft size={20} color='#004274'></ArrowLeft>{t("packages.packages_pay.back_page")}</p>
        <div className='layout-hotel-pay'>
          <section className='main-hotel-pay'>
            {
              persons?.map((item, index) => {
                return (
                  item
                )
              })
            }
            <SendInvoices />
          </section>
          <section className='aside-hotel-pay'>
            <DetailPay acomodation={cantAcomodationAdult + cantAcomodationChild} total={state?.price} />
            <DetailBuy hab={state?.listReserve} nom={state?.hotel} arrival={state?.arrival} departure={state?.departure} address={state?.dataHot?.direccion} checkIn={state?.dataHot?.checkin} checkOut={state?.dataHot?.checkout} />
            <div className='content-button-reservation-hotel'>
              <button onClick={createReservation}>Reservar</button>
              <p>Total a pagar <span>{formatearCantidad(state?.price)}</span></p>
              { 
                errorReservation &&
                <div className='err-hotel-form-person'>
                  <p style={{color:"white", fontWeight:"bold"}}>Para poder reservar debes llenar todos los datos</p>
                </div>
              }
            </div>
          </section>
        </div>
      </div>
     
    </div>
  )
}

export default MakeReservation