import { useState } from 'react';


function useMyCalendarHourViewModel() {
  const [isShowModal, setIsShowModal] = useState(false);

// citation_id: null
// created_at: "2023-07-27T22:05:59.000000Z"
// diary_id: 1
// id: 2
// // status: "available"
// // time: "09:00:00"
// updated_at: "2023-07-27T22:05:59.000000Z"


function goToAction(){
  console.log("goToAction")
  setIsShowModal(true)
}

  // 
  // const [isOpen, setIsOpen] = useState(false);
  // const [oursList, setList] = useState([])

  // const dayToCompare = `${data?.year}-${data?.monthNumb.toString().padStart(2, '0')}-${day.number}`;

  // function thisIsCurrentData(dateString) {
  //   const fechaActual = new Date().toISOString().split('T')[0];
  //   return dateString === fechaActual;
  // }

  // const isCurrentDay = thisIsCurrentData(dayToCompare);

  // async function getDataDay() {
  //   if(isOpen) return setIsOpen(false);
  //   if(isLoading) return;
  //   try {
  //     setIsLoading(true);
  //     const url = doctorURL?.get?.getDayCalendary;
  //     const response = await wellezy_api.get(url,
  //     {
  //       params: {
  //         "date": '2023-08-01',
  //         "user_id": "47"
  //       }
  //     });
  //     if(response){
  //       setList(response?.data?.disponibility)
  //     }
  //   } catch (error) {
  //     console.log("Error getDataDayDay(): ", error)
  //   }
  //   finally{
  //     setIsOpen(true)
  //     setIsLoading(false);
  //   }
  // }

  return {
    goToAction,
    isShowModal,
    setIsShowModal
  }
}

export default useMyCalendarHourViewModel;