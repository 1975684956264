import React from 'react'
import SvgIcon, { iconList } from '../../../../share/components/iconSVG'
import "./styles.css"

const DetailPayHotel = ({price,img,nom,listReserve,checkIn,checkOut,address}) => {

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    
        return cantidadFormateada;
    }

  return (
    <div className='detail-pay-hotel'>
        <div className='header-pay-hotel'>
            <img src={img} alt={nom} />
            <div className='content-info'>
                <h2>{nom}</h2>
                <div>
                    <p>{address}</p>
                    <span><SvgIcon color={"#004274"} name={iconList.IconHotel} size={25}/> {listReserve?.length}</span>
                </div>
            </div>
        </div>
        <div className='content-check-detail'>
            <section>
                <p style={{color:"#004274", fontWeight:"bold"}}>Check-In </p>
                <SvgIcon color={"#004274"} name={iconList.IconArrowLarge} size={30}/>
                <p>{checkIn}</p>
            </section>
            <section>
                <p style={{color:"#004274", fontWeight:"bold"}}>Check-Out</p>
                <SvgIcon color={"#004274"} name={iconList.IconArrowLarge} size={30}/>
                <p>{checkOut}</p>
            </section>
        </div>
        <div className='line-separation-detail-pay'></div>
        {
                listReserve?.map((item, index) => {
                    let allAcomodation = item?.acomodation?.split('-')?.map(Number);
                    console.log(item)
                    return (
                        <div className='content-hab-total-paxes' key={index}>
                            <p>{allAcomodation[0]} adultos, {allAcomodation[1]} niños</p>
                            <p style={{ display: "flex", alignItems: "center" }}><SvgIcon color={"#004274"} name={iconList.IconHotel} size={20}/> {item?.name}</p>
                        </div>
                    )
                })
            }
            {
                listReserve?.map((item, index) => {
                    
                    return (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                        <SvgIcon color={"#004274"} size={25} name={iconList.IconCoupon} />
                        <span>{item["@attributes"]?.cod}</span>
                    </div>
                    )
                })
            }
            <div className='line-separation-detail-pay'></div>
            <div className='row-detail-pay row-total-pay'>
            <p>Total</p>
            <span>{formatearCantidad(price)}</span>
        </div>
    </div>
  )
}

export default DetailPayHotel