import React, { useState, useEffect } from "react";
import Carousel from "../../share/components/CarouselServices/CarouselSevices";
import Find from "../../share/components/Find/Find";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import FormSuggestions from "../../tourismHealth/formAndSuggestions/form/FormSuggestions";
import Loading from "../../share/components/loading";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import "./styles.css";
import Paginado from "../../Specialist/Paginado/Paginado";
import BestExperiences from "../BestExperiences/BestExperiences";
import currencyFormatter from "../../../../helpers";

const ResultSearchPack = () => {
  const url = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/medic_packages`;

  const { state, pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataResult, setdataResult] = useState([]);
  let partes = pathname.split("/");
  let resultado = partes[partes.length - 1];
  const [wordSearch, setWordSearch] = useState(
    state?.keyWork == null ? resultado : state?.keyWork
  );

  const [services, setServices] = useState([]);
  const itemsForPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);
  const [dataProcedure, setDataProcedure] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const navigate = useNavigate();

  const nextHandler = () => {
    const totalElementos = services.length;

    const nextPage = currentPage + 1;

    const firtsIndex = nextPage * itemsForPage;
    if (firtsIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setdataResult([...services].splice(firtsIndex, itemsForPage));
  };
  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firtsIndex = prevPage * itemsForPage;

    setdataResult([...services].splice(firtsIndex, itemsForPage));
    setCurrentPage(prevPage);
  };

  const getInformation = () => {
    axios
      .get(url, {
        params: {
          search: wordSearch,
        },
      })
      .then((res) => {
        setdataResult([...res.data.packs].splice(0, itemsForPage));
        setServices(res.data.packs);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [selectedCards, setSelectedCards] = useState(
    new Array(dataResult?.length).fill(1)
  ); // Inicializar todos en 1

  const handleCardSelect = (index, value) => {
    const newSelectedCards = [...selectedCards];
    newSelectedCards[index] = value;
    setSelectedCards(newSelectedCards);
  };

  useEffect(() => {
    setLoading(true);
    getInformation();
  }, []);

  const detailPack = (item, id) => {
    navigate(`/TourismHealth/DetailProcedure/${id}`, {
      state: {
        data: item,
      },
    });
  };

  const goReservation = () => {
    navigate(`/TourismHealth/reservation`);
  };

  return (
    <div>
      <Find />
      <Carousel />
      <div className="content-all-result-torism">
        <h2 className="title-result-search">
          Los mejores paquetes de tratamientos médicos de salud y bienestar en
          todo el mundo
        </h2>
        <p className="phrace-result-search">
          Miles de mejores paquetes de tratamientos médicos
        </p>
        <section className="layout-result-filter">
          <div className="all-result-search-tourism">
            {loading === true ? (
              <Loading size={100} />
            ) : dataResult?.length === 0 ? (
              <h2>No hay resultados disponibles</h2>
            ) : (
              <>
                {dataResult.map((item, index) => {
                  console.log(item.id);
                  console.log(item.name);
                  return (
                    <div className="card-pack-interest-r" key={index}>
                      <section className="part-one-r">
                        <div className="content-img-interest-r">
                          <img
                            src={`${process.env.REACT_APP_URL_API_AIOP_PRT}storage/medic_pack/${item.image}`}
                            alt=""
                          />
                        </div>
                        <div className="content-info-r">
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "900",
                              color: "#004274",
                            }}
                          >
                            {item.name}
                          </p>
                          <div>
                            <div
                              className="content-icons-r"
                              style={{ marginBlock: "10px" }}
                            >
                              <SvgIcon
                                color={"#004274"}
                                name={iconList.IconPinMap}
                                size={20}
                              />
                              <span style={{ fontSize: "12px" }}>
                                {item?.destination}
                              </span>
                            </div>
                            <div
                              className="content-icons-r"
                              style={{ marginBlock: "10px" }}
                            >
                              <SvgIcon
                                color={"#004274"}
                                name={iconList.IconCheckSucces}
                                size={20}
                              />
                              <span style={{ fontSize: "12px" }}>
                                Precio a partir de{" "}
                                {item?.price.toLocaleString("de-DE")}{" "}
                                {item?.currency}
                              </span>
                            </div>
                          </div>
                          <div className="content-btns-r">
                            <button>Conozca el proveedor</button>
                            <button
                              onClick={() => {
                                detailPack(item, item.id);
                              }}
                            >
                              Ver paquete
                            </button>
                          </div>
                        </div>
                      </section>
                      {/* <section className="part-two-r">
                        <button
                          className={
                            selectedCards[index] === 1 ? "select-interest" : ""
                          }
                          onClick={() => {
                            handleCardSelect(index, 1);
                          }}
                        >
                          Descripcion general
                        </button>
                        <button
                          className={
                            selectedCards[index] === 2 ? "select-interest" : ""
                          }
                          onClick={() => {
                            handleCardSelect(index, 2);
                          }}
                        >
                          Informacion del centro
                        </button>
                        <button
                          className={
                            selectedCards[index] === 3 ? "select-interest" : ""
                          }
                          onClick={() => {
                            handleCardSelect(index, 3);
                          }}
                        >
                          Mas paquetes
                        </button>
                      </section> */}
                      {/* <section className="part-three-r">
                        <p
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></p>
                      </section> */}
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="content-buttons-cotizar">
            <div className="btns-detail-health">
              <button onClick={goReservation}>Reservar</button>
              <button>Contactar</button>
            </div>
          </div>
        </section>
      </div>
      <Paginado
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        currentPage={currentPage}
        data={services}
        mostrarNext={mostrarNext}
      />
      <BestExperiences />
    </div>
  );
};

export default ResultSearchPack;
