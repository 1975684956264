import React from 'react'
import './styles.css'
import CardHotelResult from './CardHotelResult'
import Paginado from '../Paginado/Paginado'
import Loading from '../../../share/components/loading'
import FlowPack from '../../../share/components/flowPack/FlowPack'
import { widgetsList } from '../../../../../infra/constants/app'
import { useLocation } from 'react-router-dom'
import axios from 'axios'


const AllCardsResults = ({ data, currentPage, mostrarNext, prevHandler, nextHandler, dataS, loading, seePack,dataRoom, persons,fen,fsa,rooms,country_client }) => {

    const hotels = []
    const {pathname} = useLocation()

    dataS.map((e)=>{
        hotels.push({
            "code":e?.Id
        })
    })

    const dataRequest = {
        "arrival": dataRoom?.arrival,
        "departure": dataRoom?.departure,
        "country": "CO",
        "countryClient": "CO",
        "hotels":hotels,
        "rooms":dataRoom?.rooms
      }

    const detailHotelView = () => {
        console.log(dataRequest)
        axios.post('https://travelflight.pdtcomunicaciones.com/api/hotel-do/quote-hotel', dataRequest)
          .then(res => {
            const isWidget = widgetsList.find((w) => w.path === window.location.pathname);
            let url = ""
            if (isWidget) {
              url = `/widget/hotels/`
            } else if (pathname.includes("packages")) {
              url = "/packages/hotel/"
            } else {
              url = "/hotel/"
            }
            console.log(res.data)
            // navigate(`${url}${data?.Name}`, {
            //   state: {
            //     dataHab: res.data,
            //     dataHot: informationDetail
            //   }
            // })
          })
          .catch(err => {
            console.log(err)
          })
      }

    return (
        <div className='contain-all-results-hotels'>
            {
                seePack &&
                <FlowPack data={data} fen={fen} persons={persons} fsa={fsa} type={"hotel"}/>
            }
            {

                data === undefined ?
                    <h2 style={{ textAlign: 'center' }}>No se encontraron resultados</h2>
                    :
                    (
                        loading === true ?
                            <Loading size={100}/>
                        :

                        data?.map((item, key) => {
                            return (
                                <CardHotelResult key={key} data={item} dataRoom={dataRoom} detailReservation={detailHotelView} fen={fen} fsa={fsa} country_client={country_client} rooms={rooms} persons={persons}/>
                            )
                        })
                    )

                        
            }
            <Paginado currentPage={currentPage} data={dataS} mostrarNext={mostrarNext} nextHandler={nextHandler} prevHandler={prevHandler} />
        </div>
    )
}
export default AllCardsResults