function Switcher({ size = 18, value, change }) {
  const onChange = () => {
    change(!value);
  }
  return (
    <div style={{
      background: value ? '#3e6c83' : '#e4e4e3',
      width: size * 2.5,
      padding: size / 8,
      borderRadius: (size / 8) * 2 + size / 2,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: value ? 'flex-end' : 'flex-start',
      transition: 'all ease 200ms'
    }}
      onClick={onChange}>
      <div style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        background: value ? '#1c4165' : '#8a8b8a'
      }}>
      </div>
    </div>
  )
}

export default Switcher;
