
// import { ellipsisText } from "../../utils";
import useUserProfile from "../../viewModels/profile/user.profile.viewModel";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import Find from "../share/components/Find/Find.jsx";
import './styles.css';
import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import Switcher from "../share/components/switch";
import AnyQuestions from "../home/components/anyQuestion";
import PaymentCards from "./components/paymentCards";
import CommercialProfile from "./components/sections/commercialProfile";
import MenuSideBarHome from "../home/components/MenuSideBar";
import { HambergerMenu, CloseCircle } from 'iconsax-react';
import MyCalendar from "./components/sections/myCalendar";
import MyTravels from "./components/sections/myTravels";
import AppointmentRequests from "./components/sections/appointmentRequests";
import TeleConsultation from "./components/sections/appointmentRequests/teleConsultation";
// import ProfileDetail from "./components/sections/profileDetail";


const FeatureUnderDevelopment = () => {
  return (
    <div className="functionalityUnderDevelopment">
      <SvgIcon name={iconList.IconAlertOutLine} size={60} color={'#004274'} />
      <p className="functionalityUnderDevelopmentText">
        Funcionalidad en desarrollo
      </p>
    </div>
  )
}


function ProfileUser() {
  const {
    userDetail,
    notifications,
    notificationsSelecteds,
    optionsList,
    optionActive,
    setOptionActive,
    selectOrDeselectNotification,
    isSelectedThisNotification,
    newNotifications,
    configProfile,
    switchOptionConfig,
    closeSession,
    goToTeleConsultation,
    codeTeleconsutation,
  } = useUserProfile();

  return (
    <div className="profileScreen">
      <div style={{ width: '88%', margin: 'auto' }}>
        <Find />
      </div>
      <CarouselSevices />
      <div className="profileScreen_Container">
        <div className="sideMenuBtn">
          <HambergerMenu size="25" color="#FFFFFF" />
        </div>


        <div className="profileScreen_ContainerSideLeft">
          <div className="profileScreen_ColumnContainer">
            {
              optionsList.length > 0 && optionsList.map((index, key) => {
                const isActive = optionActive === index.value;
                const customColor = isActive ? '#004274' : '#FFFFFF';
                return (
                  <div key={key}
                    onClick={() => setOptionActive(index?.value)}
                    className={`optionProfile ${isActive && 'optionProfileActive'}`}
                  >
                    <SvgIcon name={index?.icon} size={30} color={customColor} />
                    <p className="optionProfileText" style={{ color: customColor }}>{index?.text}</p>
                  </div>
                )
              })
            }
          </div>
        </div>


        {/* {optionActive === 1 &&
          <ProfileDetail userDetail={userDetail} 
          closeSession={closeSession}
          newNotifications={newNotifications}
          notifications={notifications}
          isSelectedThisNotification={isSelectedThisNotification}
          selectOrDeselectNotification={selectOrDeselectNotification}
          configProfile={configProfile}
          switchOptionConfig={switchOptionConfig}
          
          />} */}


{optionActive === 1 &&
          <div className="bigContainer">
            <div className="profileScreen_ContainerSideCenter">
              <div className="profileScreen_CardShadow profileCard">
                <div className='profileCardHeader'>
                  <div className="profileCardHeaderAvatar">
                    <div className="profileCardHeaderAvatarWrapper">
                      <img src={userDetail?.profile_photo_url} alt='profile-avatar' className="profileCardHeaderAvatarWrapperImg" />
                    </div>
                    <div className="profileCardHeaderBtn">
                      <SvgIcon name={iconList.IconPencilOutLine} size={25} color={'#FFFFFF'} />
                    </div>
                  </div>
                  <div className="profileCardHeaderInfo">
                    <p className="profileCardHeaderInfoTextBig">{userDetail?.name} {userDetail?.lastname}</p>
                    <p className="profileCardHeaderInfoTextSmall">Editar la información básica</p>
                  </div>
                </div>
                <div className="profileCardBody">
                  <div className="profileCardBodyGroup">
                    <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">Nombres</p>
                    <p className="profileCardBodyGroupText">{userDetail?.name}</p>
                  </div>
                  <div className="profileCardBodyGroup">
                    <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">Apellidos</p>
                    <p className="profileCardBodyGroupText">{userDetail?.lastname}</p>
                  </div>
                  {/* <div className="profileCardBodyGroup">
                    <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">Número de móvil</p>
                    <p className="profileCardBodyGroupText">({userDetail?.codePhone}) {userDetail?.phone}</p>
</div> */}
                  <div className="profileCardBodyGroup">
                    <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">Email</p>
                    <p className="profileCardBodyGroupText">{userDetail?.email}</p>
                  </div>
                  {/* <div className="profileCardBodyGroup">
                    <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">Fecha de nacimiento</p>
                    <p className="profileCardBodyGroupText">{userDetail?.dateOfBirth}</p>
                  </div> **/}
                  {/* <div className="profileCardBodyGroup">
                    <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">Género</p>
                    <p className="profileCardBodyGroupText">{userDetail?.gender}</p>
                  </div> **/}
                </div>
                <div className='profileCardFooter'>
                  <div className='profileCardFooterBtn' onClick={closeSession}>
                    <p className='profileCardFooterBtnText'>Cerrar sesión</p>
                  </div>
                </div>

              </div>


              <div className="buttonBig buttonBigOnly">
                <p className="buttonBigText">Anuncia tu negocio</p>
              </div>
            </div>


            <div className="profileScreen_ContainerSideRight">
              <div className="profileScreen_CardShadow notificationCard">
                <div className="notificationCardHeader">
                  <div className="notificationCardHeaderIcon">
                    <SvgIcon name={iconList.IconAlertOutLine} size={30} color={'#004274'} />
                  </div>
                  <p className="notificationCardHeaderTitle">Mis notificaciones</p>
                  {newNotifications > 0 && <p className="notificationCardHeaderInfo">{`- ${newNotifications} nueva${newNotifications > 1 ? 's' : ''}`}</p>}
                </div>
                <div className="notificationCardBody">
                  {notifications.length > 0 && notifications.map((index, key) => {
                    const isSelected = isSelectedThisNotification(index?.id);
                    if (key < 8) return (
                      <div key={key} className="notificationCardBodyNotification" style={{ opacity: index.isReaded ? 0.45 : 1 }}>
                        <div className="notificationCardBodyNotificationColumnLeft">
                          <div className="notificationCardBodyNotificationColumnLeftCheckbox">
                            <input
                              type="checkbox"
                              name="checkbox"
                              checked={isSelected}
                              onChange={() => selectOrDeselectNotification(index?.id)}
                            />
                          </div>
                          <p className="notificationCardBodyNotificationColumnLeftText">
                            {index?.name} - {index?.description}
                          </p>
                        </div>
                        <p className="notificationCardBodyNotificationColumnRight">
                          {index?.date.split('-')[0]}
                          <br />
                          {index?.date.split('-')[1]}
                        </p>
                      </div>
                    )
                  })
                  }
                </div>
                <div className="notificationCardFooter">
                  <div className="notificationCardFooterBtn">
                    <p className="notificationCardFooterBtnText">Ver más</p>
                  </div>
                </div>
              </div>
              <div className="profileScreen_CardShadow configCard">
                {configProfile.map((index, key) => {
                  if (index?.showInProfile) return (
                    <div key={key} className={`configCardOption ${configProfile.length !== key + 1 && 'configCardOptionBorder'}`}>
                      <div className="configCardOptionColumnLeft">
                        <p className="configCardOptionColumnLeftName">{index?.name}</p>
                        <p className="configCardOptionColumnLeftDescription">{index?.description}</p>
                      </div>
                      <div className="configCardOptionColumnRight">
                        <Switcher value={index?.isActive} change={(status) => switchOptionConfig(index?.id, status)} />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="profileScreen_CardShadow listCard">
                <div className="listCardHeader">
                  <p className="listCardHeaderText">Direcciones</p>
                </div>
                <div className="listCardBody">
                  <div className="listCardBodyElemt">
                    <p className="listCardBodyElemtText">Cll 94 Cra 12 #16-27 Medellín, Antioquia - Barrio Altos de Belen</p>
                  </div>
                  <div className="listCardBodyElemt">
                    <p className="listCardBodyElemtText">Cll 94 Cra 12 #16-27 Medellín, Antioquia - Barrio Altos de Belen</p>
                  </div>
                  <div className="listCardBodyElemt">
                    <p className="listCardBodyElemtText">Cll 94 Cra 12 #16-27 Medellín, Antioquia - Barrio Altos de Belen</p>
                  </div>
                </div>
                <div className="listCardFooter">
                  <div className="listCardFooterBtn">
                    <SvgIcon name={iconList.IconPlusCircleOutLine} size={25} color={'#004274'} />
                    <p className="listCardFooterBtnText">Añadir una nueva dirección</p>
                  </div>
                </div>
              </div>
              <PaymentCards />
            </div>
          </div>
        }



        {optionActive === 2 && <CommercialProfile />}
        {optionActive === 3 && <FeatureUnderDevelopment />}
        {optionActive === 4 && <MyCalendar goToTeleConsultation={goToTeleConsultation}/>}
        {optionActive === 5 && <AppointmentRequests/>}
        {optionActive === 6 && <TeleConsultation code={codeTeleconsutation}/>}
        {optionActive === 7 && <FeatureUnderDevelopment />}
        {optionActive === 8 && <FeatureUnderDevelopment />}
        {optionActive === 9 && <FeatureUnderDevelopment />}
        {optionActive === 11 && <MyTravels />}
        {optionActive === 12 && <FeatureUnderDevelopment />}
        {optionActive === 13 && <FeatureUnderDevelopment />}





      </div>

      <div className="buttonBigContainer">
        <div className="buttonBig buttonBigInGroup">
          <p className="buttonBigText">Ir a buscador médico</p>
        </div>
        <div className="buttonBig buttonBigInGroup">
          <p className="buttonBigText">Ir a travel</p>
        </div>
        <div className="buttonBig buttonBigInGroup">
          <p className="buttonBigText">Ir a tienda Wellezy</p>
        </div>
      </div>
      {/* <MayBeInterested /> */}
      <AnyQuestions title="¿Tienes alguna duda?" />
    </div>
  )
}
export default ProfileUser;
