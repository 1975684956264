import React, { useEffect } from 'react';
import './styles.css';
import Loading from '../../../share/components/loading';
import SvgIcon, { iconList } from '../../../share/components/iconSVG';
import ContainerFlights from './components/containerFlights';
import { useTranslation } from 'react-i18next';
import FlowPack from '../../../share/components/flowPack/FlowPack';
import { useLocation } from 'react-router-dom';

function FlightDetailResultsList({
  allPassengersSelected,
  loading,
  flightsList,
  isRoundTrip,
  queryError,
  nameOrigin,
  nameDestiny,
}) {

  const { pathname } = useLocation()
  const [t, i18n] = useTranslation("global")
  const smoothScrollToTop = () => {
    const currentPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (currentPosition > 0) {
      window.requestAnimationFrame(smoothScrollToTop);
      window.scrollTo(0, currentPosition - currentPosition / 8);
    }
  };

  useEffect(() => {
    smoothScrollToTop();
  }, []);

  return (
    <div className='containerFlight' >
      {loading && <Loading size={80} />}

      {!loading && queryError &&
        <div className='errosSection'>
          <div className='errosSectionIcon'>
            <SvgIcon color={"#DEDEDE"} size={140} name={iconList.IconSearch} />
          </div>
          <p className='errosSectionIconText'>{t("fligth.search_fligth.error_api")}</p>
        </div>
      }

      {!loading && !queryError &&
        <div className='column'>
          {
            pathname.includes("/packages") &&
            <FlowPack type={"fligth"} />
          }

          {!loading &&
            (
              flightsList?.recommendation ?
                flightsList?.recommendation?.map((index, i) => {
                  return (
                    <ContainerFlights
                      key={i}
                      nameOrigin={nameOrigin}
                      nameDestiny={nameDestiny}
                      Seg1={flightsList?.Seg1}
                      Seg2={flightsList?.Seg2}
                      isRoundTrip={isRoundTrip}
                      recommendation={index}
                      allPassengersSelected={allPassengersSelected}
                    />
                  )
                })
                :
                <h4>
                  No hay vuelos disponibles
                </h4>
            )
          }
        </div>
      }
    </div>
  )
};

export default FlightDetailResultsList;
