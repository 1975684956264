import React from 'react'

import './Assets/styles.css'
import { CircularProgress } from '@mui/material'
import { useTranslation } from "react-i18next"


function HotelSearchCard({ cardRef, search, setSearch, destiny, destinationSelectionFunc, placeholder,inputCity }) {

    

    const [t, i18n] = useTranslation("global")
    

  return (
    <div className='hotelSearchCard' ref={cardRef}>
        <div className='hotelSearchCard_'>
            <div className='hotelSearchCard_input'>
                <input ref={inputCity} type="text" onChange={(e)=>setSearch(e.target.value)} required placeholder= {t("hotel.search_engine.choose_destiny")} />
                {search.length > 2 & destiny.length <= 0 ?
                    <CircularProgress style={{position: 'absolute', right: '60px'}} size={30} />
                    :
                    ""
                }
            </div>
            <div className='hotelSearchCard_elements'>
                {destiny.map((element, index) => {
                    console.log(element)
                    return (
                        <div key={index} className='hotelSearchCard_element'>
                            <button onClick={() => destinationSelectionFunc(element)}>
                                 {element?.name}
                            </button>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default HotelSearchCard