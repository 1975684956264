import React, { useState } from "react";
import TourMainButton from "../TourMainButton/TourMainButton";
import ButtonTwo from "../../../assets/icons/path5882.jpg";
import ButtonOne from "../../../assets/icons/Union.jpg";
import { Link } from "react-router-dom";
import "./Assets/styles.css";
import { useTranslation } from "react-i18next";

function TourMain() {
  const [destinityOne, setDestinityOne] = useState(true);
  const [destinityTwo, setDestinityTwo] = useState(false);
  const [t, i18n] = useTranslation("global");

  const dataMain = [
    {
      id: 1,
      icon: ButtonOne,
      title: t("tours.button_one.title"),
      description: t("tours.button_one.description"),
    },
    {
      id: 2,
      icon: ButtonTwo,
      title: t("tours.button_two.title"),
      description: t("tours.button_two.description"),
    },
  ];

  const onClickFunctOne = () => {
    setDestinityOne(true);
    setDestinityTwo(false);
  };
  const onClickFunctTwo = () => {
    setDestinityOne(false);
    setDestinityTwo(true);
  };

  return (
    <div className="tourMain">
      <div className="tourMain_buttons">
        <Link to={"https://wa.link/uq1q0p"} target="blank">
          <TourMainButton
            mainButtonIcon={dataMain[0].icon}
            mainButtonTitle={dataMain[0].title}
            mainButtonDescription={dataMain[0].description}
            onClickFunct={onClickFunctOne}
            styled={
              destinityOne === true
                ? { border: "0px solid #004274" }
                : { border: "none" }
            }
            className="tourMainButton"
          />
        </Link>
        <Link to={"https://wa.link/uq1q0p"} target="blank">
          <TourMainButton
            mainButtonIcon={dataMain[1].icon}
            mainButtonTitle={dataMain[1].title}
            mainButtonDescription={dataMain[1].description}
            onClickFunct={onClickFunctTwo}
            styled={
              destinityTwo === true
                ? { border: "0px solid #004274" }
                : { border: "none" }
            }
          />
        </Link>
      </div>
    </div>
  );
}

export default TourMain;
