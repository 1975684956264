import React, { useState } from 'react'
import "./assets/styles.css"
import Paginado from './Paginado'

const ServicesEnfermeras = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0)
    const [mostrarNext, setMostrarNext] = useState(true)
    const itemsForPage = 10
  
    const [topic, setTopic] = useState([...data].splice(0,itemsForPage))
    const nextHandler = ()=>{
    const totalElementos = data.length;

    const nextPage = currentPage + 1

    const firtsIndex = nextPage * itemsForPage
    if(firtsIndex >= totalElementos) {
        setMostrarNext(false)
      return;
    }
    
    
    setCurrentPage(nextPage)
    setTopic([...data].splice(firtsIndex,itemsForPage))

  }
  const prevHandler = ()=>{
    setMostrarNext(true)
   const prevPage = currentPage - 1
   
   if(prevPage < 0)return;

   const firtsIndex = prevPage * itemsForPage

   setTopic([...data].splice(firtsIndex,itemsForPage))
    setCurrentPage(prevPage)
  }
  console.log(data)
  const value = data.length;
  return (
    <div>
      <div className='container-header-nurses'>
        <div className='header-services'>
          <div className='principal-text'>
            <h1>Servicios Enfermeria</h1>
            <p>"La mejor atencion en tu casa."</p>
          </div>
        </div>
      </div>
      <div className='container-cards-services'>
        {
          value ?
          data.map(topic => {
            return (
              <div>
                <h2>{topic.name}</h2>
              </div>
            )
          })
          :
          <div className='nurses-nothing'>
            <img src="https://cdn-icons-png.flaticon.com/512/3567/3567474.png" alt="" />
            <h2>No hay Enfermeras disponibles por el momento</h2>
          </div>
        }
      </div>

      <Paginado 
        nextHandler={nextHandler} 
        prevHandler={prevHandler} 
        currentPage={currentPage} 
        mostrarNext={mostrarNext}
        >

        </Paginado>
    </div>
  )
}

export default ServicesEnfermeras