import React, { useEffect, useRef } from "react";
import "./styles.css";
import { SearchNormal1, User } from "iconsax-react";
import HotelSearchCard from "../../../../Components/HotelSearchCard/HotelSearchCard";
import HotelDateCard from "../../../../Components/HotelDateCard/HotelDateCard";
import HotelRoomsCard from "../../../../Components/HotelRoomsCard/HotelRoomsCard";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import searchIcon from "../../../assets/icons/searchIcon.svg";

const HotelSearchEngine = ({
  titleSize,
  iconTitleSize,
  containDateFlexDirection,
  containDateJustify,
  layoutCampoWidth,
  containFinishFlexDirection,
  containFinishJustify,
  layoutCampoVWidth,
  widthContain,
  right,
  position,
  top,
  cardRef,
  openHotelSearch,
  paceholders,
  search,
  setSearch,
  destiny,
  dateEntry,
  setDateEntry,
  datesSelectedEntry,
  datesSelectedExit,
  setDatesSelected,
  cardRooms,
  setCardRooms,
  roomsSelected,
  setRoomsSelected,
  quantityPersons,
  openHotelSearchFuct,
  destinationSelectionFunc,
  searchHotelSelected,
  findCountryClient,
  nacionalityDropdown,
  setNacionalityDropdown,
  dataNacionality,
  selectedNacionality,
  nacionality,
  codeNacionality,
  loading,
  loadingSearch,
  validationForm,
  errApi,
  searchHotelNewApi,
}) => {
  const [t, i18n] = useTranslation("global");
  const { pathname } = useLocation();
  const inputNacionality = useRef(null);
  const inputCity = useRef(null);

  useEffect(() => {
    if (nacionalityDropdown) {
      inputNacionality.current.focus();
    }
  }, [nacionalityDropdown]);

  useEffect(() => {
    if (openHotelSearch) {
      inputCity.current.focus();
    }
  }, [openHotelSearch]);

  return (
    <div
      className="container_campoSearch_"
      style={{
        width: `${widthContain}%`,
        right: `${right}`,
        position: `${position}`,
        top: `${top}`,
        boxShadow: `${pathname === "/packages" ? "none" : ""}`,
      }}
    >
      <div className="contain-header-buscador-hotel">
        <SvgIcon size={46} name={iconList.IconHotel} color={"#004274"} />
        <h3 style={{ fontSize: `20px`, marginLeft: "-10px" }}>
          {t("hotel.search_engine.accommodation")}
        </h3>
      </div>
      <div className="contain-campos">
        <div
          className="layout-campo"
          style={{ width: "100%" }}
          onClick={openHotelSearchFuct}
        >
          <div className="destiny-hotel">
            <span style={{ fontSize: "12px" }}>
              {t("fligth.search_fligth.destination")}
            </span>
            <p style={{ fontSize: "12px", fontWeight: 700 }}>
              {paceholders === ""
                ? t("hotel.search_engine.choose_destiny")
                : paceholders}
            </p>
          </div>
          <div>
            <SvgIcon name={iconList.IconPinMap2} color={"#004274"} size={30} />
          </div>
        </div>
        {openHotelSearch && (
          <HotelSearchCard
            cardRef={cardRef}
            search={search}
            setSearch={setSearch}
            placeholder={paceholders}
            destiny={destiny}
            destinationSelectionFunc={destinationSelectionFunc}
            inputCity={inputCity}
          />
        )}
        <div
          className="contain_date"
          style={{
            flexDirection: `${containDateFlexDirection}`,
            justifyContent: `${containDateJustify}`,
          }}
        >
          <div
            className="layout-campo"
            style={{ width: `${layoutCampoWidth}` }}
            onClick={() => setDateEntry(!dateEntry)}
          >
            <div className="destiny-hotel">
              <span style={{ fontSize: "12px" }}>
                {t("fligth.search_fligth.date")}
              </span>
              <p style={{ fontSize: "12px", fontWeight: 700 }}>
                {datesSelectedEntry}
              </p>
            </div>
            <div>
              <SvgIcon
                name={iconList.IconCalendar3}
                color={"#004274"}
                size={30}
              />
            </div>
          </div>
          {dateEntry && (
            <HotelDateCard
              cardRef={cardRef}
              setDatesSelected={setDatesSelected}
              setDateEntry={setDateEntry}
              left={"0"}
            />
          )}
          <div
            className="layout-campo"
            style={{ width: `${layoutCampoWidth}` }}
          >
            <div className="destiny-hotel">
              <span style={{ fontSize: "12px" }}>
                {t("fligth.search_fligth.date")}
              </span>
              <p style={{ fontSize: "12px", fontWeight: 700 }}>
                {datesSelectedExit}
              </p>
            </div>
            <div>
              <SvgIcon
                name={iconList.IconCalendar3}
                color={"#004274"}
                size={30}
              />
            </div>
          </div>
        </div>
        <div
          className="contain-finish"
          style={{
            flexDirection: `${containFinishFlexDirection}`,
            justifyContent: `${containFinishJustify}`,
          }}
        >
          <div
            className="layout-campo"
            style={{ cursor: "pointer", width: `100%` }}
            onClick={() => setCardRooms(!cardRooms)}
          >
            <div className="destiny-hotel" style={{ gap: "5px" }}>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                  maxWidth: "200px",
                  fontSize: "12px",
                }}
              >
                {t("hotel.search_engine.bedrooms")}
              </span>
              <div className="contain_rooms">
                <div className="contain_rooms_">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="sbox5-3-bed-svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 2C6.34315 2 5 3.34315 5 5V10.1707C3.83481 10.5825 3 11.6938 3 13V22C3 22.5523 3.44772 23 4 23C4.55229 23 5 22.5523 5 22L5 19H19V22C19 22.5523 19.4477 23 20 23C20.5523 23 21 22.5523 21 22V13C21 11.6938 20.1652 10.5825 19 10.1707V5C19 3.34315 17.6569 2 16 2H8ZM8 10H7V5C7 4.44772 7.44772 4 8 4H16C16.5523 4 17 4.44772 17 5V10H16V8C16 6.89543 15.1046 6 14 6H10C8.89543 6 8 6.89543 8 8V10ZM10 10H14V8H10V10ZM6 12C5.44772 12 5 12.4477 5 13V17H19V13C19 12.4477 18.5523 12 18 12H6Z"
                      fill="#004274"
                    ></path>
                  </svg>
                  <p>1</p>
                </div>
                <div className="contain_rooms_">
                  <User size="22" color="#004274" />
                  <p>{quantityPersons}</p>
                </div>
              </div>
            </div>
          </div>
          {cardRooms && (
            <HotelRoomsCard
              cardRef={cardRef}
              roomsSelected={roomsSelected}
              setRoomsSelected={setRoomsSelected}
              setCardRooms={setCardRooms}
              cardRooms={cardRooms}
            />
          )}
          <div className="layout-campo-s" style={{ width: `100%` }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
              onClick={() => {
                setNacionalityDropdown(!nacionalityDropdown);
              }}
            >
              <div>
                <span style={{ fontSize: "12px" }}>
                  {t("hotel.search_engine.nationality")}
                </span>
                <div className="contain-nacionality">
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    {nacionality === ""
                      ? t("hotel.search_engine.choose")
                      : `${nacionality}, ${codeNacionality}`}
                  </p>
                </div>
              </div>
              <SvgIcon name={iconList.IconBander} color={"#004274"} size={24} />
            </div>
            {nacionalityDropdown && (
              <div className="dropdown-nacionality">
                <div>
                  <input
                    ref={inputNacionality}
                    type="text"
                    onChange={(e) => {
                      findCountryClient(e.target.value);
                    }}
                  />
                  {loading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                      }}
                      size={25}
                    />
                  )}
                </div>

                <ul>
                  {dataNacionality.length >= 1 &&
                    dataNacionality.map((item, key) => {
                      return (
                        <li
                          key={key}
                          onClick={() => {
                            selectedNacionality(item.code, item.name);
                            setNacionalityDropdown(false);
                          }}
                        >
                          {item.name}, {item.code}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
          {pathname === "/packages" ? (
            ""
          ) : (
            <div
              className="layout-campo-s"
              style={{
                width: `100%`,
                PointerEvent: loadingSearch ? "none" : "auto",
                opacity: loadingSearch ? 0.5 : 1,
                marginTop: ".5rem",
                backgroundColor: "#004274",
                height: "46px",
              }}
              onClick={searchHotelSelected}
            >
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                {loadingSearch ? (
                  <CircularProgress size={20} />
                ) : (
                  <img src={searchIcon} alt="search" />
                )}
              </div>
            </div>
          )}

          {validationForm && (
            <div className="error-hotel-search">
              <p>{t("hotel.search_engine.please_complete")}</p>
            </div>
          )}
          {errApi && (
            <div className="error-hotel-search">
              <p>{t("hotel.search_engine.please_complete")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HotelSearchEngine;
