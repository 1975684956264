import '../../styles.css';
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import usePaymentCards from '../../../../viewModels/profile/payment.cards.viewModel';
import ModalContainer from '../../../share/components/modalContainer';
import AdminPaymentCards from '../adminPaymentCards';
import ActivityIndicator from '../../../share/components/activityIndicator';
import { useState } from 'react';
import DataPerson from '../adminPaymentCards/DataPerson';
import { useEffect } from 'react';
import PaymentForm from '../adminPaymentCards/PaymentForm';

function PaymentCards({
  selectItem = false,
  selected = false,
  setIdUser,
  setEmailOwner
}) {



  const {

    cardsRegisteredList,
    isOpenModal,
    setIsOpenModal,
    addCard,
    getResponsePaymentezAdd,
    userDetail,

    isFetchign,
    isDeleting,
    isSendingCard,
    deleteCard,
    message,
    successfull,
    setIdPay,
    idPay,
    getMyMethodpays
  } = usePaymentCards();
  const cardSelected = selected
  const [cardSelectedPay, setCardSelectedPay] = useState({})
  const [step, setStep] = useState(userDetail === null ? true : false)
  const [step2, setStep2] = useState(userDetail !== null ? true : false)
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const data = {
    tel: phone,
    email: email
  }


  useEffect(() => {
    setStep(userDetail === null ? true : false)
    setStep2(userDetail !== null ? true : false)
  }, [userDetail])

  const nextStep = () => {
    if (phone === "") {
      setError("p")
    } else if (email === "") {
      setError("e")
    } else {
      setTimeout(() => {
        setStep(false)
        setStep2(true)
        getMyMethodpays()
      }, 500);
    }

  }



  return (
    <>
      {isOpenModal &&
        <ModalContainer
          show={isOpenModal}
          hidden={setIsOpenModal}
          sizeWidth={50}
          sizeHeight={60}
        >
          {isSendingCard
            ? <div>
              {!successfull && message === ''
                ? <ActivityIndicator colorFill='#004274' size={80} />
                : <div className='modalStatus'>

                  {successfull
                    ? <SvgIcon name={iconList.IconCheckSucces} size={120} color={'#58D68D'} />
                    : <SvgIcon name={iconList.IconCloseCircleOutLine} size={120} color={'#EC7063'} />
                  }
                  <p className='modalStatusText'>{message}</p>
                </div>
              }
            </div>
            :
            <>
              {
                step &&
                <DataPerson error={error} nextStep={nextStep} setEmail={setEmail} setPhone={setPhone} setIdPay={setIdPay} />
              }
              {
                step2 &&
                (
                  userDetail === null ?
                    <AdminPaymentCards
                      user={data}
                      catchResponse={getResponsePaymentezAdd}
                      login={2}
                    />
                    :

                    <AdminPaymentCards
                      user={userDetail}
                      catchResponse={getResponsePaymentezAdd}
                      login={1}
                    />
                )

              }

            </>

          }
        </ModalContainer>
      }
      <div className="profileScreen_CardShadow listCard">




        <div className="listCardFooter">
          <div className="listCardFooterBtn" onClick={addCard} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
            <SvgIcon name={iconList.IconPlusCircleOutLine} size={25} color={'#004274'} />
            <p className="listCardFooterBtnText">Añadir método de pago</p>
          </div>

        </div>
      </div>
    </>
  )
}
export default PaymentCards;