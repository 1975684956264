import { useTranslation } from "react-i18next";
import { global } from "../../../../../../global/global";
import './styles.css';
const {
  iconAirplane,
} = global.img.icons

export function RowFiveColumnsTitle({
  type,
  origin,
  destiny,
  date,
}) {
  const [t,i18n] = useTranslation("global")
  const _origin = origin.split('-');
  const _destiny = destiny.split('-');
  return (
    <div className='5Columns rowFiveColumnsTitle'>
      <RowFiveColumnsInfo
        isTitle={true}
        col1={
          <div className='titleCol'>
            <div className='FiveColumnsIconWrap'>
              <img src={iconAirplane} alt="icon air plane" className='FiveColumnsIcon' />
            </div>
            <div className='titleColText'>
              <p className='titleColTextBig'>{type}</p>
              <p className='titleColTextSmall'>{date}</p>
            </div>
          </div>
        }
        col2={
          <div className='titleColText'>
            <p className='titleColTextBig'>{_origin[1]}</p>
            <p className='titleColTextSmall'>{_origin[0]}</p>
          </div>
        }
        col3={''}
        col4={<div className='titleColText'>
          <p className='titleColTextBig'>{_destiny[1]}</p>
          <p className='titleColTextSmall'>{_destiny[0]}</p>
        </div>
        }
        col5={<p className='titleColPackage'>{t("fligth.upsell_choose.luggage")}</p>}
      />
    </div>
  )
}





export function RowFiveColumnsInfo({ isTitle = false, col1, col2, col3, col4, col5 }) {
  return (
    <div className={`5Columns ${isTitle ? 'fiveColumnsContainerTitle' : 'fiveColumnsContainerChild'}`} style={{ marginBottom: isTitle ? 0 : 10 }}>
      <div className='fiveColumnsPrimaryColumn'>{col1}</div>
      {!isTitle &&
        <div className={`fiveColumnsPrimaryColumnRowTriple`}>
          <div className='fiveColumnsColumns'>{col2}</div>
          <div className='fiveColumnsColumns'>{col3}</div>
          <div className='fiveColumnsColumns'>{col4}</div>
        </div>
      }
      {isTitle && <div className='fiveColumnsColumns'>{col2}</div>}
      {isTitle && <div className='fiveColumnsColumns fiveColumnsColumnsEmpty'>{col3}</div>}
      {isTitle && <div className='fiveColumnsColumns'>{col4}</div>}
      <div className={`fiveColumnsColumns ${!isTitle ? 'package' : 'packageTitle'}`}>{col5}</div>
    </div>
  )
}

