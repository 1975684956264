import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation,useNavigate } from 'react-router-dom';

const UseSpecialist = () => {

    const location = useLocation();
    const condition = location.pathname
    const [dataAlways, setDataAlways] = useState([])
    let counter = 0;
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState([])
    const itemsForPage = 10
    const [currentPage, setCurrentPage] = useState(0)
    const [mostrarNext, setMostrarNext] = useState(true)
    const [urlImg, setUrlImg] = useState('')
    const navigate = useNavigate()

    function findSpecialist() {
        if (condition === '/Specialists' && counter === 0) {
            setLoading(true)
            const url = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/users/doctors`;
            axios.get(url)
                .then(res => {
                    counter += 1;
                    setLoading(false)
                    setServices(res.data.users)
                    console.log(res.data)
                    setUrlImg(res.data.url)
                    return setDataAlways([...res.data.users].splice(0, itemsForPage));
                })
                .catch(error => {
                    counter += 1;
                    setLoading(false)
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        findSpecialist();

    }, [])



    const nextHandler = () => {
        const totalElementos = services.length;

        const nextPage = currentPage + 1

        const firtsIndex = nextPage * itemsForPage
        if (firtsIndex >= totalElementos) {
            setMostrarNext(false)
            return;
        }


        setCurrentPage(nextPage)
        setDataAlways([...services].splice(firtsIndex, itemsForPage))

    }
    const prevHandler = () => {
        setMostrarNext(true)
        const prevPage = currentPage - 1

        if (prevPage < 0) return;

        const firtsIndex = prevPage * itemsForPage

        setDataAlways([...services].splice(firtsIndex, itemsForPage))
        setCurrentPage(prevPage)
    }

    const filterSpecialist = (word) => {

        if (word === 'All') {
            setDataAlways([...services].splice(0, itemsForPage))
        } else {
            const keyWord = word.toLowerCase()
            const newData = []

            for (let index = 0; index < services.length; index++) {
                for (let index2 = 0; index2 < services[index].subcategories.length; index2++) {
                    let toLowerCaseW = services[index].subcategories[index2].name.toLowerCase()
                    if (toLowerCaseW.includes(keyWord)) {
                        newData.push(services[index])
                    }
                }

            }

            setDataAlways(newData)


        }



    }

    function generateUniqueId() {
        return 'xxxxx-xxxx-4xxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const filterCity = (city)=>{
        setLoading(true)
        const url = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/users/doctors`;
        axios.get(url, {
            params:{
                "search":city
            }
        })
                .then(res => {
                    counter += 1;
                    setLoading(false)
                    setServices(res.data.users)
                    setUrlImg(res.data.url)
                    return setDataAlways([...res.data.users].splice(0, itemsForPage));
                })
                .catch(error => {
                    counter += 1;
                    setLoading(false)
                    console.log(error);
                });
    }


    const detailSpecialist = (doctor)=>{
        navigate(`/Specialists/${generateUniqueId()}`,{state:{
            name: doctor?.name,
            id: doctor?.id,
            urlImage: urlImg,
            pathImage: doctor?.profile_photo_path,
            subCategories: doctor?.specialities,
            email: doctor?.email,
            infoDoctor: doctor?.information_doctor,
            offices: doctor?.information_doctor?.medical_clinics,
            images: doctor?.images,
            doctor:doctor
          }})
    }

    return {
        dataAlways,
        loading,
        nextHandler,
        prevHandler,
        currentPage,
        services,
        mostrarNext,
        urlImg,
        filterSpecialist,
        filterCity,
        detailSpecialist
    }
}

export default UseSpecialist