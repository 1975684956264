import React from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import './styles.css'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'


const CartReserve = ({ list, setList, navigateMakeReservation }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [t] = useTranslation("global")
    let suma = 0
    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return cantidadFormateada;
    }

    list.forEach((item, key) => {
        suma += parseInt(item["@attributes"]?.prr);
    })

    const deleteReserve = (item) => {
        setList(list.filter((el) => el?.acomodation !== item?.acomodation));
    }

    const navigateFligthPack = () => {
        if (list.length == 0) {
            console.log("no hay nada")
        } else {
            localStorage.setItem("hab-selected", JSON.stringify(list))
            navigate(localStorage.getItem("urlFligth"))
        }
    }



    return (
        <div className='contain-cart-reserve'>
            <h2>
                {t("hotel.bookings")}
            </h2>


            <section>
                <div className="table-wrapper">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>{t("hotel.room")}</th>
                                <th>{t("hotel.accommodation")}</th>
                                <th>{t("hotel.deleted")}</th>
                                <th>{t("hotel.price")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.length < 1 ?
                                    <tr style={{ height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <td>
                                            {t("hotel.no_selected")}
                                        </td>

                                    </tr>

                                    :

                                    list.map((item, key) => {
                                        console.log(item)
                                        return (
                                            <tr key={key}>
                                                <td>{item?.name}</td>
                                                <td>{item?.acomodation}</td>
                                                <td>
                                                    <span style={{ cursor: 'pointer' }} onClick={() => { deleteReserve(item) }}>
                                                        <SvgIcon color={'#004274'} name={iconList.IconTrashOutLine} size={20} />
                                                    </span>
                                                </td>
                                                <td>{formatearCantidad(Number(item["@attributes"]?.prr))}</td>
                                            </tr>

                                        )
                                    })
                            }

                        </tbody>
                        <thead>

                            <tr>
                                <th></th>
                                <th></th>
                                <th>Total</th>
                                <th>
                                    {
                                        list.length > 0 ?

                                            formatearCantidad(suma)
                                            :
                                            '0'
                                    }
                                </th>
                            </tr>

                        </thead>
                    </table>
                </div>
                {
                    list.length > 0 &&
                        pathname.includes("packages") ?
                        <div>
                            <button className='reserve-button' onClick={navigateFligthPack}>Selecionar Habitacion</button>
                        </div>
                        :
                        <div>
                            <button className='reserve-button' onClick={navigateMakeReservation}>{t("hotel.reserve")}</button>
                        </div>
                }

            </section>


        </div>
    )
}

export default CartReserve