import React, { useEffect } from 'react'
import './styles.css'
import Find from '../share/components/Find/Find'
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices'
import AnyQuestions from '../home/components/anyQuestion'
import {useTranslation} from "react-i18next"
import BannerInsurance from './BannerInsurance/BannerInsurance'
import ButtonSecure from './buttonSecure/ButtonSecure'
import SvgIcon, { iconList } from '../share/components/iconSVG'
import { Link } from 'react-router-dom'



const TravelSecure = () => {

const [t] = useTranslation("global")

useEffect(() => {
  window.scrollTo(0, 0);
}, [])

  return (
    <div className='contain-travel-secures'>
        <Find/>
        <CarouselSevices current={t("carousel_services.travel_secure")}/>
        {/* <AlliesInsurance/>
        <ButtonsContactSecure/>
        <FormInsurance data={UseTravelSecure}/> */}
        <ButtonSecure/>
        <BannerInsurance/>
        <section className='contain-contact-t contact contact-insurance'>
            <Link to={'https://wa.link/uq1q0p'}>
              <div>
                <SvgIcon color={'#004274'} size={150} name={iconList.IconWhatsapp} />
              </div>
              <div className='text-contact-t'>
                <h3>
                  {t("transfers.you_have_doubts")}
                </h3>
                <p>
                  {t("transfers.description")}
                </p>
              </div>
            </Link>

          </section>
        <AnyQuestions title={t('browse_home.have_doubt')}/>
    </div>
  )
}

export default TravelSecure