import React from 'react';
import '../../../styles.css';
import './styles.css';
import ActivityIndicator from '../../../../share/components/activityIndicator';
import DinamicTablet from '../../../../share/components/tablet';
import useAppointmentRequests from '../../../../../viewModels/profile/AppointmentRequests/useAppointmentRequests.ViewModel';
import ModalContainer, { sliceFrom } from '../../../../share/components/modalContainer';
import FormCreateDate from '../../../../share/components/formCreateDate';

function AppointmentRequests() {
  const {
    isLoading,
    columnas,
    actionList,
    isShowModal,
    setIsShowModal,
    appointmentRequestSelected,
    appointmentList
  } = useAppointmentRequests();

  return (
    <div className="bigContainer appointmentRequests">
      <div className="profileScreen_ContainerSideCenter">
        <ModalContainer
          show={isShowModal}
          hidden={setIsShowModal}
          sizeWidth={90}
          sizeHeight={70}
          from={sliceFrom.up}
        >
          <FormCreateDate close={setIsShowModal} data={appointmentRequestSelected}/>
        </ModalContainer>

        {isLoading &&
          <div style={{ display:'flex', justifyContent:'center'}}>
            <ActivityIndicator />
          </div>
        }
         {!isLoading &&
        <DinamicTablet header={columnas} datos={appointmentList} actions={actionList} />
        }
      </div>
    </div>
  )
}

export default AppointmentRequests;