// import { Slider } from '@mui/material';
// import { useLocation, useSearchParams } from 'react-router-dom';
// Calendar, Airplane, Filter, Location, Record, RecordCircle, SearchNormal1, TickCircle, User 
// const { AviancaLogo, LatamLogo, VivaLogo } = global.img.logos;
// import { global } from '../../../../global';
// import { map } from 'jquery';
// import InputSelect from '../../../share/components/inputs/inputSelect';
// import InputRange, { typesRange } from '../../../share/components/inputs/inputRange';
// import InputRadio, { typesRadio } from '../../../share/components/inputs/inputRadio';

import React, { useEffect, useState, useRef } from 'react';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import './styles.css';
import InputCheck, { displayMethod } from '../../../share/components/inputs/inputCheck';
import SvgIcon, { iconList } from '../../../share/components/iconSVG';
import DesinitySearcher from '../desinitySearcher';
import FlightPassengerManagement from '../ flightPassengerManagement';
import FlightsDateGoing from '../../../../../Components/FlightsDateGoing/FlightsDateGoing';
import { useTranslation } from "react-i18next"

function DropDownContainer({ name, isStatic, children }) {
   const [isOpen, setisOpen] = useState(true);
   
   return (
      <div className='dropDownContainer'>
         <div className='dropDownContainerOption' onClick={() => !isStatic && setisOpen(!isOpen)}>
            <p className='dropDownContainerOptionText'>{name}</p>
            {!isStatic && <div>
               {isOpen
                  ? <ArrowDown2 size="25" color="#104172" />
                  : <ArrowUp2 size="25" color="#104172" />
               }
            </div>
            }
         </div>
         {isOpen && children}
      </div>
   )
}

const FlightDetailFilter = ({
   isRoundTrip,
   setIsRoundTrip,
   origin,
   setOrigin,
   destiny,
   setDestiny,
   getFlights,
   allPassengersSelected,
   setAllPassengersSelected,
   setDateGoingSelected,
   setDateGoingSelectedApi,
   setDateReturnSelected,
   setDateReturnSelectedApi,
   dateReturnSelected,
   dateGoingSelected,
}) => {

   const countPassengers = allPassengersSelected?.adult + allPassengersSelected?.boy + allPassengersSelected?.baby;

   const [t, i18n] = useTranslation("global")
  const desinitySearcherTypes = {
    origin: { text: t("fligth.search_fligth.origin"), errorText: 'Cambia tu origen', placeholder: t("fligth.search_fligth.fligth_origin") },
    destination: { text: t("fligth.search_fligth.destination"), errorText: 'Cambia tu destino', placeholder: t("fligth.search_fligth.fligth_destination") },
  }

  const [checkDate, setCheckDate] = useState(false)

   //ciudades
   const cardRefPlace = useRef();
   const [isCardRefOrigin, setIsCardRefOrigin] = useState(false);
   const [isCardRefDestination, setIsCardRefDestination] = useState(false);

   const getCity = (value, callback) => {
      const selected = `${value?.name} - ${value?.iata}`;
      callback(selected)
      cardRefPlace.current.style.display = 'none';
   }


   //fechas
   const cardRefDate = useRef();
   const [isCardRefDateGoing, setIsCardRefDateGoing] = useState(false);
   const [isCardRefDateReturn, setIsCardRefDateReturn] = useState(false);

   //pasajeros
   const cardRefPassenger = useRef();
   const [isCardRefPassengers, setIsCardRefPassengers] = useState(false);
   function finishProcess() {
      if (cardRefPassenger.current) {
         cardRefPassenger.current.style.display = 'none';
         setIsCardRefPassengers(false);
      }
   }
   const getPassengersSelecteds = (type, operation) => {
      setAllPassengersSelected((prev) => {
         return {
            ...prev, [type]: operation ? allPassengersSelected[type] + 1 : allPassengersSelected[type] - 1
         }
      })
   }


   useEffect(() => {
      function handleClickOutside(event) {
         if (cardRefPlace.current && !cardRefPlace.current.contains(event.target)) {
            setIsCardRefOrigin(false)
            setIsCardRefDestination(false)
         }
         if (cardRefDate.current && !cardRefDate.current.contains(event.target)) {
            setIsCardRefDateGoing(false)
            setIsCardRefDateReturn(false)
         }
         if (cardRefPassenger.current && !cardRefPassenger.current.contains(event.target)) {
            setIsCardRefPassengers(false)
         }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [])



   return (
      <div className='flightDetailFilter'>
         <div className='cardContainerShadow'>

            <div className='cardContainerShadowHeader'>
               <div className='cardContainerShadowHeaderTitle'>
                  <SvgIcon name={iconList.IconPinMap} size={30} color={'#004274'} />
                  <p className='cardContainerShadowHeaderTitleText'>{t("fligth.search_fligth.cheap_flights")}</p>
               </div>
               <DropDownContainer
                  isStatic={true}
                  name=''
                  children={
                     <InputCheck
                        methodDisplay={displayMethod.wrap}
                        list={
                           [
                              { isActive: isRoundTrip, label: t("fligth.search_fligth.round_trip"), change: () => setIsRoundTrip(true) },
                              { isActive: !isRoundTrip, label: t("fligth.search_fligth.one_way"), change: () => setIsRoundTrip(false) },
                           ]
                        }
                     />
                  } />
            </div>


            <div className="form">
               <div className='cardContainerBorder opt-1'>
                  <div className='row' onClick={() => setIsCardRefOrigin(!isCardRefOrigin)}>
                     <div className='column'>
                        <p className='text textMedium textGray'>{t("fligth.search_fligth.origin")}</p>
                        <p className='text textMedium textBlue textBold'>
                           {origin ? origin : t("fligth.search_fligth.fligth_origin")}
                        </p>
                     </div>
                     <SvgIcon name={iconList.IconPinMap} size={30} color={'#004274'} />
                  </div>
                  {isCardRefOrigin &&
                     <div ref={cardRefPlace} className='containerDestinySearch'>
                        <DesinitySearcher
                           type={desinitySearcherTypes.origin}
                           change={(obj) => getCity(obj, setOrigin)}
                        />
                     </div>
                  }
               </div>


               <div className='cardContainerBorder opt-1' >
                  <div className='row' onClick={() => setIsCardRefDestination(!isCardRefDestination)}>
                     <div className='column'>
                        <p className='text textMedium textGray'>{t("fligth.search_fligth.destination")}</p>
                        <p className='text textMedium textBlue textBold'>
                           {destiny ? destiny : t("fligth.search_fligth.fligth_destination")}
                        </p>
                     </div>
                     <SvgIcon name={iconList.IconPinMap} size={30} color={'#004274'} />
                  </div>
                  {isCardRefDestination &&
                     <div ref={cardRefPlace} className='containerDestinySearch'>
                        <DesinitySearcher
                           type={desinitySearcherTypes.destination}
                           change={(obj) => getCity(obj, setDestiny)}
                        />
                     </div>
                  }
               </div>




               <div className='cardContainerBorder opt-1'>
                  <div className='row' onClick={() => {
                     setIsCardRefDateGoing(!isCardRefDateGoing)
                     setCheckDate(false)
                     }}>
                     <div className='column'>
                        <p className='text textMedium textGray'>{t("fligth.search_fligth.date")}</p>
                        <p className='text textMedium textBlue textBold'>
                           {dateGoingSelected?dateGoingSelected:'Ida'}
                        </p>
                     </div>
                     <SvgIcon name={iconList.IconCalendar} size={30} color={'#004274'} />
                  </div>
               </div>
               {isCardRefDateGoing &&
                     <div className='searchFlightsContainerDate' >
                        <div className='child' ref={cardRefDate}>
                        <FlightsDateGoing
                        oneWay={!isRoundTrip}
                        setOneWay={setIsRoundTrip}
                        roundTrip={isRoundTrip}
                        checkDate={checkDate}
                        setCheckDate={setCheckDate}
                        setRoundTrip={setIsRoundTrip}
                        cardRef={cardRefDate}
                        setDateGoing={setIsCardRefDateGoing}
                        dateGoing={isCardRefDateGoing}
                        searcherFlight={getFlights}
                        setDateGoingSelected={setDateGoingSelected}
                        setDateGoingSelectedApi={setDateGoingSelectedApi}
                        setDateSreturnSelected={setDateReturnSelected}
                        setDateSreturnSelectedApi={setDateReturnSelectedApi}
                        />
                         </div>
                     </div>
                  }
               <div className='cardContainerBorder opt-1'>
               <div className='row' onClick={() => {
                  if (isRoundTrip) {
                     setCheckDate(true);
                     setIsCardRefDateReturn(!isCardRefDateReturn)}
                   }
               }>
                     <div className='column'>
                        <p className='text textMedium textGray'>{t("fligth.search_fligth.date")}</p>
                        <p className='text textMedium textBlue textBold'>
                           {dateReturnSelected? dateReturnSelected:t("fligth.search_fligth.return")}
                        </p>
                     </div>
                     <SvgIcon name={iconList.IconCalendar} size={30} color={'#004274'} />
                  </div>
               </div>
               {isCardRefDateReturn &&
                     <div className='searchFlightsContainerDate' >
                        <div className='child' ref={cardRefDate}>
                        <FlightsDateGoing
                        oneWay={!isRoundTrip}
                        setOneWay={setIsRoundTrip}
                        roundTrip={isRoundTrip}
                        setRoundTrip={setIsRoundTrip}
                        cardRef={cardRefDate}
                        setDateGoing={setIsCardRefDateReturn}
                        dateGoing={isCardRefDateReturn}
                        searcherFlight={getFlights}
                        setDateGoingSelected={setDateGoingSelected}
                        setDateGoingSelectedApi={setDateGoingSelectedApi}
                        setDateSreturnSelected={setDateReturnSelected}
                        setDateSreturnSelectedApi={setDateReturnSelectedApi}
                        />
                         </div>
                     </div>
                  }

               {/* <div className='row notYet'>
                  <p className='text textMedium textBlue'>Todavia no he decidido la fecha</p>
               </div> */}

               <div className='cardContainerBorder  opt-1'>
                  <div className='row' onClick={() => setIsCardRefPassengers(!isCardRefPassengers)}>
                     <div className='column'>
                        <p className='textSimpleGray'>{t("fligth.search_fligth.passengers")}</p>
                        <p className='textTitle'>{`${countPassengers} ${t("fligth.search_fligth.passengers")}`}</p>
                     </div>
                     <SvgIcon name={iconList.IconPeoples} size={30} color={'#004274'} />
                  </div>
                  {isCardRefPassengers &&
                     <div ref={cardRefPassenger} className='containerDestinySearch'>
                        <FlightPassengerManagement
                           value={allPassengersSelected}
                           onChange={getPassengersSelecteds}
                           finishProcess={finishProcess}
                        />
                     </div>}
               </div>

               <div className='cardContainerBorder opt-1' onClick={getFlights}>
                  <div className='btnSearch'>
                     <SvgIcon name={iconList.IconSearch} size={30} color={'#004274'} />
                  </div>
               </div>

            </div>
         </div>
      </div>
   )
}
export default FlightDetailFilter;


// const rangeMin = 0
// const rangeMax = 850
// const [valueMin, changeMin] = useState(34)
// const [valueMax, changeMax] = useState(800)
// function getSimpleValue(value) {
//   changeMin(value?.min)
// }
// function getDoubleValues(values) {
//   changeMin(values?.min)
//   changeMax(values?.max)
//  }
//         <InputCheck
//               methodDisplay={displayMethod.wrap}
//               list={
//                 [
//                   { isActive: true, label: 'Todavia no he decidido la fecha', change: () => { } },
//                   // { isActive: false, label: 'Solo ida', change: () => { } },
//                   // { isActive: false, label: 'Multi destino', change: () => { } },
//                 ]
//               }
//             />
//       </div>
//     </div>
// //       <div className='cardContainerBorder'>
// //         <div className='column'>
// //           <p className='textTitle'>Marzo</p>
// //           <p className='textSimpleGray'>Desde $ 630.000</p>
// //         </div>
// //       </div>

// //       <div className='cardContainerBorder'>
// //         <div className='column'>
// //           <p className='textTitle'>Abril</p>
// //           <p className='textSimpleGray'>Desde $ 630.000</p>
// //         </div>
// //       </div>

// //       <div className='cardContainerBorder'>
// //         <div className='column'>
// //           <p className='textTitle'>Mayo</p>
// //           <p className='textSimpleGray'>Desde $ 630.000</p>
// //         </div>
// //       </div>

// //       <div className='cardContainerBorder'>
// //         <div className='column'>
// //           <p className='textTitle'>Junio</p>
// //           <p className='textSimpleGray'>Desde $ 630.000</p>
// //         </div>
// //       </div>

// //       <div className='cardContainerBorder'>
// //         <div className='column'>
// //           <p className='textTitle'>Julio</p>
// //           <p className='textSimpleGray'>Desde $ 630.000</p>
// //         </div>
// //       </div>

// //       <div className='cardContainerBorder'>
// //         <DropDownContainer
// //           isStatic={false}
// //           name='Vuelo'
// //           children={
// //             <InputCheck
// //               methodDisplay={displayMethod.flex}
// //               list={
// //                 [
// //                   { isActive: true, label: 'Ida y vuelta', change: () => { } },
// //                   { isActive: false, label: 'Solo ida', change: () => { } },
// //                 ]
// //               }
// //             />
// //           } />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Estadía'
// //           children={
// //             <InputRadio
// //               value={3}
// //               list={
// //                 [
// //                   { id: 0, name: 'Todas las estadías desde', value: '$350.289' },
// //                   { id: 1, name: 'De 1 a 5 días desde', value: '$350.289' },
// //                   { id: 2, name: 'De 6 a 10 días desde', value: '$350.289' },
// //                   { id: 3, name: 'De 11 a 15 días desde', value: '$350.289' },
// //                   { id: 4, name: 'Más de 15  días desde', value: '$350.289' },
// //                 ]
// //               }
// //               type={typesRadio.default}
// //               change={() => { }}
// //             />
// //           } />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Escalas'
// //           children={
// //             <InputRadio
// //               value={1}
// //               list={
// //                 [
// //                   { id: 0, name: 'Todas las escalas', value: '27' },
// //                   { id: 1, name: 'Directo', value: '25' },
// //                   { id: 2, name: '1 escala ', value: '2' },
// //                 ]
// //               }
// //               type={typesRadio.number}
// //               change={() => { }}
// //             />
// //           } />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Equipaje'
// //           children={
// //             <InputRadio
// //               value={1}
// //               list={
// //                 [
// //                   { id: 0, name: 'Todas las opciones', value: '27' },
// //                   { id: 1, name: 'Equipaje de mano', value: '25' },
// //                   { id: 2, name: 'Equipaje en bodega', value: '2' },
// //                 ]
// //               }
// //               type={typesRadio.number}
// //               change={() => { }}
// //             />
// //           } />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Precio'
// //           children={
// //             <InputRange
// //               symbol={'$'}
// //               type={typesRange.simple}
// //               min={rangeMin}
// //               max={rangeMax}
// //               valueMin={valueMin}
// //               change={getSimpleValue}
// //             />
// //           } />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Precio'
// //           children={
// //             <InputRange
// //               symbol={'$'}
// //               type={typesRange.double}
// //               min={rangeMin}
// //               max={rangeMax}
// //               valueMin={valueMin}
// //               valueMax={valueMax}
// //               change={getDoubleValues}
// //             />
// //           } />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Horarios'
// //           children={''} />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Aerolíneas'
// //           children={''} />

// //         <DropDownContainer
// //           isStatic={false}
// //           name='Duración'
// //           children={''} />

// //         <DropDownContainer
// //           isStatic={true}
// //           name='Ordenar Por'
// //           static
// //           children={
// //             <InputSelect
// //               value={''}
// //               list={
// //                 [
// //                   { value: 1, label: 'Precio' },
// //                   { value: 1, label: 'Clase' },
// //                   { value: 1, label: 'Escalas' },
// //                 ]
// //               }
// //             />
// //           } />
// //       </div>