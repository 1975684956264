import React, { useEffect, useState } from "react";
import Carousel from "../../share/components/CarouselServices/CarouselSevices";
import Find from "../../share/components/Find/Find";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import FormSuggestions from "../../tourismHealth/formAndSuggestions/form/FormSuggestions";
import Loading from "../../share/components/loading";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import "./styles.css";
import Paginado from "../../Specialist/Paginado/Paginado";
import BestExperiences from "../BestExperiences/BestExperiences";
import CardResultGlobal from "../../share/components/Find/ResultSearchGlobal/CardResultGlobal/CardResultGlobal";

const ResultSearchTourism = () => {
  const url = "https://aiop.com.co/api/medic_packages";

  const { state, pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataResult, setdataResult] = useState([]);
  let partes = pathname.split("/");
  let resultado = partes[partes.length - 1];
  const [wordSearch, setWordSearch] = useState(
    state?.procedure == null ? resultado : state?.procedure
  );

  const [services, setServices] = useState([]);
  const itemsForPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);
  const [dataProcedure, setDataProcedure] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const navigate = useNavigate();

  const nextHandler = () => {
    const totalElementos = services.length;

    const nextPage = currentPage + 1;

    const firtsIndex = nextPage * itemsForPage;
    if (firtsIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setdataResult([...services].splice(firtsIndex, itemsForPage));
  };
  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firtsIndex = prevPage * itemsForPage;

    setdataResult([...services].splice(firtsIndex, itemsForPage));
    setCurrentPage(prevPage);
  };

  // const getInformation = () => {
  //     axios.get(url, {

  //         params: {
  //             search: wordSearch
  //         }
  //     })
  //         .then(res => {
  //             setdataResult([...res.data.packs].splice(0, itemsForPage))
  //             setServices(res.data.packs)
  //             setLoading(false)
  //         })
  //         .catch(err => {
  //             console.log(err)
  //             setLoading(false)
  //         })
  // }

  const detailClinics = (clinic) => {
    navigate(`/Clinics/${clinic.name}`, {
      state: {
        name: clinic.name,
        address: clinic.address,
        id: clinic.id,
        urlImage: url,
        pathImage: clinic.image,
        subCategories: clinic.subcategories,
        stars: clinic.stars,
        description: clinic.description,
        city: clinic.city,
      },
    });
  };

  const detailView = (item) => {
    if (item?.email) {
      navigate(`/Specialists/${item.name}`, {
        state: {
          name: item?.name,
          id: item?.id,
          urlImage: url,
          pathImage: item?.profile_photo_url,
          subCategories: item?.subcategories,
          email: item?.email,
          infoDoctor: item?.information_doctor,
          offices: item?.cities,
          images: item?.images,
        },
      });
    } else {
      navigate(`/Clinics/${item?.name}`, {
        state: {
          name: item?.name,
          address: item?.address,
          id: item?.id,
          urlImage: url,
          pathImage: item?.image,
          subCategories: item?.subcategories,
          stars: item?.stars,
          description: item?.description,
          city: item?.city,
        },
      });
    }
  };

  function searchForProceduresOnSite() {
    axios
      .post("https://aiop.com.co/api/search", {
        search: wordSearch,
        city: state?.city === null ? "" : state?.city,
      })
      .then((res) => {
        setdataResult([...res.data.registers].splice(0, itemsForPage));
        setServices(res.data.registers);
        setLoading(false);
        setUrlImage(res.data.url);
        console.log(res.data.registers);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const [selectedCards, setSelectedCards] = useState(
    new Array(dataResult?.length).fill(1)
  ); // Inicializar todos en 1

  const handleCardSelect = (index, value) => {
    const newSelectedCards = [...selectedCards];
    newSelectedCards[index] = value;
    setSelectedCards(newSelectedCards);
  };

  const goReservation = () => {
    navigate(`/TourismHealth/reservation`);
  };

  useEffect(() => {
    setLoading(true);
    searchForProceduresOnSite();
  }, []);

  return (
    <div>
      <Find />
      <Carousel />
      <div className="content-all-result-torism">
        <h2 className="title-result-search">
          Los mejores paquetes de tratamientos médicos de salud y bienestar en
          todo el mundo
        </h2>
        <p className="phrace-result-search">
          Miles de mejores paquetes de tratamientos médicos
        </p>
        <section className="layout-result-filter">
          <div className="all-result-search-tourism">
            {loading === true ? (
              <Loading size={100} />
            ) : dataResult?.length === 0 ? (
              <h2>No hay resultados disponibles</h2>
            ) : (
              <>
                {dataResult.map((item, index) => {
                  const stars = [];
                  if (item?.stars) {
                    for (let i = 0; i < Number(item?.stars); i++) {
                      stars.push(
                        <SvgIcon
                          key={i}
                          name={iconList.IconStar}
                          color={"#FFD902"}
                          size={20}
                        />
                      );
                    }
                  } else {
                    for (let i = 0; i < 5; i++) {
                      stars.push(
                        <SvgIcon
                          key={i}
                          name={iconList.IconStar}
                          color={"#FFD902"}
                          size={20}
                        />
                      );
                    }
                  }

                  return (
                    <div className="card-pack-interest-r" key={index}>
                      <section className="part-one-r">
                        <div className="content-img-interest-r">
                          <img src={`${urlImage}${item.image}`} alt="" />
                        </div>
                        <div className="content-info-r">
                          <h3>{item.name}</h3>
                          <div className="content-icons-r">
                            <SvgIcon
                              color={"#004274"}
                              name={iconList.IconPinMap}
                              size={30}
                            />
                            <span>{item.address}</span>
                          </div>
                          <div className="content-icons-r">{stars}</div>
                          <div className="content-btns-r">
                            <button>Conozca el proveedor</button>
                            <button
                              onClick={() => {
                                detailView(item);
                              }}
                            >
                              Ver paquete
                            </button>
                          </div>
                        </div>
                      </section>
                      <section className="part-two-r">
                        <button
                          className={
                            selectedCards[index] === 1 ? "select-interest" : ""
                          }
                          onClick={() => {
                            handleCardSelect(index, 1);
                          }}
                        >
                          Descripcion general
                        </button>
                        <button
                          className={
                            selectedCards[index] === 2 ? "select-interest" : ""
                          }
                          onClick={() => {
                            handleCardSelect(index, 2);
                          }}
                        >
                          Informacion del centro
                        </button>
                        <button
                          className={
                            selectedCards[index] === 3 ? "select-interest" : ""
                          }
                          onClick={() => {
                            handleCardSelect(index, 3);
                          }}
                        >
                          Mas paquetes
                        </button>
                      </section>
                      <section className="part-three-r">
                        <div className="content-stars-card-global">
                          <ul style={{ marginLeft: "15px" }}>
                            {item?.subcategories.map((item1, key) => {
                              return (
                                <li
                                  key={key}
                                  style={{
                                    color: "rgb(134, 134, 134)",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {item.subcategories.length > 1
                                    ? `${item1.name}, `
                                    : item1.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </section>
                    </div>
                  );
                })}
                {/* {
                                                dataResult.map((item, key) => {
                                                    return (
                                                        <CardResultGlobal data={item} key={key} url={urlImage} />
                                                    )
                                                })



                                        } */}
              </>
            )}
          </div>
          <div className="content-buttons-cotizar">
            <div className="btns-detail-health">
              <button onClick={goReservation}>Cotizar</button>
              <button>Contactar</button>
            </div>
          </div>
        </section>
      </div>
      <Paginado
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        currentPage={currentPage}
        data={services}
        mostrarNext={mostrarNext}
      />
      <BestExperiences />
    </div>
  );
};

export default ResultSearchTourism;
