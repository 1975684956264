import CarRentaM from "../../viewModels/carRenta/CarRenta";
import "./assets/styles.css";

import CarouselServices, { servicesTravel } from "../share/components/CarouselServices/CarouselSevices";
import Find from "../share/components/Find/Find";
import { useTranslation } from "react-i18next";
import banner from "../../assets/pictures/bannerRentCar.png";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import SvgIcon, { iconList } from "../share/components/iconSVG";
import { Link } from "react-router-dom";
import SearchRentCar from "./SearchRentCar/SearchRentCar";

const CarRenta = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <Find></Find>

      <CarouselServices current={t("carousel_services.car_rental")} />

      <header className="header-rent-car">
        <div className="content-buscador-rent-car">
          <SearchRentCar />
        </div>
        <div className="content-banner-rentcar">
          <img src={banner} alt="" />
        </div>
      </header>
      <div style={{ width: "85%", margin: "auto" }}>
        <section className="contain-contact-t">
          <Link to={"https://wa.link/uq1q0p"}>
            <div>
              <SvgIcon
                color={"#004274"}
                size={150}
                name={iconList.IconWhatsapp}
              />
            </div>
            <div className="text-contact-t">
              <h3>{t("transfers.you_have_doubts")}</h3>
              <p>{t("transfers.description")}</p>
            </div>
          </Link>
        </section>
      </div>
    </div>
  );
};

export default CarRenta;
