import React from "react";
import Find from "../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import BannerSpecialist from "./BannerSpecialist/BannerSpecialist";
import ButtonsFilters from "./ButtonsFilters/ButtonsFilters";
import UseSpecialist from "../../viewModels/specialist/UseSpecialist";
import AllCardsSpecialist from "./AllCardsSpecialist/AllCardsSpecialist";
import Paginado from "./Paginado/Paginado";
import { useTranslation } from "react-i18next";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";

const Specialists = () => {
  const [t, i18n] = useTranslation("global");
  const {
    dataAlways,
    loading,
    nextHandler,
    prevHandler,
    currentPage,
    services,
    mostrarNext,
    urlImg,
    filterSpecialist,
    filterCity,
    detailSpecialist,
  } = UseSpecialist();

  return (
    <div>
      <Find />
      <CarouselSevices current={t("carousel_services.specialist")} />
      <BannerSpecialist />
      <ButtonsFilters
        filterSpecialist={filterSpecialist}
        filterCity={filterCity}
      />
      <AllCardsSpecialist
        data={dataAlways}
        urlImg={urlImg}
        loading={loading}
        detailSpecialist={detailSpecialist}
      />
      <Paginado
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        currentPage={currentPage}
        data={services}
        mostrarNext={mostrarNext}
      />

      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Specialists;
