import { useTranslation } from 'react-i18next';
import './styles.css';

function ComponentPassenger({ data, baby, toEdit, editing }) {

  const [t, i18n] = useTranslation("global")


  return (
    <>
      <div className='tabletPassengersPassenger'>
        <div className='tabletPassengersPassengerL'>
          {!baby &&
            <p className='tabletPassengersGroupType'>{data?.key}</p>
          }
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.name")}</p>
            <p className='tabletPassengersGroupValue'>{data.name}</p>
          </div>
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.last_name")}</p>
            <p className='tabletPassengersGroupValue'>{data.lastName}</p>
          </div>
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.identify")}</p>
            <p className='tabletPassengersGroupValue'>{data.documentNumber}
            </p></div>
          {data.key !== 'CONTACT' &&
            <div className='tabletPassengersGroup'>
              <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.gender")}</p>
              <p className='tabletPassengersGroupValue'>{data.gender}</p>
            </div>}

          {data.key !== 'CONTACT' &&
            <div className='tabletPassengersGroup'>
              <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.date")}</p>
              <p className='tabletPassengersGroupValue'>{data.birth}</p>
            </div>
          }
          {data.key === 'CONTACT' &&
            <div className='tabletPassengersGroup'>
              <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.email")}</p>
              <p className='tabletPassengersGroupValue'>{data.email}</p>
            </div>
          }
          {data.key === 'CONTACT' &&
            <div className='tabletPassengersGroup'>
              <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.phone")}</p>
              <p className='tabletPassengersGroupValue'>{data.codePhone} - {data.numberPhone}</p>
            </div>
          }
          {data.key === 'CONTACT' &&
            <div className='tabletPassengersGroup'>
              <p className='tabletPassengersGroupKey'>{t("fligth.table_passengers.address")}</p>
              <p className='tabletPassengersGroupValue'>{data.address}</p>
            </div>
          }
        </div>
        <div className='tabletPassengersPassengerR'>
          {
            editing &&
            <div className='tabletPassengersBtnEdit' onClick={() => toEdit(data)}>
              <p className='tabletPassengersBtnEditText'>{t("fligth.table_passengers.edit")}</p>
            </div>
          }
        </div>
      </div>
      {
        data.baby &&
        <div className='tabletPassengersContainerBaby'>
          <p className='tabletPassengersGroupType'
          >{data.name} {t("fligth.table_passengers.carries_hands")}:</p>
          <ComponentPassenger data={data.baby} baby={true} toEdit={toEdit} editing={editing} />
        </div>
      }
    </>
  )
}


function TabletPassengers({
  list,
  toEdit = () => { },
  editing = false,
  title,
}) {


  console.log("............list", list)
  return (
    <div className='tabletPassengers'>
      <div className='tabletPassengersHead'>
        <p className='tabletPassengersHeadTitle'>{title}</p>
      </div>
      <div className='tabletPassengersBody'>
        {
          list && list.map((index, key) => {
            return (
              <ComponentPassenger
                key={key}
                data={index}
                baby={false}
                toEdit={toEdit}
                editing={editing}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default TabletPassengers;
