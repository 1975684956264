import { useState, useEffect,useContext } from "react";
import { iconList } from "../../../views/share/components/iconSVG";
import { doctorURL } from "../../../../infra/urls";
import { wellezy_api } from "../../../../infra/api";
import UserContext from '../../../../Context/user/userProvider';

function useAppointmentRequests() {
  const { userDetail } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [ appointmentRequestSelected, setAppointmentRequestSelected] = useState(true);
  const [appointmentList, setAppointmentList] =useState([]);

  const columnas = [
    { key: 'name', value: 'Nombre' },
    { key: 'identification', value: 'Documento' },
    { key: 'tel', value: 'Teléfono' },
    { key: 'procedure', value: 'Tratamiento' },
    { key: 'actions', value: 'actions' },
  ];
  
  const schedule = (data) => {
    setAppointmentRequestSelected(data);
    setIsShowModal(true)
  }

  const actionList = [
    { color: '#004274', size: 25, icon: iconList.IconCalendar, label: 'Agendar', action: schedule },
  ]

  async function  getAppointment(){
    try {
      setIsLoading(true);
      const url = doctorURL?.get?.appointmentsPending
      const data = {
        user_id: userDetail?.id,
      };
      const response = await wellezy_api.get(url,{
        params : data
      })
      const newAppointments = response?.data?.appointments;
      setAppointmentList(newAppointments);
    } catch (error) {
      console.log("Error in  getAppointment(): ", error)
    }
    finally{
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    getAppointment();
  },[])

  return {
    appointmentList,
    isLoading,
    columnas,
    actionList,
    isShowModal,
    setIsShowModal,
    appointmentRequestSelected
  }
}
export default useAppointmentRequests;
