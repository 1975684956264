import React from 'react'
import "./styles.css"
import BannerTourismHealth from './bannerTourismHealth/BannerTourismHealth'
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices'
import FormAndSuggestions from './formAndSuggestions/FormAndSuggestions'
import BestExperiences from './BestExperiences/BestExperiences'
import OutstandingPlaces from './outstandingPlaces/OutstandingPlaces'
import NewsTourism from './newsTourism/NewsTourism'
import PackagesInterest from './packagesInterest/PackagesInterest'

const TourismHealth = () => {
  return (
    <div className='content-tourism-health'>
        <BannerTourismHealth/>
        <CarouselSevices/>
        <FormAndSuggestions/>
        <BestExperiences/>
        <OutstandingPlaces/>
        <NewsTourism/>
        <PackagesInterest/>
    </div>
  )
}

export default TourismHealth