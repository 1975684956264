import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

const Banner = ({ name, address, urlImg, pathImage }) => {
  return (
    <div className="contain-banner-clinics">
      <p className="route-clinic">
        {" "}
        <Link to={"/Clinics"}>Clinics</Link> {">"} <span>{name} </span>
      </p>
      <div className="contain-principal-banner-clinic">
        <h1>{name}</h1>
        <p>{address}</p>
        <img src={`https://staging.aiop.com.co/storage/${pathImage}`} alt={name} />
      </div>
    </div>
  );
};

export default Banner;
