import React from 'react'
import background from '../../../../assets/pictures/bgshop.png'
import card from '../../../../assets/svg/cartBuy.png'
import like from '../../../../assets/svg/like.svg'
import love from '../../../../assets/svg/heartChat.svg'
import cloud from "../../../../assets/svg/cloud.svg"

import './styles.css'
import {useTranslation} from "react-i18next"
import { useNavigate } from 'react-router-dom'

const ShopWellezy = () => {

const [t] = useTranslation("global")

const navigate = useNavigate()

const navigatePage = ()=>{
  navigate("/shopWellezy")
}

  return (
    <div className='contain-shop-wellezy'>
        <div className='contain-info-shopping'>
            <section className='info-letters-shop'>
                <span className='explore'>{t('browse_home.shopping_wellezy.explore')}</span>
                <h2><span>{t('browse_home.shopping_wellezy.title')}</span><span>Wellezy</span></h2>
                <p>{t('browse_home.shopping_wellezy.pharse')}</p>
                <button onClick={navigatePage}>{t('browse_home.shopping_wellezy.go_shop')}</button>
            </section>
            <section className='contain-img-shop'>
                <img className='shop-logo-home tp-img' src={love} alt="logo-home" />
                <img className='cart-home-shop' src={card} alt="cart-home" />
                <img className='shop-logo-home' src={like} alt="logo-home" />
            </section>
        </div>
        <div className='contain-clouds'>
          <div className='position-cloud'>
            
            <img src={cloud} className='clouds' alt="" />
            <img src={cloud} className='clouds' alt="" />
          </div>
          <div className='position-cloud2'>
            <img src={cloud} className='clouds' alt="" />
            
            <img src={cloud} className='clouds' alt="" />
          </div>
          <div className='position-cloud3'>
            <img src={cloud} className='clouds' alt="" />
            <img src={cloud} className='clouds' alt="" />
           
          </div>
        </div>
        
    </div>
  )
}

export default ShopWellezy