import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UseCosmetics = () => {
  const navigate = useNavigate();
  const [dataCosmetics, setDataCosmetics] = useState([]);
  const location = useLocation();
  const condition = location.pathname;
  let counter = 0;
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const itemsForPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);
  const [urlImage, setUrlImage] = useState("");

  function findCosmetics() {
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/products/beauty`
    if (condition === "/Cosmetics" && counter === 0) {
      setLoading(true);
      axios
        .get(url, {
          params: {
            search: "",
          },
        })
        .then((res) => {
          counter += 1;
          setLoading(false);
          setServices(res.data.products);
          console.log(res.data);

          setUrlImage(res.data.url);
          setDataCosmetics([...res.data.products].splice(0, itemsForPage));
        })
        .catch((error) => {
          counter += 1;
          setLoading(false);
          console.log(error);
        });
    }
  }

  useEffect(() => {
    findCosmetics();
  }, []);

  const nextHandler = () => {
    const totalElementos = services.length;

    const nextPage = currentPage + 1;

    const firtsIndex = nextPage * itemsForPage;
    if (firtsIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setDataCosmetics([...services].splice(firtsIndex, itemsForPage));
  };

  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firtsIndex = prevPage * itemsForPage;

    setDataCosmetics([...services].splice(firtsIndex, itemsForPage));
    setCurrentPage(prevPage);
  };

  function generateUniqueId() {
    return "xxxxx-xxxx-4xxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const detailCosmetics = (cosmetic) => {
    navigate(`/Cosmetics/${generateUniqueId()}`, {
      state: {
        name: cosmetic.name,
        id: cosmetic.id,
        urlImage: urlImage,
        pathImage: cosmetic.images[0].url,
        quantity: cosmetic.quantity,
        description: cosmetic.description,
        price: cosmetic.price,
      },
    });
  };

  return {
    dataCosmetics,
    loading,
    nextHandler,
    prevHandler,
    currentPage,
    mostrarNext,
    services,
    urlImage,
    detailCosmetics,
  };
};

export default UseCosmetics;
