import React, { useEffect, useState } from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'
import ModalContainer from '../../../share/components/modalContainer'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'

const HabDetail = ({ Hab, id, persons, typeAcomodation, listReserve, setListReserve }) => {
    const [selectHab, setSetselectHab] = useState({})
    const [alertList, setAlertList] = useState(false)
    const [habOption, setHabOption] = useState(Hab[0].reg[0])
    const [t] = useTranslation("global")
    const [activeChangeService, setActiveChangeService] = useState(false)
    const [errChangeServices, setErrChangeServices] = useState(false)

    const selectedHabFunction = (item) => {
        setSetselectHab(item)
        let bodyNew = item?.reg[0]
        bodyNew["acomodation"] = typeAcomodation
        bodyNew["name"]= item["@attributes"]?.desc
        setHabOption(bodyNew)
        setSetselectHab((prevState) => {
            return { ...prevState, acomodation: typeAcomodation };
        });
    }

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return cantidadFormateada;
    }

    const joinReserve = (item) => {
        const isDuplicate = listReserve.some((reserve) => reserve.acomodation === item.acomodation);
        console.log(item)

        if (isDuplicate) {

            setAlertList(true);
            setTimeout(() => {
                setAlertList(false);
            }, 2000);
        } else {

            setListReserve((prevList) => [...prevList, item]);
        }

    }

    const optionNr = (tipoReembolso, include) => {

        const alojamientoEncontrado = selectHab?.reg?.find((hab) => {
            return hab['@attributes'].cod === include && hab['@attributes'].nr === tipoReembolso;
        });

        console.log(alojamientoEncontrado)

        if (alojamientoEncontrado === undefined) {
            setErrChangeServices(true)
            setTimeout(() => {
                setErrChangeServices(false)
            }, 2000);
        } else {
            alojamientoEncontrado["acomodation"] = typeAcomodation
            alojamientoEncontrado["name"] = selectHab["@attributes"]?.desc
            setHabOption(alojamientoEncontrado);
        }


    }





    return (
        <div className='contain-hab-detail'>
            <section className='contain-all-hab'>
                {
                    Hab.map((item, key) => {
                        return (
                            <div className='card-hab-detail' key={key}>
                                <div className='content-info-hab'>
                                    <h2>{item["@attributes"]?.desc}</h2>
                                    <p><SvgIcon color={'#004274'} name={iconList.IconPeoples} size={20} /> ({typeAcomodation}) {t("fligth.search_fligth.adults")}, {t("fligth.search_fligth.childrens")}</p>

                                    <p><SvgIcon color={'#004274'} name={iconList.IconCheckSucces} size={20} />{t("hotel.days_advance")}: {item?.reg[0]?.gastos?.gasto?.dias_antelacion}</p>
                                    <p><SvgIcon color={'#004274'} name={iconList.IconCheckSucces} size={20} />{t("hotel.hours_advance")}: {item?.reg[0]?.gastos?.gasto?.horas_antelacion}</p>
                                    <p><SvgIcon color={'#004274'} name={iconList.IconCheckSucces} size={20} />{t("hotel.nights_spending")}: {item?.reg[0]?.gastos?.gasto?.noches_gasto}</p>
                                    <p><SvgIcon color={'#004274'} name={iconList.IconCheckSucces} size={20} /> {item?.reg[0]["@attributes"]?.cod}</p>
                                </div>

                                <div className='content-selected-hab'>
                                    <input type="radio" name={`hab${id}`} id="" onClick={() => selectedHabFunction(item)} />
                                    <span>{t("hotel.select")}</span>
                                </div>
                            </div>
                        )
                    })
                }


            </section>
            <section className='contain-price-info-hab'>
                {selectHab['@attributes'] ?
                    <>
                        {/* <span>{persons} {persons > 1 ? 'Personas' : 'Persona'}</span> */}
                        <span className='price-Hab-select'>{formatearCantidad(Number(habOption["@attributes"]?.prr))}</span>
                        <p>{t("hotel.include_taxes")}</p>
                        <span className='what-include'>{t("hotel.what_include_price")}</span>
                        <button onClick={() => { joinReserve(habOption) }}>{t("hotel.add_reservation")}</button>
                        {
                            alertList &&
                            <span className='alertList'>{t("hotel.only_add")}</span>
                        }

                        <div>
                            <h3>{t("hotel.info_reservation")}</h3> 
                            <ul>
                                <li>
                                    <SvgIcon color={'#002474'} name={iconList.IconCheckSucces} size={20} /> {selectHab["@attributes"]?.desc}
                                </li>
                                <li style={{ cursor: "pointer" }}>

                                    <SvgIcon color={'#002474'} name={iconList.IconCheckSucces} size={23} /> <p style={{ display: "flex", flexDirection: "column" }}>{habOption["@attributes"]?.cod}
                                        <span style={{ fontWeight: "bold", fontSize: "13px" }} onClick={() => {
                                            setActiveChangeService(true)
                                        }}>Cambiar</span></p>




                                </li>
                                <li style={{ cursor: "pointer" }}>
                                    {
                                        habOption["@attributes"]?.nr === "No reembolsable"

                                            ?
                                            <>
                                                <SvgIcon color={'#002474'} name={iconList.IconCloseCircleOutLine} size={23} /> <p onClick={() => {
                                                    optionNr("Reembolsable", habOption["@attributes"]?.cod)
                                                }} style={{display:"flex", flexDirection:"column"}}>{habOption["@attributes"]?.nr}{errChangeServices &&
                                                    <span style={{color:"red",fontWeight:"bold", fontSize:"13px"}}>
                                                        Habitacion no es reembolsable
                                                    </span>}</p>
                                                
                                            </>

                                            :
                                            <>
                                                <SvgIcon color={'#002474'} name={iconList.IconCheckSucces} size={20} /> <p onClick={() => {
                                                    optionNr("No reembolsable", habOption["@attributes"]?.cod)
                                                }} style={{display:"flex", flexDirection:"column"}}>{habOption["@attributes"]?.nr}{errChangeServices &&
                                                    <span style={{color:"red",fontWeight:"bold", fontSize:"13px"}}>
                                                        Habitacion es reembolsable
                                                    </span>}</p>
                                                
                                            </>


                                    }


                                </li>
                            </ul>
                        </div>
                    </>
                    :
                    <div>
                        <h2 className='unselected-hab'>
                            {t("hotel.no_selected")}
                        </h2>
                    </div>
                }
            </section>

            <ModalContainer show={activeChangeService} hidden={setActiveChangeService}>
                <div className='content-services-modal-hab'>
                    <h3>Escoje tu comodidad</h3>
                    <ul className='list-change-services-hab'>
                        {
                            selectHab?.reg?.map((tarifa, index) => {
                                return (
                                    <li onClick={() => {
                                        optionNr(habOption["@attributes"]?.nr, tarifa["@attributes"]?.cod)
                                        setActiveChangeService(false)
                                    }} key={index}>
                                        <SvgIcon color={'#002474'} name={iconList.IconCheckSucces} size={20} />
                                        {tarifa["@attributes"]?.cod}
                                    </li>
                                )
                            })
                        }


                    </ul>
                </div>


            </ModalContainer>


        </div>
    )
}

export default HabDetail