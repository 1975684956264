import React from 'react'
import banner from '../../../assets/pictures/bannerSpecialist.png'
import './styles.css'
import specialist from '../../../assets/images/Especialistas.png'
import { useTranslation } from 'react-i18next'
import SearchSpecialist from '../SearchSpecialist/SearchSpecialist'

const BannerSpecialist = () => {

  const [t,i18n] = useTranslation("global")

  return (
    <header className='header-fligths'>
        <div className='content-buscador-fli'>
          <SearchSpecialist/>
        </div>
        <div className='content-banner-t'>
          <img src={banner} alt="" />
        </div>
      </header>
  )
}

export default BannerSpecialist