import React, { useRef, useEffect, useState } from 'react';
import './styles.css';
import SvgIcon, { iconList } from '../../../../../share/components/iconSVG';
import { RowFiveColumnsTitle, RowFiveColumnsInfo } from '../rowFiveColumns';
import { useTranslation } from 'react-i18next';




const ComponentScales = ({ count }) => {
  const [t, i18n] = useTranslation("global")
  const getScale = count.length > 1
  return getScale ? t("fligth.list_flights.scale") : t("fligth.list_flights.direct");
}



function ContainerChecked({ flight, activedJourney, setActivedJourney, id, name }) {
  return (
    <div className='ContainerChecked'>
      <div className='ContainerCheckedUp'>
        <div className='checkActive' onClick={() => setActivedJourney(flight)}>
          {activedJourney?.num == flight?.num && <div className='checkInactive'></div>}
        </div>
        <div className='ContainerCheckedLogo'>
          <img src={`https://pics.avs.io/60/60/${id?.marketingCarrier}.png`} alt={id?.marketingCarrier} className='ContainerCheckedLogoFile' />
        </div>
      </div>
      <p className='ContainerCheckedName'>{name}</p>
    </div>
  )
}






function BagsContainer({ list }) {
  const isAvailable = (key) => {
    const bag = key + 1;
    return bag <= list;
  }
  const bagsLis = [
    iconList?.IconBagSmall,
    iconList?.IconBagMedium,
    iconList?.IconBagBig,
  ]
  return (
    <div className='bagsContainer'>
      {
        bagsLis.map((index, key) => {
          return (
            <div key={key} className='bagsContainerIcon'>
              <SvgIcon name={bagsLis[key]} color={isAvailable(key) ? '#104172' : '#EDEDED'} size={50} />
            </div>
          )
        })
      }
    </div>
  )
}



function ListFlights({
  isRoundTrip,
  Seg1,
  Seg2,
  nameOrigin,
  nameDestiny,
  recommendation,
  outwardJourney,
  setOutwardJourney,
  returnJourney,
  setReturnJourney
}) {
console.log(isRoundTrip)
  const containerRef = useRef(null);
  const [t, i18n] = useTranslation("global")
  const [isShowScales, setIsShowScales] = useState(false);
  const arraySegs = [Seg1, Seg2];

  const recommendationSegsList = [
    recommendation?.seg?.Seg1,
    recommendation?.seg?.Seg2,
  ];

  const extractDate = (obj) => {
    if (obj === undefined) {
      return "No hay fecha"
    } else {
      const respuesta = obj[0]?.segments[0]?.productDateTime?.dateOfDeparture || "hola";

      return respuesta;
    }

  };

  return (
    <div className='componentChild' ref={containerRef}>
      <RowFiveColumnsTitle
        type={t("fligth.search_fligth.going")}
        origin={nameOrigin}
        destiny={nameDestiny}
        date={extractDate(arraySegs[0])}
      />
      <div className='componentChildContainer'>
        {
          recommendationSegsList[0].map((segmentFlightRefItem, key) => {
            const flight = arraySegs[0]?.find((obj) => obj.num == segmentFlightRefItem?.refNumber)
            const firstSegment = flight?.segments[0];
            if (key === 0 && !outwardJourney) { setOutwardJourney(flight) }
            return (
              <>
                <RowFiveColumnsInfo
                  key={key}
                  col1={
                    <ContainerChecked
                      flight={flight}
                      activedJourney={outwardJourney}
                      setActivedJourney={setOutwardJourney}
                      id={firstSegment?.companyId}
                      name={firstSegment?.companyName}
                    />}
                  col2={<p className='fiveColumnsTextBig'>{firstSegment?.productDateTime?.timeOfDeparture}</p>}
                  col3={<p className='fiveColumnsTextSmall'>
                    <ComponentScales count={flight.segments} />
                  </p>}
                  col4={<p className='fiveColumnsTextBig'>{firstSegment?.productDateTime?.timeOfArrival}</p>}
                  col5={<BagsContainer list={recommendation?.bag[0]} />}
                />
                {isShowScales && <div></div>}
              </>
            )
          })
        }
      </div>
     
      {isRoundTrip &&
        <>
           <RowFiveColumnsTitle
            type={t("fligth.search_fligth.return")}
            origin={nameDestiny}
            destiny={nameOrigin}
            date={extractDate(arraySegs[1])}
          /> 
          <div className='componentChildContainer'>
            {
              recommendationSegsList[1] && recommendationSegsList[1].map((segmentFlightRefItem, key) => {
                const flight = arraySegs[1].find((obj) => obj.num == segmentFlightRefItem?.refNumber)
                const firstSegment = flight?.segments[0];
                if (key === 0 && !returnJourney) setReturnJourney(flight)
                return (<>
                  <RowFiveColumnsInfo
                    key={key}
                    col1={<ContainerChecked flight={flight} activedJourney={returnJourney} setActivedJourney={setReturnJourney} id={firstSegment?.companyId} name={firstSegment?.companyName} />}
                    col2={<p className='fiveColumnsTextBig'>{firstSegment?.productDateTime?.timeOfDeparture}</p>}
                    col3={<p className='fiveColumnsTextSmall'>{flight.segments.length > 1 ? t("fligth.list_flights.scale") : t("fligth.list_flights.direct")}</p>}
                    col4={<p className='fiveColumnsTextBig'>{firstSegment?.productDateTime?.timeOfArrival}</p>}
                    col5={<BagsContainer list={recommendation?.bag[1]} />}
                  />
                  {isShowScales && <div></div>}
                </>
                )
              })
            }
          </div>
        </>
      }
    </div>
  )
}

export default ListFlights;
