import { useRef } from 'react'
import './styles.css'


import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'


const GaleryDoctor = ({ urlImage, images}) => {

    const constraintsRef = useRef(null)

    const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;

    const MotionBox = styled(motion.div)`
    width: 100vw;
  `
  const [t] = useTranslation("global") 


    return (
        <div className='contain-galery-detail-doctor'>
            <h3>{t("specialist.detail.gallery")}</h3>

            <MotionConstraints className='slider_container ' ref={constraintsRef}>
                <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}>
                    {
                        images?.map((item, key) => {
                            return (
                                <motion.div className='div_homeTurism' key={key}>
                                    <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                        <img src={`${urlImage}${item.url}`} alt="" className='galery-doctor-detail'/>                                       
                                    </Grid>
                                </motion.div>
                            )
                        })
                    }

                </MotionBox>
            </MotionConstraints>
        </div>
    )
}

export default GaleryDoctor