import CarouselSevices from '../share/components/CarouselServices/CarouselSevices'
import Find from '../share/components/Find/Find'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from "react-i18next"
import SearchPackages from './SearchPackage/SearchPackages'
import banner from "../../assets/pictures/bannerPackages.png"
import PackageSection from '../home/components/PackagesSection/PackageSection'
import axios from 'axios';
import './assets/styles.css'

import { useState, useEffect } from 'react'
import { get, keys } from '../../localStorage';
import AnyQuestions from '../home/components/anyQuestion';

const Packages = () => {
  const [t] = useTranslation("global")
  const [isShowingFind, setIsShowingFind] = useState(false);
  const [inputValue, setInputValue] = useState('')
  const [selectValue, setSelectValue] = useState('')

  function removeHtmlTags(inputText) {
    // Expresión regular para buscar etiquetas HTML
    const regex = /<[^>]+>/g;
    // Reemplazar las etiquetas HTML con una cadena vacía
    const cleanedText = inputText.replace(regex, '');
    return cleanedText;
  }

  async function isWidgetLS() {
    try {
      const response = await get(keys?.isWidget)
      setIsShowingFind(response?.enable)
    } catch (error) {
      console.log("Error in isWidgetLS(): ", error)
    }
  }

  return (
    <div>
      {!isShowingFind && <Find /> }
      <CarouselSevices current={t("carousel_services.packages")} />
      <header className='header-fligths'>
        <div className='content-buscador-fli content-buscador-packages'>
          <div style={{ display: "none" }}>
            <SearchPackages width={100} />
          </div>
          <div style={{ width: "100%" }}>
            <div className='title-search-package'>
              <h2>Encuentra el Paquete de Viaje Ideal</h2>
            </div>
            <div className='description-search-package'>
              <p>Embárcate en una búsqueda emocionante para descubrir los paquetes de viaje perfectos que harán realidad tus sueños. Desde destinos exóticos hasta escapadas relajantes, nuestra selección cuidadosa te acerca a experiencias inolvidables.</p>
            </div>
            <div>
              <label className='label-filter-package' htmlFor="">Lugar</label>
              <input className='input-filter-packages' type="text" onChange={(event) => setInputValue(event.target.value)} />
            </div>
            <div>
              <label className='label-filter-package' htmlFor="">Tipo de destino</label>
              <select className='select-filter-packages' name="" id="" onChange={(event) => setSelectValue(event.target.value)}>
                <option value="">Todas</option>
                <option value="international">Internacional</option>
                <option value="national">Nacional</option>
                <option value="regional">Regional</option>
              </select>
            </div>
          </div>
        </div>
        <div className='content-banner-t'>
          <img src={banner} alt="" />
        </div>
      </header>

      <PackageSection inputValue={inputValue} selectValue={selectValue} seeker={false}/>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  )
}

export default Packages