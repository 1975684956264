import React, { useState } from 'react'
import "./style.css"
import Slider from "react-slick"
import videoPor from "../../../../assets/svg/videoPor.png"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import ModalContainer, { sliceFrom } from '../../../share/components/modalContainer';
import logoYoutube from "../../../../assets/icons/youtube.png"
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination"
import "swiper/css/navigation"

import { Navigation, Pagination, EffectCoverflow } from "swiper/modules"

const CarouselVideo = () => {

    const images = [
        {
            url: "https://www.youtube.com/watch?v=wXZ-8VrhW38"
        },
        {
            url: "https://www.youtube.com/watch?v=DbLUdJcv0uk"
        },

        {
            url: "https://www.youtube.com/watch?v=eCHfILmfd4U"
        },
        {
            url: "https://www.youtube.com/watch?v=_GI8hr8gYIU"
        },
        {
            url: "https://www.youtube.com/watch?v=bkvRLckKpAg&t=6s"
        }
    ]

    const [videoId, setVideoId] = useState("")
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrowt nextv" onClick={onClick}>
                <FaArrowRight color='#004274' />
            </div>
        );
    };



    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrowt prevt" onClick={onClick}>
                <FaArrowLeft color='#004274' />
            </div>
        );
    };

    const [imageIndex, setImageIndex] = useState(0);




    const getVideoIdFromUrl = (url) => {
        const videoIdMatch = url.match(/v=([a-zA-Z0-9_-]+)/);
        return videoIdMatch ? videoIdMatch[1] : null;
    };

    function getthumbnailUrl(videoUrl) {
        const videoId = getVideoIdFromUrl(videoUrl);
        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
        return { thumbnailUrl, videoId };
    }
    const openModal = (id) => {
        setModalIsOpen(true);
        setVideoId(id)
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setVideoId('')
    };

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next),
    };

    return (
        <div className='body-car'>
            <ModalContainer
                hidden={closeModal}
                show={modalIsOpen}
                sizeHeight={90}
                sizeWidth={90}
                from={sliceFrom.down}
                hiddenButton={false}
            >
                <iframe
                    title={videoId}
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    allowFullScreen={true}
                    className='iframeVideoPqrs'
                ></iframe>
            </ModalContainer>
            {/* <Slider {...settings}>
                {images.map((img, idx) => {
                    const thumbnailUrl = getthumbnailUrl(img.url);
                    return (
                        <div key={idx} onClick={() => {
                            openModal(thumbnailUrl?.videoId)
                        }} className={idx === imageIndex ? "slidev activeSlide" : "slidev"}>
                            <img className="imgVideo" src={thumbnailUrl?.thumbnailUrl} alt={"imagen video"} />
                            <img className='logo-youtube-up' src={logoYoutube} alt="logo youtube" />
                        </div>
                    )

                })}
            </Slider> */}
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={2}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                }}
                modules={[EffectCoverflow,Navigation]}
                className='swiper_container'
            >

                {images.map((img, idx) => {
                    const thumbnailUrl = getthumbnailUrl(img.url);
                    return (
                        <SwiperSlide key={idx}>

                            <img onClick={() => {
                                openModal(thumbnailUrl?.videoId)
                            }} src={thumbnailUrl?.thumbnailUrl} alt={"imagen video"} className='swiper-img'/>
                           <img onClick={() => {
                                openModal(thumbnailUrl?.videoId)
                            }} className='logo-youtube-up-swiper' src={logoYoutube} alt="logo youtube" /> 

                        </SwiperSlide>

                    )

                })}

            </Swiper>
        </div>
    )
}

export default CarouselVideo