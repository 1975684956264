const typeOfCoin = { name: 'Peso colombiano', abbreviation: 'COP', simbol: '$' };

export function currencyFormat(numero) {
  const numeroFormateado = numero?.toLocaleString("en");
  return {
    currency: typeOfCoin.abbreviation,
    value:numeroFormateado,
    total: `${typeOfCoin.abbreviation} ${numeroFormateado}`
  }
}

export function textToLowerCase(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function ellipsisText(text, maxCaracter) {
  let result = text.length > maxCaracter
      ? text.substring(0, maxCaracter) + '...'
      : text;
  return result;
}