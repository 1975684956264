import React, {useState} from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import './styles.css'
import wordSpecialist from './wordSpecialist'
import { useTranslation } from 'react-i18next'
import useBrowseHome from '../../../viewModels/home/browse.home.viewModel'

const ButtonsFilters = ({filterSpecialist, filterCity}) => {

    const [t,i18n] = useTranslation("global")
    const {wordSpeialist} = wordSpecialist()
    const [dropdownSpecialist, setDropdownSpecialist] = useState(false)
    const [dropdownCityFilters, setDropdownCityFilters] = useState(false)
    const [wordCity, setWordCity] = useState('')
    const [wordSpecialistw, setWordSpecialistw] = useState('')

    const {proceduresList} = useBrowseHome()

    return (
        <div className='contain-btn-filters-specialist'>
            <button onClick={()=>setDropdownSpecialist(!dropdownSpecialist)}>
                <SvgIcon name={iconList.IconListMedicaments} size={60} color={'#004274'} />
                <section className='layout-btn-specialist'>
                    <h3>
                        {t("specialist.buttons.specialty")}
                    </h3>
                    <p>{wordSpecialistw === '' ? 'Cirugia Dental, Cirugia Plastica' : wordSpecialistw}</p>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </section>
                {
                    dropdownSpecialist &&
                    <section className='dropdown-Specialist'>
                        {
                            proceduresList.map((item,key)=>{
                                return(
                                    <div className='dropdown-Specialist-list' key={key} onClick={()=>{
                                        setDropdownSpecialist(!dropdownSpecialist)
                                        filterSpecialist(item.name)
                                        setWordSpecialistw(item.name)
                                    }}>
                                        <p>{item?.name}</p>
                                    </div>
                                )
                            })
                        }
                    </section>
                }
            </button>
            <button>
                <SvgIcon name={iconList.IconMap} size={60} color={'#004274'} />
                <section onClick={()=>{setDropdownCityFilters(!dropdownCityFilters)}} className='layout-btn-specialist'>
                    <h3>
                        {t("specialist.buttons.where")}
                    </h3>
                    <p>{wordCity === '' ? 'Cartagena, Barranquilla, San Andres' : wordCity}</p>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </section>
                {
                    dropdownCityFilters &&
                    <section className='dropdown-Specialist-city'>
                        <input type="text" onChange={(e)=>{setWordCity(e.target.value)}} />
                        <button onClick={()=>{
                            filterCity(wordCity)
                            setDropdownCityFilters(!dropdownCityFilters)
                            }}>
                                {t("specialist.buttons.search")}
                        </button>
                    </section>
                }
            </button>
            {/* <button>
                <SvgIcon name={iconList.IconFilters} size={60} color={'#004274'} />
                <section className='layout-btn-specialist'>
                    <h3>
                        Filtros
                    </h3>
                    <p>Personaliza tu busqueda</p>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </section>
            </button> */}
        </div>
    )
}

export default ButtonsFilters