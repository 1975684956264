import Axios from "axios";
import UserContext from "../../../Context/user/userProvider";

import { useState, useEffect, useContext } from "react";
import { iconList } from "../../views/share/components/iconSVG";
import { keys, set as setLocalStorage } from "../../localStorage";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

function useLoginUser() {
  const { setUserDetail, setUserToken } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formRegister, setFormRegister] = useState({
    email: "",
    password: "",
  });

  const regexList = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  };

  const screensList = {
    home: "/",
    register: "/register",
  };

  function getInputsForm(event) {
    setInputError([]);
    const value = event.target.value;
    const key = event.target.id;
    const regex = regexList[key];
    const errorClear = inputError.filter((obj) => obj.key !== key);
    const newError = { key, message: event.target.name };
    if (regex) {
      const validation = regex.test(value);
      const errorExists = inputError.find((obj) => obj.key === key);
      if (!validation) {
        if (!errorExists) {
          if (errorExists && errorExists.length > 0) {
            setInputError([...inputError, newError]);
          } else {
            setInputError([newError]);
          }
        }
      } else if (errorExists) {
        setInputError([...errorClear]);
      }
    }
    if (value === "") {
      setInputError(errorClear);
    }
    setFormRegister({
      ...formRegister,
      [key]: value,
    });
  }

  const inputsList = [
    {
      label: "Intruduce tu Email",
      icono: iconList.IconPencilOutLine,
      type: "text",
      id: "email",
      value: formRegister.email,
      placeHolder: "Agrega tu email",
      require: true,
      func: getInputsForm,
      name: "El email es invalido",
    },
    {
      label: "Intruduce tu contraseña",
      icono: iconList.IconPencilOutLine,
      type: showPassword ? "text" : "password",
      id: "password",
      value: formRegister.password,
      placeHolder: "Intruduce una contraseña",
      require: true,
      func: getInputsForm,
      name: "Contraseña no valida",
    },
  ];

  function getInputError(input) {
    let res = false;
    if (inputError.length > 0) {
      for (const e in inputError) {
        const error = inputError[e];
        if (error.key === input?.id) {
          res = error;
          break;
        }
      }
    }
    return res;
  }

  async function onLogin() {
    if (loading) return;
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/login`
      const data = {
        email: formRegister.email,
        password: formRegister.password,
      };
      const response = await Axios.post(url, data);
      const userData = response?.data;
      const session = await setLocalStorage(keys.authUserSession, userData);
      if (session) {
        setUserDetail(userData?.user);
        setUserToken(userData?.token);
        if (location?.state?.routeMemory) {
          const screen = location?.state?.routeMemory;
          const dataProps = location?.state?.dataMemory;
          dataProps.refresh = true;
          navigate(`${screen}`, { state: dataProps });
        } else {
          console.log("===> home");
          window.location.href = "/";
        }
      }
    } catch (error) {
      if (error && error?.response?.data?.error) {
        const message = error?.response?.data?.error;
        const newError = [
          { key: "email", message: message },
          { key: "password", message: message },
        ];
        setInputError(newError);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const value = inputError.length > 0;
    setBtnDisable(value);
  }, [inputError]);

  useEffect(() => {
    let result = true;
    if (
      inputError.length === 0 &&
      formRegister.email !== "" &&
      formRegister.password.length > 7
    ) {
      result = false;
    }
    setBtnDisable(result);
  }, [formRegister, inputError]);

  return {
    screensList,
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    loading,
    getInputError,
    onLogin,
  };
}

export default useLoginUser;
