import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'


const FilterAsideHotels = ({widthContain, position,setFirstRange, setSecondRange, activeFilterPrice ,setActiveFilterPrice, errorFilter,setResetResult,resetResult}) => {
  const [t] = useTranslation("global")

  return (
    <div className='contain-filter-aside-hotels' style={{width:`${widthContain}`, position:`${position}`}}>
         <section className="container-filter-price">
              <h3>
                {t("hotel.prices")}
              </h3>
              <div className='input-filtrado-price'>
                <div>
                  <label htmlFor="">{t("fligth.search_fligth.from")}</label>
                  <input onChange={(e) => {setFirstRange(e.target.value)  }} type="number" />
                </div>
                <div>
                  <label htmlFor="">{t("fligth.search_fligth.until")}</label>
                  <input onChange={(e) => {setSecondRange(e.target.value)  }} type="number" />
                </div>
                 {
                  errorFilter === '' ?
                    ''
                    :
                    <div className='error-form top-m'>
                      <p>{errorFilter}</p>
                    </div>

                } 
                <div className='aplicar-filtro'>
                  <button onClick={() => {
                    setActiveFilterPrice(!activeFilterPrice) }}>
                      {t("hotel.apply")}
                  </button>
                  <button onClick={() => {
                      setResetResult(!resetResult) }}>
                      {t("hotel.to_reset")}
                 </button>
                </div>
              </div>

            </section>
    </div>
  )
}

export default FilterAsideHotels