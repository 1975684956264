import React, { useEffect, useState } from 'react'
import './Assets/styles.css'
import { CloseCircle } from 'iconsax-react'
import { useTranslation } from 'react-i18next'

const RoomCard = ({ index, deletRoom, addAdult, adult, child, subAdult, data, roomSelected, setRoomSelected }) => {

    const [activeFunctionRemove, setactiveFunctionRemove] = useState(false)
    const [activeFunctionAdd, setactiveFunctionAdd] = useState(false)

    const [t, i18n] = useTranslation("global")

    function changeAge(id, value) {
        const updatedData = roomSelected.map((item, key) => {
            if (key === index) {
                return {
                    ...item,
                    age: item.age.map((item2, key2) => {
                        let newValue = item2;
                        if (key2 === id) {
                            const _newValue = item2 + value
                            if (_newValue >= 1) newValue = _newValue
                        }
                        return (newValue)
                    }
                    )
                };
            }
            return item;
        });
        setRoomSelected(updatedData);
    }

    const addMinors = (key) => {
        setactiveFunctionAdd(!activeFunctionAdd)
        setRoomSelected(roomSelected.map((room, newIndex) => {
            if (newIndex === key) {
                return {
                    ...room,
                    child: room.child + 1,
                }
            } else {
                return room
            }
        }))
    }
    const subMinors = (key) => {
        setactiveFunctionRemove(!activeFunctionRemove)
        setRoomSelected(roomSelected.map((room, newIndex) => {
            if (newIndex === key) {
                if (room.child === 0) {

                    return {
                        ...room,
                        child: 0,
                    }
                }
                return {
                    ...room,
                    child: room.child - 1,
                }
            } else {
                return room
            }
        }))
    }


    const addChildren = () => {

        setRoomSelected(roomSelected.map((room, newIndex) => {

            if (newIndex === index) {
                const array = [...roomSelected]
                const selectArray = array[index]
                selectArray.age.push(1)

                return {
                    ...room,
                    age: selectArray.age
                }
            } else {
                return room
            }
        }))
    }

    const removeChild = () => {
        setRoomSelected(roomSelected.map((room, newIndex) => {
            if (newIndex === index) {
                if (room.child === 0) {
                    return {
                        ...room,
                        age: []
                    }
                }
                const array = [...roomSelected]
                const selectArray = array[index]
                selectArray.age.pop()
                return {
                    ...room,
                    age: selectArray.age
                }
            } else {
                return room
            }
        }))
    }

    useEffect(() => {
        addChildren()
    }, [activeFunctionAdd])

    useEffect(() => {
        removeChild()
    }, [activeFunctionRemove])




    return (
        <div className='roomCard'>
            <div className='roomCard_'>
                <div className='roomCard_roomOne'>
                    {index >= 1 ?
                        <p>{t('hotel.search_engine.room')} {index + 1}</p>
                        :
                        <p>{t('hotel.search_engine.room')} 1</p>
                    }
                    {index >= 1 &&
                        <CloseCircle className='roomCard_roomOneCloseButton' size="30" color="#004274" onClick={() => deletRoom(index)} />
                    }
                </div>
                <div className='roomCard_roomPeople'>
                    <div className='roomCard_roomPerson'>
                        <div className='roomCard_roomPerson_'>
                            <h4>{t('fligth.search_fligth.adults')}</h4>
                            <p>{t('fligth.search_fligth.from')} 18 {t('fligth.search_fligth.years')}</p>
                        </div>
                        <div className='roomCard_roomPersonButtons'>
                            <button onClick={() => subAdult(index)} style={adult === 1 ? { backgroundColor: 'rgba(0, 66, 116, .5)', cursor: 'not-allowed' } : { backgroundColor: '#004274' }}>-</button>
                            <p>{adult}</p>
                            <button onClick={() => addAdult(index)}>+</button>
                        </div>
                    </div>
                    <div className='roomCard_roomPerson'>
                        <div className='roomCard_roomPerson_'>
                            <h4>{t('fligth.search_fligth.childrens')}</h4>
                            <p>{t('fligth.search_fligth.until')} 17 {t('fligth.search_fligth.years')}</p>
                        </div>
                        <div className='roomCard_roomPersonButtons'>
                            <button onClick={() => subMinors(index)} style={child === 0 ? { backgroundColor: 'rgba(0, 66, 116, .5)', cursor: 'not-allowed' } : { backgroundColor: '#004274' }}>-</button>
                            <p>{child}</p>
                            <button onClick={() => addMinors(index)}>+</button>
                        </div>
                    </div>
                    {
                        child >= 1 &&
                        <div className='contain-age-childrens'>
                            <h3>{t('hotel.search_engine.age')}</h3>
                            <div className='contain-all-ages-children'>
                                {

                                    data.age.map((item, key) => {

                                        return (
                                            <div key={key}>
                                                <h4>
                                                {t('hotel.search_engine.child')} {key + 1}
                                                </h4>
                                                <div className='contain-buttons-age-children'>
                                                    <button onClick={() => {
                                                        changeAge(key, -1)
                                                    }}>-</button>
                                                    <span>{item}</span>
                                                    <button onClick={() => {
                                                        changeAge(key, +1)
                                                    }}>+</button>
                                                </div>
                                            </div>
                                        )
                                    })

                                }
                            </div>

                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default RoomCard