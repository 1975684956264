import React from 'react';
import './styles.css';
import SvgIcon, { iconList } from '../../../share/components/iconSVG';
import {useTranslation} from "react-i18next"

function AnyQuestions({ title }) {

  const [t,i18n] = useTranslation("global")

  return (
    <section className='anyQuestionsContainer'>
      <div className='anyQuestions'>
        <p className='anyQuestionsTitle'>{title}</p>

        <div className='anyQuestionsOptionsList'>
          <div className='anyQuestionsOption'>
            <div className='anyQuestionsOptionIcon'>
              <SvgIcon name={iconList.IconPinMap} color={'#17AEE6'} size={40} />
            </div>
            <p className='anyQuestionsOptionText'>{t('browse_home.any_question.attention_points')}</p>
          </div>
          <div className='anyQuestionsOption'>
            <div className='anyQuestionsOptionIcon'>
              <SvgIcon name={iconList.IconChat} color={'#17AEE6'} size={40} />
            </div>
            <p className='anyQuestionsOptionText'>{t('browse_home.any_question.online_chat')}</p>
          </div>
          <div className='anyQuestionsOption'>
            <div className='anyQuestionsOptionIcon'>
              <SvgIcon name={iconList.IconPhoneRing} color={'#17AEE6'} size={40} />
            </div>
            <p className='anyQuestionsOptionText'>{t('browse_home.any_question.call_us')}</p>
          </div>
          <div className='anyQuestionsOption'>
            <div className='anyQuestionsOptionIcon'>
              <SvgIcon name={iconList.IconCall} color={'#17AEE6'} size={40} />
            </div>
            <p className='anyQuestionsOptionText'>{t('browse_home.any_question.we_call')}</p>
          </div>
          <div className='anyQuestionsOption'>
            <div className='anyQuestionsOptionIcon'>
              <SvgIcon name={iconList.IconMegaPhone} color={'#17AEE6'} size={40} />
            </div>
            <p className='anyQuestionsOptionText'>{t('browse_home.any_question.contact_us')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AnyQuestions;
