import { useState, useEffect, useContext } from 'react';
import { doctorURL, videoSdkURL } from '../../../infra/urls';
import { videoSDK_api, wellezy_api } from '../../../infra/api';
import { authToken } from '../../../infra/constants/tokens.video.sdk';
import UserContext from '../../../Context/user/userProvider';

function useFormCreateAppointment(data, close) {
  const emptySate = {
    created_at: "",
    date: "",
    description: "",
    email: "",
    hour: "",
    id: null,
    identification: "",
    meet_id: null,
    name: "",
    procedure: "",
    status: "",
    tel: "",
    updated_at: "",
    user_id: 1
  }

  const { userDetail } = useContext(UserContext);
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isEnableBtn, setIsEnableBtn] = useState(false)
  const [formData, setFormData] = useState({ ...emptySate })
  const [isSuccessful, setIsSuccessful] = useState(false);

  function getFormData(event) {
    const value = event.target.value;
    const key = event.target.id;
    setFormData({
      ...formData,
      [key]: value
    })
  }

  async function getIdMeet() {
    try {
      const response = await videoSDK_api.post(videoSdkURL.post.rooms, {},
        {
          headers: {
            authorization: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      return response?.data?.roomId;
    } catch (error) {
      console.log("Error in getIdMeet(): ", error)
    }
  }

  async function save() {
    if (!isEnableBtn || isSaving) return;
    try {
      setIsSaving(true);
      const roomId = await getIdMeet();
      if (roomId) {
        formData.meet_id = roomId;
        formData.user_id = userDetail?.id;
        formData.appointment_id = formData?.id;
        formData.status = 'assing';
        delete formData.created_at;
        delete formData.updated_at;
        delete formData.id;
        delete formData.procedure;
        delete formData.office_id;
        delete formData.procedure;
        delete formData.user_id;
        const data = formData;

        const response = await wellezy_api.put(doctorURL.put.appointmentsUpdate, data)
        if (response) {
          setIsSuccessful(true)
        }
      }
    } catch (error) {
      console.log("Error in save(): ", error)
      closeAll()
    }
    finally {
      setIsSaving(false);

    }
  }

  function closeAll() {
    setIsSuccessful(false);
    close(false);
  }

  useEffect(() => {
    if (isSuccessful) {

      setTimeout(() => {
        closeAll()
      }, 5000);
    }
  }, [isSuccessful])


  useEffect(() => {
    const isCompleted = (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.tel !== "" &&
      formData.description !== "" &&
      formData.identification !== "" &&
      formData.procedure !== "" &&
      formData.date !== "" &&
      formData.hour !== ""
    );
    setIsEnableBtn(isCompleted);
  }, [formData])


  useEffect(() => {
    if (data) {
      if (data.id !== formData.id) {
        setIsEditing(false)
        setFormData({ ...data })
      }
    } else {
      setFormData({ ...emptySate })
      setIsEditing(true)
    }
  }, [data])






  return {
    formData,
    getFormData,
    save,
    isSaving,
    isEditing,
    isEnableBtn,
    isSuccessful,
  }
}

export default useFormCreateAppointment;