import { ArrowDown2 } from 'iconsax-react'
import React, { useEffect } from 'react'
import Bichectomia from './Assets/Img/Bichectomia.png'
import './Assets/styles.css'
import { Link as LinkScroll } from 'react-scroll'

const ProcedureCatergoryDetailsHeader = ({data}) => {
    
  return (
    <div className='procedureCatergoryDetailsHeader'>
        <div className='procedureCatergoryDetailsHeader_img'>
            <div className='procedureCatergoryDetailsHeader-img_background'/>
            <img src={`https://pdtclientsolutions.com/crm-public/img/category/picture/${data.img}`} alt={data.name} />    
        </div>
        <div className='procedureCatergoryDetailsHeader_text'>
            <div className='procedureCatergoryDetailsHeader-text_title'>
                <h1>{data.name}</h1>
                {/* <p>cirugía de megillas</p> */}
            </div>
            <br />
            <br />
            {/* <div className='procedureCatergoryDetailsHeader-text_description'>
                <p>Reduce el volumen de las mejillas, acentúa los pómulos y el contorno mandibular mejorando el aspecto de tu rostro.</p>
            </div> */}
            <div className='procedureCatergoryDetailsHeader-text_button'>
                <LinkScroll to='scrollOne' spy={true} smooth={true} offset={-100} duration={500}>ver más <span><ArrowDown2 size="32" color="#004274"/></span></LinkScroll>
            </div>
        </div>
    </div>
  )
}

export default ProcedureCatergoryDetailsHeader