import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'


const MakeAppointment = ({ address, city }) => {
  const [t] = useTranslation("global")
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
    
      const currentDate = new Date();
      const currentDay = currentDate.getDate();
      const [monthNumber, setMonthNumber] = useState(currentDate.getMonth());
      const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
      const [dateElements, setDateElements] = useState([]);
    
      const writeMonth = (monthNum, yearNum) => {
        const elements = [];
    
        const startDay = () => {
          const start = new Date(yearNum, monthNum, 1);
          return start.getDay() === 0 ? 6 : start.getDay() - 1;
        };
    
        const getTotalDays = (month) => {
          if (month === -1) month = 11;
    
          if (
            month === 0 ||
            month === 2 ||
            month === 4 ||
            month === 6 ||
            month === 7 ||
            month === 9 ||
            month === 11
          ) {
            return 31;
          } else if (month === 3 || month === 5 || month === 8 || month === 10) {
            return 30;
          } else {
            return isLeap(yearNum) ? 29 : 28;
          }
        };
    
        const isLeap = (year) => {
          return (year % 100 !== 0 && year % 4 === 0) || year % 400 === 0;
        };
    
        for (let i = startDay(); i > 0; i--) {
          elements.push(
            <div key={`prev-${i}`} className="calendar__date calendar__item calendar__last-days">
              {getTotalDays(monthNum - 1) - (i - 1)}
            </div>
          );
        }
    
        for (let i = 1; i <= getTotalDays(monthNum); i++) {
          const key = `${monthNum}-${i}`;
          if (i === currentDay) {
            elements.push(
              <div key={key} className="calendar__date calendar__item calendar__today">
                {i}
              </div>
            );
          } else {
            elements.push(
              <div key={key} className="calendar__date calendar__item">
                {i}
              </div>
            );
          }
        }
    
        setDateElements(elements);
      };
    
      const lastMonth = () => {
        if (monthNumber !== 0) {
          setMonthNumber(monthNumber - 1);
        } else {
          setMonthNumber(11);
          setCurrentYear(currentYear - 1);
        }
      };
    
      const nextMonth = () => {
        if (monthNumber !== 11) {
          setMonthNumber(monthNumber + 1);
        } else {
          setMonthNumber(0);
          setCurrentYear(currentYear + 1);
        }
      };
    
      useEffect(() => {
        writeMonth(monthNumber, currentYear);
      }, [monthNumber, currentYear]);

    

    return (
        <div className='contain-make-appointment'>
            <h3 >{t("specialist.detail.make_appointment")}</h3>
            <p>{t("specialist.detail.text_request")}</p>

            <div className='contain-select-clinic'>
                <p>
                    {t("specialist.detail.select_clinic")}:
                </p>
                <div className='dropdown-select-clinic'>
                    <span>
                        {/* {address?.length === 0 ? '' : address[0]?.name} */}
                        {address}
                    </span>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </div>
                <button>
                    {t("specialist.detail.see_more_date")}
                </button>
            </div>
            <div className="calendar-make-appointment">
                <div className="calendar__info">
                    <div className="calendar__prev" id="prev-month" onClick={lastMonth}>&#9664;</div>
                    <div className="calendar__month" id='month'>{monthNames[monthNumber]}</div>
                    <div className="calendar__year"  id="year">{currentYear.toString()}</div>
                    <div className="calendar__next" id="next-month" onClick={nextMonth}>&#9654;</div>
                </div>

                <div className="calendar__week">
                    <div className="calendar__day calendar__item">Mon</div>
                    <div className="calendar__day calendar__item">Tue</div>
                    <div className="calendar__day calendar__item">Wed</div>
                    <div className="calendar__day calendar__item">Thu</div>
                    <div className="calendar__day calendar__item">Fri</div>
                    <div className="calendar__day calendar__item">Sat</div>
                    <div className="calendar__day calendar__item">Sun</div>
                </div>

                <div className="calendar__dates" id="dates">
                    {dateElements}
                </div>
            </div>
        </div>
    )
}

export default MakeAppointment