import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const UseClinics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const condition = location.pathname;
  let counter = 0;
  const [loading, setLoading] = useState(false);
  const [dataClinics, setDataClinics] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const [services, setServices] = useState([]);
  const itemsForPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);

  function findClinics() {
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/clinics`
    if (condition === "/Clinics" && counter === 0) {
      setLoading(true);
      axios
        .get(url, {
          params: {
            search: "",
          },
        })
        .then((res) => {
          counter += 1;
          setLoading(false);
          setServices(res.data.cinics);
          console.log(res.data.cinics);
          setUrlImage(res.data.url);
          setDataClinics([...res.data.cinics].splice(0, itemsForPage));
        })
        .catch((error) => {
          counter += 1;
          setLoading(false);
          console.log(error);
        });
    }
  }

  useEffect(() => {
    findClinics();
  }, []);

  const nextHandler = () => {
    const totalElementos = services.length;

    const nextPage = currentPage + 1;

    const firtsIndex = nextPage * itemsForPage;
    if (firtsIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setDataClinics([...services].splice(firtsIndex, itemsForPage));
  };

  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firtsIndex = prevPage * itemsForPage;

    setDataClinics([...services].splice(firtsIndex, itemsForPage));
    setCurrentPage(prevPage);
  };

  const personalizeFind = (keyWord) => {
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/clinics`
    setLoading(true);
    axios
      .get(url, {
        params: {
          search: keyWord,
        },
      })
      .then((res) => {
        counter += 1;
        setLoading(false);
        setServices(res.data.cinics);
        setUrlImage(res.data.url);
        setDataClinics([...res.data.cinics].splice(0, itemsForPage));
      })
      .catch((error) => {
        counter += 1;
        setLoading(false);
        console.log(error);
      });
  };

  const cityFind = (keyWord) => {
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/clinics`
    setLoading(true);
    axios
      .get(url, {
        params: {
          search: keyWord,
        },
      })
      .then((res) => {
        counter += 1;
        setLoading(false);
        setServices(res.data.cinics);
        setUrlImage(res.data.url);
        setDataClinics([...res.data.cinics].splice(0, itemsForPage));
      })
      .catch((error) => {
        counter += 1;
        setLoading(false);
        console.log(error);
      });
  };

  const filterClinics = (word) => {
    if (word === "All") {
      setDataClinics([...services].splice(0, itemsForPage));
    } else {
      const keyWord = word.toLowerCase();
      const newData = [];

      for (let index = 0; index < services.length; index++) {
        for (
          let index2 = 0;
          index2 < services[index].subcategories.length;
          index2++
        ) {
          let toLowerCaseW =
            services[index].subcategories[index2].name.toLowerCase();
          if (toLowerCaseW.includes(keyWord)) {
            newData.push(services[index]);
          }
        }
      }

      console.log(newData);

      setDataClinics(newData);
    }
  };

  function generateUniqueId() {
    return "xxxxx-xxxx-4xxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const detailClinics = (clinic) => {
    navigate(`/Clinics/${generateUniqueId()}`, {
      state: {
        name: clinic.name,
        address: clinic.address,
        id: clinic.id,
        urlImage: urlImage,
        pathImage: clinic.image,
        subCategories: clinic.subcategories,
        stars: clinic.stars,
        description: clinic.description,
        city: clinic.city,
      },
    });
  };

  return {
    dataClinics,
    urlImage,
    currentPage,
    nextHandler,
    prevHandler,
    mostrarNext,
    services,
    loading,
    personalizeFind,
    cityFind,
    filterClinics,
    detailClinics,
  };
};

export default UseClinics;
