import React from "react"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './Loader.css'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};



const Favorites = ()=>{
  

    return (
      <Carousel responsive={responsive}>
      <div className="carousel-item">
        <h1>Elemento 1</h1>
      </div>
      <div className="carousel-item">
        <h1>Elemento 2</h1>
      </div>
      <div className="carousel-item">
        <h1>Elemento 3</h1>
      </div>
      <div className="carousel-item">
        <h1>Elemento 4</h1>
      </div>
      <div className="carousel-item">
        <h1>Elemento 5</h1>
      </div>
      <div className="carousel-item">
        <h1>Elemento 6</h1>
      </div>
    </Carousel>
    );
  };



export default Favorites

