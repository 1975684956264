import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import VivaLogo from '../../FlightDetailFilter/Assets/Img/VivaLogo.png';
import LatamLogo from '../../FlightDetailFilter/Assets/Img/LatamLogo.png';
import AviancaLogo from '../../FlightDetailFilter/Assets/Img/AviancaLogo.png';


function useFlightDetail() {
  const location = useLocation();

  const nameOrigin = location.state.buttonOriginFlight
  const nameDestiny = location.state.buttonDestinityFlight
  const nameOriginSlice = nameOrigin.slice(1, -5);
  const nameDestinySlice = nameDestiny.slice(1, -5);
  // const citiesDataState = location.state.citiesDataState
  const passengersSelected = location.state.passengersSelecteds
  const dateGoingSelectedApi = location.state.dateGoingSelectedApi
  const dateSreturnSelectedApi = location.state.dateSreturnSelectedApi
  const codeIataDestiny = location.state.codeiataDestinity
  const codeIataOrigin = location.state.codeIataOrigin
  const oneWay = location.state.oneWay
  const roundTrip = location.state.roundTrip
  const passengersSelectedsAdult = Number(location.state.passengersSelecteds.adult)
  const passengersSelectedsBoy = Number(location.state.passengersSelecteds.boy)
  const passengersSelectedsBaby = Number(location.state.passengersSelecteds.baby)
  const passengersSelecteds = Number(passengersSelectedsAdult + passengersSelectedsBoy)

  const [flights, setFlights] = useState([])
  const [flightsSelect, setFlightsSelect] = useState([])
  const [value, setValue] = useState('1')

  const airlines = [
    {
      id: 1,
      img: VivaLogo,
      name: 'viva air',
      priceDirect: 1050,
      priceOneScale: 950,
      priceMoreScale: null,
    },
    {
      id: 2,
      img: LatamLogo,
      name: 'latam airlines',
      priceDirect: 1100,
      priceOneScale: 1080,
      priceMoreScale: null,
    },
    {
      id: 3,
      img: AviancaLogo,
      name: 'avianca',
      priceDirect: 1120,
      priceOneScale: 1100,
      priceMoreScale: 1050,
    },
  ]

  const apiOneWay = {
    "searchs": 50,
    "class": false,
    "qtyPassengers": passengersSelecteds,
    "adult": passengersSelectedsAdult,
    "child": passengersSelectedsBoy,
    "baby": passengersSelectedsBaby,
    "itinerary":
      [
        {
          "departureCity": `${codeIataOrigin}`,
          "arrivalCity": `${codeIataDestiny}`,

          "hour": `${dateGoingSelectedApi}`
        }
      ]
  }




  
  const apiRoundTrip = {
    "searchs": 50,
    "class": false,
    "qtyPassengers": passengersSelecteds,
    "adult": passengersSelectedsAdult,
    "child": passengersSelectedsBoy,
    "baby": passengersSelectedsBaby,
    "itinerary":
      [
        {
          "departureCity": `${codeIataOrigin}`,
          "arrivalCity": `${codeIataDestiny}`,

          "hour": `${dateGoingSelectedApi}`
        },
        {
          "departureCity": `${codeIataDestiny}`,
          "arrivalCity": `${codeIataOrigin}`,

          "hour": `${dateSreturnSelectedApi}`
        }
      ]
  }


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    //setValue(newValue);
  };


  // CONNECT API
  useEffect(() => {
    const getFlights = async () => {
      const url = process.env.REACT_APP_URL_API_FLY;
      const apiLoad = await roundTrip === false && oneWay === true ? apiOneWay : apiRoundTrip
      const result = await axios.post(url, apiLoad
      )
      /* 
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });  */
      setFlights(result.data.data)
      setFlightsSelect(result.data.data)
      console.log(result.data)
    }
    getFlights()
  }, []);

  return {
    airlines,
    flightsSelect,
    handleChange,
    value,
    flights,
    oneWay,
    roundTrip,
    passengersSelected,
    nameOriginSlice,
    nameDestinySlice,
  }

}

export default useFlightDetail;