import React from 'react'
import { useState ,useEffect} from 'react'
import CloseModal from './assets/img/cerrar.svg'
import Mensaje from './Mensaje'


const Modal = ({setModal, modal, animarModal, setAnimarModal, gastoEditar}) => {


    const [nombre, setNombre] = useState('')
    const [correo, setCoreo] = useState("")
    const [telefono, setTelefono] = useState('')
    const [sexo, setSexo] = useState("")
    const [pais, setPais] = useState("")
    const [mensajeError, setMensajeError] = useState('')

    useEffect(() => {
        if(Object.keys(gastoEditar).length > 0){
            setNombre(gastoEditar.nombre)
            setCoreo(gastoEditar.correo)
            setTelefono(gastoEditar.telefono)
           }
    }, [])
    
    const guardarGasto = (nombre,correo,telefono,sexo,pais)=> {
        console.log(nombre + ", " + correo + ", " + telefono + ", " + sexo + ", " + pais)
        handleModal()
    }
    const handleModal= ()=>{
        setAnimarModal(false)

        setTimeout(()=>{
            modal ? setModal(false) : setModal(true)
        },500)
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if([ nombre, correo, telefono, sexo, pais ].includes('')){
            setMensajeError('Todos los Campos Son Obligatorios')
            
            setTimeout(()=>{
                setMensajeError('')
            },3000)
            return;
        }

        guardarGasto({nombre,correo,telefono,sexo,pais})
    }

return (
    <div className='modal-new'>
        <div className='cerrar-modal'>
            <img 
                src={CloseModal} 
                alt="close modal"
                onClick={handleModal}
            />
        </div>
        <form onSubmit={handleSubmit} className={`formulario ${animarModal ? "animar" : 'cerrar'}`}>
            <legend>Contacto</legend>
            {mensajeError && <Mensaje tipo="error">{mensajeError}</Mensaje>}
            <div className='campo'>
                <input 
                    id='nombre'
                    type="text" 
                    placeholder='Nombre'
                    value={nombre}
                    onChange={e => {setNombre(e.target.value)}}
                />
            </div>
            <div className='campo'>
                <input 
                    id='correo'
                    type="text" 
                    placeholder='Correo'
                    value={correo}
                    onChange={e => {setCoreo(e.target.value)}}
                />
            </div>
            <div className='campo'>
                <input 
                    id='telefono'
                    type="text" 
                    placeholder='Telefono'
                    value={telefono}
                    onChange={e => {setTelefono(e.target.value)}}
                />
            </div>
            <div className='campo'>
                <select  
                    id="categoria"
                    value={sexo}
                    onChange={e => {setSexo(e.target.value)}}
                >
                    <option value="">-- Sexo --</option>
                    <option value="Mujer">Mujer</option>
                    <option value="hombre">Hombre</option>
                    
                </select>
            </div>
            <div className='campo'>
                <input 
                    id='pais'
                    type="text" 
                    placeholder='Pais'
                    value={pais}
                    onChange={e => {setPais(e.target.value)}}
                />
            </div>
            <input 
                type="submit" value="Enviar" />
        </form>
    </div>
  )
}

export default Modal