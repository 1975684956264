import {useEffect} from 'react'
import './styles.css';
import SvgIcon, { iconList } from "../iconSVG";

export const sliceFrom ={
  up :  {show:'modalContainerShowUp', hidden:'modalContainerHiddenUp' },
  down : {show:'modalContainerShowDown', hidden:'modalContainerHiddenDown' },
  left : {show:'modalContainerShowLeft', hidden:'modalContainerHiddenLeft' },
  right : {show:'modalContainerShowRight', hidden:'modalContainerHiddenRight' },
}

function ModalContainer({
  show,
  hidden,
  sizeWidth=80,
  sizeHeight=60,
  children,
  hiddenButton = false,
  from = sliceFrom.right
}) {

  useEffect(() => {
    const body = document.querySelector('body');
    show ? body.style.overflow = 'hidden' : body.style.overflow = '';
  }, [show]);

  return (
    <div className={`modalContainer ${show ? from?.show : from?.hidden}`}>
      {!hiddenButton &&
        <div className='modalContainerBtn'
          onClick={() => hidden(!show)}
        >
          <SvgIcon color={'#FFF'} name={iconList.IconCloseCircleOutLine} size={30} />
        </div>
      }
      <div
        className='cardContainer'
        style={{
          width: `${sizeWidth}%`,
          height: `${sizeHeight}%`,
        }}>
        {children}
      </div>
    </div>
  )
}

export default ModalContainer;
