

const wordSpecialist = () => {


    const wordSpeialist = [
        {
            name: 'All'
        },
        {
            name: 'Abdominoplastia'
        },
        {
            name: 'Depilacion'
        },
        {
            name: 'Lipomarcacion'
        },
        {
            name: 'Vaginoplastia'
        },
        {
            name: 'Bichectomia'
        },
        {
            name: 'Frontoplastia'
        },
        {
            name: 'Lipolisis'
        },
        {
            name: 'Cruroplastia'
        },
        {
            name: 'Pexia'
        },
        {
            name: 'Reducción Mamaria'
        },
        {
            name: 'Lifting'
        },
        {
            name: 'Implante'
        },
        {
            name: 'Blefaroplastia'
        },
        {
            name: 'Ginecomastia'
        },
        {
            name: 'Liposuccion'
        },
        {
            name: 'Braqueoplastia'
        },
        {
            name: 'Rinoplastia'
        },
        {
            name: 'LabioPlastia'
        },
        {
            name: 'Retiro'
        },
    ]



  return {
    wordSpeialist
  }
}

export default wordSpecialist