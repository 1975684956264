import './styles.css';
import SvgIcon, { iconList } from '../../share/components/iconSVG';
import { Record, RecordCircle } from 'iconsax-react'
import useSearchFlights from "../../../viewModels/flights/search.flights.viewModel";
import DesinitySearcher from '../components/desinitySearcher';
import FlightPassengerManagement from '../components/ flightPassengerManagement';
import FlightsDateGoing from '../../../../Components/FlightsDateGoing/FlightsDateGoing';
import { useTranslation } from "react-i18next"
import { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom"
import ModalContainer from '../../share/components/modalContainer';



function SearchFlights({ accompanied = true, isIframe = false, cardRefPlace,
  cardRefPassenger,
  cardRefDate,
  isRoundTrip,
  setIsRoundTrip,
  buttonDisable,
  searcherFlight,

  //ciudades
  //ida
  isCardRefOrigin,
  setIsCardRefOrigin,
  selectedOrigin,
  getSelectedOrigin,

  //retorno
  isCardRefDestination,
  setIsCardRefDestination,
  selectedDestination,
  getSelectedDestination,
  //fechas
  dateGoingSelected,
  setDateGoingSelected,
  dateReturnSelected,
  setDateReturnSelected,
  isCardRefDate,
  setIsCardRefDate,
  setDateGoingSelectedApi,
  setDateReturnSelectedApi,

  //pasajeros
  isManagingPassengers,
  setIsManagingPassengers,
  passengersSelecteds,
  getPassengersSelecteds,
  ellipsisText, }) {

  const [t, i18n] = useTranslation("global")
  const { pathname } = useLocation()
  const [checkDate, setCheckDate] = useState(false)
  const [inputFocused, setInputFocused] = useState(true)
  const [inputFocusedD, setInputFocusedD] = useState(true)
  const containerRefDes = useRef(null)
  const containerRefOri = useRef(null)
  const [dateOneWay, setDateOneWay] = useState(localStorage.getItem("dateOne") ? new Date(localStorage.getItem("dateOne")) : new Date())
  const [dateReturn, setDateReturn] = useState(localStorage.getItem("dateReturn") ? new Date(localStorage.getItem("dateReturn")) : new Date())

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRefDes.current && !containerRefDes.current.contains(event.target)) {
        setInputFocusedD(false)
      }else if(!containerRefDes.current || containerRefDes.current.contains(event.target)){
        setInputFocusedD(true)
      }
    }


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    //Recuperacion de la fecha

    setDateOneWay(localStorage.getItem("dateOne") === null ? new Date() : new Date(localStorage.getItem("dateOne")))
    setDateGoingSelected(localStorage.getItem("dateOne") === null ? "" : new Date(localStorage.getItem("dateOne")).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }))
    setDateGoingSelectedApi(localStorage.getItem("dateOne") === null ? "" : new Date(localStorage.getItem("dateOne")).toISOString('en-us', { year: "numeric", month: "short", day: "numeric" }))

    setDateReturn(localStorage.getItem("dateReturn") === null ? new Date() : new Date(localStorage.getItem("dateReturn")))
    setDateReturnSelected(localStorage.getItem("dateReturn") === null ? "" : new Date(localStorage.getItem("dateReturn")).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }))
    setDateReturnSelectedApi(localStorage.getItem("dateReturn") === null ? "" : new Date(localStorage.getItem("dateReturn")).toISOString('en-us', { year: "numeric", month: "short", day: "numeric" }))


    function handleClickOutside(event) {
      if (containerRefOri.current && !containerRefOri.current.contains(event.target)) {
        setInputFocused(false)
      }else if(!containerRefOri.current || containerRefOri.current.contains(event.target)){
        setInputFocused(true)
      }
    }


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const desinitySearcherTypes = {
    origin: { text: t("fligth.search_fligth.origin"), errorText: 'Cambia tu origen', placeholder: "Ingresa desde donde estas" },
    destination: { text: t("fligth.search_fligth.destination"), errorText: 'Cambia tu destino', placeholder: "Ingresa hacia donde vas" }
  }


  const [background, setBackground] = useState(0)

  const selectTypeResercation = (type) => {
    type === 1 ? setBackground(type) : setBackground(type)
  }


  return (
    accompanied === true ?
      <div className='searchFlightsContainer' style={{ boxShadow: `${pathname === "/packages" ? "none" : ""}` }}>
        <div className='searchFlightsContainerHeader'>
          <div className='searchFlightsRow' >
            <div className='searchFlightsContainerHeaderOption'>
              <SvgIcon name={iconList.IconPlane} color={'#004274'} size={30} />
              <p className='searchFlightsContainerHeaderOptionTextBig'>{t("fligth.search_fligth.book_fligth")}</p>
            </div>
            {/* <div className='searchFlightsContainerHeaderOption'>
            <SvgIcon name={iconList.IconHotel} color={'#004274'} size={30} />
            <p className='searchFlightsContainerHeaderOptionTextBig'>Hotel</p>
           </div>
           <div className='searchFlightsContainerHeaderOption'>
            <SvgIcon name={iconList.IconTravel} color={'#004274'} size={30} />
            <p className='searchFlightsContainerHeaderOptionTextBig'>Paquetes</p>
           </div> */}
          </div>
          <div className={`${accompanied ? 'searchFlightsColumn' : 'searchFlightsRow'}`} id={accompanied ? "" : "column-round-trip"}>
            <div className='searchFlightsContainerHeaderOption' onClick={() => {
              setIsRoundTrip(false)
              setCheckDate(false)
            }}>
              {isRoundTrip
                ? <Record size="15" color="#104172" />
                : <RecordCircle size="15" color="#104172" variant="Bold" />
              }
              <p className='searchFlightsContainerHeaderOptionTextSmall'>{t("fligth.search_fligth.one_way")}</p>
            </div>
            <div className='searchFlightsContainerHeaderOption' onClick={() => {
              setIsRoundTrip(true)
              setCheckDate(true)
            }}>
              {isRoundTrip
                ? <RecordCircle size="15" color="#104172" variant="Bold" />
                : <Record size="15" color="#104172" />
              }
              <p className='searchFlightsContainerHeaderOptionTextSmall'>{t("fligth.search_fligth.round_trip")}</p>
            </div>
          </div>
        </div>
        {/* <div className='reserva-p' style={{ margin: "1rem 0" }}>
        <div className={background === 1 ? 'select' : 'unselect'} onClick={() => selectTypeResercation(1)}>
          <p><SvgIcon color={background === 0 ? '#004274' : '#ffffff'} name={iconList.IconPlane} size={30} />{t("packages.packages_medics")} + <SvgIcon color={background === 0 ? '#004274' : '#ffffff'} name={iconList.IconHotel} size={30} /> {t("packages.hotel")}</p>
        </div>
       </div> */}
        <div className={`${accompanied ? '' : 'display-button-search'}`}>
          <div className={`${accompanied ? 'searchFlightsContainerBodyVertical' : 'searchFlightsContainerBody'}`}>
            <div ref={containerRefOri} className='input-destiny-origin-fligth'>
              <div className='content-inputs'>
                <div className='first-section' >
                  <p className='label-destiny'>{t("fligth.search_fligth.origin")}</p>
                  {
                    selectedOrigin == "o" ?
                      <DesinitySearcher type={desinitySearcherTypes.origin} change={getSelectedOrigin} inputFocused={inputFocused} />
                      :
                      (
                        selectedOrigin === selectedDestination
                          ? <p onClick={() => { getSelectedOrigin("o") }} className='value-origin-destiny' style={{ color: 'red' }}>{ellipsisText('Cambia tu origen', 17)}</p>
                          : <p onClick={() => { getSelectedOrigin("o") }} className='value-origin-destiny'>{selectedOrigin}</p>
                      )

                  }


                  {/* {selectedOrigin === selectedDestination
                      ? <p style={{ color: 'red' }}>{ellipsisText('Cambia tu origen', 17)}</p>
                      : <p className='select-destiny-input'>{ellipsisText(selectedOrigin === "o" ? desinitySearcherTypes.origin.placeholder : selectedOrigin, 17)}</p>
                    } */}
                </div>
                <SvgIcon name={iconList.IconPinMap} color={'#004274'} size={30} />
              </div>
              {/* {isCardRefOrigin &&
                  <div
                    ref={cardRefPlace}
                  >
                    <DesinitySearcher type={desinitySearcherTypes.origin} change={getSelectedOrigin} isCardRef={isCardRefOrigin} />
                  </div>} */}
            </div>

            <div ref={containerRefDes} className='input-destiny-origin-fligth'>
              <div className='content-inputs'>
                <div className='first-section' >
                  <p className='label-destiny'>{t("fligth.search_fligth.destination")}</p>
                  {
                    selectedDestination == "d" ?
                      <DesinitySearcher type={desinitySearcherTypes.destination} change={getSelectedDestination} inputFocused={inputFocusedD} />
                      :
                      (
                        selectedOrigin === selectedDestination
                          ? <p onClick={() => { getSelectedDestination("d") }} className='value-origin-destiny' style={{ color: 'red' }}>{ellipsisText('Cambia tu origen', 17)}</p>
                          : <p onClick={() => { getSelectedDestination("d") }} className='value-origin-destiny'>{selectedDestination}</p>
                      )

                  }
                </div>
                <SvgIcon name={iconList.IconPinMap} color={'#004274'} size={30} />
              </div>
            </div>

            <div className={`searchFlightsRow ${accompanied ? 'searchFlightsContainerRowAlfa' : 'searchFlightsContainerRowBeta'}`}>
              <div className='searchFlightsContainerBodyBorder' id='width_C' onClick={() => {
                setCheckDate(false)
                setIsCardRefDate(true)

              }}>
                <div className='searchFlightsContainerBodyOptionTexts'>
                  <p className='searchFlightsContainerBodyOptionTextSmall'>{t("fligth.search_fligth.date")}</p>
                  <p className='searchFlightsContainerBodyOptionTextBig'>{dateGoingSelected === "" ? t("fligth.search_fligth.going") : dateGoingSelected}</p>
                </div>
                <SvgIcon name={iconList.IconCalendar} color={'#004274'} size={30} />
              </div>

              <div
                className='searchFlightsContainerBodyBorder'
                id='width_C'
                onClick={() => {
                  if (isRoundTrip) {
                    setCheckDate(true);
                    setIsCardRefDate(true);
                  }
                }}
                style={{ opacity: !isRoundTrip ? 0.25 : 1 }}
              >
                <div className='searchFlightsContainerBodyOptionTexts'>
                  <p className='searchFlightsContainerBodyOptionTextSmall'>{t("fligth.search_fligth.date")}</p>
                  <p className='searchFlightsContainerBodyOptionTextBig'>{dateReturnSelected === "" ? t("fligth.search_fligth.return") : dateReturnSelected}</p>
                </div>
                <SvgIcon name={iconList.IconCalendar} color={'#004274'} size={30} />
              </div>

              {isCardRefDate &&
                <ModalContainer
                  show={isCardRefDate}
                  hidden={setIsCardRefDate}
                  sizeWidth={70}
                  sizeHeight={80}
                >
                  <div className='searchFlightsContainerDate'>
                    <FlightsDateGoing
                      oneWay={!isRoundTrip}
                      setOneWay={setIsRoundTrip}
                      roundTrip={isRoundTrip}
                      setRoundTrip={setIsRoundTrip}
                      setDateGoing={setIsCardRefDate}
                      dateGoing={isCardRefDate}
                      cardRef={cardRefDate}
                      setDateGoingSelected={setDateGoingSelected}
                      setDateGoingSelectedApi={setDateGoingSelectedApi}
                      setDateSreturnSelected={setDateReturnSelected}
                      setDateSreturnSelectedApi={setDateReturnSelectedApi}
                      searcherFlight={searcherFlight}
                      checkDate={checkDate}
                      setCheckDate={setCheckDate}
                      dateGoingSelected={dateGoingSelected}
                      dateSreturnSelected={dateReturnSelected}
                      accompanied={accompanied}
                      setDateOneWay={setDateOneWay}
                      setDateReturn={setDateReturn}
                      dateOneWay={dateOneWay}
                      dateReturn={dateReturn}
                    />
                  </div>
                </ModalContainer>

              }
            </div>

            <div className='inputs-triple-flight' onClick={() => setIsManagingPassengers(true)}>


              <div className='contain-label-date'>
                <p className='label-destiny'>{t("fligth.search_fligth.passengers")}</p>
                <p className='select-destiny-input'>{passengersSelecteds.adult + passengersSelecteds.baby + passengersSelecteds.boy} Pasajeros</p>
              </div>
              <SvgIcon color={"#004274"} name={iconList.IconTwoPerson} size={30} />


              {isManagingPassengers &&
                <ModalContainer
                  show={isManagingPassengers}
                  hidden={setIsManagingPassengers}
                  sizeWidth={70}
                  sizeHeight={40}
                >
                  <div>
                    <FlightPassengerManagement
                      value={passengersSelecteds}
                      onChange={getPassengersSelecteds}
                      setIsManagingPassengers={setIsManagingPassengers}
                      refPass={cardRefPassenger}
                      isManagingPassengers={isManagingPassengers}
                    />
                  </div>
                </ModalContainer>

              }

            </div>

          </div>
          {
            pathname === "/packages" ?
              ""
              :
              <button disabled={buttonDisable} onClick={searcherFlight} className='button-search-flight' id={`${accompanied ? 'width_E' : 'width_D'}`}>
                <SvgIcon name={iconList.IconSearch} color={buttonDisable ? '#CCCCCC' : '#ffffff'} size={30} />
              </button>
          }
        </div>
      </div>


      :

      <div className='searchFlightsContainer' style={{ boxShadow: `${pathname === "/packages" ? "none" : ""}` }}>
        <div className='searchFlightsContainerHeader'>
          <div className='searchFlightsRow' >
            <div className='searchFlightsContainerHeaderOption'>
              <SvgIcon name={iconList.IconPlane} color={'#004274'} size={30} />
              <p className='searchFlightsContainerHeaderOptionTextBig'>{t("fligth.search_fligth.book_fligth")}</p>
            </div>
            {/* <div className='searchFlightsContainerHeaderOption'>
            <SvgIcon name={iconList.IconHotel} color={'#004274'} size={30} />
            <p className='searchFlightsContainerHeaderOptionTextBig'>Hotel</p>
          </div>
          <div className='searchFlightsContainerHeaderOption'>
            <SvgIcon name={iconList.IconTravel} color={'#004274'} size={30} />
            <p className='searchFlightsContainerHeaderOptionTextBig'>Paquetes</p>
          </div> */}
          </div>
          <div className={`${accompanied ? 'searchFlightsColumn' : 'searchFlightsRow'}`} id={accompanied ? "" : "column-round-trip"}>
            <div className='searchFlightsContainerHeaderOption' onClick={() => {
              setIsRoundTrip(false)
              setCheckDate(false)
            }}>
              {isRoundTrip
                ? <Record size="15" color="#104172" />
                : <RecordCircle size="15" color="#104172" variant="Bold" />
              }
              <p className='searchFlightsContainerHeaderOptionTextSmall'>{t("fligth.search_fligth.one_way")}</p>
            </div>
            <div className='searchFlightsContainerHeaderOption' onClick={() => {
              setIsRoundTrip(true)
              setCheckDate(true)
            }}>
              {isRoundTrip
                ? <RecordCircle size="15" color="#104172" variant="Bold" />
                : <Record size="15" color="#104172" />
              }
              <p className='searchFlightsContainerHeaderOptionTextSmall'>{t("fligth.search_fligth.round_trip")}</p>
            </div>
          </div>
        </div>
        {/* <div className='reserva-p' style={{ margin: "1rem 0" }}>
        <div className={background === 1 ? 'select' : 'unselect'} onClick={() => selectTypeResercation(1)}>
          <p><SvgIcon color={background === 0 ? '#004274' : '#ffffff'} name={iconList.IconPlane} size={30} />{t("packages.packages_medics")} + <SvgIcon color={background === 0 ? '#004274' : '#ffffff'} name={iconList.IconHotel} size={30} /> {t("packages.hotel")}</p>
        </div>
      </div> */}
        <div className='contain-btn-camps-flight'>
          <div className='content-all-camps-search'>
            <div className='content-inputs-destiny-dual'>
              <div ref={containerRefOri} className='input-destiny-origin'>
                <div className='content-inputs'>
                  <div className='first-section' >
                    <p className='label-destiny'>{t("fligth.search_fligth.origin")}</p>
                    {
                      selectedOrigin == "o" ?
                        <DesinitySearcher type={desinitySearcherTypes.origin} change={getSelectedOrigin} inputFocused={inputFocused} />
                        :
                        (
                          selectedOrigin === selectedDestination
                            ? <p onClick={() => { getSelectedOrigin("o") }} className='value-origin-destiny' style={{ color: 'red' }}>{ellipsisText('Cambia tu origen', 17)}</p>
                            : <p onClick={() => { getSelectedOrigin("o") }} className='value-origin-destiny'>{selectedOrigin}</p>
                        )

                    }


                    {/* {selectedOrigin === selectedDestination
                      ? <p style={{ color: 'red' }}>{ellipsisText('Cambia tu origen', 17)}</p>
                      : <p className='select-destiny-input'>{ellipsisText(selectedOrigin === "o" ? desinitySearcherTypes.origin.placeholder : selectedOrigin, 17)}</p>
                    } */}
                  </div>
                  <SvgIcon name={iconList.IconPinMap} color={'#004274'} size={30} />
                </div>
                {/* {isCardRefOrigin &&
                  <div
                    ref={cardRefPlace}
                  >
                    <DesinitySearcher type={desinitySearcherTypes.origin} change={getSelectedOrigin} isCardRef={isCardRefOrigin} />
                  </div>} */}
              </div>
              <div ref={containerRefDes} className='input-destiny-origin'>
                <div className='content-inputs'>
                  <div className='first-section' >
                    <p className='label-destiny'>{t("fligth.search_fligth.destination")}</p>
                    {
                      selectedDestination == "d" ?
                        <DesinitySearcher type={desinitySearcherTypes.destination} change={getSelectedDestination} inputFocused={inputFocusedD} />
                        :
                        (
                          selectedOrigin === selectedDestination
                            ? <p onClick={() => { getSelectedDestination("d") }} className='value-origin-destiny' style={{ color: 'red' }}>{ellipsisText('Cambia tu origen', 17)}</p>
                            : <p onClick={() => { getSelectedDestination("d") }} className='value-origin-destiny'>{selectedDestination}</p>
                        )

                    }
                  </div>
                  <SvgIcon name={iconList.IconPinMap} color={'#004274'} size={30} />
                </div>
              </div>
              {/* <div className='input-destiny-origin' onClick={() => setIsCardRefDestination(true)}>
                <div className='content-inputs'>
                  <div>
                    <p className='label-destiny'>{t("fligth.search_fligth.destination")}</p>
                    {selectedOrigin === selectedDestination
                      ? <p style={{ color: 'red' }}>{ellipsisText('Cambia tu destino', 17)}</p>
                      : <p className='select-destiny-input'>{ellipsisText(selectedDestination === "d" ? desinitySearcherTypes.destination.placeholder : selectedDestination, 17)}</p>
                    }
                  </div>
                  <SvgIcon name={iconList.IconPinMap} color={'#004274'} size={30} />
                </div>
                {isCardRefDestination &&
                  <div
                    ref={cardRefPlace}
                  >
                    <DesinitySearcher type={desinitySearcherTypes.destination} change={getSelectedDestination} isCardRef={isCardRefDestination} />
                  </div>}
              </div> */}
            </div>


            <div className='contend-inputs-date-passenger'>
              <div className='inputs-triple' onClick={() => {
                setCheckDate(false)
                setIsCardRefDate(true)

              }}>
                <div className='contain-label-date'>
                  <p className='label-destiny' >{t("fligth.search_fligth.date")}</p>
                  <p className='select-destiny-input'>{dateGoingSelected === "" ? t("fligth.search_fligth.going") : dateGoingSelected}</p>
                </div>
                <SvgIcon name={iconList.IconCalendar} color={'#004274'} size={30} />
              </div>

              <div className='inputs-triple'
                onClick={() => {
                  if (isRoundTrip) {
                    setCheckDate(true);
                    setIsCardRefDate(true);
                  }
                }}
                style={{ opacity: !isRoundTrip ? 0.25 : 1 }}
              >
                <div className='contain-label-date'>
                  <p className='label-destiny'>{t("fligth.search_fligth.date")}</p>
                  <p className='select-destiny-input'>{dateReturnSelected === "" ? t("fligth.search_fligth.return") : dateReturnSelected}</p>
                </div>
                <SvgIcon name={iconList.IconCalendar} color={'#004274'} size={30} />
              </div>

              {isCardRefDate &&
                <ModalContainer
                  show={isCardRefDate}
                  hidden={setIsCardRefDate}
                  sizeWidth={70}
                  sizeHeight={80}
                >
                  <div className='searchFlightsContainerDate'>
                    <FlightsDateGoing
                      oneWay={!isRoundTrip}
                      setOneWay={setIsRoundTrip}
                      roundTrip={isRoundTrip}
                      setRoundTrip={setIsRoundTrip}
                      setDateGoing={setIsCardRefDate}
                      dateGoing={isCardRefDate}
                      cardRef={cardRefDate}
                      setDateGoingSelected={setDateGoingSelected}
                      setDateGoingSelectedApi={setDateGoingSelectedApi}
                      setDateSreturnSelected={setDateReturnSelected}
                      setDateSreturnSelectedApi={setDateReturnSelectedApi}
                      searcherFlight={searcherFlight}
                      checkDate={checkDate}
                      setCheckDate={setCheckDate}
                      dateGoingSelected={dateGoingSelected}
                      dateSreturnSelected={dateReturnSelected}
                      accompanied={accompanied}
                      setDateOneWay={setDateOneWay}
                      setDateReturn={setDateReturn}
                      dateOneWay={dateOneWay}
                      dateReturn={dateReturn}
                    />
                  </div>
                </ModalContainer>

              }

              <div className='inputs-triple' onClick={() => setIsManagingPassengers(true)}>


                <div className='contain-label-date'>
                  <p className='label-destiny'>{t("fligth.search_fligth.passengers")}</p>
                  <p className='select-destiny-input'>{passengersSelecteds.adult + passengersSelecteds.baby + passengersSelecteds.boy} Pasajeros</p>
                </div>
                <SvgIcon color={"#004274"} name={iconList.IconTwoPerson} size={30} />


                {isManagingPassengers &&
                  <ModalContainer
                    show={isManagingPassengers}
                    hidden={setIsManagingPassengers}
                    sizeWidth={70}
                    sizeHeight={40}
                  >
                    <div>
                      <FlightPassengerManagement
                        value={passengersSelecteds}
                        onChange={getPassengersSelecteds}
                        setIsManagingPassengers={setIsManagingPassengers}
                        refPass={cardRefPassenger}
                        isManagingPassengers={isManagingPassengers}
                      />
                    </div>
                  </ModalContainer>

                }

              </div>
            </div>



          </div>
          {
            pathname === "/packages" ?
              ""
              :
              <button disabled={buttonDisable} className='btn-search-flight-horizontal' onClick={searcherFlight}>
                <SvgIcon name={iconList.IconSearch} color={buttonDisable ? '#CCCCCC' : '#ffffff'} size={40} />
              </button>
          }
        </div>
      </div >


  )
}

export default SearchFlights;