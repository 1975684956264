import useBrowseHome from "../../../../viewModels/home/browse.home.viewModel";
import SvgIcon, { iconList } from '../../../share/components/iconSVG';
import { ArrowDown2, ArrowUp2, Heart } from 'iconsax-react';
import './styles.css';
import CircularProgress from '@mui/material/CircularProgress';
import ActivityIndicator from '../../../share/components/activityIndicator'
import { useTranslation } from "react-i18next"
import bannernew from '../../../../assets/svg/bannerNewHome.png'
import SearchFlights from "../../../flights/search.flights.view";
import useSearchFlights from "../../../../viewModels/flights/search.flights.viewModel";
import wellezyIcon from "../../../../assets/logos/wellezy.png"
import { useLocation, useNavigate } from "react-router-dom";

function BrowseHome({ pathname }) {

  const [t, i18n] = useTranslation("global")

  const {
    disableBtn,
    loading,
    tabOpen,
    cardRefProcedure,
    cardRefDestination,
    proceduresSelected,
    isOpenproceduresList,
    setIsOpenproceduresList,
    proceduresList,
    chooseProcedure,
    destinationsSelected,
    isOpenDestinationsList,
    setIsOpenDestinationsList,
    destinationsList,
    chooseDestination,
    searchForProceduresOnSite,
    goToWelezyShop,
    loadingSearch
  } = useBrowseHome();

  const isIframe = false

  const {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,

    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,

    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,

    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    finishProcess,
    ellipsisText,
  } = useSearchFlights(isIframe);


  const navigate = useNavigate()

  const gotoOption = () => {
    navigate(`/TourismHealthPack/${proceduresSelected}`, {
      state: {
        keyWork: ""
      }
    })
  }




  return (
    <section className='browseHomeColumn' >

      <img src={bannernew} className="img-banner-home" alt="banner-home" />
      <div className="effect-bacground">
        <div className="logo-home-banner">
          <img src={wellezyIcon} />
        </div>
        <div className="contain-home-browse">
          <div className='browseHomeColumnContainer'>
            <h3 className="titleBrowseNeed">{t("browse_home.need_procedures")}</h3>
            <div className="browseHomeColumnSideLeftForm"
              style={{ backgroundColor: tabOpen === 0 ? '#FFFFFF' : '#EDEDED' }}
            >
              <div className="browseHomeColumnSideLeftFormColumns">
                <div className={`browseHomeColumnSideLeftFormColumnsGroup
                ${tabOpen === 1 ? 'browseHomeTabOpen' : tabOpen === 2 ? 'browseHomeTabLeftClose' : 'tabBorder'}`}

                >
                  <div className="browseHomeColumnSideLeftFormColumnsText">
                    <p className="browseHomeColumnSideLeftFormColumnsTextTitle">{t("browse_home.what_procedures_looking")}</p>
                    {proceduresSelected
                      ? <p className="browseHomeColumnSideLeftFormColumnsTextValue">{proceduresSelected}</p>
                      : <p className="browseHomeColumnSideLeftFormColumnsTextValue">{t("browse_home.procedures")}</p>
                    }
                  </div>
                  {loading
                    ? <CircularProgress size={15} />
                    : <div
                      className="browseHomeColumnSideLeftFormColumnsBtn"
                      onClick={() => { setIsOpenproceduresList(!isOpenproceduresList) }}
                    >
                      {isOpenproceduresList
                        ? <ArrowUp2 size="25" color="#104172" variant="Bold" />
                        : <ArrowDown2 size="25" color="#104172" variant="Bold" />
                      }
                    </div>
                  }
                </div>

                <div className={`browseHomeColumnSideLeftFormColumnsGroup
                ${tabOpen === 2 ? 'browseHomeTabOpen' : tabOpen === 1 ? 'browseHomeTabRightClose' : ''}`}

                >
                  <div className="browseHomeColumnSideLeftFormColumnsText">
                    <p className="browseHomeColumnSideLeftFormColumnsTextTitle">{t("browse_home.where")}</p>
                    {destinationsSelected
                      ? <p className="browseHomeColumnSideLeftFormColumnsTextValue">{destinationsSelected}</p>
                      : <p className="browseHomeColumnSideLeftFormColumnsTextValue">Cartagena, Barranquilla...</p>
                    }
                  </div>
                  {loading
                    ? <CircularProgress size={15} />
                    : <div
                      className="browseHomeColumnSideLeftFormColumnsBtn"
                      onClick={() => { setIsOpenDestinationsList(!isOpenDestinationsList) }}
                    >
                      {isOpenDestinationsList
                        ? <ArrowUp2 size="25" color="#104172" variant="Bold" />
                        : <ArrowDown2 size="25" color="#104172" variant="Bold" />
                      }
                    </div>
                  }
                </div>
              </div>

              {isOpenproceduresList &&
                <div className="browseHomeColumnSideLeftFormListContainer" ref={cardRefProcedure}>
                  {
                    proceduresList.length > 0 && proceduresList.map((index, key) => {
                      return (
                        <div className="browseHomeColumnSideLeftFormListOption" onClick={() => chooseProcedure(index)} key={key}>
                          <p className="browseHomeColumnSideLeftFormListOptionText">{index?.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
              }

              {isOpenDestinationsList &&
                <div className="browseHomeColumnSideLeftFormListContainer" ref={cardRefDestination}>
                  {
                    destinationsList.length > 0 && destinationsList.map((index, key) => {
                      return (
                        <div className="browseHomeColumnSideLeftFormListOption" onClick={() => chooseDestination(index)} key={key}>
                          <p className="browseHomeColumnSideLeftFormListOptionText">{index?.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
              }

              {!(isOpenDestinationsList || isOpenproceduresList) &&

                pathname !== "/" ?

                <button style={{ opacity: loadingSearch ? 0.5 : 1 }} className={`${disableBtn ? 'browseHomeColumnSideLeftFormBtnDisabled' : 'browseHomeColumnSideLeftFormBtn'}`}
                  onClick={() => !loadingSearch && gotoOption()} disabled={disableBtn}>
                  {!loadingSearch &&
                    <p className="browseHomeColumnSideLeftFormBtnText">{t("browse_home.search")}</p>}
                  <div className="browseHomeColumnSideLeftFormBtnIcon">
                    {
                      loadingSearch ?
                        <ActivityIndicator size={25} colorFill="#ffffff" />
                        :
                        <SvgIcon name={iconList.IconSearch} size={25} color={"#FFFFFF"} />
                    }

                  </div>
                </button>

                :

                <button style={{ opacity: loadingSearch ? 0.5 : 1 }} className={`${disableBtn ? 'browseHomeColumnSideLeftFormBtnDisabled' : 'browseHomeColumnSideLeftFormBtn'}`}
                  onClick={() => !loadingSearch && searchForProceduresOnSite()} disabled={disableBtn}>
                  {!loadingSearch &&
                    <p className="browseHomeColumnSideLeftFormBtnText">{t("browse_home.search")}</p>}
                  <div className="browseHomeColumnSideLeftFormBtnIcon">
                    {
                      loadingSearch ?
                        <ActivityIndicator size={25} colorFill="#ffffff" />
                        :
                        <SvgIcon name={iconList.IconSearch} size={25} color={"#FFFFFF"} />
                    }

                  </div>
                </button>
              }
            </div>

          </div>
          <section className="contain-search-fligth-banner">
            <SearchFlights accompanied={false} buttonDisable={buttonDisable} cardRefDate={cardRefDate} cardRefPassenger={cardRefPassenger} cardRefPlace={cardRefPlace} dateGoingSelected={dateGoingSelected} dateReturnSelected={dateReturnSelected} ellipsisText={ellipsisText} finishProcess={finishProcess} getPassengersSelecteds={getPassengersSelecteds} getSelectedDestination={getSelectedDestination} getSelectedOrigin={getSelectedOrigin} isCardRefDate={isCardRefDate} isCardRefDestination={isCardRefDestination} isCardRefOrigin={isCardRefOrigin} isManagingPassengers={isManagingPassengers} isRoundTrip={isRoundTrip} passengersSelecteds={passengersSelecteds} searcherFlight={searcherFlight} selectedDestination={selectedDestination} selectedOrigin={selectedOrigin} setDateGoingSelected={setDateGoingSelected} setDateGoingSelectedApi={setDateGoingSelectedApi} setDateReturnSelected={setDateReturnSelected} setDateReturnSelectedApi={setDateReturnSelectedApi} setIsCardRefDate={setIsCardRefDate} setIsCardRefDestination={setIsCardRefDestination} setIsCardRefOrigin={setIsCardRefOrigin} setIsManagingPassengers={setIsManagingPassengers} setIsRoundTrip={setIsRoundTrip} />
          </section>
        </div>

      </div>


    </section>
  )
}
export default BrowseHome;
