
import React from 'react'
import ColombiaMap from './Assets/Img/ColombiaMap.jpg'
import travelForm from './Assets/Img/travelForm.jpeg'
import Travel from './Assets/Img/Travel.jpg'
import { Button, TextField, Box } from '@mui/material'
import AlliesDetailMainCarrosel from '../AlliesDetailMainCarrosel/AlliesDetailMainCarrosel'
import AlliesDetailMainCarroselAirline from '../AlliesDetailMainCarroselAirline/AlliesDetailMainCarroselAirline'
import scrollWeb from "./Assets/Img/scrolling.png"
import scrollMobile from "./Assets/Img/scroll.png"
import Contrato from "./Assets/USUARIO.pdf"
import './Assets/styles.css'
const AlliesDetailsMain = () => {



    return (


        <div className='procedureCategoryDetailsMainn'>

            <div className='contained-Clinics'>
                <div className='background-contained-clinics'>
                    <div className='div-clinics'>
                        <div className='titleClinics'>
                            <div>
                                <h2>+20 CLÍNICAS</h2>
                            </div>

                        </div>
                        <div className='subTittleClinics'>
                            <label>
                                Aliadas en todo el país
                            </label>
                        </div>

                    </div>
                    <div className='paragraph'>
                        <p >
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'content here,
                            content here', making it look like readable English. Many desktop punlishing packages and web page edit).
                        </p>
                    </div>

                </div>


            </div>

            <AlliesDetailMainCarrosel />
            <div className='procedureCategoryDetailsMain_one' >


                <div className='content-Info-Travel' id='MainCategory'>
                    <label>ESTAMOS ALIADOS CON</label>
                    <h1>+30 EMPRESAS DE VIAJE</h1>
                    <label>EN TODO EL MUNDO</label>
                    <p>EXPLORA EL MUNDO CON WELLEZY</p>
                </div>

            </div>

            <AlliesDetailMainCarroselAirline />


            <div className='container-form-all' >

                <div className='form-Allies-img'>
                    <img src={travelForm} alt="travelForm" />
                </div>




                <div className='formAllies-form-container'>
                    <form class="form-allies">
                        <h2>¿Quieres ser uno de nuestros aliados?</h2>
                        <div className='contain-input-form'>
                            <div className='container-scrolling'>
                                <img src={scrollWeb} alt="scroll" />
                                <img src={scrollMobile} alt="scroll" />
                            </div>
                            <p type="Nombre:"><input type="text" placeholder="Escribe tu Nombre"></input></p>
                            <p type="Titulo"><input type="text" placeholder="Tu titulo"></input></p>
                            <p type="Fecha de Nacimiento:"><input type="date" placeholder="Fecha"></input></p>
                            <p type="Lugar de Naciemiento:"><input type="text" placeholder="Ej:Bogota"></input></p>
                            <div className='content-select-form'>
                                <p type="Abreviatura:">
                                    <select>
                                        <option selected value="" >Seleccione</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Dra">Dra</option>


                                    </select>
                                </p>
                                <p type="Especialidad:">
                                    <select >
                                        <option value="" selected >Selecciona</option>
                                        <option value="Medicina General" selected>Medicina General</option>
                                        <option value="Cirujia Plastica" selected>Cirujia Plastica</option>
                                        <option value="Medicina Estetica" selected>Medicina Estetica</option>
                                        <option value="Anastesiologia" selected>Anastesiologia</option>
                                        <option value="Cardiologia" selected>Cardiologia</option>
                                        <option value="Fisioterapeutas" selected>Fisioterapeutas</option>
                                        <option value="Psicologos" selected>Psicologos</option>
                                        <option value="Cosmetologia" selected>Cosmetologia</option>
                                        <option value="MaxiloFacial" selected>MaxiloFacial</option>
                                        <option value="Gastroenterologia" selected>Gastroenterologia</option>
                                        <option value="Homeopatia" selected>Homeopatia</option>
                                        <option value="Nutricion" selected>Nutricion</option>
                                        <option value="Oncologia" selected>Oncologia</option>


                                    </select>
                                </p>
                            </div>
                            <p type="Experiencia en años:"><input type="number" placeholder="1"></input></p>
                            <p type="Direccion:"><input type="text" placeholder="Cra 66 #77"></input></p>
                            <p type="Ciudad Donde Opera?:"><input type="text" placeholder="Mensaje que deseas dejar"></input></p>
                        </div>

                        <div className='container-terminos'>
                            <input type={"checkbox"}></input>
                            <a href={"./Visualizador"} target="_blank">Terminos Condiciones</a>
                        </div>
                        <button>Send Message</button>
                        <div className='footer-form'>
                            <span class="fa fa-phone"></span>+57 318 8088170
                            <span class="fa fa-envelope-o"></span> contact@wellezy.com
                        </div>
                    </form>
                </div>




            </div>
        </div>

    )
}

export default AlliesDetailsMain