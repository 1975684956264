import React from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'


const SearchClinics = () => {

    const [t] = useTranslation("global")

    return (
        <div className='content-search-ambulance'>
            <div className='content-legend-ambulance'>
                <SvgIcon name={iconList.IconDoctor} size={50} color={"#004274"} />
                <h3>{t("clinics.title")}</h3>
            </div>
            <div className='camp-ambulance'>
                <SvgIcon name={iconList.IconListMedicaments} size={30} color={"#004274"} />
                <div>
                    <h4>
                        {t("clinics.buttons.specialty")}
                    </h4>
                    <p>
                        {t("specialist.abrevement_specialit")}
                    </p>
                </div>
                <SvgIcon name={iconList.IconArrowDown2} size={15} color={"#004274"} />

            </div>
            <div className='camp-ambulance'>
                <SvgIcon name={iconList.IconPinMap} size={30} color={"#004274"} />
                <div>
                    <h4>
                        {t("clinics.buttons.where")}
                    </h4>
                    <p>
                        {t("specialist.abrevement_city")}
                    </p>
                </div>
                <SvgIcon name={iconList.IconArrowDown2} size={15} color={"#004274"} />

            </div>
            <button className='search-ambulance'>
                <SvgIcon name={iconList.IconSearch} size={30} color={"#ffffff"} />
            </button>
            <div className='camp-ambulance' style={{ marginTop: "1.5rem" }}>
                <SvgIcon name={iconList.IconFilters} size={30} color={"#004274"} />
                <div>
                    <h4>
                    {
                            t("clinics.filters")
                        }
                    </h4>
                    <p>
                    {t("specialist.personalize_search")}
                    </p>
                </div>
                <SvgIcon name={iconList.IconArrowDown2} size={15} color={"#004274"} />

            </div>
        </div>
    )
}

export default SearchClinics