import './styles.css'
function FooterButton({
  icon = '',
  title = '',
  subTitle = '',
  toBeTaken = () => { }
}) {
  return (
    <div onClick={toBeTaken}
    className='pqrsFooterButton'
    >
      {
        icon &&
        <div className='pqrsFooterButtonIcon'>
          icon
        </div>
      }
      <div className='pqrsFooterButtonColumn'>
        <p className='pqrsFooterButtonTitle'>{title}</p>
        <p className='pqrsFooterButtonSubtitle'>{subTitle}</p>
      </div>
    </div>
  )
}

export default FooterButton