import React from 'react';
import '../../../styles.css';
import './styles.css';
import MonthComponent from './components/mouthComponent';
import useMyCalendarViewModel from '../../../../../viewModels/profile/myCalendar/useMyCalendarViewModel';
import ActivityIndicator from '../../../../share/components/activityIndicator';
import SvgIcon, { iconList } from '../../../../share/components/iconSVG';
import ModalContainer, { sliceFrom } from '../../../../share/components/modalContainer';
import FormCreateDate from '../../../../share/components/formCreateDate';
import DinamicTablet from '../../../../share/components/tablet';
import CardInfo from './components/cardInfo';

function MyCalendar({ goToTeleConsultation }) {
  const {
    isLoading,
    isShowModal,
    setIsShowModal,
    getAppointmentsFiltered,

    filterByMonth,
    isShowMonthList,
    setIsShowMonthList,
    monthList,
    chooseFilterByMonth,

    filterByYear,
    isShowYearList,
    setIsShowYearList,
    yearList,
    cooseFilterByYear,
    tabletHeader,
    tabletData,
    tabletActions,
    appointmentSelected,
    createAppointment,

    isShowModalCardInfo,
    setIsShowModalCardInfo,


  } = useMyCalendarViewModel(goToTeleConsultation);
  return (
    <div className="bigContainer  myCalendar">

      <ModalContainer
        show={isShowModal}
        hidden={setIsShowModal}
        sizeWidth={90}
        sizeHeight={70}
        from={sliceFrom.up}
      >
        <FormCreateDate close={setIsShowModal} data={appointmentSelected} />
      </ModalContainer>

      <ModalContainer
        show={isShowModalCardInfo}
        hidden={setIsShowModalCardInfo}
        sizeWidth={90}
        sizeHeight={70}
        from={sliceFrom.up}
      >
        <CardInfo close={setIsShowModalCardInfo} data={appointmentSelected} goToTeleConsultation={goToTeleConsultation}/>
      </ModalContainer>

      <div className="profileScreen_ContainerSideCenter">
        {isLoading &&
          <div className='activityIndicatorContainer'>
            <ActivityIndicator colorFill='#004274' size={40} />
          </div>
        }

        {!isLoading &&
          <DinamicTablet
            header={tabletHeader}
            datos={tabletData}
            actions={tabletActions}
          >
          </DinamicTablet>
        }
      </div>

      <div className="profileScreen_ContainerSideRight">
        <div className='filterContainer'>
          <p className='filterContainerTitle'>Filtrar por</p>
          <div className='filterRow'>

            <div className='filterContainerGroup' onClick={() => setIsShowMonthList(!isShowMonthList)}>
              <label>Mes</label>
              <p className='value'>{filterByMonth?.name ? filterByMonth?.name : 'Click aquí'}</p>
            </div>
            <div className='filterContainerGroup' onClick={() => setIsShowYearList(!isShowYearList)}>
              <label>Año</label>
              <p className='value'>{filterByYear !== 0 ? filterByYear : 'Click aquí'}</p>
            </div>
            {isShowYearList &&
              <div className='listOptions'>
                {
                  yearList.map((elem, key) => {
                    return (
                      <div key={key} onClick={() => cooseFilterByYear(elem)} className='option'>
                        <p className={`${filterByYear === elem ? 'selected' : 'text'}`}>
                          {elem}
                        </p>
                      </div>
                    )
                  })
                }
              </div>
            }
            {isShowMonthList &&
              <div className='listOptions'>
                {
                  monthList.map((item, key) => {
                    return (
                      <div key={key} onClick={() => chooseFilterByMonth(item)} className='option'>
                        <p className={`${filterByMonth?.id === item.id ? 'selected' : 'text'}`}>
                          {item.name}</p>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>

          <div className='filterContainerBtn' onClick={getAppointmentsFiltered}>
            {isLoading && <ActivityIndicator colorFill='#ffffff' size={20} />}
            {!isLoading && <SvgIcon color={'#FFFFFF'} name={iconList.IconSearch} size={25} />}
            {!isLoading && <p className='filterContainerBtnText'>Filtrar</p>}
          </div>
        </div>

        <div className='createDatebtn'
          onClick={() => createAppointment()}>
          <SvgIcon
            color={'#FFFFFF'}
            name={iconList.IconCalendar}
            size={25}
          />
          <p className='createDateBtnText'>Crear cita</p>
        </div>

      </div>
    </div>
  )
}

export default MyCalendar;
