import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
function usePassengerInformationFormStep3(
  userDetail,
  userToken,
  location,
  PNR,
  dataSavePNR,
) {
  const [step, setStep] = useState(1);
  const [t] = useTranslation("global")
  const stepList = [
    { id: 1, title: t("fligth.form_passenger.step.payment_method") },
    // { id: 2, title: 'Seguridad' },
  ];
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [paymentCardSelected, setPaymentCardSelected] = useState(null);
  const [form, setForm] = useState({
    codeCVC: '',
    description: '',
    reference: ''
  })


  const [message, setMessage] = useState(null);
  function getEvent(event) {
    const value = event?.target?.value;
    const key = event?.target?.id;
    setForm({
      ...form,
      [key]: value
    })
  }

  async function generateTicket(data) {
    if (isFetching) return;
    setIsFetching(true)
    try {
      const url = `${process.env.REACT_APP_URL_API_FLY}ticket`;
      const result = await axios.post(url, data);
      setMessage({
        text1: t("fligth.form_passenger.step.ticket_exit"),
        text2: t("fligth.form_passenger.step.send_information"),
        statusOK: true
      })
      setShowModalTicket(true)
    } catch (error) {
      console.log("Error in generateTicket()", error)


      setShowModalTicket(true)
      setMessage({
        text1: 'Error, Estamos en mantenimiento',
        text2: 'Por favor intente mas tarde',
        statusOK: false
      })


    }
    finally {
      setIsFetching(false)
    }
  }

  function generateTicketData() {

    function getRec() {
      return PNR?.itineraryChange === 0 && PNR?.passengerChange === 0 ? 0 : 1;
    }

    function getDescription() {
      const date = location?.itinerary[0]?.dateDeparture.split('T')[0];
      const hour = location?.itinerary[0]?.dateDeparture.split('T')[1].split('+')[0];
      const from = location?.itinerary[0].from;
      const to = location?.itinerary[0].to;
      const bookingClass = location?.itinerary[0].bookingClass;
      const company = location?.itinerary[0].company;
      const res = `TP/${from}-${to}/${date}-${hour}/${company}-${bookingClass}`;
      return res;
    }

    function getReference() {
      const number = Math.floor(Math.random() * 90000000) + 10000000;
      return `Ref-${PNR?.pnr?.pnrHeader?.reservationInfo?.reservation?.controlNumber}-${number}`;
    }

    const data = {
      session: {
        sessionId: PNR?.session?.sessionId,
        sequenceNumber: PNR?.session?.sequenceNumber,
        securityToken: PNR?.session?.securityToken,
      },
      record: PNR?.pnr?.pnrHeader?.reservationInfo?.reservation?.controlNumber,
      day: location?.itinerary[0]?.dateDeparture.split('T')[0],
      hour: location?.itinerary[0]?.dateDeparture.split('T')[1].split('+')[0],
      value: location?.priceTotal,
      rec: getRec(),
      cash: true,
      ti: false,
      multi: false,

      pay: {
        card_id: paymentCardSelected?.id,
        user_id: `${userDetail?.id}`,
        email: userDetail?.email,
        description: getDescription(),
        dev_reference: getReference(),
        token: paymentCardSelected?.token,
        cvc: '',
        amount: location?.priceTotal,
        vat: location?.priceTaxes,
      }
    }
    generateTicket(data);
  }

  function reset() {
    setShowModalTicket(false)
    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  }

  useEffect(() => {
    if (!isFetching && showModalTicket) {
      setTimeout(() => {
        reset()
      }, 10000);
    }
  }, [isFetching, showModalTicket])

  const travellersCount = dataSavePNR?.travellers?.length;
  const email = dataSavePNR?.travellers[travellersCount]?.email;

  return {
    step,
    setStep,
    stepList,
    paymentCardSelected,
    setPaymentCardSelected,
    form,
    getEvent,
    generateTicketData,
    isFetching,
    showModalTicket,
    setShowModalTicket,
    email,
    reset,
    message,
  }
}

export default usePassengerInformationFormStep3;
