import React from 'react'
import TransportC from '../../ui/views/transport/primaryView/TransportC'

const TransportRoute = () => {
  return (
    <div>
        <TransportC></TransportC>
    </div>
  )
}

export default TransportRoute