import React from 'react'
import "./styles.css"
import { useLocation } from 'react-router-dom'


const StateSteps = ({step}) => {

  
  return (
    <div className='content-state-steps'>
      <span style={{ background: `${step >= 1 ? "#004274" : ""}` }}>1</span>
      <div style={{ background: `${step >= 2 ? "#004274" : ""}` }}></div>
      <span style={{ background: `${step >= 2 ? "#004274" : ""}` }}>2</span>
      <div style={{ background: `${step >= 3 ? "#004274" : ""}` }}></div>
      <span style={{ background: `${step >= 3 ? "#004274" : ""}` }}>3</span>
      <div style={{ background: `${step >= 4 ? "#004274" : ""}` }}></div>
      <span style={{ background: `${step >= 4 ? "#004274" : ""}` }}>4</span>
      <div style={{ background: `${step === 5 ? "#004274" : ""}` }}></div>
      <span style={{ background: `${step === 5 ? "#004274" : ""}` }}>5</span>
    </div>
  )
}

export default StateSteps