import React, { useState } from "react";
import SpanishLogo from "../../../../../Components/Header/Assets/Img/SpanishLogo.png";
import EnglishLogo from "../../../../../Components/Header/Assets/Img/EnglishLogo.png";
import { ArrowDown2, ShoppingCart, Location } from "iconsax-react";
import ProcedureSearch from "../../../../../Components/ProcedureSearch/ProcedureSearch";
import "./assets/style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iconLocationHeader from "../../../../assets/icons/iconLocationHeader.svg";
import iconShoppingCart from "../../../../assets/icons/iconShoppingCart.svg";

const Find = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const ubicationData = [
    { id: 1, location: "Colombia" },
    { id: 2, location: "Cartagena" },
    { id: 3, location: "Barranquilla" },
    { id: 4, location: "Cali" },
    { id: 5, location: "Bogota" },
    { id: 6, location: "Bucaramanga" },
    { id: 7, location: "Manizales" },
    { id: 8, location: "Cucuta" },
  ];

  // const languageData = [
  //     { id: 1, language: 'Español', logo: SpanishLogo },
  //     { id: 2, language: 'Inglés', logo: EnglishLogo },
  //     { id: 3, language: 'Francés', logo: EnglishLogo }
  // ]

  const [loading, setLoading] = useState(false);
  const [locationDroped, setLocationDroped] = useState(false);
  const [locationSelected, setLocationSelected] = useState(t("find.location"));

  const [languageDroped, setLanguageDroped] = useState(false);
  const [languageSelected, setLanguageSelected] = useState("Español");
  const [languageLogoSelected, setLanguageLogoSelected] = useState(SpanishLogo);

  const locationDropedFunction = (e) => {
    const clicked = e.target.attributes.category.value;
    setLocationSelected(clicked);
    setLocationDroped(false);
  };
  const languageDropedFunction = (e) => {
    const clicked = e.target.attributes.category.value;
    setLanguageSelected(clicked);
    setLanguageDroped(false);
    clicked === "Español"
      ? setLanguageLogoSelected(SpanishLogo)
      : clicked === "Inglés"
      ? setLanguageLogoSelected(EnglishLogo)
      : setLanguageLogoSelected(EnglishLogo);
  };

  function searchAll(keyword) {
    navigate(`/TourismHealth/${keyword}`, {
      state: {
        procedure: keyword,
        city: locationSelected === "Colombia" ? "" : locationSelected,
      },
    });
  }

  return (
    <section className="form-find-b">
      <div className="headerSearch">
        <div
          className="headerSearch_location"
          onClick={() => setLocationDroped(!locationDroped)}
        >
          <div className="headerSearch_location_">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "14px",
              }}
            >
              <img src={iconLocationHeader} alt="location" />
              <p
                className="headerSearch_text"
                style={{ fontSize: "11px", marginLeft: "14px" }}
              >
                {locationSelected}
              </p>
            </div>
            <ArrowDown2 size="8" color="#004274" />
          </div>
          {locationDroped && (
            <div className="headerSearch_locationDroped">
              <div style={{ width: "90%", height: "90%" }}>
                {ubicationData.map((data, key) => {
                  return (
                    <button
                      key={key}
                      className="headerSearch_locationDropedButton"
                      category={data.location}
                      onClick={locationDropedFunction}
                    >
                      {data.location}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div
          className="procedureSearch_search"
          style={{ opacity: loading ? 0.5 : 1 }}
        >
          <ProcedureSearch busqueda={searchAll} loading={loading} />
        </div>
        <div className="headerSearch_language">
          <div className="headerSearch_language_">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img src={iconShoppingCart} alt="Español" />
              </div>
              <p
                className="headerSearch_text"
                style={{ fontSize: "11px", marginLeft: "14px" }}
              >
                {t("find.my_cart")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Find;
