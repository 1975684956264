import React from "react";
import Find from "../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import BannerClinics from "./bannerClinics/BannerClinics";
import ButtonsFilters from "./buttonsFilters/ButtonsFilters";
import UseClinics from "../../viewModels/clinics/UseClinics";
import AllClinics from "./AllClinics/AllClinics";
import Paginado from "./Paginado/Paginado";
import { useTranslation } from "react-i18next";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";

const Clinics = () => {
  const [t, i18n] = useTranslation("global");
  const {
    dataClinics,
    urlImage,
    currentPage,
    nextHandler,
    prevHandler,
    mostrarNext,
    services,
    loading,
    personalizeFind,
    cityFind,
    filterClinics,
    detailClinics,
  } = UseClinics();

  return (
    <div>
      <Find />
      <CarouselSevices current={t("carousel_services.clinics")} />
      <BannerClinics />
      <ButtonsFilters
        personalizerFind={personalizeFind}
        cityFind={cityFind}
        filterSpecialist={filterClinics}
      />
      <AllClinics
        dataClinics={dataClinics}
        urlImage={urlImage}
        loading={loading}
        detailClinics={detailClinics}
      />
      <Paginado
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        currentPage={currentPage}
        data={services}
        mostrarNext={mostrarNext}
      />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Clinics;
