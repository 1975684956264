import React from 'react'
import "./styles.css"

const DetailPay = ({acomodation, price, taxes, total, discount}) => {

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
        });
    
        return cantidadFormateada;
    }

    var porcentaje = 35;
    var resultado = Number(total) * (porcentaje / 100);

  return (
    <div className='detail-pay-hotel'>
        <h2>Detalle del pago</h2>
        <div className='row-detail-pay-normal'>
            <p>Traslado para {acomodation}</p>
            <span>{price}</span>
        </div>
        <div className='row-detail-pay-normal'>
            <p>Cargos</p>
            <span>{taxes}</span>
        </div>
        <div className='row-detail-pay row-discount-pay'>
            <p>Descuentos</p>
            <span>-{formatearCantidad(resultado)}</span>
        </div>
        <div className='row-detail-pay row-total-pay'>
            <p>Total</p>
            <span>{formatearCantidad(total)}</span>
        </div>
        <div className='line-separation'></div>
        <div className='row-aditional-services'>
            <p>Cargos adicionales</p>
            <span>Te los cobrará el traslado cuando llegues a destino.</span>
        </div>
        <div className='row-detail-pay row-secure-hotel'>
            <section>
                <p>Seguro viajero</p>
                <span>Persona por dia</span>
            </section>
            <span style={{color:"#004274", fontWeight:"bold"}}>2.5 USD</span>
        </div>
    </div>
  )
}

export default DetailPay