import React, { useState, useEffect } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import './styles.css'
import Find from '../../share/components/Find/Find'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import AsideHotelResult from './aside/AsideHotelResult'
import AllCardsResults from './allResultsCards/AllCardsResults'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import FilterAsideHotels from './aside/FilterAsideHotels/FilterAsideHotels'
import HotelSearchEngine from '../HotelSearchEngine/HotelSearchEngine'
import UseHotels from '../../../viewModels/hotels/UseHotels'
import { useTranslation } from 'react-i18next'
import axios from 'axios'


const HotelResults = () => {

  const [firstRange, setFirstRange] = useState(0)
  const [loadingSearcht, setLoadingSearcht] = useState(false)
  const [resetResult, setResetResult] = useState(false)
  const [secondRange, setSecondRange] = useState(0)
  const [errorFilter, setErrorFilter] = useState('')
  const [activeFilterPrice, setActiveFilterPrice] = useState(false)
  const { state, pathname } = useLocation()
  const [dropdownFilter, setDropdownFilter] = useState(false)
  const [dropdownSearch, setDropdownSearch] = useState(false)
  const [seePack, setSeePack] = useState(pathname.includes("/packages/hotels") ? true : false) 
  const [t] = useTranslation("global")
  let rooms = [];
  const navigate = useNavigate()
  const {
    cardRef,
    openHotelSearch,
    paceholders,
    search,
    setSearch,
    destiny,
    dateEntry,
    setDateEntry,
    datesSelected,
    setDatesSelected,
    cardRooms,
    setCardRooms,
    roomsSelected,
    setRoomsSelected,
    quantityPersons,
    openHotelSearchFuct,
    destinationSelectionFunc,
    searchHotelSelected,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    loadingSearch,
    validationForm,
    errApi
  } = UseHotels()

 // La URL proporcionada
let datos = {
  arrival: '',
  departure: '',
  country: 'CO',
  countryClient: 'CO',
  rooms: rooms
};

const match = pathname.match(/idHotel=(\d+)/);

  // Verificar si hubo una coincidencia y obtener el valor de idHotel
  const idHotel = match ? match[1] : "";

// Función para convertir una fecha en el formato deseado
function convertirFecha(fecha) {
  let partes = fecha.split('-');
  return partes[0] + '/' + partes[1] + '/' + partes[2];
}

// Dividir la cadena de consulta en sus partes
let partes = pathname.split('&');

// Inicializar un objeto para almacenar los datos


// Iterar a través de las partes de la cadena de consulta y asignar valores al objeto de datos
for (let i = 0; i < partes.length; i++) {
  let parametro = partes[i].split('=');
  let clave = parametro[0];
  let valor = parametro[1];

  switch (clave) {
    case 'arrival':
      datos.arrival = convertirFecha(valor);
      break;
    case 'departure':
      datos.departure = convertirFecha(valor);
      break;
  }
}

// Utilizamos expresiones regulares para buscar las cadenas de adult, child y age en el enlace
let adultMatch = pathname.match(/adult=([\d,]+)/);
let childMatch = pathname.match(/child=([\d,]+)/);
let ageMatch = pathname.match(/age=([\d,-]+)/);

// Función para convertir una cadena de números separados por comas en una matriz de enteros
function parseNumbers(str) {
    return str.split(',').map(function (num) {
        return parseInt(num, 10);
    });
}

// Extraemos los valores y los convertimos en matrices
let adultValues = adultMatch ? parseNumbers(adultMatch[1]) : [];
let childValues = childMatch ? parseNumbers(childMatch[1]) : [];
let ageValues = ageMatch ? ageMatch[1].split(',').map(function (ageRange) {
    return ageRange.split('-').map(function (num) {
        return parseInt(num, 10);
    });
}) : [];

// Formateamos los valores como se indica
let formattedValues = {
    "adult": adultValues,
    "child": childValues,
    "age": ageValues
};


// Creamos la estructura de rooms


// Verificamos la longitud de las matrices adult, child y age
let maxLength = Math.max(formattedValues.adult.length, formattedValues.child.length, formattedValues.age.length);

// Iteramos para crear las habitaciones
for (let i = 0; i < maxLength; i++) {
  let room = {
      adult: formattedValues.adult[i] || 1, // Por defecto 1 adulto si no hay valor
      child: formattedValues.child[i] || 0, // Por defecto 0 niños si no hay valor
      age: formattedValues.age[i] == 0 || formattedValues.age[i] === undefined ? [] : formattedValues.age[i] // Por defecto una matriz vacía si no hay valor
  };
  rooms.push(room);
}

// Mostrar los datos resultantes




  const itemsForPage = 10;
  const [dataHotels, setDataHotels] = useState(state?.data?.error === "error" ? [] : state?.data?.slice(0, itemsForPage));
  const [services, setServices] = useState(state?.data?.error === "error" ? [] : state?.data);
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);

  const nextHandler = () => {
    const totalElementos = services.length;
    const nextPage = currentPage + 1;
    const firstIndex = nextPage * itemsForPage;

    if (firstIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setDataHotels(services.slice(firstIndex, firstIndex + itemsForPage));
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firstIndex = prevPage * itemsForPage;

    setDataHotels(services.slice(firstIndex, firstIndex + itemsForPage));
    setCurrentPage(prevPage);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);

  };

  let totalPersons = rooms.reduce(function (total, room) {
    return total + room.adult + room.child;
}, 0);

function reloadPage() {
  window.location.reload(true)
}

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   setLoadingSearcht(true)
  //   if(!state){
      
  //     axios.get("https://travelflight.pdtcomunicaciones.com/api/hotel-do/hotels",{
  //     params:{
  //       "destination":idHotel
  //     }
  //   })
  //       .then(res => {
  //         setLoadingSearcht(false)
  //         navigate(`${pathname}`, {
  //           state: {
  //             data: res.data.Hotel,
  //           }
  //         })
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         setLoadingSearcht(false)
  //       })
      
  //   }else{
  //     setLoadingSearcht(false)
  //   }
  // }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    if(!state){
      setLoadingSearcht(true)
      axios.post(`${process.env.REACT_APP_URL_API_FLY}restel/disponibility`, datos)
      .then(res => {
        setLoadingSearcht(false)
        console.log(res.data)
        navigate(`${pathname}`, {
          state: {
            data: res.data,
            persons: totalPersons,
            rooms: rooms,
            fen: datos.arrival,
            fsa: datos.departure,
            country_client:datos.countryClient
          }
        })
        reloadPage()
      })
      .catch(err => {
        console.log(err)
        setLoadingSearcht(false)
      })
    }
  }, [])



  useEffect(() => {
    if (Number(firstRange) || Number(secondRange) > 0) {
      if (services.length > 0) {
        setErrorFilter('')
        const filtrados = state?.data?.filter(item => {
          return item.res.pax[0].hab[0].reg[0]["@attributes"].prr >= firstRange && item.res.pax[0].hab[0].reg[0]["@attributes"].prr <= secondRange
        })
        setDataHotels(filtrados.slice(0, itemsForPage))
        setServices(filtrados)
      }
    } else {
      setErrorFilter(t("hotel.necessary_camp"))
    }

  }, [activeFilterPrice])

  useEffect(() => {

    setDataHotels(state?.data?.error === "error" ? [] : state?.data?.slice(0, itemsForPage))
    setServices(state?.data?.error === "error" ? [] : state?.data)
    setFirstRange(0)
    setSecondRange(0)
  }, [resetResult])



  return (
    <div className='contain-results-hotels'>
      <Find />
      <CarouselSevices current={servicesTravel[1].title} />
      <div className='contain-route-hotel-result'>
        <p className='route-clinic'> <Link to={'/hotel'}>Hotel</Link> {'>'} <span>{t("hotel.result")}</span></p>
      </div>

      <div className='contain-buttons-mobile-hotels'>
        <button onClick={() => { setDropdownFilter(!dropdownFilter) }}>
          <SvgIcon color={'#004274'} size={30} name={iconList.IconFilters} />
          {t("hotel.filters")}
        </button>
        <button onClick={() => { setDropdownSearch(!dropdownSearch) }}>
          <SvgIcon color={'#004274'} size={30} name={iconList.IconSearch} />
          {t("hotel.search")}
        </button>
        {
          dropdownFilter &&
          <FilterAsideHotels widthContain={'300px'} position={'absolute'} />
        }
        {
          dropdownSearch &&
          <HotelSearchEngine titleSize={'20px'} iconTitleSize={'50'} containDateFlexDirection={'column'} containDateJustify={'space-between'} layoutCampoWidth={'100%'} containFinishFlexDirection={'column'} containFinishJustify={'space-between'} layoutCampoVWidth={'100%'} widthContain={'310px'} position={'absolute'} right={'0'} top={'60px'} cardRef={cardRef} cardRooms={cardRooms} codeNacionality={codeNacionality} dataNacionality={dataNacionality} dateEntry={dateEntry} datesSelectedEntry={datesSelected.entryDate}
          datesSelectedExit={datesSelected.exitDate} destinationSelectionFunc={destinationSelectionFunc} destiny={destiny} errApi={errApi} findCountryClient={findCountryClient} loading={loading} loadingSearch={loadingSearch} nacionality={nacionality} nacionalityDropdown={nacionalityDropdown} openHotelSearch={openHotelSearch} openHotelSearchFuct={openHotelSearchFuct} paceholders={paceholders} quantityPersons={quantityPersons} roomsSelected={roomsSelected} search={search} searchHotelSelected={searchHotelSelected} selectedNacionality={selectedNacionality} setCardRooms={setCardRooms} setDateEntry={setDateEntry} setDatesSelected={setDatesSelected} setNacionalityDropdown={setNacionalityDropdown} setRoomsSelected={setRoomsSelected} setSearch={setSearch} validationForm={validationForm}/>
        }
      </div>

      <div className='main-content-result-hotel'>
        <AsideHotelResult
          setSecondRange={setSecondRange}
          activeFilterPrice={activeFilterPrice}
          setActiveFilterPrice={setActiveFilterPrice}
          erroFilter={errorFilter}
          setFirstRange={setFirstRange}
          setResetResult={setResetResult}
          resetResult={resetResult}
        />
        <AllCardsResults data={dataHotels} currentPage={currentPage} dataS={services} mostrarNext={mostrarNext} nextHandler={nextHandler} prevHandler={prevHandler} loading={loadingSearcht} seePack={seePack} dataRoom={datos} persons={state?.persons} fen={state?.fen} fsa={state?.fsa} rooms={state?.rooms} country_client={state?.country_client}/>
      </div>
    </div>
  )
}

export default HotelResults