import React, { useState } from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import { useTranslation } from 'react-i18next';
import "./styles.css"

const PersonForm = ({ index, type, setPaxes,paxes }) => {
    const [sex, setSex] = useState("")
    const [accordion, setAccordion] = useState(index === 1 && type === "adult" ? true : false)
    const [nom, setNom] = useState('')
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState("")
    const [hotel, setHotel] = useState("")
    const [flightNumber, setFlightNumber] = useState("")
    const [age, setAge] = useState('')
    const [t] = useTranslation("global")
    const [addHErr, setAddHErr] = useState(false)
    const [checkSuccess, setCheckSuccess] = useState(false)
    const [typeDocument, setTypeDocument] = useState("Doc")
    const [Document, setDocument] = useState("")
    const [modalTypeDoc, setModalTypeDoc] = useState(false)
    const listTypeDoc = [
        "Pas",
        "CI",
        "TI",
        "CE",
        "PPT"
    ]

    const addHuesped = () => {
            if (nom === "" || lastName === "" || flightNumber === "" || email === "" || tel === "" || address === "" || Document === "" || hotel === "" ) {
                setAddHErr(true)
            } else {
                setAddHErr(false)
                setPaxes((prevList) => [...prevList, {
                    name: nom,
                    lastname: lastName,
                    email: email,
                    tel: tel,
                    doc:Document,
                    hotel:hotel,
                    address:address,
                    flightNumber:flightNumber
                }])
                setAccordion(false)
                setCheckSuccess(true)
            }   
    }

    return (
        <div>
            <section className='accordion-detail-form-hotel' style={{ backgroundColor: `${paxes.length > 0 ? "rgba(49, 115, 49, 0.677)" : ""}` }} onClick={() => { setAccordion(!accordion) }}>
                <div>
                    <h2>{type === "child" ? "Niño" : "Adulto"} {index}</h2>
                    {
                        index === 1 && type === "adult" &&
                        <p>Será responsable del traslado</p>
                    }

                </div>
                <span className='circle-icon-accordion'>
                    {
                        paxes.length > 0 ?
                            <SvgIcon color={'#ffff'} name={iconList.IconCheckSucces} size={20} />
                            :
                            (
                                accordion ?
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='#ffff' width={20} height={20} ><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" /></svg>
                                    :
                                    <SvgIcon color={'#ffff'} name={iconList.IconArrowDown} size={20} />
                            )

                    }

                </span>

            </section>
            {
                paxes.length === 0 ?
                    <div className='form-persons-pay-hotel'>
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Nombres</label>
                            <input type="text" onChange={(e) => { setNom(e.target.value) }} placeholder={index === 1 && type === "adult" ? "Nombre del tomador" : "Nombre"} />
                        </div>
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Apellidos</label>
                            <input type="text" onChange={(e) => { setLastName(e.target.value) }} placeholder={index === 1 && type === "adult" ? "Apellido del tomador" : "Apellido"} />
                        </div>
                        {
                            index === 1 && type === "adult" &&
                            <div className='camp-form-person-hotel-doc'>
                                <label htmlFor="">Documento</label>
                                <div className='contain-inputs-selecteds'>
                                    <div className='select-type-doc'>
                                        <div className='select-onclick-modal-type' onClick={()=>{
                                            setModalTypeDoc(true)
                                        }}>
                                            <span>{typeDocument}</span>
                                            <SvgIcon color={"rgb(99, 99, 99)"} name={iconList.IconArrowDown} size={15} />
                                        </div>
                                        {
                                            modalTypeDoc &&
                                            <div className='modal-select-type-doc'>
                                                <ul>
                                                    {
                                                        listTypeDoc.map((item,index)=>{
                                                            return(
                                                                <li key={index} onClick={()=>{
                                                                    setTypeDocument(item)
                                                                    setModalTypeDoc(false)
                                                                }}>{item}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <input type="text" onChange={(e) => { setDocument(e.target.value) }} placeholder={index === 1 && type === "adult" ? "Documento del Tomador" : ""} />
                                </div>

                            </div>
                        }

                        {
                            index === 1 && type === "adult" &&
                            <>
                                <div className='camp-form-person-hotel'>
                                    <label htmlFor="">Telefono</label>
                                    <input type="text" onChange={(e) => { setTel(e.target.value) }} placeholder={index === 1 ? "Telefono del tomador" : "Telefono"} />
                                </div>
                                <div className='camp-form-person-hotel'>
                                    <label htmlFor="">Email</label>
                                    <input type="text" onChange={(e) => { setEmail(e.target.value) }} placeholder={index === 1 ? "Email del tomador" : "Email"} />
                                </div>
                            </>
                        }
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Direccion</label>
                            <input type="text" onChange={(e) => { setAddress(e.target.value) }} placeholder={"Ingresa la direccion"} />
                        </div>
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Hotel</label>
                            <input type="text" onChange={(e) => { setHotel(e.target.value) }} placeholder={"Hotel"} />
                        </div>
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Numero de vuelo</label>
                            <input type="text" onChange={(e) => { setFlightNumber(e.target.value) }} placeholder={"Ingresa tu numero de vuelo"} />
                        </div>
                        {
                            addHErr &&
                            <div className='err-hotel-form-person'>
                                <p>Para poder guardar los datos debes llenar todos los datos</p>
                            </div>
                        }
                        <button className='btn-save-info-passenger' onClick={addHuesped}>Guardar</button>

                    </div>
                    :

                    ""
            }
        </div>
    )
}

export default PersonForm