import { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


function useSearchFlights(isIframe) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const cardRefPlace = useRef()
  const cardRefPassenger = useRef()
  const cardRefDate = useRef()
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [isCardRefOrigin, setIsCardRefOrigin] = useState(false)
  const [selectedOrigin, setSelectedOrigin] = useState('o')
  const [isCardRefDestination, setIsCardRefDestination] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState('d')
  const [dateGoingSelected, setDateGoingSelected] = useState('')
  const [dateReturnSelected, setDateReturnSelected] = useState('')
  const [isCardRefDate, setIsCardRefDate] = useState(false)
  const [dateGoingSelectedApi, setDateGoingSelectedApi] = useState('')
  const [dateReturnSelectedApi, setDateReturnSelectedApi] = useState('')
  const [codeiataDestinity, setCodeiataDestinity] = useState('');
  const [codeIataOrigin, setCodeIataOrigin] = useState('');
  const [isManagingPassengers, setIsManagingPassengers] = useState(false)
  const [passengersSelecteds, setPassengersSelecteds] = useState({
    adult: 1,
    boy: 0,
    baby: 0
  })

  function getSelectedOrigin(value) {
    if (value == "o") {
      setSelectedOrigin("o")
    } else {
      const selected = `${value?.nameAirport} - ${value?.codeIataAirport}`;
      localStorage.setItem("select-origin", selected)
      localStorage.setItem("iata-origin", value?.codeIataAirport)
      setSelectedOrigin(selected)
      setCodeIataOrigin(value?.codeIataAirport)
    }
  }

  function getSelectedDestination(value) {
    if (value == "d") {
      setSelectedDestination("d")
    } else {
      const selected = `${value?.nameAirport} - ${value?.codeIataAirport}`;
      localStorage.setItem("select-destiny", selected)
      localStorage.setItem("iata-destiny", value?.codeIataAirport)
      setSelectedDestination(selected)
      setCodeiataDestinity(value?.codeIataAirport)
    }

  }

  const getPassengersSelecteds = (type, operation) => {
    setPassengersSelecteds((prev) => {
      return {
        ...prev, [type]: operation ? passengersSelecteds[type] + 1 : passengersSelecteds[type] - 1
      }
    })
  }



  const showButtonSearch = () => {
    let isDisable = true;
    if (!isRoundTrip) {
      if (
        selectedOrigin !== 'Origen vuelo'
        && selectedDestination !== 'Destino Vuelo'
        && dateGoingSelected !== 'Ida') {
        isDisable = false;
      }
    }
    else if (isDisable && dateReturnSelected !== 'Vuelta') {
      isDisable = false
    }
    setButtonDisable(isDisable);
  }

  function ellipsisText(text, maxCaracter) {
    let result = ''
    if (text.includes("-")) {
      const textArray = text.split('-');
      const newText = `${textArray[1]} - ${textArray[0]}`;
      result = newText.length > maxCaracter
        ? newText.substring(0, maxCaracter) + '...'
        : newText;
    }
    else {
      result = text;
    }
    return result;
  }
  //Url= /flight/&departure=2023-10-04T05:00:00.000Z&return=2023-09-28T05:00:00.000Z&iataD=MDE&iataO=BOG&destinationC=Jose%20Marie%20Cordova%20-%20MDE&originC=Eldorado%20-%20BOG&adult=1&boy=0&baby=0&isRoundTrip=true

  //return: fecha de retorno 
  //departure: fecha de salida
  //iataD: codigo iata destino
  //iataO: codigos iata origen
  //destinationC: ciudad de destino
  //originC: ciudad de origen
  //adult: cantidad de adultos
  //baby: cantidad de bebes
  //isRoundTrip: indica si es ida y vuelta o solo ida, el valor es true o false
  //boy: cantidad de niños

  //con los datos que te estoy dando necesito contruir la siguiente estructura cuando recibo url

  // {
  //   "searchs": 50,
  //   "class": false,
  //   "qtyPassengers": adult + boy,
  //   "adult": adult,
  //   "child": boy,
  //   "baby": baby,
  //   "itinerary":
  //     [
  //       {
  //         "departureCity": departure,
  //         "arrivalCity": return,
  //         "hour": departure
  //       },
  //     ]
  // }
  function searcherFlight() {
    let url = ""
    if (isIframe) {
      if (pathname === "/packages") {
        url = '/iframe/packages/flight'
      } else {
        url = '/iframe/flight'
      }

    } else if (pathname === "/packages") {
      url = "/packages/flight"
    } else {
      url = "/flight"
    }

    pathname === "/packages" ?

      localStorage.setItem("urlFligth", `${url}/&departure=${dateGoingSelectedApi}&return=${dateReturnSelectedApi}&iataD=${codeiataDestinity}&iataO=${codeIataOrigin}&destinationC=${selectedDestination}&originC=${selectedOrigin}&adult=${passengersSelecteds?.adult}&boy=${passengersSelecteds?.boy}&baby=${passengersSelecteds?.baby}&isRoundTrip=${isRoundTrip}&`)
      :
      navigate(`${url}/&departure=${dateGoingSelectedApi}&return=${dateReturnSelectedApi}&iataD=${codeiataDestinity}&iataO=${codeIataOrigin}&destinationC=${selectedDestination}&originC=${selectedOrigin}&adult=${passengersSelecteds?.adult}&boy=${passengersSelecteds?.boy}&baby=${passengersSelecteds?.baby}&isRoundTrip=${isRoundTrip}&`,
        {
          state: {
            dateReturnSelectedApi,
            dateGoingSelectedApi,
            codeiataDestinity,
            codeIataOrigin,
            buttonOriginFlight: selectedOrigin,
            buttonDestinityFlight: selectedDestination,
            passengersSelecteds,
            roundTrip: isRoundTrip,
            // clasSelected, //no needed
            // dateGoingSelected, //no needed
            // dateSreturnSelected, //no needed
            // oneWay, //no needed
            // DestinityFlight//no needed
          }
        })
    window.location = window.location.href;


  }


  useEffect(() => {
    showButtonSearch()
  }, [isRoundTrip, dateGoingSelected, dateReturnSelected, selectedOrigin, selectedDestination]);

  useEffect(() => {
    setIsCardRefOrigin(false)
    setIsCardRefDestination(false)
  }, [selectedOrigin, selectedDestination])


  useEffect(() => {

    setSelectedOrigin(localStorage.getItem("select-origin") === null ? "o" : localStorage.getItem("select-origin"))
    setCodeIataOrigin(localStorage.getItem("iata-origin") === null ? "o" : localStorage.getItem("iata-origin"))
    setSelectedDestination(localStorage.getItem("select-destiny") === null ? "d" : localStorage.getItem("select-destiny"))
    setCodeiataDestinity(localStorage.getItem("iata-destiny") === null ? "d" : localStorage.getItem("iata-destiny"))

    function handleClickOutside(event) {
      if (cardRefPlace.current && !cardRefPlace.current.contains(event.target)) {
        setIsCardRefOrigin(false)
        setIsCardRefDestination(false)
      }
      if (cardRefPassenger.current && !cardRefPassenger.current.contains(event.target)) {
        setIsManagingPassengers(false)
      }
      if (cardRefDate.current && !cardRefDate.current.contains(event.target)) {
        setIsCardRefDate(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };



  }, [])

  return {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,
    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,
    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,


    setDateGoingSelectedApi,
    setDateReturnSelectedApi,


    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    ellipsisText
  }
}
export default useSearchFlights;