import React, { useState } from 'react';
import Controls from '../components/controls';
import { useMeeting } from "@videosdk.live/react-sdk";
import ParticipantView from '../components/participantView';
import ActivityIndicator from '../../share/components/activityIndicator';

function MeetingView(props) {

  const {
    meetingId,
    isValidatingRoom,
    isDisabledRoom,
    onMeetingLeave,
  } = props;

  const [joined, setJoined] = useState(null);

  const statusList = {
    joined: { value: 'JOINED' },
    joining: { value: 'JOINING' },
  }

  const { join, participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },

    onMeetingLeft: () => {
      onMeetingLeave();
    },
  });

  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };

  return (
    <div className='meetingView'>
      <h3>Meeting Id: {meetingId}</h3>

      {isValidatingRoom &&
        <ActivityIndicator
          size={22}
          colorFill={'#FFFFFF'}
        />
      }

      {!isValidatingRoom && isDisabledRoom &&
        <p className='notRoom'>sala no disponible</p>
      }

      {!isValidatingRoom && !isDisabledRoom &&
        <>
          {joined && joined == statusList?.joined?.value
            ?
            <>  <Controls />
              <div className='participantContainer'>
                {[...participants.keys()].map((participantId) => (
                  <ParticipantView
                    participantId={participantId}
                    key={participantId}
                  />
                ))}
              </div>
            </>
            : joined && joined == statusList?.joining?.value ?
              <div className='allCenter'>
                <ActivityIndicator
                  size={40}
                  colorFill={'#FFFFFF'}
                />
                <p className='onlyText'>Joining the meeting...</p>
              </div>
              :
              <div className='btn' onClick={joinMeeting}>
                <p className='btnText'>Join</p>
              </div>
          }
        </>
      }
      {!joined &&
        <div onClick={() => onMeetingLeave()} className='btn'>
          <p className='btnText'>
            volver atras
          </p>
        </div>
      }
    </div>
  )
}
export default MeetingView;


// const [isFullScreen, setIsFullScreen] = useState(false);