import { useTranslation } from 'react-i18next'
import ModalContainer from '../../share/components/modalContainer'
import Loading from '../../share/components/loading'
import SvgIcon,{iconList} from '../../share/components/iconSVG'
import { ArrowLeft } from 'iconsax-react'
import Find from '../../share/components/Find/Find'
import CarouselSevices from '../../share/components/CarouselServices/CarouselSevices'
import PersonForm from "./PersonForm/PersonForm"
import SendInvoices from './SendInvoce/SendInvoices'
import DetailPay from "./detailPay/DetailPay"
import DetailBuy from './detailBuy copy/DetailBuy'
import useMakeReservationTransport from '../../../viewModels/transport/useMakeReservation'
import { useNavigate } from 'react-router-dom'


const ReservationTransport = () => {

const [t] = useTranslation("global")

const {
    createLinkPay,
    cantPassenger,
    reservation,
    errorReservation,
    setPaxes,
    paxes,
    loadingRespose,
    loadingReservation,
    setLoadingReservation,
    state,
    needUser
} = useMakeReservationTransport()

const navigate = useNavigate()

function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });

    return cantidadFormateada;
  }

const volverPaginaAnterior = () => {
    navigate(-1);
  };

  return (
    <div>
        <Find/>
        <CarouselSevices/>
         <div className='contain-hotel-pay'>
        <ModalContainer
        show={loadingReservation}
        hidden={setLoadingReservation}
        sizeWidth={60}
        sizeHeight={50}
      >
          <Loading size={90} />
      </ModalContainer>
        <p onClick={volverPaginaAnterior} className='return-url'><ArrowLeft size={20} color='#004274'></ArrowLeft>{t("packages.packages_pay.back_page")}</p>
        <div className='layout-hotel-pay'>
          <section className='main-hotel-pay'>
          <PersonForm index={1}  type={"adult"} setPaxes={setPaxes} paxes={paxes}/>
            <SendInvoices />
          </section>
          <section className='aside-hotel-pay'>
            <DetailPay  total={state?.price} acomodation={cantPassenger[1]}/>
            <DetailBuy item={state?.service} />
            <div className='content-button-reservation-hotel'>
              <button onClick={createLinkPay}>Ir a pagar</button>
              <p>Total a pagar <span>{formatearCantidad(state?.price)}</span></p>
              { 
                errorReservation != "" &&
                <div className='err-hotel-form-person'>
                  <p style={{color:"white", fontWeight:"bold"}}>{errorReservation}</p>
                </div>
              }
               { 
                needUser &&
                <div className='err-hotel-form-person'>
                  <p style={{color:"white", fontWeight:"bold"}}>Para poder reservar debe agregar la persona a cargo del servicio</p>
                </div>
              }
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ReservationTransport