import axios from 'axios';
import { useState, useRef, useContext, useEffect } from 'react'
import * as locales from 'react-date-range/dist/locale';
import Api from './Api';
import { useLocation, useNavigate } from 'react-router-dom';




const TransportC = () => {


    const location = useLocation();
    const condition = location.pathname
    const [formValidate, setFormValidate] = useState('')

    const navigate = useNavigate();


    const {
        Loggin,
        cityUrl,
        originUrl,
        destinyUrl,
        servicesUrl
    } = Api()


    console.log(Loggin)
    const constraintsRef = useRef(null)
    const [letters, setLetters] = useState(1)
    const [adult, setAdult] = useState(1)
    const [boy, setBoy] = useState(0)
    const [baby, setBaby] = useState(0)
    const [calendar, setcalendar] = useState(false)
    const [calendarTwo, setCalendarTwo] = useState(false)
    const [typeT, setTypeT] = useState('')
    const [ciudadDropdown, setCiudadDropdown] = useState(false)
    const [aeropuertoDropdown, setAeropuertoDropdown] = useState(false)
    const [destinyDropdown, setDestinyDropdown] = useState(false)
    const [dated, setDated] = useState(null);
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [dateApi, setDateApi] = useState()

    const [politicas, setPoliticas] = useState(localStorage.getItem('politicas'))
    const [userId, setUserId] = useState(localStorage.getItem('userId'))
    const [cityList, setCityList] = useState([])
    const [city, setCity] = useState("")
    const [citySelected, setcitySelected] = useState('Selecciona la ciudad')
    const [originList, setOriginList] = useState([]) 
    const [originSelected, setOriginSelected] = useState('')
    const [loadingCity, setLoadingCity] = useState(false)
    const [loadingOrigin, setLoadingOrigin] = useState(false)
    const [loadingDestiny, setLoadingDestiny] = useState(false)
    const [destinyList, setDestinyList] = useState([])
    const [destinySelected, setDestinySelected] = useState('')
    const regex = /RESTEASY\d{6}/;
    const [loader, setLoader] = useState(false)
    const nameMapper = {

        de: 'German',
        enGB: 'English (United Kingdom)',
        enUS: 'English (United States)',
        es: 'Spanish',

    };

    const localeOptions = Object.keys(locales)
        .map(key => ({
            value: key,
            label: `${key} - ${nameMapper[key] || ''}`
        }))
        .filter(item => nameMapper[item.value]);

    const [locale, setLocale] = useState('es');


    const changeScreen = (e) => {
        setLetters(e)
    }

    const handleDateChange = date => {
        //esta funcion me convierte la fecha en el siguiente formato 2023-04-29-14-55
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}`;
        setDateApi(formattedDate)
        setSelectedDate(date);
    };

    function reloadPage() {
        window.location.reload(true)
    }


    const passengersCal = (e, tip, num) => {
        e.preventDefault()
        if (tip == 'ad') {
            if (num == -1 && adult == 0) {
                setAdult(0)
            } else {
                setAdult(adult + num)
            }

        } else if (tip == 'bo') {
            if (num == -1 && boy == 0) {
                setBoy(0)
            } else {
                setBoy(boy + num)
            }

        } else {
            if (num == -1 && baby == 0) {
                setBaby(0)
            } else {
                setBaby(baby + num)
            }
        }

    }




    if (condition === '/transport') {
       
        axios.post(Loggin)
            .then(res => {
                localStorage.setItem('politicas', res.data.response.token)
                localStorage.setItem('userId', res.data.response.userId)
            })
            .catch(error => {
                console.log(error)
            })
    }




    useEffect(() => {
        const source = axios.CancelToken.source();
        const findCity = () => {
            setLoadingCity(true)
            if (city === "") {
                setCityList([])
                setcitySelected("Selecciona la ciudad")
                setLoadingCity(false)
            } else {
                axios.post(cityUrl, {
                    "token": localStorage.getItem('politicas'),
                    "city": city
                }, { cancelToken: source.token })
                    .then(res => {
                        setCityList(res.data.response)
                        setLoadingCity(false)
                    })
                    .catch(err => {
                        if (!axios.isCancel(err)) {
                            console.log("Error en searchCity: ", err);
                            setLoadingCity(false)
                        }
                    })
            }

        }
        // const getResults = async () => {
        //   try {
        //     setDestiny([])
        //     const res = await axios.post('https://travelflight.pdtcomunicaciones.com/api/restel/cities', {
        //       "code": `${search}`
        //     }, { cancelToken: source.token });

        //     setDestiny(res.data);
        //   } catch (err) {
        //     if (!axios.isCancel(err)) {
        //       console.log("Error en searchCity: ", err);
        //     }
        //   }
        // };

        const debounce = (func, delay) => {
            let timeoutId;
            return (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    func(...args);
                }, delay);
            };
        };

        const debouncedSearchCity = debounce(findCity, 2000);



        debouncedSearchCity()

        return () => {
            source.cancel("Componente desmontado. Cancelar solicitudes pendientes.");
        };
    }, [city]);

    const findOrigin = () => {
        setLoadingOrigin(true)
        axios.post(originUrl, {
            "service": "FROM_TO_AIRPORT",
            "city_id": citySelected.id,
            "destination_id": 0,
            "token": localStorage.getItem('politicas')
        })
            .then(res => {
                setOriginList(res.data.response)
                setLoadingOrigin(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingOrigin(false)
            })
    }

    const findDestiny = () => {
        setLoadingDestiny(true)
        axios.post(destinyUrl, {
            "service": "FROM_TO_AIRPORT",
            "city_id": citySelected.id,
            "origin_id": originSelected.id,
            "token": localStorage.getItem('politicas')
        })
            .then(res => {
                setDestinyList(res.data.response)
                setLoadingDestiny(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingDestiny(false)
            })
    }

    function generateUniqueId() {
        return 'xxxxx-xxxx-4xxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const findServices = (e) => {
        e.preventDefault()
        if (typeT === '') {
            setFormValidate('Escoge el tipo de Traslado')
        } else if (dateApi === '') {
            setFormValidate('Escoge la fecha y hora')
        } else if (adult === 0) {
            setFormValidate('No se puede ir sin pasajeros')
        } else if (citySelected === '') {
            setFormValidate('Seleciona una ciudad')
        } else if (originSelected === '') {
            setFormValidate('Selecciona un origen')
        } else if (destinySelected === '') {
            setFormValidate('Selecciona un destino')
        } else {

            navigate(`/transport/${generateUniqueId()}`, {
                state: {
                    type: typeT,
                    cityId: citySelected.id,
                    originId: originSelected.id,
                    destinyId: destinySelected.id,
                    dateApi: dateApi,
                    adult: adult,
                    userId: userId,
                    baby: baby
                }
            })

        }

    }



    return {
        constraintsRef,
        formValidate,
        letters,
        setLetters,
        adult,
        setAdult,
        baby,
        setBaby,
        calendar,
        setcalendar,
        calendarTwo,
        setCalendarTwo,
        typeT,
        setTypeT,
        ciudadDropdown,
        setCiudadDropdown,
        aeropuertoDropdown,
        setAeropuertoDropdown,
        dated,
        setDated,
        date,
        setDate,
        nameMapper,
        localeOptions,
        locale,
        setLocale,
        changeScreen,
        passengersCal,
        locales,
        politicas,
        userId,
        cityList,
        citySelected,
        setcitySelected,
        findOrigin,
        originList,
        originSelected,
        setOriginList,
        setOriginSelected,
        findDestiny,
        destinyList,
        setDestinyList,
        destinySelected,
        setDestinySelected,
        destinyDropdown,
        setDestinyDropdown,
        findServices,
        selectedDate,
        handleDateChange,
        loader,
        setCityList,
        loadingCity,
        loadingDestiny,
        loadingOrigin,
        city,
        setCity
    }
}

export default TransportC