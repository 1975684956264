import React, {  useState, useRef } from 'react'


import Loading from '../../share/components/loading'
import './styles.css'
import imgTours from '../../../assets/pictures/tours.webp'
import CleanText from '../../../utils/CleanText'
import SvgIcon, { iconList } from '../../share/components/iconSVG'

import img1 from '../../../assets/pictures/img-1.jpg'
import img2 from '../../../assets/pictures/img-2.jpg'
import img3 from '../../../assets/pictures/img-3.webp'
import CarouselGalery from './carouselGalery/CarouselGalery'

import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from '@mui/material'



const TourResultSearch = ({ data, loading }) => {


    const imgGalery = [
        {
            img: img1
        },
        {
            img: img2
        },
        {
            img: img3
        },
    ]

    const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;

    const MotionBox = styled(motion.div)`
    width: 100vw;
  `
    const { cleanTextHelper } = CleanText()

    const [dropdownDetailTours, setDropdownDetailTours] = useState([])

    const [hiddenDescription, setHiddenDescription] = useState(false)

    const toggleDropdown = (key) => {
        setDropdownDetailTours((prevState) => {
            const newState = [...prevState];
            newState[key] = !newState[key];
            return newState;
        });
    };

    const constraintsRef = useRef(null)

    return (
        <div className='contain-result-tours-t'>
            {


                loading &&
                <Loading size={100} />

            }

            {
                !loading && data.length > 0 &&
                <>

                    <h3 style={{ width: '100%' }} >Resultados de la busqueda</h3>
                    {
                        data.map((item, key) => {
                            const isDropdownOpen = dropdownDetailTours[key] || false;
                            return (
                                <div className='card-tours-result' key={key}>
                                    <img src={item.image === null ? imgTours : item.image} alt="" />
                                    <div className='info-card-tours-result'>
                                        <h3>
                                            {item?.name}
                                        </h3>
                                        <div className='icon-info-card-tours-result'>
                                            <SvgIcon color={'#004274'} size={25} name={iconList.IconBus} />
                                            <SvgIcon color={'#004274'} size={25} name={iconList.IconBag} />
                                            <SvgIcon color={'#004274'} size={25} name={iconList.IconFood} />
                                            <SvgIcon color={'#004274'} size={25} name={iconList.IconCalendar} />

                                        </div>
                                        <button onClick={() => toggleDropdown(key)}>
                                            Mas detalles
                                        </button>
                                    </div>
                                    {
                                        isDropdownOpen &&
                                        <div className='effect-hover-car'>
                                            <CarouselGalery data={imgGalery} />
                                            <h3>
                                                {item?.name}
                                            </h3>
                                            <div className='icon-info-card-tours-result'>
                                                <SvgIcon color={'#004274'} size={25} name={iconList.IconBus} />
                                                <SvgIcon color={'#004274'} size={25} name={iconList.IconBag} />
                                                <SvgIcon color={'#004274'} size={25} name={iconList.IconFood} />
                                                <SvgIcon color={'#004274'} size={25} name={iconList.IconCalendar} />

                                            </div>
                                            <div onClick={() => setHiddenDescription(!hiddenDescription)} className='effect-hover-info'>

                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '.2rem' }}>
                                                    <h4>Detalles del Tour</h4>
                                                    <span>^</span>
                                                </div>
                                                {
                                                    hiddenDescription &&
                                                    <div className='description-info-toggle'>
                                                        <p>{cleanTextHelper(item.description)}</p>
                                                    </div>
                                                }

                                            </div>
                                            <button onClick={() => toggleDropdown(key)} className='button-hidden-effect'>
                                                Cerrar
                                            </button>
                                        </div>
                                    }

                                </div>
                            )
                        })}
                    {
                        <div className='content-deploy-cards-tour-mobile'>
                            <MotionConstraints className='slider_container height-tours' ref={constraintsRef}>
                                <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}>
                                    {
                                        data.map((item, key) => {
                                            const isDropdownOpen = dropdownDetailTours[key] || false;
                                            return (
                                                <motion.div className='div_homeTurism' key={key}>
                                                    <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                                        <div className='card-tours-result-mobile' >
                                                            <img src={item.image === null ? imgTours : item.image} alt="" />
                                                            <div className='info-card-tours-result'>
                                                                <h3>
                                                                    {item?.name}
                                                                </h3>
                                                                <div className='icon-info-card-tours-result'>
                                                                    <SvgIcon color={'#004274'} size={25} name={iconList.IconBus} />
                                                                    <SvgIcon color={'#004274'} size={25} name={iconList.IconBag} />
                                                                    <SvgIcon color={'#004274'} size={25} name={iconList.IconFood} />
                                                                    <SvgIcon color={'#004274'} size={25} name={iconList.IconCalendar} />

                                                                </div>
                                                                <button onClick={() => toggleDropdown(key)}>
                                                                    Mas detalles
                                                                </button>
                                                            </div>
                                                            {
                                                                isDropdownOpen &&
                                                                <div className='effect-hover-car'>
                                                                    <CarouselGalery data={item.images} />
                                                                    <h3>
                                                                        {item?.name}
                                                                    </h3>
                                                                    <div className='icon-info-card-tours-result'>
                                                                        <SvgIcon color={'#004274'} size={25} name={iconList.IconBus} />
                                                                        <SvgIcon color={'#004274'} size={25} name={iconList.IconBag} />
                                                                        <SvgIcon color={'#004274'} size={25} name={iconList.IconFood} />
                                                                        <SvgIcon color={'#004274'} size={25} name={iconList.IconCalendar} />

                                                                    </div>
                                                                    <div onClick={() => setHiddenDescription(!hiddenDescription)} className='effect-hover-info'>

                                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '.2rem' }}>
                                                                            <h4>Detalles del Tour</h4>
                                                                            <span>^</span>
                                                                        </div>
                                                                        {
                                                                            hiddenDescription &&
                                                                            <div className='description-info-toggle'>
                                                                                <p>{cleanTextHelper(item.description)}</p>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                    <button onClick={() => toggleDropdown(key)} className='button-hidden-effect'>
                                                                        Cerrar
                                                                    </button>
                                                                </div>
                                                            }

                                                        </div>
                                                    </Grid>
                                                </motion.div>
                                            )
                                        })
                                    }

                                </MotionBox>
                            </MotionConstraints>
                        </div>
                    }
                </>

            }
        </div>
    )
}

export default TourResultSearch