import React, { useEffect, useState } from "react";
import "./styles.css";
import { HambergerMenu, CloseCircle } from "iconsax-react";
import { global } from "../../../../global";
import SideBar from "../sideBar";
import ChangeLanguage from "../changeLanguage";
import JoinTheClub from "../joinTheClub";
import { NavLink, useLocation } from "react-router-dom";
import MenuSideBarHome from "../../../home/components/MenuSideBar";
import { useNavigate } from "react-router-dom";
import HeaderSession from "../headerSession";
import { useTranslation } from "react-i18next";

const {
  logos: { wellezyLogo },
} = global.img;

function Header() {
  const [isShowerSideBar, setIsShowerSideBar] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("global");
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [colorNavbar, setColorNavbar] = useState(true);
  const navBarList = [
    { text: t("browse_home.nav_bar.fligths"), url: '/fligths' },
    { text: t("browse_home.nav_bar.tourism"), url: '/Tourismhealth' },
    { text: t("browse_home.nav_bar.us"), url: '/aboutus' },
    { text: t("browse_home.nav_bar.specialist_services"), url: '/#' },
    { text: t("browse_home.nav_bar.build_pack"), url: 'https://wa.link/640gfp' },
    { text: 'PQRS', url: '/pqrs' },
  ]

  function goToOptionMenu(screen) {
    const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/[\w .-]*)*\/?$/i;
    const extarnalURL = urlPattern.test(screen);
    extarnalURL ? (window.location.href = screen) : navigate(`${screen}`, {});
  }

  return (
    <>
      <SideBar
        open={isShowerSideBar}
        close={setIsShowerSideBar}
        width={400}
        child={<MenuSideBarHome clearSideBar={setIsShowerSideBar} />}
      />
      <div
        className="headerContainerWrap"
        style={{
          background: `${pathname === "/" ? "#ffffff00" : "#104172"}`,
          position: `${pathname === "/" ? "absolute" : "relative"}`,
          width: `${pathname === "/" ? "100%" : "auto"}`,
        }}
      >
        <div className="headerContainer">
          <div className="headerContainerLeft">
            <div
              className="headerMenu"
              onClick={() => setIsShowerSideBar(!isShowerSideBar)}
            >
              {isShowerSideBar ? (
                <CloseCircle size="32" color="#FFFFFF" />
              ) : (
                <HambergerMenu size="32" color="#FFFFFF" />
              )}
            </div>
            <NavLink to="/">
              <div className="headerLogo">
                {pathname === "/" ? (
                  ""
                ) : (
                  <img
                    src={wellezyLogo}
                    alt="logo-wellezy"
                    className="headerLogoImg"
                  />
                )}
              </div>
            </NavLink>
          </div>

          <div className="headerContainerRight">
            <div className="navbarTextContainer">
              {navBarList.map((index, key) => {
                const isActive = window.location.pathname === index?.url;
                return (
                  <div
                    key={key}
                    className={`navBarOption ${
                      isActive ? "navBarOptionActive" : ""
                    }`}
                    onClick={() => goToOptionMenu(index?.url)}
                  >
                    <p className="navBarOptionText">{index.text}</p>
                  </div>
                );
              })}
            </div>
            {/* <JoinTheClub /> */}
            <ChangeLanguage />
            <HeaderSession />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
