import React from 'react'
import "./styles.css"

const SuccessPay = ({ amount, order, name, email, phone, agency, hotel, arrival, departure }) => {
  return (
    <div className='content-pay-success'>
      <h3>Pago Exitoso</h3>
      <p className='thanks-received'>¡Gracias! se ha recibido su pago de Cop {amount}</p>
      <p className='order-id'>Order Id {order}</p>
      <h4>Datos de tu pedido</h4>
      <div className='information-invoices'>
        <section className='column-info-order'>
          <p>
            <span>
              Nombre:
            </span>
            {name}
          </p>
          <p>
            <span>
              Correo:
            </span>
            {email}
          </p>
          <p>
            <span>
              Telefono:
            </span>
            {phone}
          </p>
        </section>
        <section className='column-info-order'>
          <p>
            <span>
              Agencia:
            </span>
            {agency}
          </p>
          <p>
            <span>
              Hotel:
            </span>
            {hotel}
          </p>
          <p>
            <span>
              Llegada:
            </span>
            {arrival}
          </p>
          <p>
            <span>
              Salida:
            </span>
            {departure}
          </p>
        </section>
      </div>
    </div>
  )
}

export default SuccessPay