import React from 'react'
import { useLocation } from "react-router-dom"
import AboutUsC from '../../ui/views/AboutUs/AboutUs'

const AboutUs = () => {

  const location = useLocation()
  const position = location.pathname.split('/')[1]
  console.log(position)

  return (
    <div>
      <AboutUsC/>
    </div>
  )
}

export default AboutUs