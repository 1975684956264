import React from 'react';
import './styles.css';
import { currencyFormat } from '../../../../utils';
import { useTranslation } from 'react-i18next';

function ContainerPriceDinamic({
  price,
  toBuy
}) {

  const [t,i18n] = useTranslation("global")

  return (
    <div className='sectionPriceDinamicContainer'>
      <div className='sectionPriceDinamic'>
        <p className='textBig'>
          <span className='textMediun'>{currencyFormat(0).currency}</span>
          {currencyFormat(price?.fareAmount).value}
        </p>
        <p className='textMediun'>{t("fligth.list_flights.taxes")}:</p>
        <p className='textMediun'>{currencyFormat(price?.taxAmount).total}</p>
        <p className='textSmall'>{t("fligth.list_flights.price_with_tax")}: <span className='textSmallWeight'>{currencyFormat(price?.totalAmount).total}</span>
        </p>
        <div className='btn' onClick={() => toBuy(false)}>
          <p className='btnText'>{t("fligth.list_flights.buy")}</p>
        </div>
      </div>
    </div>
  )
}

export default ContainerPriceDinamic;
