import React, { memo, useEffect, useState } from "react";
import InputSimple from "../../TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple";
import DateRangePickerComponentSura from "../../SuraFormQuote/DateRangePickerComponentSura/DateRangePickerComponentSura";

import "./Assets/Styles.css"

import { ReactComponent as IconPlane } from "./Assets/svg/IconPlane.svg"
import { ReactComponent as IconCalendar } from "./Assets/svg/IconCalendar.svg";
import { ReactComponent as IconPeople } from "./Assets/svg/IconPeople.svg";
import { ReactComponent as IconAdd } from "../../SuraFormQuote/Assets/svg/IconPlus.svg"
import { ReactComponent as IconMinus } from "../../SuraFormQuote/Assets/svg/IconLess.svg"

const DateRangePickerComponentMemo = memo(DateRangePickerComponentSura);

const SuraEditFormQuote = ()=> {
    //Data del viaje
    const [ formEditQuote, setFormEditQuote ] = useState(false);
    const [ btnSelected, setBtnSelected ] = useState(false);
    const [ btnSelectedAerialLand, setBtnSelectedAerialLand ] = useState(false);
    const [dateEnd, setDateEnd] = useState("");
    const [dateStart, setDateStart] = useState("");
    const [validate, setValidate] = useState("");
    const [passengerCount, setPassengerCount] = useState(1);

    //Validación de datos
    const validateData = () => {
        if(!dateStart || dateEnd){
            setValidate("date"); 
            return false;
        }
        return true;
    }

    //Efecto para deseleccionar el botón de tipo de viaje
    useEffect(() => {
        if(btnSelected){
            setBtnSelectedAerialLand(false)
        }
    }, [btnSelected]);

    return(
        <div>
            <div className="container-edit-form-quote">
                <div className="detail-form-sura">
                <IconPlane/>
                    <p>Centroamérica Y Caribe - Colombia</p>
                </div>
                <p>|</p>
                <div className="detail-form-sura">
                    <IconCalendar/>
                    <p>17 feb. 2024 - 22 feb. 2024</p>
                </div>
                <p>|</p>
                <div className="detail-form-sura">
                    <IconPeople/>
                    <p>Viajeros: 1</p>
                </div>
                <div>
                    <button onClick={() => setFormEditQuote(!formEditQuote)}>MODIFICAR FECHAS</button>
                </div>
            </div>
            <div style={formEditQuote ? {display: "block"}: {display: "none"}} className="container-patter-edit-quote">
                <div className="container-form-complet-edit-sura">
                    <div style={{ marginTop: "15px" }} className="container-btn-n-i-l-a">
                        <div className="btn-national-international-sura">
                            <button className={btnSelected ? "btn-left-sura": "btn-left-sura btn-selected"} onClick={() => setBtnSelected(false)}>Nacional</button>
                            <button className={btnSelected ? "btn-right-sura btn-selected": "btn-right-sura"} onClick={() => setBtnSelected(true)}>Internacional</button>
                        </div>
                        <div style={{ marginTop: "30px" }} className="btn-land-aerial">
                        <button 
                            className={
                                btnSelectedAerialLand 
                                ? "btn-left-sura"
                                : "btn-left-sura btn-selected"
                            } 
                            onClick={
                                () => setBtnSelectedAerialLand(false)
                            } style={
                                !btnSelected ? null 
                                : {
                                    width: "100%", 
                                    borderRadius: "8px"
                                }
                                }>Aéreo</button>
                            {
                                !btnSelected 
                                ?<button 
                                    className={ 
                                        btnSelectedAerialLand 
                                        ? "btn-right-sura btn-selected"
                                        : "btn-right-sura" 
                                    } 
                                    onClick={
                                        () => setBtnSelectedAerialLand(true)
                                    }>
                                    Terrestre
                                </button>
                                :null
                            }
                        </div>
                    </div>
                    <div>
                        <InputSimple label="Origen"/>
                            <DateRangePickerComponentMemo 
                                setDateStart={setDateStart} 
                                setDateEnd={setDateEnd} 
                                setValidate={setValidate} 
                                validate={validate}
                            />
                    </div>
                    <div>
                        <InputSimple label="Destino"/>
                        <div style={{ marginTop: "30px" }} className="container-btns-input btn-add-less-sura">
                            <div>
                                <button onClick={() => setPassengerCount(passengerCount > 1 ? passengerCount - 1: passengerCount - 0 )}>
                                    <IconMinus/>
                                </button>
                            </div>
                            <div>
                            <input type="number" value={passengerCount} onChange={(e) => setPassengerCount(e.target.value)} />
                            </div>
                            <div>
                                <button onClick={() => setPassengerCount(passengerCount < 10 ? passengerCount + 1: passengerCount + 0 )}>
                                    <IconAdd />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-quote-again-sura">
                    <button onClick={validateData} >Cotizar</button>
                </div>
            </div>
        </div>
    )
}

export default SuraEditFormQuote