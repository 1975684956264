import { useState, useEffect } from 'react'
import Find from '../../ui/views/share/components/Find/Find'
import TourMain from '../../ui/views/tours/TourMain/TourMain'
import ToursHeader from '../../ui/views/tours/ToursHeader/ToursHeader'
import CarouselSevices from '../../ui/views/share/components/CarouselServices/CarouselSevices'
import './Assets/styles.css'
import TourResultSearch from '../../ui/views/tours/TourResultSearch/TourResultSearch'
import useTours from '../../ui/viewModels/tours/Tours'
import AnyQuestions from '../../ui/views/home/components/anyQuestion'
import { useTranslation } from "react-i18next"
import { get, keys } from '../../ui/localStorage';


function Tours() {
  const [t] = useTranslation("global");
  const [isShowingFind, setIsShowingFind] = useState(false)
  const {
    loading,
    findTours,
    data,
    error,
    place,
    setPlace
  } = useTours()

  async function isWidgetLS() {
    try {
      const response = await get(keys?.isWidget)
      setIsShowingFind(response?.enable)
    } catch (error) {
      console.log("Error in isWidgetLS(): ", error)
    }
  }

  useEffect(() => {
    isWidgetLS();
  }, [])

  return (
    <div>
      {!isShowingFind && <Find /> }
      <CarouselSevices current={t("carousel_services.tours")} />
      <ToursHeader
        findTours={findTours}
        place={place}
        setPlace={setPlace}
        error={error}
      />

      <TourResultSearch
        loading={loading}
        data={data}
      />
      <div className='tours_main'>

        {/* {
          data == false &&
          <TourAllCard data={dataAlways} loading={loading} />
        }

        <TourPaginado
          data={infoData}
          identificador={data == false ? 'DA' : 'DO'}
          prev={prevPag}
          next={nextPag}
        /> */}

        <TourMain />

        <AnyQuestions title={t('browse_home.have_doubt')} />
      </div>

    </div>
  )
}

export default Tours