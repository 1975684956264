import useJoinScreen from "../../../viewModels/videoSdk/joinScreen.viewModel";
import ActivityIndicator from '../../share/components/activityIndicator';

function JoinScreen(props) {

  const {
    code,
    setCode,
    createRoom,
    disableButton,
  } = useJoinScreen(props)

  return (
    <div className='videsdk joinScreen'>
      <div className='form'>
        <div className='card'>
          <input
            type="text"
            placeholder="Enter Meeting Id"
            value={`${code}`}
            onChange={(e) => {
              setCode(e?.target?.value);
            }}
          />
          <div onClick={() => createRoom(false)} className={`btn ${disableButton ? "joinScreenDisableButtonOn" : "joinScreenDisableButtonOff"}`}>
            <p className='btnText'>
              Join
            </p>
          </div>
          <p className="onlyText">or</p>
          <div onClick={() => createRoom(true)} className='btn'>
            {props.creatingRoom ?
              <ActivityIndicator
                size={22}
                colorFill={'#FFFFFF'}
              />
              :
              <p className='btnText'>
                Create Meeting
              </p>
            }
          </div>
        </div>
      </div>
      <div className='info'>
        <img src={'https://forjarsalud.com.co/wp-content/uploads/2022/03/close-up-woman-videocalling-employee-2048x1365.jpg'} />
      </div>
    </div>
  );
}

export default JoinScreen;
