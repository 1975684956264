import './datetime.css';
import DateTimeDisplay from './DateTimeDisplay';
import useCountDown from '../../../../viewModels/home/useCountDown';
import video from '../../../../assets/videos/comingSoon.mp4'
import { useRef, useState } from 'react';


const ExpiredNotice = () => {
  return (
    <div className="show-counter">
      <video autoPlay loop muted
      >
          <source src={video} type="video/mp4" />
      </video>
      <span className='cooming-soon'>Bienvenidos a la nueva era del turismo en salud</span>
      <p className='congratulations-lanz-p'>Disfruta de una Experiencia Inolvidable</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  
  return (
    <div className="show-counter">
      <video autoPlay loop muted
      >
          <source src={video} type="video/mp4" />
      </video>
      <h1 className='cooming-soon'>COMMING SOON</h1>
      <p className='cooming-soon-p'>Embrace the Future of Medicals Tourism.
        <br /> Wellezy: Where Health Meets Tech</p>
      <a
        className="countdown-link"
        href='/'
      >
        <DateTimeDisplay isDanger={days <= 3} type="Days" value={days} />
        <p>:</p>
        <DateTimeDisplay isDanger={false} type="Hours" value={hours} />
        <p>:</p>
        <DateTimeDisplay isDanger={false} type="Mins" value={minutes} />
        <p>:</p>
        <DateTimeDisplay isDanger={false} type="Seconds" value={seconds} />
      </a>
    </div>
    
  );
};

const CountDownTimer = ({ targetDate,setLanzamiento,lanzamiento }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);
  console.log(days + hours + minutes + seconds, 'count')
  if (days + hours + minutes + seconds <= 0) {
    setTimeout(() => {
        setLanzamiento(true)
    }, 5000);
    
    return <ExpiredNotice />;
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
  }
};

export default CountDownTimer;