import React, { useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from '@mui/material'
import img1 from "../../../assets/images/news.png"
import img2 from "../../../assets/images/news.png"
import img3 from "../../../assets/images/news.png"
import img4 from "../../../assets/images/news.png"

const NewsTourism = () => {

    const constraintsRef = useRef(null);
    const MotionConstraints = styled(motion.div)`overflow-x: hidden;`;
    const MotionBox = styled(motion.div)`width: 100vw;`;

    const imgs = [
        {
            img: img1,
            hour:"MEDELLIN - 11:46 AM",
            text: "Convenio Wellezy y Finanmed, la primera financiera para la aplicación que busca impulsar el turismo en saud en Colombia..."
        },
        {
            img: img2,
            hour:"MEDELLIN - 11:46 AM",
            text: "Convenio Wellezy y Finanmed, la primera financiera para la aplicación que busca impulsar el turismo en saud en Colombia..."
        },
        {
            img: img3,
            hour:"MEDELLIN - 11:46 AM",
            text: "Convenio Wellezy y Finanmed, la primera financiera para la aplicación que busca impulsar el turismo en saud en Colombia..."
        },
        {
            img: img4,
            hour:"MEDELLIN - 11:46 AM",
            text: "Convenio Wellezy y Finanmed, la primera financiera para la aplicación que busca impulsar el turismo en saud en Colombia..."
        }
    ]


  return (
    <div className='content-best-experiences'>
    <h2>Ultimas Noticias</h2>
    <div className='header-experiences'>
        <p>Conoce los lugares mas destacados y más seleccionados para procedimientos médicos</p>
        <button>
            Ver todas las Noticias
        </button>
    </div>
    <section >
        <MotionConstraints className='slider_container' ref={constraintsRef}>
            <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}>
                {
                    imgs.map((item, key) => {
                        return (
                            <motion.div className='div_homeTurism' key={key}>
                                <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                    <div className='card'>
                                        <div className='content-img-best-experience'>
                                            <img src={item.img} alt="" />
                                        </div>
                                        <h3>{item.hour}</h3>
                                        <p styles={{color:"#999999",marginTop:"5px",fontSize:"12px"}}>{item.text}</p>
                                    </div>
                                </Grid>
                            </motion.div>
                        )
                    })
                }

            </MotionBox>
        </MotionConstraints>


    </section>

</div>
  )
}

export default NewsTourism