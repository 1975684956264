import { useState, useContext } from "react";
import { iconList } from "../../views/share/components/iconSVG";
import { logOut } from "../session/index.js";
import UserContext from "../../../Context/user/userProvider";

const UserCustomer = [
  { value: 1, text: "Mi perfil", icon: iconList.IconProfile },
];

const UserServiceProvider = [
  { value: 1, text: "Mi perfil", icon: iconList.IconProfile },
  { value: 2, text: "Mis movimientos", icon: iconList.IconWalletOutLine },
  { value: 3, text: "Mis favoritos", icon: iconList.IconHeartOutLine },
  { value: 4, text: "Citas", icon: iconList.IconCalendarOutLine2 },
  { value: 5, text: "Mis pedidos", icon: iconList.IconOrders },
  { value: 6, text: "Mis viajes", icon: iconList.IconPlane },
  { value: 7, text: "Cupones", icon: iconList.IconCoupon },
];

const UserProfessional = [
  { value: 1, text: "Mi perfil", icon: iconList.IconProfile },
  { value: 2, text: "Mi perfil comercial", icon: iconList.IconDealDone },
  { value: 3, text: "Mis movimientos", icon: iconList.IconWalletOutLine },
  { value: 4, text: "Tu agenda", icon: iconList.IconCalendarOutLine2 },
  { value: 5, text: "Solicitud de citas", icon: iconList.IconCalendarOutLine2 },
  {
    value: 6,
    text: "Tele consulta",
    icon: iconList.IconTeleconsultationsOutLine,
  },
  { value: 7, text: "Créditos Finanmed", icon: iconList.IconDatafono },
  { value: 8, text: "Seguros y blindaje", icon: iconList.IconInsurance },
  {
    value: 9,
    text: "Tienda (insumos y más)",
    icon: iconList.IconShopSuppliesAndMore,
  },
  { value: 10, text: "Mis pedidos", icon: iconList.IconOrders },
  { value: 11, text: "Mis viajes", icon: iconList.IconPlane },
  { value: 12, text: "Mis favoritos", icon: iconList.IconHeartOutLine },
  { value: 13, text: "Cupones", icon: iconList.IconCoupon },
];

function useUserProfile() {
  const [optionActive, setOptionActive] = useState(1);
  const [notificationsSelecteds, setNotificationsSelecteds] = useState([]);
  const { userDetail, userToken } = useContext(UserContext);
  const [codeTeleconsutation, setCodeTeleconsutation] = useState("");

  const userData = {
    notifications: [
      {
        id: 0,
        name: "Andres",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        date: "11 may - 10:35 pm",
        isReaded: false,
        type: "",
      },
      {
        id: 1,
        name: "Falta de pago",
        description: "safdfasfdsafasgfsa",
        date: "11 may - 10:35 pm",
        isReaded: true,
        type: "",
      },
      {
        id: 2,
        name: "Alguien ha visto tu perfil",
        description: "safdfasfdsafasgfsa",
        date: "11 may - 10:35 pm",
        isReaded: true,
        type: "",
      },
      {
        id: 3,
        name: "Promoción",
        description: "safdfasfdsafasgfsa",
        date: "11 may - 10:35 pm",
        isReaded: true,
        type: "",
      },

      {
        id: 4,
        name: "Andres",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        date: "11 may - 10:35 pm",
        isReaded: false,
        type: "",
      },
    ],
    config: {},
    paymentMethods: [],
    address: [],
  };

  function isSelectedThisNotification(id) {
    return !!(notificationsSelecteds.length > 0
      ? notificationsSelecteds.find((element) => element.id === id)
      : false);
  }

  function selectOrDeselectNotification(elementId) {
    const selected = isSelectedThisNotification(elementId);
    if (selected) {
      const newArray = notificationsSelecteds.filter(
        (elemente) => elemente.id !== elementId
      );
      setNotificationsSelecteds(newArray);
    } else {
      const newArray = [...notificationsSelecteds, { id: elementId }];
      setNotificationsSelecteds(newArray);
    }
  }

  const [configProfile, setConfigProfile] = useState([
    {
      showInProfile: true,
      id: "sms_notification",
      name: "Notificaciones de citas por SMS",
      description:
        "Recibirás mensajes SMS según la configuración del remitente",
      isActive: false,
    },
    {
      showInProfile: true,
      id: "email_marketing",
      name: "Notificaciones de marketing por email",
      description: "Recibe ofertas y novedades por email",
      isActive: true,
    },
    {
      showInProfile: true,
      id: "sms_marketing",
      name: "Notificaciones de marketing por SMS",
      description:
        "Si has enviando el SMS STOP y no sigues suscrito, responde START para volver a suscribirte",
      isActive: false,
    },
  ]);

  const switchOptionConfig = (id, value) => {
    setConfigProfile((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, isActive: value };
        }
        return item;
      });
    });
  };

  // const optionsList = userData?.profile?.type === 'professional' ? UserProfessional : userData?.profile?.type === 'serviceProvider' ? UserServiceProvider : UserCustomer;

  const optionsList = UserProfessional;

  const newNotifications = userData?.notifications
    ? userData?.notifications.filter((obj) => obj?.isReaded === false).length
    : 0;

  async function closeSession() {
    try {
      await logOut();
    } catch (error) {
      console.error("Error in closeSession(),", error);
    }
  }

  function goToTeleConsultation(code = "") {
    console.log("goToTeleConsultation, code: ", code);
    if (code) {
      setCodeTeleconsutation(code);
      setOptionActive(6);
    }
  }

  return {
    userDetail,
    notifications: userData.notifications,
    notificationsSelecteds,
    optionsList,
    optionActive,
    setOptionActive,
    selectOrDeselectNotification,
    isSelectedThisNotification,
    newNotifications,
    configProfile,
    switchOptionConfig,
    closeSession,
    goToTeleConsultation,
    codeTeleconsutation,
  };
}

export default useUserProfile;
