import './styles.css';
import React, { useRef, useState } from 'react';

const Group = ({ label = '', text = '' }) => {
  return (
    <div className='cardInfoGroup'>
      <div className='cardInfoGroupLabel'>
        <p className='cardInfoGroupLabelText'>{label}</p>
      </div>
      <div className='cardInfoGroupValue'>
        <p className='cardInfoGroupValueText'>{text}</p>
      </div>
    </div>
  )
}

function CardInfo({ data, close, goToTeleConsultation }) {

  const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  const textoACopiar = data?.meet_id || '';
  const textoRef = useRef(null);
  
  const date = new Date(data?.date);
  const dayOfWeekIndex = date.getDay();
  const monthIndex = date.getMonth();

  const dateInfo = {
    dayDateNumber: data?.date.split('-')[2],
    dayDateName: daysOfWeek[dayOfWeekIndex],
    dayDateMonthName: `de ${months[monthIndex]}`,
    dayDateHour:  data?.hour,
    dayDateHourMeridiem: data?.hour.split(':')[0] < 12 ? 'a.m.' : 'p.m.'
  }

const copyLink = () => {
  if (textoRef.current) {
    navigator.clipboard.writeText(`https://wellezy.com/virtual-meeting-rooms/wellezy/${textoACopiar}` )
      .then(() => {
        alert('Texto copiado al portapapeles');
      })
      .catch((error) => {
        console.error('Error al copiar el texto:', error);
      });
  }
};

  const copyTextCode = () => {
    if (textoRef.current) {
      navigator.clipboard.writeText(textoACopiar)
        .then(() => {
          alert('Texto copiado al portapapeles');
        })
        .catch((error) => {
          console.error('Error al copiar el texto:', error);
        });
    }
  };

  const copyTextCodeAndGoToTeleConsultation = () => {
    const body = document.querySelector('body');
    body.style.overflow = '';
    copyTextCode();
    close()
    goToTeleConsultation(data?.meet_id);

 
  }



  return (
    <div className="cardInfo">
      <div className='cardInfoSide cardInfoSideLeft'>
        {/* <div className='avatarContainer'> */}
        {/* <img className='avatarContainerImg' alt='foto_profile' src={dateInfo?.avatar} /> */}
        {/* </div> */}
      </div>

      <div className='cardInfoSide cardInfoSideRigth'>
        <Group label={'Paciente'} text={data?.name} />
        <div className='CardInfoRow'>
          <Group label={'Cedula'} text={data?.identification} />
          <Group label={'Celular'} text={data?.tel} />
        </div>

        <Group label={'Correo'} text={data?.email} />
        <Group label={'Tratamiento'} text={data?.procedure} />

        {data?.meet_id &&
          <div className='cardInfoCodeWrapper' onClick={copyTextCode}>
            <p className='cardInfoCodeWrapperLabel'>Código TELESALUD</p>
            <p className='cardInfoCodeWrapperText' ref={textoRef} >{data?.meet_id}</p>
          </div>
        }

        <div className='CardInfoRow'>
          <div onClick={copyTextCode} className='btnLinkCopy'>
            <p className='btnLinkCopyText'>copiar codigo</p>
          </div>

          <div onClick={copyLink} className='btnLinkCopy'>
            <p className='btnLinkCopyText'>copiar enlace</p>
          </div>

          <div onClick={copyTextCodeAndGoToTeleConsultation} className='btnLinkCopy'>
            <p className='btnLinkCopyText'>iniciar tele consulta</p>
          </div>
        </div>

       


        {/* <div className='CardInfoRow'>
          <div className='cardInfoFooterBtn'>
            TELESALUD.png
          </div>
          <div className='cardInfoFooterBtn'>
            <p className='cardInfoFooterBtnText'>
              Reagendar cita
            </p>
          </div>
        </div> */}

      </div>
      <div className='cardInfoBackBar'>
        <div className='infoContainer'>
          <p className='infoContainerDay'>{dateInfo?.dayDateName}</p>
          <p className='infoContainerDayNumber'>{dateInfo?.dayDateNumber}</p>
          <p className='infoContainerMouth'>{dateInfo?.dayDateMonthName}</p>
          <div className='infoContainerHourWrapper'>
            <p className='infoContainerHour'>{dateInfo?.dayDateHour} {dateInfo?.dayDateHourMeridiem}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardInfo;
