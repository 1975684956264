import React, { useEffect, useState } from "react";
import InputSimple from "../../../../../Components/TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple";
import InputDocument from "../../../../../Components/TerrawindPaymentCard/InputDocument/InputDocument";
import axios from "axios";

import "./Assets/styles.css"

import { ReactComponent as IconAdd } from "./Assets/svg/IconPlus.svg"
import { ReactComponent as IconMinus } from "./Assets/svg/IconLess.svg"
import { ReactComponent as IconCheck } from "../ModalForm/Assets/svg/Check.svg"
import { useNavigate } from "react-router-dom";

const ModalPay = ({isModalOpen, packId, closeModal}) => {
    const [names , setNames] = useState("")
    const [email , setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [validate, setValidate] = useState("")
    const [passengerCount, setPassengerCount] = useState(1)
    const [termConditions, setTermConditions] = useState(false)
    const [observatiosn, setObservatiosn] = useState("")

    // Extención de navegaión

    const navigate = useNavigate();

    const handleClickOutside = (event) => {
        setTermConditions(false)
        event.stopPropagation()
        closeModal()
    }

    const handleClickedInside = (event) => {
        event.stopPropagation()
    }

    useEffect(() => {
        console.log(termConditions)
    }, [termConditions])

    const handleFormSubmit = () => {
        if(!termConditions){
            setValidate("termConditions")
            return
        }else if(!validateData()){
            return
        }

        const data ={
            "name": names,
            "email": email,
            "tel": phone,
            "city": city,
            "observations": observatiosn,
            "terms": termConditions,
            "passengers": passengerCount,
            "pack_id": 1
        }

        console.log(data)

        axios.post(`${process.env.REACT_APP_URL_API_AIOP_STG}api/pack/form`, data)
        .then((response) => {
            console.log(response)
            if(response.status === 200){

                const idResponse = response.data.id

                const dataPassengerPage = {
                    passengerCount,
                    packId,
                    idResponse
                }
        
                const dataPassengerEncode = encodeURIComponent(JSON.stringify(dataPassengerPage))
                navigate(`/widget/packages/passenger/${dataPassengerEncode}`)
            }
        })
        .catch((error) => {
            console.error(error)
        })
    }

    const validateData = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if(names === "" || names.length < 3 || /\d/.test(names)){
          setValidate("names");
          return false;
        }else if(!emailRegex.test(email) || email.trim() === "" || /[\s]/.test(email)){
          setValidate("email");
          return false;
        }else if(isNaN(phone)){
          setValidate("phoneText");
          return false;
        }else if(phone === "" || phone.length < 8){
          setValidate("phone");
          return false
        }else if(city === ""){
            setValidate("city");
            return false
        }else{
          setValidate("")
        }
    
        return true;
      }
    
      useEffect(() => {
        validateData();
      }, [names, phone, email, city]);

      useEffect(() => {
        if(termConditions){
            setValidate("")
        }
      }, [termConditions])

    if(!isModalOpen){
        return null
    }
    return (
        <div className="form-package-container" onClick={handleClickOutside}>
        <div className="modal-header-package">
            <p>PAGA TU PAQUETE AHORA</p>
        </div>
        <div className="modal-form-package" onClick={handleClickedInside}>
            <div className="container-form-flex-package">
                <div className={ validate === "names" ? "validate-input-voucher": null}>
                    <InputSimple label="Nombres *" onInputChange={setNames}/>
                    { validate === "names" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
                <div className={ validate === "email" ? "validate-input-voucher": null}>
                    <InputSimple label="Correo *" onInputChange={setEmail}/>
                    { validate === "email" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
            </div>
            <div className="container-form-flex-package">
                <div className={validate === "phone" || validate === "phoneText"? "validate-input-voucher": null}>
                    <InputSimple label="Teléfono *" onInputChange={setPhone}/>
                        { validate === "phone"  || validate === "phoneText"? <p className="span-simple-error">{validate === "phone" ? "El campo es requerido": validate === "phoneText" ? "El campo debe ser numerico": null}</p> : null}
                </div>
                <div className={ validate === "city" ? "validate-input-voucher": null}>
                    <InputSimple label="Ciudad *" onInputChange={setCity}/>
                    { validate === "city" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
            </div>
            <div className="container-form-flex-package">
                <div>
                    <p style={{ marginBottom: "10px" }}>Cantidad de pasajeros</p>
                    <div className="input-mount-passenger">
                        <div>
                            <button onClick={() => passengerCount === 1 ? setPassengerCount(passengerCount - 0) : setPassengerCount(passengerCount - 1)}>
                                <IconMinus/>
                            </button>
                        </div>
                        <div>
                            <input type="number" value={passengerCount}/>
                        </div>
                        <div>
                            <button onClick={() => setPassengerCount(passengerCount + 1)}>
                                <IconAdd />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="observatiosn-textarea">
                    <p style={{ marginBottom: "10px" }}>Observaciones</p>
                    <textarea style={{ border: "1px solid #D9D9D9", borderRadius: "8px", width: "100%", padding: "10px"}} id="" cols="30" rows="3" onChange={(e) => setObservatiosn(e.target.value)}></textarea>
                </div>
            </div>
            <div>
                <div className="input-see-data-passenger">
                    <input type="checkbox" id="termConditions" onClick={() => setTermConditions(!termConditions) }/>
                    <label htmlFor="termConditions" className="term-conditions">{termConditions ? <IconCheck/>: null}</label>
                    <p>Acepto terminos y condiciones *</p>
                </div>
                { validate === "termConditions" ? <p className="span-simple-error">Se debe confirmar los terminso y condiciones</p> : null}
            </div>
            <div className="button-container-package">
                <button onClick={handleFormSubmit}>PAGAR</button>
            </div>
        </div>
    </div>
    )
}

export default ModalPay;