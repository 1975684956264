import React from 'react'
import banner from "../../../assets/pictures/bannerSecureTravel.png"
import "./styles.css"

const BannerInsurance = () => {
  return (
    <div className='content-banner-insurance'>
        <img src={banner} alt="" />
    </div>
  )
}

export default BannerInsurance