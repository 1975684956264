import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Find from '../../share/components/Find/Find'
import CarouselServices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import BannerDetail from './BannerDetail/BannerDetail'
import FirstInfo from './FirtsInfo/FirstInfo'
import InfoDetailDoctor from './InfoDetailDoctor/InfoDetailDoctor'
import GaleryDoctor from './GaleryDoctor/GaleryDoctor'
import MakeAppointment from './makeAppointment/MakeAppointment'
import ModalQuo from './ModalQuo/ModalQuo'
import UseDetailSpecialist from '../../../viewModels/specialist/UseDetailSpecialist'
import WantQuo from './WantQuo/WantQuo'


const DetailSpecialist = () => {
  const { state } = useLocation();
  const navigate = useNavigate()

  const {
    sendInformation,
    name,
    setName,
    email,
    setEmail,
    error,
    succesScreen,
    description,
    setDescription,
    tel,
    setTel,
    subject,
    setSubject,
    setModal,
    modal,
    loadingS
  } = UseDetailSpecialist()

  useEffect(() => {
    const body = document.querySelector('body');
  modal ? body.style.overflow = 'hidden' : body.style.overflow = '';
  }, [modal])


  const goReservation = ()=>{
    navigate(`/Specialist/reservation/${state?.name}`, {state:{
      name:state?.name,
      categories:state?.subCategories,
      urlImage:state?.pathImage,
      stars: state?.infoDoctor?.stars,
      id: state?.id
    }})
  }

  return (
    <div className='contain-detail-specialist'>
      <Find />
      <CarouselServices current={servicesTravel[7].title} />
      <BannerDetail goReservation={goReservation} name={state?.name} specialist={state?.subCategories} urlImage={state?.urlImage} pathImage={state?.pathImage} setModal={setModal} />
      <FirstInfo stars={state?.infoDoctor?.stars} cities={state?.offices} setModal={setModal} />
      <InfoDetailDoctor infoDoctor={state?.infoDoctor?.description} infoSpecialist={state?.infoDoctor?.speciality} offices={state?.offices} urlImage={state?.urlImage} specialist={state?.subCategories} />
      <MakeAppointment address={state?.offices} />
      <GaleryDoctor urlImage={state?.urlImage} images={state?.images} />
      <WantQuo name={state?.name}/>
      {
        modal &&
        <ModalQuo
          setModal={setModal}
          description={description}
          setDescription={setDescription}
          email={email}
          setEmail={setEmail}
          tel={tel}
          setTel={setTel}
          name={name}
          setName={setName}
          subject={subject}
          setSubject={setSubject}
          sendInformation={sendInformation}
          succesScreen={succesScreen}
          error={error}
          id={state?.id}
          loading={loadingS}
        />
      }

    </div>
  )
}

export default DetailSpecialist