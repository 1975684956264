import React, { useState } from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import { useTranslation } from 'react-i18next';
import "./styles.css"

const PersonForm = ({ index, type, setPaxes }) => {
    const [sex, setSex] = useState("")
    const [accordion, setAccordion] = useState(index === 1 && type === "adult" ? true : false)
    const [nom, setNom] = useState('')
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [lastName, setLastName] = useState('')
    const [age, setAge] = useState('')
    const [t] = useTranslation("global")
    const [addHErr, setAddHErr] = useState(false)
    const [checkSuccess, setCheckSuccess] = useState(false)
    const [typeDocument, setTypeDocument] = useState("Tipo Documento")
    const [Document, setDocument] = useState("")
    const [modalTypeDoc, setModalTypeDoc] = useState(false)
    const listTypeDoc = [
        {
            value:"CC",
            field:"Cedula Ciudadania"
        },
        {
            value:"TI",
            field:"Tarjeta Identidad"
        },
        {
            value:"PPN",
            field:"Pasaporte"
        },
        {
            value:"CE",
            field:"Cedula Extranjeria"
        },
        {
            value:"RUT",
            field:"Registro único tributario"
        },
        {
            value:"NIT",
            field:"Número de Identificación Tributaria"
        }
    ]

    const addHuesped = () => {
        if (index === 1 && type === "adult") {
            if (nom === "" || lastName === "" || sex === "" || email === "" || tel === "" || age === "" || Document === "" || typeDocument === "Tipo Documento") {
                setAddHErr(true)
            } else {
                setAddHErr(false)
                setPaxes((prevList) => [...prevList, {
                    sex: sex,
                    name: nom,
                    lastname: lastName,
                    age: age,
                    email: email,
                    tel: tel,
                    age: age,
                    doc:Document,
                    typeDoc:typeDocument
                }])
                setAccordion(false)
                setCheckSuccess(true)
            }

        } else if (type === "child") {
            if (nom === "" || lastName === "" || sex === "" || age === "") {
                setAddHErr(true)
            } else {
                setAddHErr(false)
                setPaxes((prevList) => [...prevList, {
                    sex: sex,
                    name: nom,
                    lastname: lastName,
                    age: age
                }])
                setAccordion(false)
                setCheckSuccess(true)
            }

        } else {
            if (nom === "" || lastName === "" || sex === "" || age === "") {
                setAddHErr(true)
            } else {
                setAddHErr(false)
                setPaxes((prevList) => [...prevList, {
                    sex: sex,
                    name: nom,
                    lastname: lastName,
                    age: age
                }])
                setAccordion(false)
                setCheckSuccess(true)
            }
        }
    }

    return (
        <div>
            <section className='accordion-detail-form-hotel' style={{ backgroundColor: `${checkSuccess ? "rgba(49, 115, 49, 0.677)" : ""}` }} onClick={() => { setAccordion(!accordion) }}>
                <div>
                    <h2>{type === "child" ? "Niño" : "Adulto"} {index}</h2>
                    {
                        index === 1 && type === "adult" &&
                        <p>Será responsable de hacer el check-in y el check-out en el alojamiento</p>
                    }

                </div>
                <span className='circle-icon-accordion'>
                    {
                        checkSuccess ?
                            <SvgIcon color={'#ffff'} name={iconList.IconCheckSucces} size={20} />
                            :
                            (
                                accordion ?
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='#ffff' width={20} height={20} ><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" /></svg>
                                    :
                                    <SvgIcon color={'#ffff'} name={iconList.IconArrowDown} size={20} />
                            )

                    }

                </span>

            </section>
            {
                accordion ?
                    <div className='form-persons-pay-hotel'>
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Nombres</label>
                            <input type="text" onChange={(e) => { setNom(e.target.value) }} placeholder={index === 1 && type === "adult" ? "Nombre del tomador" : "Nombre"} />
                        </div>
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Apellidos</label>
                            <input type="text" onChange={(e) => { setLastName(e.target.value) }} placeholder={index === 1 && type === "adult" ? "Apellido del tomador" : "Apellido"} />
                        </div>
                        {
                            index === 1 && type === "adult" &&
                            <div className='camp-form-person-hotel-doc'>
                                <label htmlFor="">Documento</label>
                                <div className='contain-inputs-selecteds'>
                                    <div className='select-type-doc'>
                                        <div className='select-onclick-modal-type' onClick={()=>{
                                            setModalTypeDoc(true)
                                        }}>
                                            <span>{typeDocument}</span>
                                            <SvgIcon color={"rgb(99, 99, 99)"} name={iconList.IconArrowDown} size={15} />
                                        </div>
                                        {
                                            modalTypeDoc &&
                                            <div className='modal-select-type-doc'>
                                                <ul>
                                                    {
                                                        listTypeDoc.map((item,index)=>{
                                                            return(
                                                                <li key={index} onClick={()=>{
                                                                    setTypeDocument(item?.value)
                                                                    setModalTypeDoc(false)
                                                                }}>{item?.field}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <input type="text" onChange={(e) => { setDocument(e.target.value) }} placeholder={index === 1 && type === "adult" ? "Documento del Tomador" : ""} />
                                </div>

                            </div>
                        }

                        {
                            index === 1 && type === "adult" &&
                            <>
                                <div className='camp-form-person-hotel'>
                                    <label htmlFor="">Telefono</label>
                                    <input type="text" onChange={(e) => { setTel(e.target.value) }} placeholder={index === 1 ? "Telefono del tomador" : "Telefono"} />
                                </div>
                                <div className='camp-form-person-hotel'>
                                    <label htmlFor="">Email</label>
                                    <input type="text" onChange={(e) => { setEmail(e.target.value) }} placeholder={index === 1 ? "Email del tomador" : "Email"} />
                                </div>
                            </>
                        }
                        <div className='camp-form-person-hotel'>
                            <label htmlFor="">Edad</label>
                            <input type="text" onChange={(e) => { setAge(e.target.value) }} placeholder={index === 1 && type === "adult" ? "Edad del tomador" : "Edad"} />
                        </div>
                        <div className='camp-passenger-form'>
                            <label htmlFor="" style={{ color: "#004274" }}>Sexo</label>
                            <div className='contain-sex-selected'>
                                <section className='sex-selected-pay' onClick={() => {
                                    setSex("F")

                                }}>

                                    <div>
                                        <span style={{ backgroundColor: `${sex === "F" ? "#004274" : ""}` }}>

                                        </span>
                                    </div>

                                    <p>
                                        Femenino
                                    </p>
                                </section>
                                <section className='sex-selected-pay' onClick={() => {
                                    setSex("M")
                                }}>
                                    <div>
                                        <span style={{ backgroundColor: `${sex === "M" ? "#004274" : ""}` }}>

                                        </span>
                                    </div>

                                    <p>
                                        Masculino
                                    </p>
                                </section>
                            </div>
                        </div>
                        {
                            addHErr &&
                            <div className='err-hotel-form-person'>
                                <p>Para poder guardar los datos debes llenar todos los datos</p>
                            </div>
                        }
                        <button className='btn-save-info-passenger' onClick={addHuesped}>Guardar</button>

                    </div>
                    :

                    ""
            }
        </div>
    )
}

export default PersonForm