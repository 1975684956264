import React, { useRef } from 'react'
import "./styles.css"
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from '@mui/material'
import img1 from "../../../assets/images/bestExperience1.png"
import img2 from "../../../assets/images/bestExperience2.png"
import img3 from "../../../assets/images/bestExperience3.png"
import img4 from "../../../assets/images/bestExperience4.png"

const BestExperiences = () => {
    const constraintsRef = useRef(null);
    const MotionConstraints = styled(motion.div)`overflow-x: hidden;`;
    const MotionBox = styled(motion.div)`width: 100vw;`;

    const imgs = [
        {
            img: img1,
            text: "IMPLANTES MAMARIOS EN MEDELLÍN TODO INCLUIDO"
        },
        {
            img: img2,
            text: "DISEÑO DE SONRISA EN BOGOTA TODO INCLUIDO"
        },
        {
            img: img3,
            text: "TRATAMIENTO DE FERTILIDAD FIV EN COLOMBIA TODO INCLUIDO"
        },
        {
            img: img4,
            text: "CIRUGÍA DE PERDIDAD DE PESO EN ARGENTINA"
        }
    ]

    return (
        <div className='content-best-experiences'>
            <h2>Mejores experiencias</h2>
            <div className='header-experiences'>
                <p>Conoce los lugares mas destacados y más seleccionados para procedimientos médicos</p>
                <button>
                    Ver todas los lugares destacados
                </button>
            </div>
            <section >
                <MotionConstraints className='slider_container' ref={constraintsRef}>
                    <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}>
                        {
                            imgs.map((item, key) => {
                                return (
                                    <motion.div className='div_homeTurism' key={key}>
                                        <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                            <div className='card'>
                                                <div className='content-img-best-experience'>
                                                    <img src={item.img} alt="" />
                                                    <div className='modal-top'>
                                                        <span>
                                                        $ 3500USD
                                                        </span>
                                                    </div>
                                                </div>
                                                <h3>{item.text}</h3>
                                            </div>
                                        </Grid>
                                    </motion.div>
                                )
                            })
                        }

                    </MotionBox>
                </MotionConstraints>


            </section>

        </div>
    )
}

export default BestExperiences