import axios from "axios";
import { useState } from "react";

const UseDetailSpecialist = () => {
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [succesScreen, setSuccesScreen] = useState(false);
  const [modal, setModal] = useState(false);
  const [loadingS, setLoadingS] = useState(false);

  const sendInformation = (e, id) => {
    e.preventDefault();

    if (
      name === "" ||
      tel === "" ||
      email === "" ||
      description === "" ||
      subject === ""
    ) {
      setError(true);
    } else {
      setError(false);
      setLoadingS(true);
      const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/appointment/doctor`
      axios
        .post(url, {
          name: name,
          tel: tel,
          email: email,
          description: description,
          subject: subject,
          user_id: id,
        })
        .then((res) => {
          setSuccesScreen(true);
          setLoadingS(false);
          setTimeout(() => {
            setModal(false);
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          setLoadingS(false);
        });
    }
  };
  return {
    sendInformation,
    name,
    setName,
    email,
    setEmail,
    error,
    succesScreen,
    description,
    setDescription,
    tel,
    setTel,
    subject,
    setSubject,
    setModal,
    modal,
    loadingS,
  };
};

export default UseDetailSpecialist;
