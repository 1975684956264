import React, { useEffect, useState } from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../../share/components/iconSVG/index'
import { currencyFormat } from '../../../../utils'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CircularProgress } from '@mui/material'

import { widgetsList } from '../../../../../infra/constants/app'

import { useTranslation } from 'react-i18next'
import Loading from '../../../share/components/loading'



const CardHotelResult = ({ data, dataRoom, detailReservation, persons,fen,fsa,rooms,country_client }) => {

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [t] = useTranslation("global")
  const [dataHot, setDataHot] = useState([])
  const [loadingViewRoom, setLoadingViewRoom] = useState(false)
  const stars = [];
  const [informationDetail, setInformationDetail] = useState([])


  // Crear un objeto URL
  const match = pathname.match(/idHotel=(\d+)/);

  // Verificar si hubo una coincidencia y obtener el valor de idHotel
  const idHotel = match ? match[1] : "";


  for (let i = 0; i < Number(data?.cat); i++) {
    stars.push(<SvgIcon key={i} name={iconList.IconStar} color={'#FFD902'} size={20} />);
  }

  function formatterText(frase) {
    // Dividir la frase en palabras individuales
    let palabras = frase?.toLowerCase()?.split(' ');

    // Capitalizar la primera letra de cada palabra
    for (let i = 0; i < palabras?.length; i++) {
      palabras[i] = palabras[i]?.charAt(0)?.toUpperCase() + palabras[i]?.slice(1);
    }

    // Unir las palabras en una sola frase
    let resultado = palabras?.join(' ');

    return resultado;
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   // getInformation()
  // }, [])

  const findRoomPrice = data?.res?.pax[0]?.hab?.reduce((minObjeto, objeto) => {
    if (objeto?.reg[0]["@attributes"]?.prr < minObjeto?.reg[0]["@attributes"]?.prr) {
      return objeto;
    } else {
      return minObjeto;
    }
  });

  function sumPorcentage(num) {
    const numero = Number(num)
    const porcentaje = numero * 0.35;
    const resultado = numero + porcentaje;
    const resultadoRedondeado = Math.round(resultado * 100) / 100;
    return resultadoRedondeado;

  }

  // const getInformation = () => {
  //   setLoadingViewRoom(true)
  //   if (idHotel === "") {
  //     console.log("id vacio")
  //   } else {
  //     axios.get("https://travelflight.pdtcomunicaciones.com/api/hotel-do/hotel-information", {
  //       params: {
  //         "hotel": data.Id
  //       }
  //     })
  //       .then(res => {
  //         console.log(res.data)
  //         setLoadingViewRoom(false)
  //         setInformationDetail(res.data.Hotel)

  //       })
  //       .catch(err => {
  //         console.log(err)
  //         setLoadingViewRoom(false)
  //       })
  //   }
  // }

  // const dataRequest = {
  //   "arrival": dataRoom?.arrival,
  //   "departure": dataRoom?.departure,
  //   "country": "CO",
  //   "countryClient": "CO",
  //   "hotels":
  //     [

  //       {

  //         "code": informationDetail?.Id

  //       }


  //     ],
  //   "rooms": dataRoom?.rooms
  // }

  const detailHotelView = ()=>{
    setLoadingViewRoom(true)
    axios.post('https://travelflight.pdtcomunicaciones.com/api/restel/information',{
        hotel: data?.cod
    })
    .then(res=>{
        console.log(res.data?.hotel.parametros)
        setDataHot(res.data?.hotel.parametros.hotel) 
        setLoadingViewRoom(false)
        const isWidget = widgetsList.find((w) => w.path === window.location.pathname);
        let url = ""
          if(isWidget){
            url = `/widget/hotels/`
          }else if(pathname.includes("packages")){
            url = "/packages/hotel/"
          }else{
            url = "/hotel/"
          }
  
        navigate(`${url}${data.nom}`, {
          state: {
            dataHab: data,
            dataHot: res?.data?.hotel?.parametros?.hotel,
            persons: persons,
            fen: fen,
            fsa: fsa,
            rooms: rooms,
            country_client: country_client
          }
        })
    })
    .catch(err=>{
      console.log(err)
    })
  
  }

  return (
    <div className='card-result-hotel'>
      <div className='contain-porcentage-desc'>
        <span>
          -35%
        </span>
      </div>
      <div className='contain-img-hotel-result'>
        <img className='img-hotel-result' src={data?.foto} alt="" />
        <div className="service-room-include">
          <SvgIcon name={iconList.IconFood} color={'#ffffff'} size={30} />
          <span>{findRoomPrice.reg[0]["@attributes"].cod}</span>
        </div>
      </div>

      <div className='contain-info-result-hotels'>
        <section className='contain-info-hotel-card'>

          <h2>{data?.nom}</h2>
          <p className='dir-hotel-card'>{formatterText(data?.dir)}</p>
          <div className='star-hotel-card'>
            <span>
              {Number(data?.cat).toFixed(1)}
            </span>
            <div>
              {stars}
            </div>
          </div>
          <div className='card-hotel-habitacion'>
            <p >
              <SvgIcon name={iconList.IconRestRoom} color={'#004274'} size={30} />
              {findRoomPrice["@attributes"].desc}
            </p>
          </div>
        </section>
        <section className='contain-price-hotel-card'>
          <div>
            <p className='price-normal-hotel-card'>
              {sumPorcentage(findRoomPrice.reg[0]["@attributes"].prr)}
            </p>
            <p className='price-descount-hotel-card'>
              {currencyFormat(findRoomPrice.reg[0]["@attributes"].prr).total}
            </p>
            <p className='taza-impuestos'>
              {t("hotel.include_taxes")}
            </p>
            <p className='what-include'>{t("hotel.what_include_price")}</p>
          </div>
          <div className='button-detail-habitaciones'>
            <button onClick={detailHotelView} style={{ PointerEvent: loadingViewRoom ? 'none' : 'auto', opacity: loadingViewRoom ? 0.5 : 1 }}>
              {
                loadingViewRoom === true ?
                  <CircularProgress size={25} />
                  :
                  t("hotel.see_habitation")
              }

            </button>
          </div>
        </section>
      </div>
    </div>
    // <div className='card-result-hotel'>
    //   {
    //     loadingViewRoom ?
    //       <Loading size={100} />

    //       :
    //       <>
    //         <div className='contain-porcentage-desc'>
    //           <span>
    //             -35%
    //           </span>
    //         </div>
    //         <div className='contain-img-hotel-result'>
    //           <img className='img-hotel-result' src={informationDetail?.ThumbnailUrl} alt="" />
    //           <div className="service-room-include">
    //             <SvgIcon name={iconList.IconFood} color={'#ffffff'} size={30} />
    //             <span>{findRoomPrice?.reg[0]["@attributes"]?.cod}</span>
    //           </div>
    //         </div>

    //         <div className='contain-info-result-hotels'>
    //           <section className='contain-info-hotel-card'>

    //             <h2>{data?.Name}</h2>
    //             <p className='dir-hotel-card'>{formatterText(informationDetail?.Address?.Street)}</p>
    //             <div className='star-hotel-card'>
    //               <ul>
    //                 <li><span>Type:</span> {informationDetail?.AccommodationType}</li>
    //                 <li><span>Check-in:</span> {informationDetail?.CheckIn}</li>
    //                 <li><span>Check-out:</span> {informationDetail?.CheckOut}</li>
    //               </ul>
    //             </div>
    //             <div className='card-hotel-habitacion'>
    //               <p >
    //                 <SvgIcon name={iconList.IconRestRoom} color={'#004274'} size={30} />
    //                 {informationDetail?.TotalRooms} Total rooms
    //               </p>
    //             </div>
    //           </section>
    //           <section className='contain-price-hotel-card'>
    //             <div>
    //               <ul>
    //                 <li className='ellisisp-text'><span>Country:</span> {informationDetail?.Address?.Country?.Name}</li>
    //                 <li className='ellisisp-text'><span>City:</span> {informationDetail?.Address?.City?.Name}</li>
    //                 <li><span>Adults-only:</span> {informationDetail?.AdultOnly ? "Yes" : "No"}</li>
    //               </ul>

    //               <p className='short-description-hotel'>{informationDetail?.ShortDescription}</p>
    //             </div>
    //             <div className='button-detail-habitaciones'>
    //               <button onClick={() => {
    //                 detailReservation()
    //               }}>{
    //                   loadingViewRoom ?
    //                     <CircularProgress size={20} />

    //                     :

    //                     t("hotel.see_habitation")
    //                 }
    //               </button>
    //             </div>
    //           </section>
    //         </div>
    //       </>

    //   }

    // </div>

  )
}

export default CardHotelResult