import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const UseBooking = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [age, setAge] = useState("")
    const [tel, setTel] = useState("")
    const [reason, setReason] = useState("")
    const [treatmentTime, setTreatmentTime] = useState("")
    const [budget, setBudget] = useState(false)
    const [otherReviews, setOtherReviews] = useState(false)
    const [department, setDepartment] = useState("")
    const [city, setCity] = useState("")
    const [zone, setZone] = useState("")
    const [procedure, setProcedure] = useState("")
    const [errCamp, setErrCamp] = useState(false)
    const [code, setCode] = useState("+57")
    const [successApointmentState, setSuccessApointmentState] = useState(false)
    const [dataApointment, setDataApointment] = useState()
    const [steps, setSteps] = useState(1)
    const [loading, setLoading] = useState(false)
    const [campFull, setCampFull] = useState(false)
   const navigate = useNavigate()

    const sendInfo = (id)=>{
        const telNew = code.concat(tel)
        const data = {
            "name": name,
            "email":email,
            "description":description,
            "age":age,
            "tel":telNew,
            "reason":reason,//motivo consulta
            "treatment_time":treatmentTime,//tiempo de tratamiento,
            "budget":budget ? 0 : 1, //presupuesto
            "other_reviews":otherReviews,//revisiones otros especialistas
            "department":department,
            "city":city,
            "zone":"n/a",
            "procedure":procedure,//tratamiento a realizar
            "pack_id":Number(id)
          }
        if(name === "" || email === "" || tel === "" || code === ""){
            setErrCamp(true)
            setTimeout(() => {
                setErrCamp(false)
            }, 3000);
        }else{
            setLoading(true)
            const url = process.env.REACT_APP_URL_SEND_INFO_TOURISM_HEALTH
            axios.post(url,data)
            .then(res=>{
                setDataApointment(res.data)
                setSteps(6)
                setSuccessApointmentState(true)
                setLoading(false)
                setTimeout(() => {
                  navigate("/Tourismhealth")
                }, 2000);
            })
            .catch(err=>{
                console.log(err)
            })
        }
    }

    const nextStep = ()=>{
        if(steps != 4){
          if(steps === 1){
            if(reason === "" || description === ""){
              setCampFull(true)
              setTimeout(() => {
                setCampFull(false)
              }, 3000);
            }else{
              setSteps(steps + 1)
            }
          }else if(steps === 2){
            if(budget === "" || treatmentTime === "" || otherReviews === ""){
              setCampFull(true)
              setTimeout(() => {
                setCampFull(false)
              }, 3000);
            }else{
              setSteps(steps + 1)
            }
          }else if(steps === 3){
             if(city === "" || department === "" || age === ""){
              setCampFull(true)
              setTimeout(() => {
                setCampFull(false)
              }, 3000);
            }else{
              setSteps(steps + 1)
            }
          }
        }else{
          console.log("es 4")
        }
      }
    
      const prevStep = ()=>{
        if(steps > 1){
          setSteps(steps - 1)
        }else{
          console.log("es 1")
        }
      }



  return {
    name,
    setName,
    email, 
    setEmail,
    description, 
    setDescription,
    age, 
    setAge,
    tel, 
    setTel,
    reason, 
    setReason,
    treatmentTime,
    setTreatmentTime,
    budget,
    setBudget,
    otherReviews,
    setOtherReviews,
    department,
    setDepartment,
    city,
    setCity,
    zone,
    setZone,
    procedure,
    setProcedure,
    errCamp,
    setCode,
    code,
    sendInfo,
    successApointmentState,
    dataApointment,
    steps,
    prevStep,
    nextStep,
    loading,
    campFull
  }
}

export default UseBooking