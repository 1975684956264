import React from 'react'
import TourHeaderInfo from '../TourHeaderInfo/TourHeaderInfo'
import TourHeaderSearch from '../TourHeaderSearch/TourHeaderSearch'
import Banner from '../../../assets/pictures/BannerTours.png'

import './assets/styles.css'

function ToursHeader({ findTours, error, place, setPlace }) {
  return (
    <div className='toursHeader'>
      <img className='banner-tour' src={Banner} alt='Tours' style={{top:"413px",left:"112px",borderRadius:"22px",
      }}/>
      {/* <div className='bg-black-tours'>
        <TourHeaderSearch
          findTours={findTours}
          place={place}
          setPlace={setPlace}
          error={error}

        />
        <TourHeaderInfo />
      </div> */}
    </div>
  )
}

export default ToursHeader