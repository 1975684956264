import useFormCreateAppointment from '../../../../viewModels/share/form.create.appointment';
import ActivityIndicator from '../activityIndicator';
import SvgIcon, { iconList } from '../iconSVG';
// import CalendarChooseDate from '../calendarChooseDate';
import './styles.css';
function FormCreateDate({ close, data }) {
  const {
    formData,
    getFormData,
    isEditing,
    save,
    isEnableBtn,
    isSaving,
    isSuccessful
  } = useFormCreateAppointment(data, close);

  return (
    <div className="formCreateDate">
      <div className="formCreateDate head">
        <p className='title'>Gestion de datos del paciente para agendar citas</p>
      </div>
      {isSuccessful &&
        <SvgIcon color={'#50AE42'} name={iconList.IconCheckSucces} size={400} />
      }
      {!isSuccessful &&
        <div className="formCreateDate body">
          <div className='sectionLeft'>
            <div className='inputGroup'>
              <label>Nombre completo</label>
              {isEditing
                ? <input id={'name'} value={formData?.name} placeholder='Nombre Completo' onChange={getFormData} />
                : <p>{formData?.name}</p>
              }
            </div>
            <div className='inputGroup'>
              <label>Identificación</label>
              {isEditing
                ? <input id={'identification'} value={formData?.identification} placeholder='Nro de identificación' onChange={getFormData} />
                : <p>{formData?.identification}</p>
              }
            </div>
            <div className='inputGroup'>
              <label>Email</label>
              {isEditing
                ? <input id={'email'} value={formData?.email} placeholder='Email' onChange={getFormData} />
                : <p>{formData?.email}</p>
              }
            </div>
            <div className='inputGroup'>
              <label>Teléfono</label>
              {isEditing
                ? <input id={'tel'} value={formData?.tel} placeholder='Teléfono' onChange={getFormData} />
                : <p>{formData?.tel}</p>
              }
            </div>
            <div className='inputGroup'>
              <label>Descripción</label>
              {isEditing
                ? <input id={'description'} value={formData?.description} placeholder='Asunto' onChange={getFormData} />
                : <p>{formData?.description}</p>
              }
            </div>
            <div className='inputGroup'>
              <label>Procedimiento</label>
              {isEditing
                ? <input id={'procedure'} value={formData?.procedure} placeholder='Procedimiento' onChange={getFormData} />
                : <p>{formData?.procedure}</p>
              }
            </div>
          </div>

          <div className='sectionRight'>
            <p className='sectionRightTitle'>Seleccionar fecha y a hora.</p>
            <div className='selectTime'>
              <label >Seleccionar Fecha</label>
              <input type="date" id={'date'} onChange={getFormData} />
            </div>
            <div className='selectTime'>
              <label>Seleccionar Hora</label>
              <input type="time" id={'hour'} onChange={getFormData} />
            </div>
            <div className='selectedTime'>
              <label>Fecha Seleccionada: </label>
              {formData?.date
                ? <p className='on'>{formData?.date}</p>
                : <p className='off'>Fecha no seleccionada</p>
              }
            </div>

            <div className='selectedTime'>
              <label>Hora Seleccionada:</label>
              {formData?.hour
                ? <p className='on'>{formData?.hour}</p>
                : <p className='off'>Hora no seleccionada</p>
              }
            </div>
          </div>
        </div>
      }

      {!isSuccessful &&
        <div className="formCreateDate foot">
          <div className={`formCreateDateFootBtn ${isEnableBtn ? 'enableBtnON' : 'enableBtnOFF'}`} onClick={save}>
            {isSaving
              ? <ActivityIndicator />
              : <p className='formCreateDateFootBtnText'>Aceptar</p>
            }
          </div>
          <div className='formCreateDateFootBtn outline' onClick={() => close(false)}>
            <p className='formCreateDateFootBtnText'>Canlelar</p>
          </div>
        </div>
      }
    </div>
  )
}
export default FormCreateDate;
