import React, { useState } from 'react';
import ModalContainer, { sliceFrom } from '../../../share/components/modalContainer';
import './styles.css'

function ListInstructionvideos({ data = [] }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoId, setVideoId] = useState('');

  const openModal = (id) => {
    setModalIsOpen(true);
    setVideoId(id)
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setVideoId('')
  };

  const getVideoIdFromUrl = (url) => {
    const videoIdMatch = url.match(/v=([a-zA-Z0-9_-]+)/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  function getthumbnailUrl(videoUrl) {
    const videoId = getVideoIdFromUrl(videoUrl);
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    return { thumbnailUrl, videoId };
  }

  return (
    <div className='pqrsVideosContainer'>
      <ModalContainer
        hidden={closeModal}
        show={modalIsOpen}
        sizeHeight={90}
        sizeWidth={90}
        from={sliceFrom.down}
        hiddenButton={false}
      >
        <iframe
          title={videoId}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen={true}
          className='iframeVideoPqrs'
        ></iframe>
      </ModalContainer>
      <p className='pqrsVideosTitle'>VIDEOS INSTRUCTIVOS</p>
      <div className='pqrsVideosRow'>
        {data.map((multimedia, key) => {
          const thumbnailUrl = getthumbnailUrl(multimedia?.url);
          return (
            <div className='pqrsVideosMultimedia' key={key} onClick={() => openModal(thumbnailUrl?.videoId)}>
              <div className='pqrsVideosMultimediaWrapperImg'>
                <img src={thumbnailUrl?.thumbnailUrl} alt={multimedia?.name} className='pqrsVideosMultimediaImg' />
              </div>
              {multimedia?.name &&
                <p className='pqrsVideosMultimediaName'>{multimedia?.name}</p>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ListInstructionvideos;
