import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Paquetes = () => {
  const navigate = useNavigate();

  const originCityRef = useRef();
  const destinyCityRef = useRef();
  const roundTripPackages = useRef();
  const goinRef = useRef();

  const [background, setBackground] = useState(1);
  const [calendar, setcalendar] = useState(false);
  const [calendarTwo, setCalendarTwo] = useState(false);
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [step, setStep] = useState(1);

  const location = useLocation();
  let counter = 0;
  const [loading, setLoading] = useState(false);
  const [dataPackages, setDataPackages] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const [services, setServices] = useState([]);
  const itemsForPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);
  const [loadingCircular, setLoadingCircular] = useState(false);
  const [loadingPack, setLoadingPack] = useState(false);
  const [cities, setCities] = useState([]);
  const [citieSelected, setCitieSelected] = useState("");

  function findServices() {
    if (counter === 0) {
      setLoading(true);
      const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/packages`
      axios
        .get(url, {
          params: {
            search: "",
          },
        })
        .then((res) => {
          counter += 1;
          setLoading(false);
          setServices(res.data.packs);
          setUrlImage(res.data.url);
          setDataPackages([...res.data.packs].splice(0, itemsForPage));
        })
        .catch((error) => {
          counter += 1;
          setLoading(false);
          console.log(error);
        });
    }
  }

  useEffect(() => {
    findServices();
  }, []);

  const nextHandler = () => {
    const totalElementos = services.length;

    const nextPage = currentPage + 1;

    const firtsIndex = nextPage * itemsForPage;
    if (firtsIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setDataPackages([...services].splice(firtsIndex, itemsForPage));
  };

  const nextStep = (steps) => {
    setStep(2);
  };

  const prevStep = (steps) => {
    setStep(1);
  };

  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firtsIndex = prevPage * itemsForPage;

    setDataPackages([...services].splice(firtsIndex, itemsForPage));
    setCurrentPage(prevPage);
  };

  const [cityDestiny, setCityDestiny] = useState(false);
  const [cityOrigin, setCityOrigin] = useState(false);

  const selectTypeResercation = (type) => {
    type === 1
      ? setBackground(type)
      : type === 2
      ? setBackground(type)
      : setBackground(0);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        originCityRef.current &&
        !originCityRef.current.contains(event.target)
      ) {
        setCityOrigin(false);
      }
      if (
        destinyCityRef.current &&
        !destinyCityRef.current.contains(event.target)
      ) {
        setCityDestiny(false);
      }
      if (
        roundTripPackages.current &&
        !roundTripPackages.current.contains(event.target)
      ) {
        setCalendarTwo(false);
      }
      if (goinRef.current && !goinRef.current.contains(event.target)) {
        setcalendar(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function generateUniqueId() {
    return "xxxxx-xxxx-4xxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const selectedCity = (city) => {
    setCitieSelected(city);
    setCityOrigin(false);
  };

  const findCity = () => {
    setLoadingCircular(true);
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/cities`
    axios
      .get(url)
      .then((res) => {
        setCities(res.data.cities);
        setLoadingCircular(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const detailPackages = (
    name,
    id,
    image,
    stars,
    description,
    price,
    address
  ) => {
    navigate(`/packages/${generateUniqueId()}`, {
      state: {
        name: name,
        id: id,
        urlImage: urlImage,
        pathImage: image,
        stars: stars,
        description: description,
        price: price,
        address: address,
      },
    });
  };

  const findPackages = () => {
    setLoadingPack(true);
    axios
      .get(
        `${
          background === 1
            ? `${process.env.REACT_APP_URL_API_AIOP_STG}api/medic_packages`
            : `${process.env.REACT_APP_URL_API_AIOP_STG}api/packages`
        }`,
        {
          params: {
            search: citieSelected,
          },
        }
      )
      .then((res) => {
        setLoadingPack(false);
        console.log(res.data.packs);
        navigate("/package/results", {
          state: {
            data: res.data.packs,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    dataPackages,
    loading,
    nextHandler,
    prevHandler,
    selectTypeResercation,
    background,
    services,
    currentPage,
    mostrarNext,
    cityOrigin,
    setCityOrigin,
    originCityRef,
    detailPackages,
    findCity,
    loadingCircular,
    cities,
    selectedCity,
    citieSelected,
    findPackages,
    loadingPack,
    isRoundTrip,
    setIsRoundTrip,
    prevStep,
    nextStep,
    step,
  };
};

export default Paquetes;
