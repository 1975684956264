//dependences
import React, { useState, useEffect } from 'react'
import { match } from 'path-to-regexp';
import { Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';

//providers, store and styles
import "./app.css"
import { TransportProvider } from './Context/transport/TransportProvider'
import { UserDetailsProvider } from './Context/user/userProvider';
import store from './store';
import { set, keys } from './ui/localStorage'
//new routes clean architecture
import Header from './ui/views/share/components/header';
import Home from './ui/views/home';
import Footer from './ui/views/share/components/footer';
import ProfileUser from './ui/views/profileUser';
import UserRegister from './ui/views/session/register';
import UserLogin from './ui/views/session/login';
import Flights from './ui/views/flights/flights.view';
import ResultPackages from './ui/views/Packages/ResultPackages/ResultPackages';
import InitialRoomPX from './ui/views/videoSDK/px/initialRoom';
import PassengerInformationForm from './ui/views/flights/passenger.information.form.view';
import PurelyIframeFlight from './ui/views/flights/purelyIframe';
import ResultSearchGlobal from './ui/views/share/components/Find/ResultSearchGlobal/ResultSearchGlobal';
import MakeReservation from './ui/views/Hotel/MakeReservation/MakeReservation'
import PqrsPrimaryScreen from './ui/views/pqrs/primaryScreen';
import PackagesPay from './ui/views/Packages/PackagesPay/PackagesPay';
import Ambulance from './ui/views/ambulance/Ambulance';
import ShopWellezy from './ui/views/shopWellezy/ShopWellezy';
import BookingFlow from './ui/views/Specialist/DetailSpecialist/BookingFlow/BookingFlow';
import BookingFlowClinics from './ui/views/clinics/DetailsClinics/BookingFlow/BookingFlow';
import TourismHealth from './ui/views/tourismHealth/TourismHealth';
import ResultSearchTourism from "./ui/views/tourismHealth/resultSearch/ResultSearchTourism"
import ReservationTransport from './ui/views/transport/reservationTransport/ReservationTransport';
import DetailPackhealth from './ui/views/tourismHealth/detailPackHealth/DetailPackhealth';
import ResultSearchPack from './ui/views/tourismHealth/resultSearchPack/ResultSearchPack';




//old routes
import Procedures from './Routes/Procedures/Procedures';
import Doctors from './Routes/Doctors/Doctors';
import Doctor from './Routes/Doctor/Doctor';
import Nurses from './Routes/Nurses/Nurses';
import Nurse from './Routes/Nurse/Nurse';
import Turism from './Routes/Turism/Turism';
import Allies from './Routes/Allies/Allies';
import About from './Routes/About/About';
import Recovery from './Routes/Recovery/Recovery';
import Tour from './Routes/Tour/Tour';
import FlightSelected from './Routes/FlightSelected/FlightSelected';
import FlightDetails from './Routes/FlightDetails/FlightDetails';
import ProcedureCategoryDetails from './Routes/ProcedureCategoryDetails/ProcedureCategoryDetails';
import ProcedureCategory from './Routes/ProcedureCategory/ProcedureCategory';
import ResultPayment from './Routes/ResultPayment/ResultPayment';
import Form from './Routes/Form/Form';
import AboutUs from './Routes/AboutUs/AboutUs';
import RateClass from './Routes/RateClass/RateClass';
import Paymentgateway from './Routes/Paymentgateway/Paymentgateway';
import ServicesCategory from './Routes/ServicesCategory/ServicesCategory';
import ShoppingCart from './Routes/ShoppingCart/ShoppingCart';
import Favorites from './Routes/Favorites/Favorites';
import logoWellezy from './Routes/Home/Assets/img/LogoWhite.png'
import FlightsCheckout from './Routes/FlightsCheckout/FlightsCheckout';
import RouteVisualizador from './Routes/Visualizador/RouteVisualizador';
import TravelRoute from './Routes/TravelRoute/TravelRoute';
import FlightRoute from './Routes/FlightRoute/FlightRoute';
import HotelRoute from './Routes/HotelRoute/HotelRoute';
import Tours from './Routes/Tours/Tours';
import TransportRoute from './Routes/TransportRoute/TransportRoute';
import PackagesRoute from './Routes/PackagesRoute/PackagesRoute';
import CarRentaRoute from './Routes/CarRentaRoute/CarRentaRoute';
import TransportResultsSearch from './Routes/TransportRoute/TransportResultsSearch';
import TravelSecureRoute from './Routes/travelSecureRoute/TravelSecureRoute';
import SpecialistRoute from './Routes/SpecialistRoute/SpecialistRoute';
import ClinicsRoute from './Routes/ClinicsRoute/ClinicsRoute';
import SpecialistDetailsRoute from './Routes/SpecialistRoute/SpecialistDetailsRoute';
import HealthWellnessRoute from './Routes/HealthWellnessRoute/HealthWellnessRoute';
import PharmacyRoute from './Routes/PharmacyRoute/PharmacyRoute';
import RecoveryHouseRoute from './Routes/RecoveryHouseRoute/RecoveryHouseRoute';
import CosmeticsRoute from './Routes/CosmeticsRoute/CosmeticsRoute';
import DetailsClinicsRoute from './Routes/ClinicsRoute/DetailsClinicsRoute';
import DetailsCosmeticsRoute from './Routes/CosmeticsRoute/DetailsCosmeticsRoute';
import DetailsRecoveryHouseRoute from './Routes/RecoveryHouseRoute/DetailsRecoveryHouseRoute';
import DetailsPharmacyRoute from './Routes/PharmacyRoute/DetailsPharmacyRoute';
import DetailsHealthRoute from './Routes/HealthWellnessRoute/DetailsHealthRoute';
import HotelResultRoute from './Routes/HotelRoute/HotelResultRoute';
import HotelDetailRoute from './Routes/HotelRoute/HotelDetailRoute';
import DetailPackagesRoute from './Routes/PackagesRoute/DetailPackagesRoute';
import { pathIframe, widgetsList, pathWidget } from './infra/constants/app';

import Terrawind from './Routes/Terrawind/Terrawind';
import TerrawindMethodPay from './Routes/TerrawindMethodPay/TerrawindMethodPay';
import TerraWindVoucher from './Routes/TerrawindVoucher/TerrawindVoucher';
import TerrawindGenerateVoucher from './Routes/TerrawindGenerateVoucher/TerrawindGenerateVoucher';
import SuraDataPassengers from './Routes/SuraDataPassengers/SuraDataPassengers';
import BookingFlowHealt from './ui/views/tourismHealth/detailPackHealth/BookingFlow/BookingFlow';
import BookingFlowReservation from './ui/views/tourismHealth/resultSearchPack/BookingFlow/BookingFlow';
import WellezyNews from './ui/views/news/WellezyNews';
import PayHotel from './ui/views/Hotel/MakeReservation/PayHotel/PayHotel';
import SuraSummaryPayRoute from './Routes/SuraSummaryPay/SuraSummaryPay';
import PackageDataPassengerRoute from './Routes/PackageDataPassengerRoute/PackageDataPassengerRoute';
import PayPackageTourismRoute from './Routes/PayPackageTourismRoute/PayPackageTourismRoute';
import PackageTourismVoucherRoute from './Routes/PackageTourismVoucherRoute/PackageTourismVoucherRoute';

import { PrivacyPolicies } from './Routes/PrivacyPolicies/PrivacyPolicies';
import PayTransport from './ui/views/transport/reservationTransport/PayTransport/PayTransport';
import FlightsPay from './ui/views/flights/flightsPay/FlightsPay';

const App = () => {
  let showFooterAndNav = true;
  const allPaths = [ ...pathWidget, ...pathIframe ];

  for (let i = 0; i < allPaths.length; i++) {
    const pathPattern = allPaths[i];
    const pathMatcher = match(pathPattern);
    if (pathMatcher(window.location.pathname)) {
      showFooterAndNav = false;
      break;
    }
  }

  async function setServicesMenu() {
    try {
      let list = [];
      const isWidget = widgetsList.find((w) => w.path === window.location.pathname)
      console.log("isWidget", isWidget)


      if (isWidget) {
        list = isWidget.options;
      }
      const widgetEnable = !!isWidget;
      await set(keys?.isWidget, { enable: widgetEnable })
      await set(keys?.servicesMenu, list)
    } catch (error) {
      console.log('Error in setServicesMenu():', error)
    }
  };

  useEffect(() => {
    setServicesMenu();
  }, [])

  return (
    <Provider store={store}>
      <UserDetailsProvider>
        <TransportProvider>
          <div className='app'>
              {showFooterAndNav && <Header />}
              <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/:id' element={<Home />} />
                
                <Route path='/procedures' element={<Procedures />} />
                <Route path='/doctors' element={<Doctors />} />
                <Route path='/doctor/:id' element={<Doctor />} />
                <Route path='/nurse/:id' element={<Nurse />} />
                <Route path='/Visualizador' element={<RouteVisualizador />} />
                <Route path='/nurses' element={<Nurses />} />
                <Route path='/turism' element={<Turism />} />
                <Route path='/allies' element={<Allies />} />
                <Route path='/resultSearch/:id' element={<ResultSearchGlobal />} />
                <Route path='/about' element={<About />} />
                <Route path='/privacy-policies' element={<PrivacyPolicies />} />
                <Route path='/recovery' element={<Recovery />} />
                <Route path='/tour/:id' element={<Tour />} />
                <Route path='/procedure/:id' element={<ProcedureCategory />} />
                <Route path='/procedure/:id/:id' element={<ProcedureCategoryDetails />} />
                <Route path='/services/:id' element={<ServicesCategory />} />
                <Route path='/carRentals' element={<CarRentaRoute />} />
                <Route path='/package/results' element={<ResultPackages />} />
                <Route path='/Travel' element={<TravelRoute />} />
                <Route path='/form' element={<Form />} />
                <Route path='/aboutus' element={<AboutUs />} />
                <Route path='/Specialists' element={<SpecialistRoute />} />
                <Route path='/Specialists/:id' element={<SpecialistDetailsRoute />} />
                <Route path='/Clinics' element={<ClinicsRoute />} />
                <Route path='/Clinics/:id' element={<DetailsClinicsRoute />} />
                <Route path='/flightsCheckout' element={<FlightsCheckout />} />
                <Route path='/resultPayment' element={<ResultPayment />} />
                <Route path='/rateClass' element={<RateClass />} />
                <Route path='/paymentgateway' element={<Paymentgateway />} />  {/* favorites */}
                <Route path='/shoppingCart' element={<ShoppingCart />} />
                <Route path='/favorites' element={<Favorites />} />
                <Route path='/profile' element={<ProfileUser />} />
                <Route path='/register' element={<UserRegister />} />
                <Route path='/login' element={<UserLogin />} />
                <Route path='/flightPassengersForm' element={<PassengerInformationForm />} />
                <Route path='/iframe/flightPassengersForm' element={<PassengerInformationForm />} />
                <Route path='/Pharmacy' element={<PharmacyRoute />} />
                <Route path='/Pharmacy/:id' element={<DetailsPharmacyRoute />} />
                <Route path='/healthWellness' element={<HealthWellnessRoute />} />
                <Route path='/healthWellness/:id' element={<DetailsHealthRoute />} />
                <Route path='/RecoveryHouse' element={<RecoveryHouseRoute />} />
                <Route path='/iframe/fligth' element={<PurelyIframeFlight />} />
                <Route path='/RecoveryHouse/:id' element={<DetailsRecoveryHouseRoute />} />
                <Route path='/Cosmetics' element={<CosmeticsRoute />} />
                <Route path='/Cosmetics/:id' element={<DetailsCosmeticsRoute />} />
                <Route path='/virtual-meeting-rooms/:entity/:id' element={<InitialRoomPX />} />
                <Route path='/pqrs' element={<PqrsPrimaryScreen />} />
                <Route path='/ambulance' element={<Ambulance />} />
                <Route path='/shopWellezy' element={<ShopWellezy />} />
                <Route path='/Specialist/reservation/:id' element={<BookingFlow/>} />
                <Route path='/Clinic/reservation/:id' element={<BookingFlowClinics/>} />
                <Route path='/TourismHealth' element={<TourismHealth></TourismHealth>} />
                <Route path='/TourismHealth/:id' element={<ResultSearchTourism/>} />
                <Route path='/TourismHealthPack/:id' element={<ResultSearchPack/>} />
                <Route path='/TourismHealth/DetailProcedure/:id' element={<DetailPackhealth/>} />
                <Route path='/TourismHealth/reservation/:id' element={<BookingFlowHealt/>} />
                <Route path='/TourismHealth/reservation' element={<BookingFlowReservation/>} />
                <Route path='/wellezy-news' element={<WellezyNews/>} />


                {/* Transport */}
                <Route path='/transport' element={<TransportRoute></TransportRoute>} />
                <Route path='/transport/:id' element={<TransportResultsSearch />} />
                <Route path='/transport/reserve' element={<ReservationTransport/>} />
                <Route path='/transport/PayTransport' element={<PayTransport/>} />
 

                {/*fligths*/}
                <Route path='/flight/selected' element={<FlightSelected />} />
                <Route path='/flight/:id' element={<Flights />} />
                <Route path='/iframe/flight/:id' element={<Flights />} />
                <Route path='/flightDetails' element={<FlightDetails />} />
                <Route path='/fligths' element={<FlightRoute />} />
                <Route path='/iframe/fligths' element={<FlightRoute />} />
                <Route path='/widget/fligths' element={<FlightRoute />} />
                <Route path='/flight/flightsPay' element={<FlightsPay />} />

                {/*hotel*/}
                <Route path='/hotel' element={<HotelRoute />} />
                <Route path='/hotel/:id' element={<HotelDetailRoute />} />
                <Route path='/hotels/:id' element={<HotelResultRoute />} />
                <Route path='/hotels/reservation' element={<MakeReservation />} />
                <Route path='/hotels/PayHotel' element={<PayHotel />} />
                {/* <Route path='/hotels/Payment' element={<PayHotel />} /> */}
                <Route path='/widget/hotel' element={<HotelRoute />} />
                <Route path='/widget/hotel/:id' element={<HotelDetailRoute />} />
                <Route path='/widget/hotels/:id' element={<HotelResultRoute />} />
                <Route path='/widget/hotels/reservation' element={<MakeReservation />} />

                {/*tours*/}
                <Route path='/tours' element={<Tours />} />
                <Route path='/widget/tours' element={<Tours />} />

                {/*packages*/}
                <Route path='/packages' element={<PackagesRoute />} />
                <Route path='/packages/:id' element={<DetailPackagesRoute />} />
                <Route path='/packages/hotels/:id' element={<HotelResultRoute />} />
                <Route path='/packages/flight/:id' element={<Flights />} />
                <Route path='/packages/pay' element={<PackagesPay/>} />
                <Route path='/packages/hotel/:id' element={<HotelDetailRoute />} />
                <Route path='/widget/packages' element={<PackagesRoute />} />
                <Route path='/widget/packages/:id' element={<DetailPackagesRoute />} />
                <Route path='/widget/packages/passenger/:data' element={<PackageDataPassengerRoute />} />
                <Route path='/widget/packages/pay/:id' element={<PayPackageTourismRoute />} />
                <Route path='/packages/voucher' element={<PackageTourismVoucherRoute />} />

                {/*travel secure*/}
                <Route path='/travelSecure' element={<TravelSecureRoute />} />
                {/*terrawind*/}
                <Route path='/travelSecure/terrawind/:data' element={<Terrawind/>}/>
                <Route path='/travelSecure/terrawind/voucher/:data' element={<TerraWindVoucher/>} />
                <Route path='/travelSecure/terrawind/methodPay/:data' element={<TerrawindMethodPay/>} />
                <Route path='/travelSecure/terrawind/generateVoucher/' element={<TerrawindGenerateVoucher/>} />
                {/*sura*/}
                <Route path='/travelSecure/sura/passengers' element={<SuraDataPassengers/>}/>
                <Route path='/travelSecure/sura/summaryPay' element={<SuraSummaryPayRoute/>}/>
                {/*clinics*/}
              </Routes>
              {
                showFooterAndNav && <Footer />
              }
          </div>
        </TransportProvider>
      </UserDetailsProvider>
    </Provider>
  )
}

export default App