import React from 'react'
import "./styles.css"

const PendingPay = ({description,name,email,phone}) => {
  return (
    <div className='content-pay-success'>
      <h3 style={{color:"rgb(84, 16, 96)"}}>Pago Pendiente</h3>
      <p className='thanks-received'>{description}</p>
      {/* <p className='order-id'>Order Id {order}</p> */}
      <h4>Datos de tu pedido</h4>
      <div className='information-invoices'>
        <section className='column-info-order'>
          <p>
            <span>
              Nombre:
            </span>
            {name}
          </p>
          <p>
            <span>
              Correo:
            </span>
            {email}
          </p>
          <p>
            <span>
              Telefono:
            </span>
            {phone}
          </p>
        </section>
      </div>
    </div>
  )
}

export default PendingPay