import './styles.css'
import DoubleTitle from '../components/doubleTitle';
import BarSearch from '../components/barSearch';
import JoinUs from '../components/jounUs';
import OptionsTabs from '../components/optionsTabs';
import ListInstructionvideos from '../components/listInstructionvideos';
import FrequentQuestions from '../components/frequentQuestions';
import FormContactUs from '../components/formContactUs';
import FooterButton from '../components/footerButton';
import AnyQuestions from '../../home/components/anyQuestion';
import usePqrsPrimaryScreen from '../../../viewModels/pqrs/pqrs.primaryScreen.viewModel';

function PqrsPrimaryScreen() {

  const {
    activatedTab,
    setActivatedTab,
    dataPqrs,
    isEstablishedSession,
    dataSelected,
  } = usePqrsPrimaryScreen();

  return (
    <div className='pqrsPrimaryScreen'>
      <DoubleTitle
        simpleText='¿NECESITAS AYUDA?'
        bigText='¿CÓMO PODEMOS AYUDARTE?'
      />
      {activatedTab &&
        <OptionsTabs
          tabActived={activatedTab}
          tabList={dataPqrs}
          tabChoose={setActivatedTab}
        />
      }
      <BarSearch />
      {!isEstablishedSession && <JoinUs />}

      {activatedTab && <ListInstructionvideos data={dataSelected?.videos} />}

      {activatedTab && 
        <div className='pqrsPrimaryScreenRow'>
          <FrequentQuestions data={dataSelected?.faq} />
          <FormContactUs />
        </div>}

      <div className='pqrsPrimaryScreenRow'>
        <FooterButton
          icon={''}
          title={'Nuestras políticas de la comunidad'}
          subTitle={'Cómo construimos una base de confianza'}
          toBeTaken={() => { }}
        />
        <FooterButton
          icon={''}
          title={'Consejs y sugerncias de seguridad'}
          subTitle={'Recursos para ayudar a los viajeros a mantenerse seguros'}
          toBeTaken={() => { }}
        />
      </div>
      <AnyQuestions title="¿Tienes alguna duda?" />
    </div>
  )
}

export default PqrsPrimaryScreen;
