import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import '../../initialRoom/styles.css';


function ParticipantView(props) {
  const micRef = useRef(null);
  const {
    webcamStream,
    micStream,
    webcamOn,
    micOn,
    isLocal,
    displayName,
  } = useParticipant(props?.participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);




const primeraLetra = displayName.charAt(0);


  return (
    <div className="participant">


      <div className="participantInfo up">
        <p>Webcam: {webcamOn ? "ON" : "OFF"}
        </p>

        <p>Mic:{" "}
          {micOn ? "ON" : "OFF"}
        </p>
      </div>

      <audio ref={micRef} autoPlay playsInline muted={isLocal} />

      {webcamOn &&
        <ReactPlayer
          playsinline
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          url={videoStream}
          height={"300px"}
          width={"300px"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
      }
      {!webcamOn &&
        <div className="participantAvatar">

          <p className="participantAvatarText">
            {primeraLetra}
          </p>
          </div>}


        <div className="participantInfo down">
        <p>Participant: {displayName}</p>
      </div>
    </div>
  )
}
export default ParticipantView;