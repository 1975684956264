import React, { useState } from 'react'
import './Assets/styles.css'

function TourMainButton({ mainButtonIcon, mainButtonTitle, mainButtonDescription, onClickFunct, styled }) {

  return (
    <div className='tourMainButton' onClick={onClickFunct} style={styled}>
        <div className='tourMainButton_'>
          <div className='tourMainButton_icon'>
            <img src={mainButtonIcon} alt={mainButtonTitle} />
          </div>
          <div className='tourMainButton_text' style={{marginLeft:"35px"}}>
              <p className='tourMainButton_textTitle'style={{fontSize:"19px"}}>{mainButtonTitle}</p>
              <p className='tourMainButton_textContent'>{mainButtonDescription}</p>
          </div>
        </div>
    </div>
  )
}

export default TourMainButton