import { Grid } from '@mui/material'
import './Assets/styles.css'
import ProcedureCatergoryDetailsHeader from '../../Components/ProcedureCategoryDetailsHeader/ProcedureCatergoryDetailsHeader'
import ProcedureCategoryDetailsMain from '../../Components/ProcedureCategoryDetailsMain/ProcedureCategoryDetailsMain'
import {useLocation} from "react-router-dom"



const ProcedureCategoryDetails = () => {
  
  const location = useLocation()
  const data = location.state 
  const categoryId = location.pathname.split('/')
  console.log(categoryId)
console.log({data})
  

   

  return (
    <Grid container justifyContent='center'>
       
          
            <>
           <ProcedureCatergoryDetailsHeader  data={data}/>
           <ProcedureCategoryDetailsMain  data={data}/>
            </>
          
        
        
    </Grid>
  )
}

export default ProcedureCategoryDetails