import React, { useState } from 'react';
import './styles.css';
import {useTranslation} from "react-i18next"

function FlightPassengerManagement({ value, onChange, setIsManagingPassengers ,refPass, isManagingPassengers}) {
    const [error, setError] = useState('');
    const [t,i18n] = useTranslation("global")
    function assessCounter(type, operationSuma) {
        const { adult, boy, baby } = value || {};
        const totalPassengers = adult + boy + baby;
        const maxPassengers = 9
        const isError = error !== '';
        const errorMessages = {
            error1: "Puedes hacer reserva hasta un máximo de 9 pasajeros.",
            error2: "El número de adultos debe ser igual al número de bebés. (0 a 23 meses)",
            error3: "Por cada adulto solo pueden viajar 2 menores de edad. (2 a 11 años)"
        };
        if (!operationSuma) {
            //minimo adulto
            if (type === 'adult' && value[type] === 1) return false;
            //minimo boy y baby
            if ((type === 'boy' || type === 'baby') && value[type] === 0) return false;
        }
        if (operationSuma) {
            if (totalPassengers === maxPassengers) {
                setError(errorMessages.error1);
                return;
            }
            if (type === 'boy' && adult * 2 === boy) {
                setError(errorMessages.error3);
                return;
            }

            if (type === 'baby' && adult === baby) {
                setError(errorMessages.error2);
                return;
            }
        }
        if (isError && ((type === 'baby' && value[type] <= adult) || (type === 'boy' && value[type] <= adult * 2))) {
            setError('');
            onChange(type, operationSuma);
        }
        onChange(type, operationSuma);
    }

    const hiddenM = ()=>{
        setIsManagingPassengers(!isManagingPassengers)
    }

    return (
        <div className='flightPassengerManagement' ref={refPass}>
            <p className='flightPassengerManagementTitle'>{t("fligth.search_fligth.passengers")}</p>
            <p className='flightPassengerManagementError'>{error}</p>
            <div className='flightPassengerManagementGroup'>
                <div className='flightPassengerManagementGroupLeft'>
                    <p className='flightPassengerManagementGroupName'>{t("fligth.search_fligth.adults")}</p>
                    <p className='flightPassengerManagementGroupdescription'></p>
                </div>
                <div className='flightPassengerManagementGroupRigth'>
                    <div className='flightPassengerManagementGroupRigthBtn' onClick={() => assessCounter('adult', false)}>
                        <p className='flightPassengerManagementGroupRigthBtnText'>-</p>
                    </div>
                    <p className='flightPassengerManagementGroupRigthValue'>{value?.adult}</p>
                    <div className='flightPassengerManagementGroupRigthBtn' onClick={() => assessCounter('adult', true)}>
                        <p className='flightPassengerManagementGroupRigthBtnText'>+</p>
                    </div>
                </div>
            </div>
            <div className='flightPassengerManagementGroup'>
                <div className='flightPassengerManagementGroupLeft'>
                    <p className='flightPassengerManagementGroupName'>{t("fligth.search_fligth.childrens")}</p>
                    <p className='flightPassengerManagementGroupdescription'>2 {t("fligth.search_fligth.to")} 11 {t("fligth.search_fligth.years")}</p>
                </div>
                <div className='flightPassengerManagementGroupRigth'>
                    <div className='flightPassengerManagementGroupRigthBtn' onClick={() => assessCounter('boy', false)}>
                        <p className='flightPassengerManagementGroupRigthBtnText'>-</p>
                    </div>
                    <p className='flightPassengerManagementGroupRigthValue'>{value?.boy}</p>
                    <div className='flightPassengerManagementGroupRigthBtn' onClick={() => assessCounter('boy', true)}>
                        <p className='flightPassengerManagementGroupRigthBtnText'>+</p>
                    </div>
                </div>
            </div>
            <div className='flightPassengerManagementGroup'>
                <div className='flightPassengerManagementGroupLeft'>
                    <p className='flightPassengerManagementGroupName'>{t("fligth.search_fligth.babies")}</p>
                    <p className='flightPassengerManagementGroupdescription'>0 A 23 {t("fligth.search_fligth.months")}</p>
                </div>
                <div className='flightPassengerManagementGroupRigth'>
                    <div className='flightPassengerManagementGroupRigthBtn' onClick={() => assessCounter('baby', false)}>
                        <p className='flightPassengerManagementGroupRigthBtnText'>-</p>
                    </div>
                    <p className='flightPassengerManagementGroupRigthValue'>{value?.baby}</p>
                    <div className='flightPassengerManagementGroupRigthBtn' onClick={() => assessCounter('baby', true)}>
                        <p className='flightPassengerManagementGroupRigthBtnText'>+</p>
                    </div>
                </div>
            </div>
            {/* <div className='flightPassengerManagementBtn' onClick={hiddenM}>
                <p className='flightPassengerManagementBtnText'>{t("fligth.search_fligth.continue")}</p>
            </div> */}
        </div>
    )
}

export default FlightPassengerManagement