import React from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import imgClinic from '../../../../assets/pictures/img-3.webp'
import { useTranslation } from 'react-i18next'


const InfoDetailDoctor = ({ infoDoctor, infoSpecialist, offices, urlImage, specialist }) => {

const [t] = useTranslation("global")

    return (
        <div className='contain-info-detail-doctor'>
            <section className='detalle-info-doctor-p'>
                <h3>{t("specialist.detail.information")}</h3>
                <p>
                    {
                    infoDoctor == null ?
                    t("specialist.detail.no_information")
                    :
                    infoDoctor
                    }
                </p>
                <h3>{t("specialist.detail.especialty")}</h3>
                <p>
                {
                    infoSpecialist == null ?
                    t("specialist.detail.no_information")
                    :
                    infoSpecialist
                    }
                  
                </p>
                <button>{t("specialist.detail.read_more")}</button>
            </section>
            <section className='list-detalle-especialidades'>
                <h3>{t("specialist.detail.information")}</h3>
                <h4>{t("specialist.detail.surgery_plastic")}</h4>
                <div className='list-detalle-especialidades-layout'>
                    <ul>
                        {
                            specialist.map((item,key)=>{
                                return(
                                    <li key={key}><SvgIcon name={iconList.IconCheckSucces} size={30} color={'#004274'} />{item.name}</li>
                                )
                            })
                        }
                    </ul>
                </div>
                <h4>{t("specialist.detail.surgery_estetic")}</h4>
                {
                    // offices?.map((item, key) => {
                    //     return (
                    //         <div className='office-work-doctor' key={key}>
                    //             <div className='office-work-doctor-header'>
                    //                 <div>
                    //                     <p>
                    //                         <span>{t("transfers.city")}:</span> {item?.name}
                    //                     </p>
                    //                     {/* <p>
                    //                         <span>Direccion:</span> {item?.address}
                    //                     </p> */}
                    //                 </div>
                    //             </div>

                    //             {/* <div className='office-work-doctor-main'>
                    //                 <h4>Descripcion:</h4>
                    //                 <p>
                    //                    {item?.description}
                    //                 </p>

                    //             </div> */}
                    //         </div>
                    //     )
                    // })
                }
                <div className='office-work-doctor'>
                    {
                        offices
                    }
                </div>

                <button>{t("specialist.detail.read_more")}</button>
            </section>
           
        </div>
    )
}

export default InfoDetailDoctor