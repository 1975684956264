import "./styles.css";
import { useTranslation } from "react-i18next";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import CarouselSevices from "../../share/components/CarouselServices/CarouselSevices";
import { Link } from "react-router-dom";
import Find from "../../share/components/Find/Find";
import useTransport from "../../../viewModels/transport/TransportC";
import { iconList } from "../../share/components/iconSVG";
import SvgIcon from "../../share/components/iconSVG";
import AnyQuestions from "../../home/components/anyQuestion";
import banner from "../../../assets/pictures/bannerTraslados.png";

import SearchTransport from "./SearchTransport";

const Transport = () => {
  const [t] = useTranslation("global");
  const {
    adult,
    baby,
    setTypeT,
    typeT,
    ciudadDropdown,
    setCiudadDropdown,
    aeropuertoDropdown,
    setAeropuertoDropdown,
    formValidate,
    passengersCal,
    findCity,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginSelected,
    findDestiny,
    destinyList,
    destinySelected,
    setDestinySelected,
    destinyDropdown,
    setDestinyDropdown,
    findServices,
    selectedDate,
    handleDateChange,
    loader,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    city,
    setCity,
    setOriginList,
    setDestinyList
  } = useTransport();

  return (
    <div>
      <Find />
      <CarouselSevices current={t("carousel_services.transfers")} />
      <header className="header-taxi-car">
          <SearchTransport
            adult={adult}
            aeropuertoDropdown={aeropuertoDropdown}
            baby={baby}
            cityList={cityList}
            citySelected={citySelected}
            ciudadDropdown={ciudadDropdown}
            destinyDropdown={destinyDropdown}
            destinyList={destinyList}
            destinySelected={destinySelected}
            findDestiny={findDestiny}
            findOrigin={findOrigin}
            findServices={findServices}
            formValidate={formValidate}
            handleDateChange={handleDateChange}
            originList={originList}
            originSelected={originSelected}
            passengersCal={passengersCal}
            selectedDate={selectedDate}
            setAeropuertoDropdown={setAeropuertoDropdown}
            setCiudadDropdown={setCiudadDropdown}
            setDestinyDropdown={setDestinyDropdown}
            setDestinySelected={setDestinySelected}
            setOriginSelected={setOriginSelected}
            setTypeT={setTypeT}
            setcitySelected={setcitySelected}
            loader={loader}
            width={"30%"}
            setCityList={setCityList} 
            loadingCity={loadingCity} 
            loadingDestiny={loadingDestiny} 
            loadingOrigin={loadingOrigin}
            setCity={setCity}
            setDestinyList={setDestinyList}
            setOriginList={setOriginList}
          />
        <div className="content-banner-taxicar">
          <img src={banner} alt="" />
        </div>
      </header>

      <section
        className="contain-contact-t"
        style={{ width: "85%", margin: "auto" }}
      >
        <Link to={"https://wa.link/uq1q0p"}>
          <div>
            <SvgIcon
              color={"#004274"}
              size={150}
              name={iconList.IconWhatsapp}
            />
          </div>
          <div className="text-contact-t">
            <h3>{t("transfers.you_have_doubts")}</h3>
            <p>{t("transfers.description")}</p>
          </div>
        </Link>
      </section>

      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Transport;
