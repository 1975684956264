import { useMeeting } from "@videosdk.live/react-sdk";
import '../../initialRoom/styles.css';
// import { ToastContainer, toast } from 'react-toastify';

function Controls() {
  const { leave, toggleMic, toggleWebcam } = useMeeting();
  const permissionStates = {
    granted: { value: 'granted', showModal: false },
    denied: { value: 'denied', showModal: true },
    prompt: { value: 'prompt', showModal: true },
    unprompted: { value: 'unprompted', showModal: true },
    dismissed: { value: 'dismissed', showModal: true },
    inactive: { value: 'inactive', showModal: true },
  }

  const permissionsList = {
    microphone: 'microphone',
    camera: 'camera'
  }

  async function requestAccess(data) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ ...data });
      console.log('Acceso a la cámara concedido');
    } catch (error) {
      console.error('Error al acceder a la cámara:', error);
    }
  }

  async function checkPermission(permissionsName) {
    try {
      const permissionStatus = await navigator.permissions.query({ name: permissionsName });
      if (permissionStatus) {
        const currentPermission = permissionStates[permissionStatus?.state]
        return currentPermission.showModal;
      }
    } catch (error) {
      console.error('Error al verificar el estado del permiso de la cámara:', error);
    }
  }

  async function controlMic() {
    const permissionsStatus = await checkPermission(permissionsList.microphone);
    if (permissionsStatus) {
      console.log("el usuario no ha otorgado permisos")



    }
    else {
      console.log("toggleMic();")
      toggleMic();
    }
  }

  async function controlCam() {
    const permissionsStatus = await checkPermission(permissionsList.camera);
    if (permissionsStatus) {
      console.log("el usuario no ha otorgado permisos")
      // notify()
    }
    else {
      console.log("toggleWebcam();")
      toggleWebcam();
    }
  }

  return (
    <div className="controlsContainer"
    >
      {/* <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      /> */}

      <div onClick={() => controlMic()} className="controlBtn">
        <p className="controlBtnText">toggleMic</p>
      </div>
      <div onClick={() => controlCam()} className="controlBtn">
        <p className="controlBtnText">toggleWebcam</p>
      </div>
      <div onClick={() => leave()} className="controlBtn">
        <p className="controlBtnText">Leave</p>
      </div>
    </div>
  );
}

export default Controls;