import axios from 'axios'
import React, { useEffect, useState } from 'react'

const usePayTransport = () => {

    const [dataValue, setDataValue] = useState(localStorage.getItem('data-pay-transport') ? JSON.parse(localStorage.getItem('data-pay-transport'))  : null)
    const [requestValue, setRequestValue] = useState(localStorage.getItem('request-pay-transport') ? JSON.parse(localStorage.getItem('request-pay-transport'))  : null)
    const [statusTransaction, setStatusTransaction] = useState({})
    const [requestId, setRequestId] = useState(localStorage.getItem("request-id-pay"))
    const [dataEmitter, setDataEmitter] = useState({})
    const [loadinPay, setLoadinPay] = useState(true)
    const [incompleteInformation, setIncompleteInformation] = useState("")


    const emitterReservation = ()=>{
        const url = process.env.REACT_APP_URL_EMITTER_RESERVATION_TRANSPORT
        axios.post(url,requestValue)
        .then(res=>{
          console.log(res.data)
          setDataEmitter(res.data)
          setLoadinPay(false)
          localStorage.removeItem("request-id-pay")
          localStorage.removeItem("request-pay-transport")
          localStorage.removeItem("data-pay-transport")
        })
        .catch(err=>{
          console.log(err)
          setLoadinPay(false)
          setIncompleteInformation("Error al emitir la reserva")
        })
       
      }
    

    const getStatePay = ()=>{
        setLoadinPay(true) 
        const url = process.env.REACT_APP_URL_STATE_PAY
        axios.post(url,{
          request_id: Number(requestId)
        })
        .then(res=>{
          console.log(res.data)
          setStatusTransaction(res.data)
          if(res.data?.status?.status === "APPROVED"){
            setRequestValue(prevState => ({
                ...prevState,
                name: res.data?.request?.payer?.name + " " + res.data?.request?.payer?.surname,
                email: res.data?.request?.payer?.email,
                cel: res.data?.request?.payer?.mobile
              }));
          }else{
          setLoadinPay(false)
          }
        }) 
        .catch(err=>{
          console.log(err)
          setLoadinPay(false)
          setIncompleteInformation("Error en la consulta de tu pago consulta nuestro asesores")
        })
      }
      useEffect(() => {
       getStatePay()
      }, [])
 
      useEffect(() => {
        if(statusTransaction?.request?.payer?.mobile === requestValue?.cel){
           emitterReservation() 
        }
      }, [requestValue])
      
      

  return {
    loadinPay,
    setLoadinPay,
    dataEmitter,
    dataValue,
    statusTransaction,
    incompleteInformation
  }
}

export default usePayTransport