import './styles.css';
function FormContactUs() {

  return (
    <div className='pqrsFormContactUs'>
      <p className='pqrsFormContactUsTitle'>NECESITAS CONTÁCTARNOS?</p>
      <p className='pqrsFormContactUsSubtitle'>
        Dejanos tus datos y te resolveremos las dudas que puedas tener, nos pondremos en contacto contigo
      </p>

      <div className='pqrsFormContactUsRow'>
        <div className='pqrsFormContactUsGroup'>
          <label className='pqrsFormContactUsGroupLabel'>Nombre</label>
          <div className='pqrsFormContactUsGroupWrapper'>
            <input className='pqrsFormContactUsGroupInput' />
          </div>
        </div>

        <div className='pqrsFormContactUsGroup'>
          <label className='pqrsFormContactUsGroupLabel'>Apellidos</label>
          <div className='pqrsFormContactUsGroupWrapper'>
            <input className='pqrsFormContactUsGroupInput' />
          </div>
        </div>
      </div>

      <div className='pqrsFormContactUsGroup'>
        <label className='pqrsFormContactUsGroupLabel'>Número de Celular</label>
        <div className='pqrsFormContactUsGroupWrapper'>
          <input className='pqrsFormContactUsGroupInput' />
        </div>
      </div>

      <div className='pqrsFormContactUsGroup'>
        <label className='pqrsFormContactUsGroupLabel'>Correo electrónico</label>
        <div className='pqrsFormContactUsGroupWrapper'>
          <input className='pqrsFormContactUsGroupInput' />
        </div>
      </div>

      <div className='pqrsFormContactUsGroup'>
        <label className='pqrsFormContactUsGroupLabel'>¿Cual es tu duda?</label>
          <textarea className='pqrsFormContactUsGroupTextarea' rows="4"/>
      </div>

      <div className='pqrsFormContactUsBtn'>
        <p className='pqrsFormContactUsBtnText'>Enviar</p>
      </div>

    </div>
  )
}

export default FormContactUs;
