import React, { useState } from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import "./styles.css"
import { useTranslation } from 'react-i18next';

const ServicesInclude = ({ serviciosHot, serviciosHab, checkin, checkout, star }) => {

    const stars = [];
    const [t] = useTranslation("global")

    for (let i = 0; i < Number(star); i++) {
        stars.push(<SvgIcon key={i} name={iconList.IconStar} color={'#ffff00'} size={30} />);
    }

    const [dropdownHot, setDropdownHot] = useState(false)
    const [dropdownHab, setDropdownHab] = useState(false)

    return (
        <div className='contain-services-include'>
            <div className='contain-dorpdowns'>
                <section className='contain-stars-hotel'>
                    <div className='stars-div'>
                        {
                            stars
                        }
                    </div>
                    <div className='stars-description'>
                        <span>
                            {Number(star).toFixed(1)}
                        </span>
                        <p>
                            {Number(star) === 5 ? t("hotel.excelent") : t("hotel.good")}
                        </p>
                    </div>
                </section>
                <section onClick={() => { setDropdownHot(!dropdownHot) }} className='servicios-include-dropdown'>
                    <h3>{t("hotel.services_hotel")}</h3>
                    {
                        dropdownHot === false ?
                            <SvgIcon name={iconList.IconArrowDown} color={"#ffffff"} size={30} />
                            :
                            <SvgIcon name={iconList.IconCloseCircleOutLine} color={"#ffffff"} size={30} />

                    }

                </section>
                {
                    dropdownHot &&

                    <ul>
                        {
                            serviciosHot === undefined ?
                                <li>
                                    <SvgIcon name={iconList.IconAlertOutLine} color={"#004274"} size={30} />
                                    {t("hotel.no_services")}
                                </li>

                                :
                                serviciosHot.map((item, key) => {
                                    return (
                                        <li key={key}>
                                            <SvgIcon name={iconList.IconCheckSucces} color={"#004274"} size={30} />
                                            {item?.desc_serv}
                                        </li>
                                    )
                                })
                        }

                    </ul>

                }
                <section onClick={() => { setDropdownHab(!dropdownHab) }} className='servicios-include-dropdown'>
                    <h3>{t("hotel.services_habitation")}</h3>
                    {
                        dropdownHab === false ?
                            <SvgIcon name={iconList.IconArrowDown} color={"#ffffff"} size={30} />
                            :
                            <SvgIcon name={iconList.IconCloseCircleOutLine} color={"#ffffff"} size={30} />

                    }
                </section>
                {
                    dropdownHab &&

                    <ul>
                        {
                            serviciosHab === undefined ?
                                <li>
                                    <SvgIcon name={iconList.IconAlertOutLine} color={"#004274"} size={30} />
                                    {t("hotel.no_services")}
                                </li>

                                :

                                serviciosHab.map((item, key) => {
                                    return (
                                        <li key={key}>
                                            <SvgIcon name={iconList.IconCheckSucces} color={"#004274"} size={30} />
                                            {item?.descripciones}
                                        </li>
                                    )
                                })
                        }

                    </ul>

                }
            </div>
            <div className='contain-checkin'>
                <h3>{t("hotel.condition_hot")}</h3>
                <div className='check-in-out'>
                    <p>{t("hotel.hour_entrance")}:</p>
                    <span>{t("hotel.from")} {checkin}</span>
                </div>
                <div className='check-in-out'>
                    <p>{t("hotel.hour_departure")}:</p>
                    <span>{t("hotel.before")} {checkout}</span>
                </div>
                <div className='check-in-out'>
                    <p>{t("hotel.breakfast")}:</p>
                    <span>{t("hotel.to_hour")} { }</span>
                </div>
            </div>
        </div>
    )
}

export default ServicesInclude