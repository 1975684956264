import useTeleConsultation from '../../../../../../viewModels/profile/teleConsultation/useTeleConsultation.viewModel';
import ActivityIndicator from '../../../../../share/components/activityIndicator';
import InitialRoom from '../../../../../videoSDK/initialRoom';
import './styles.css';

function TeleConsultation({
  code,
}) {
  const {
    codeValue,
    getNewCode,
    initTeleConsultation,
    isLoading,
  } = useTeleConsultation(code);

  const initRoomAvailable= true;

  return (
    <div className="bigContainer teleConsultation">
      <div className="profileScreen_ContainerSideCenter">
        {initRoomAvailable
        ?<InitialRoom code={code}/>
        :
        <div className="teleConsultationContainer">
          <div className='teleConsultationContainerCard'>
            <p className='teleConsultationContainerCardTitle'>
              Ingrese el código de invitación para acceder a la sala de conferecia virtual.
            </p>
            <div className='teleConsultationContainerCardInputContainer'>
              <input placeholder="Código"
                className='teleConsultationContainerCardInput'
                value={codeValue}
                onChange={getNewCode}
              />
            </div>
            {isLoading &&
              <div className='teleConsultationContainerCardBtn'>
                <ActivityIndicator />
              </div>}
            {!isLoading &&
              <div className='teleConsultationContainerCardBtn'
                onClick={initTeleConsultation}>
                <p className='teleConsultationContainerCardBtnText'>Acceder</p>
              </div>
            }
          </div>
        </div>}
      </div>
    </div>
  )
}

export default TeleConsultation;
