import React, { useState } from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import Loading from '../../share/components/loading'
import { useTranslation } from 'react-i18next'

const AllCardsSpecialist = ({ data, urlImg, loading, detailSpecialist }) => {

    const [t,i18n] = useTranslation("global")
    const [dropdownSpecialist, setDropdownSpecialist] = useState(false)
    const [dropdownDetailSpecialist, setDropdownDetailSpecialist] = useState([])

    const toggleDropdown = (key) => {
        setDropdownDetailSpecialist((prevState) => {
            const newState = [...prevState];
            newState[key] = !newState[key];
            return newState;
        });
    };

    return (
        <div className='contain-allcards-specialist'>
            {
                loading === true ?
                    <Loading size={100} />

                    :

                    (
                        data.length === 0 ?
                            <h2>No Hay Resultados</h2>
                            :
                            data.map((item, key) => {
                                const isDropdownOpen = dropdownDetailSpecialist[key] || false;
                                return (
                                    <div className='card-specialist' key={key} >
                                        <img src={`https://aiop.com.co/storage/doctors/1/profile/ubWHqMgA7QaBSBr4ecW0iriX3QqSl9fpBjVyVcZD.png`} alt="" />
                                        <section className='contain-info-card-specialist'>
                                            <div className='info-card-specialist-a'>
                                                <div className='info-card-specialist-a-main'>
                                                    <h3>
                                                        {item.name}
                                                    </h3>
                                                    {/* <h4>
                                            Especialidades:
                                        </h4>
                                         */}
                                                </div>
                                                <div className='info-card-specialist-a-child'>
                                                    <span>4.5</span><SvgIcon name={iconList.IconStar} color={'#ffff00'} size={15} />
                                                </div>
                                            </div>
                                            <div className='info-card-specialist-b'>
                                                <div className='info-card-specialist-b-main'>
                                                    <SvgIcon name={iconList.IconPinMap} color={'#004274'} size={20} />
                                                    <p>{item?.information_doctor.length === 0 ? "Cali, Medellin" : item?.information_doctor?.address}</p>
                                                </div>
                                                <div className='info-card-specialist-b-child'>
                                                    <span onClick={() => { 
                                                        //toggleDropdown(key) 
                                                        detailSpecialist(item)
                                                        }}>{t("specialist.buttons.see_more")}</span>
                                                </div>
                                                {
                                                    isDropdownOpen &&
                                                    <div className='effect-hover-car-specialist' style={{ padding: '1rem' }}>
                                                        <h3>Especialidades</h3>
                                                        <div >
                                                            <ul className='list-specialist'>
                                                                {item.subcategories.map((item, key) => {
                                                                    return (
                                                                        <li key={key}>
                                                                            {item.name}
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <h4>
                                                            Correo:
                                                        </h4>
                                                        <div className='cont-email-specialist'>
                                                            <p>{item.email}</p>
                                                        </div>
                                                        <button className='button-hidden-effect' onClick={() => { toggleDropdown(key) }}>
                                                            Cerrar
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </section>
                                    </div>
                                )
                            })
                    )
            }

        </div>
    )
}

export default AllCardsSpecialist