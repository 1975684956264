import React from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import "./styles.css"
import { global } from '../../../../global';


const {
    img: {
        logos: {
            AviancaLogo,
            LatamLogo,
            VivaLogo,
        }
    }

} = global;

const DetailPayFlight = ({ info }) => {

    const airlinesList = [
        {
            id: 1,
            img: VivaLogo,
            name: 'Viva Air',
            priceDirect: 1050,
            priceOneScale: 950,
            priceMoreScale: null,
        },
        {
            id: 2,
            code: "LA",
            img: LatamLogo,
            name: 'Latam Airlines',
            priceDirect: 1100,
            priceOneScale: 1080,
            priceMoreScale: null,
        },
        {
            id: 3,
            code: "AV",
            img: AviancaLogo,
            name: 'Avianca',
            priceDirect: 1120,
            priceOneScale: 1100,
            priceMoreScale: 1050,
        },
    ]

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return cantidadFormateada;
    }

    function convertirFecha(fechaStr) {
        // Separar la cadena en partes: año, mes, día, hora, minuto
        let partes = fechaStr.split(/[-T:+]/);
        let año = partes[0];
        let mes = partes[1];
        let dia = partes[2];
        let hora = partes[3];
        let minuto = partes[4];

        // Obtener el nombre completo del mes
        let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        let mesCompleto = meses[parseInt(mes) - 1];

        // Formatear la fecha en el formato deseado
        let fechaFormateada = `${dia} ${mesCompleto}, ${año} ${hora}:${minuto}`;

        return fechaFormateada;
    }

    return (
        <div className='detail-pay-hotel'>
            <div className='header-pay-hotel'>
                {
                    info?.company === "LA" &&
                    <img src={LatamLogo} alt={info?.company} />
                }
                {
                    info?.company === "AV" &&
                    <img src={AviancaLogo} alt={info?.company} />
                }
                {
                    info?.company === "VI" &&
                    <img src={VivaLogo} alt={info?.company} />
                }
                <div className='content-info'>
                    <h2>{info?.company === "LA" ? "Latam Airlines" : info?.company === "AV" ? "Avianca" : "Viva Air"}</h2>
                    <div>
                        <p>{info?.passengersSelected?.adult + info?.passengersSelected?.boy + info?.passengersSelected?.baby}</p>
                        <span><SvgIcon color={"#004274"} name={iconList.IconPlane} size={25} /> </span>
                    </div>
                </div>
            </div>
            <div className='content-check-detail'>
                {
                    info?.itinerary.map((item, key) => {

                        return (
                            <>
                                <section key={key}>
                                    <p className='text-from-to'>{item?.from} <span>{convertirFecha(item.dateDeparture)}</span></p>
                                    <SvgIcon color={"#004274"} name={iconList.IconArrowLarge} size={30} />
                                    <p className='text-from-to'>{item?.to} <span>{convertirFecha(item.dateArrival)}</span></p>
                                </section>
                            </>

                        )
                    })
                }
            </div>
            <div className='line-separation-detail-pay'></div>
            <div className='content-detail-price-flight'>
                <div className='sub-total row-detail-pay'>
                    <p>
                        Sub Total
                    </p>
                    <span>
                        {formatearCantidad(info?.priceWithOutTaxes)}
                    </span>
                </div>
                <div className='taxes row-detail-pay'>
                    <span>
                        Impuestos
                    </span>
                    <span>
                        {formatearCantidad(info?.priceTaxes)}
                    </span>
                </div>
            </div>
            <div className='line-separation-detail-pay'></div>
            <div className='row-detail-pay row-total-pay'>
                <p>Total</p>
                <span>{formatearCantidad(info?.priceTotal)}</span>
            </div>
        </div>
    )
}

export default DetailPayFlight