import React from 'react'
import './styles.css'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'


const FirstInfo = ({stars, cities, setModal}) => {
    const [t] = useTranslation("global")
    return (
        <div className='contain-firts-info-specilist'>
            <section className='first-info-specialist-sedes'>
                <ul>
                    <li>
                        <SvgIcon name={iconList.IconPinMapBlank} size={20} color={'#0042749a'} />
                        {/* {cities?.length} {cities?.length > 1 ? 'sedes' : 'sede'} en{cities?.map((item,key)=>{
                            return(
                                <span style={{color:'black'}} key={key}>
                                    {item?.name}{cities?.length > 1 ? ',' : ''} 
                                </span>
                            )
                        })}{cities?.length > 1 ? '...' : ''} <span>{t("specialist.detail.see_more")}</span> */}
                        {
                            cities
                        }
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconCalendar} size={20} color={'#004274'} />
                        {t("specialist.detail.request_quote")} <span onClick={()=>{setModal(true)}}>{t("specialist.detail.view_calendar")}</span>
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconDoctor} size={20} color={'#004274'} />
                        {t("specialist.detail.reply")} 72 {t("specialist.detail.hours")}
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconCheckSucces} size={20} color={'#004274'} />
                        {t("specialist.detail.register")}
                    </li>
                </ul>
            </section>
            <section className='first-info-specialist-rate'>
                <ul className='list-star-first-info'>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                    <li>
                        <SvgIcon name={iconList.IconStar} size={30} color={'#ffff00'} />
                    </li>
                </ul>
                <div className='points-first-info'>
                    <div className='rate-detail-specialist'>
                        <p>{5.0}</p>
                    </div>
                    <div>
                        <p style={{color:'#4CA391', fontWeight:'700'}}>{stars >= 5 ? 'Excelente' : 'Bueno'}</p>
                        <p>{t("specialist.detail.see_comentaris")}</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FirstInfo