import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Grid } from "@mui/material";
import "./styles.css";
import SvgIcon, { iconList } from "../../share/components/iconSVG/index";
import axios from "axios";
import Loading from "../../share/components/loading";
import { useNavigate } from "react-router-dom";

const PackagesInterest = () => {
  const navigate = useNavigate();
  const constraintsRef = useRef(null);
  const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;
  const MotionBox = styled(motion.div)`
    width: 100vw;
  `;

  const [loading, setLoading] = useState(false);
  const url = "https://aiop.com.co/api/medic_packages/top";
  const [dataPackages, setDataPackages] = useState([]);

  const getTopMedic = () => [
    setLoading(false),
    // axios
    //   .get(url)
    //   .then((res) => {
    //     setDataPackages(res.data.packs);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //   }),
  ];

  useEffect(() => {
    setLoading(true);
    getTopMedic();
  }, []);

  const [selectedCards, setSelectedCards] = useState(
    new Array(dataPackages?.length).fill(1)
  ); // Inicializar todos en 1

  const handleCardSelect = (index, value) => {
    const newSelectedCards = [...selectedCards];
    newSelectedCards[index] = value;
    setSelectedCards(newSelectedCards);
  };

  const goToOption = (keywork) => {
    if (keywork === "") {
      navigate(`/TourismHealthPack/all`, {
        state: {
          keyWork: keywork,
        },
      });
    } else {
      navigate(`/TourismHealthPack/${keywork}`, {
        state: {
          keyWork: keywork,
        },
      });
    }
  };

  const goToDetail = (procedure) => {
    navigate("/TourismHealth/DetailProcedure", {
      state: {
        data: procedure,
      },
    });
  };

  return (
    <div className="content-best-experiences">
      <h2>Paquetes que te pueden interesar</h2>
      <div className="header-experiences">
        <p>
          Conoce los lugares mas destacados y más seleccionados para
          procedimientos médicos
        </p>
        <button
          onClick={() => {
            goToOption("");
          }}
        >
          Ver todos los paquetes
        </button>
      </div>
      <section className="content-pack-interest">
        {loading === true ? (
          <Loading size={100} />
        ) : dataPackages?.length === 0 ? (
          <h2>No hay disponibilidad por el momento</h2>
        ) : (
          <MotionConstraints className="slider_container" ref={constraintsRef}>
            <MotionBox
              className="slider_"
              drag="x"
              dragConstraints={constraintsRef}
            >
              {dataPackages?.map((item, index) => {
                return (
                  <motion.div className="div_homeTurism" key={index}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={2}
                      className="grid_cardHomeTurism"
                    >
                      <div className="card-pack-interest">
                        <section className="part-one">
                          <div className="content-img-interest">
                            <img
                              src={`https://staging.aiop.com.co/storage/${item.image}`}
                              alt=""
                            />
                          </div>
                          <div className="content-info">
                            <h3>{item.name}</h3>
                            <div className="content-icons">
                              <SvgIcon
                                color={"#004274"}
                                name={iconList.IconPinMap}
                                size={30}
                              />
                              <span>Viena, Austria</span>
                            </div>
                            <div className="content-icons">
                              <SvgIcon
                                color={"#004274"}
                                name={iconList.IconCheckSucces}
                                size={30}
                              />
                              <span>Precio a partir de ${item.price}</span>
                            </div>
                            <div className="content-btns">
                              <button>Conozca el proveedor</button>
                              <button
                                onClick={() => {
                                  goToDetail(item);
                                }}
                              >
                                Ver paquete
                              </button>
                            </div>
                          </div>
                        </section>
                        <section className="part-two">
                          <button
                            className={
                              selectedCards[index] === 1
                                ? "select-interest"
                                : ""
                            }
                            onClick={() => {
                              handleCardSelect(index, 1);
                            }}
                          >
                            Descripcion general
                          </button>
                          <button
                            className={
                              selectedCards[index] === 2
                                ? "select-interest"
                                : ""
                            }
                            onClick={() => {
                              handleCardSelect(index, 2);
                            }}
                          >
                            Informacion del centro
                          </button>
                          <button
                            className={
                              selectedCards[index] === 3
                                ? "select-interest"
                                : ""
                            }
                            onClick={() => {
                              handleCardSelect(index, 3);
                            }}
                          >
                            Mas paquetes
                          </button>
                        </section>
                        <section className="part-three">
                          <p>{item.description}</p>
                        </section>
                      </div>
                    </Grid>
                  </motion.div>
                );
              })}
            </MotionBox>
          </MotionConstraints>
        )}
      </section>
    </div>
  );
};

export default PackagesInterest;
