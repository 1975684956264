import { Calendar, Record, RecordCircle } from 'iconsax-react';
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-date-range';
import { Calendar as CalendarNew } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './Assets/styles.css'
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import * as locales from 'react-date-range/dist/locale';

const FlightsDateGoing = ({ setOneWay, oneWay, setRoundTrip, roundTrip, setDateGoing, dateGoing, cardRef, setDateGoingSelected, setDateGoingSelectedApi, setDateSreturnSelected, setDateSreturnSelectedApi, checkDate, setCheckDate, dateGoingSelected, dateSreturnSelected, accompanied, dateOneWay, setDateOneWay, dateReturn, setDateReturn }) => {


  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);


  const nameMapper = {
    ar: 'Arabic',
    bg: 'Bulgarian',
    ca: 'Catalan',
    cs: 'Czech',
    cy: 'Welsh',
    da: 'Danish',
    de: 'German',
    el: 'Greek',
    enGB: 'English (United Kingdom)',
    enUS: 'English (United States)',
    eo: 'Esperanto',
    es: 'Spanish',
    et: 'Estonian',
    faIR: 'Persian',
    fi: 'Finnish',
    fil: 'Filipino',
    fr: 'French',
    hi: 'Hindi',
    hr: 'Croatian',
    hu: 'Hungarian',
    hy: 'Armenian',
    id: 'Indonesian',
    is: 'Icelandic',
    it: 'Italian',
    ja: 'Japanese',
    ka: 'Georgian',
    ko: 'Korean',
    lt: 'Lithuanian',
    lv: 'Latvian',
    mk: 'Macedonian',
    nb: 'Norwegian Bokmål',
    nl: 'Dutch',
    pl: 'Polish',
    pt: 'Portuguese',
    ro: 'Romanian',
    ru: 'Russian',
    sk: 'Slovak',
    sl: 'Slovenian',
    sr: 'Serbian',
    sv: 'Swedish',
    th: 'Thai',
    tr: 'Turkish',
    uk: 'Ukrainian',
    vi: 'Vietnamese',
    zhCN: 'Chinese Simplified',
    zhTW: 'Chinese Traditional'
  };

  const localeOptions = Object.keys(locales)
    .map(key => ({
      value: key,
      label: `${key} - ${nameMapper[key] || ''}`
    }))
    .filter(item => nameMapper[item.value]);

  const [locale, setLocale] = React.useState('es');

  const [t, i18n] = useTranslation("global")
  const [changeDate, setChangeDate] = useState(false)
  const [fecha, setFecha] = useState(0)
  const date = new Date()
  date.setDate(date.getDate() + 1);

  const location = useLocation()

  const position = location.pathname.split('/')[1]

  const dateMax = new Date()
  dateMax.setDate(date.getDate() + 360);





  const hiddenM = () => {
    setDateGoing(!dateGoing)
  }

  useEffect(() => {
    setFecha(fecha)
  }, [fecha])

  {/*  CHECK BOX */ }
  const changeRoundTrip = () => {
    setOneWay(false)
    setRoundTrip(true)
    setCheckDate(true)
    /*  roundTrip === false && oneWay=== true ? console.log('ida y vuelta') : console.log(' solo ida ') */
  }
  {/*  CHECK BOX */ }
  const changeOneWay = () => {
    setOneWay(true)
    setCheckDate(false)
    setRoundTrip(false)
    /*   roundTrip === true && oneWay=== false ? console.log(' solo ida ') : console.log(' ida y vuelta ') */
  }

  let diaS = 0
  console.log(roundTrip)

  const onChangeOnlyDateOne = (item) => {
    if (!checkDate) {
      localStorage.setItem("dateOne", item)
      console.log(item)
      console.log(item.toISOString('en-us', { year: "numeric", month: "short", day: "numeric" }))
      setDateOneWay(item)
      setDateGoingSelected(item.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }))
      setDateGoingSelectedApi(item.toISOString('en-us', { year: "numeric", month: "short", day: "numeric" }))
    } else {
      localStorage.setItem("dateReturn", item)
      setDateReturn(item)
      setFecha(calcularDiasEntreFechas(dateOneWay.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" }), item.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })))
      setDateSreturnSelected(item.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }))
      setDateSreturnSelectedApi(item.toISOString('en-us', { year: "numeric", month: "short", day: "numeric" }))
    }


  }

  function calcularDiasEntreFechas(fechaInicio, fechaFin) {
    const unDiaEnMilisegundos = 1000 * 60 * 60 * 24; // 1 día en milisegundos
    const fechaInicioObj = new Date(fechaInicio);
    const fechaFinObj = new Date(fechaFin);
    const diferenciaEnMilisegundos = fechaFinObj - fechaInicioObj;
    const diasDeDiferencia = Math.round(diferenciaEnMilisegundos / unDiaEnMilisegundos);
    return diasDeDiferencia;
  }


  useEffect(() => {
    if (range[0].endDate) {
      //ida
      localStorage.setItem("dateOne", range[0].startDate)
      setDateOneWay(range[0].startDate)
      setDateGoingSelected(range[0].startDate.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }))
      setDateGoingSelectedApi(range[0].startDate.toISOString('en-us', { year: "numeric", month: "short", day: "numeric" }))

      //vuelta
      localStorage.setItem("dateReturn", range[0].endDate)
      setDateReturn(range[0].endDate)
      setFecha(calcularDiasEntreFechas(range[0].startDate.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" }), range[0].endDate.toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })))
      setDateSreturnSelected(range[0].endDate.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }))
      setDateSreturnSelectedApi(range[0].endDate.toISOString('en-us', { year: "numeric", month: "short", day: "numeric" }))
    }

  }, [range])



  return (
    <div className='flightsDateGoing_container' ref={cardRef}>
      {checkDate === false ?
        <div className='flightsDateGoing' style={{ right: `${accompanied ? "auto" : "0"}` }}>
          <div className='flightsDateGoing_'>
            <div className='flightsDateGoing_date' style={{ border: '1px solid #004274' }}>
              <div className='flightsDateGoing_date_'>
                <div>
                  <h2>{t("fligth.search_fligth.going")}</h2>
                  <p style={{ color: 'black' }}>{dateOneWay.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                </div>
                <div className='icons-cale'>
                  <Calendar size="28" color="#004274" />
                </div>
              </div>
            </div>{
              roundTrip &&
              <div className='flightsDateGoing_date'>
                <div className='flightsDateGoing_date_'>
                  <div>
                    <h2>{t("fligth.search_fligth.return")}</h2>
                    <p style={{ color: 'black' }}>{dateReturn.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                  </div>
                  <div className='icons-cale'>
                    <Calendar size="28" color="#004274" variant="Bold" />
                  </div>
                </div>
              </div>
            }
            <div className={position === "fligths" ? 'flightsHeaderFind_checkBox-c' : 'flightsHeaderFind_checkBox'}>
              <div className='flightsHeaderFind_checkBoxOne'>
                <div onClick={changeRoundTrip}>
                  {roundTrip ?
                    <RecordCircle style={{ cursor: 'pointer' }} size="28" color="#004274" />
                    :
                    <Record style={{ cursor: 'pointer' }} size="28" color="#004274" />
                  }
                </div>
                <p>{t("fligth.search_fligth.round_trip")}</p>
              </div>
              <div className='flightsHeaderFind_checkBoxOne'>
                <div onClick={changeOneWay}>
                  {oneWay ?
                    <RecordCircle style={{ cursor: 'pointer' }} size="28" color="#004274" />
                    :
                    <Record style={{ cursor: 'pointer' }} size="28" color="#004274" />
                  }
                </div>
                <p>{t("fligth.search_fligth.one_way")}</p>
              </div>
            </div>

          </div>
          <div className='dateRangeOnly'>
            {
              roundTrip ?
                <DateRange
                  editableDateInputs={true}
                  onChange={item => setRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  rangeColors={["#004274", '#969696', '#969696']	
                }
                />
                :
                <CalendarNew maxDate={dateMax} locale={locales[locale]} onChange={onChangeOnlyDateOne} date={dateOneWay} />
            }

            <div className='contain-btn-select-f'>
              <button onClick={hiddenM}>{t("fligth.search_fligth.select_fligth")}</button>
            </div>
          </div>

        </div>
        :
        <div className='flightsDateGoing'>
          <div className='flightsDateGoing_'>

            <div className='flightsDateGoing_date'>
              <div className='flightsDateGoing_date_'>
                <div>
                  <h2 >{t("fligth.search_fligth.going")}</h2>
                  <p style={{ color: 'black' }}>{dateOneWay.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                </div>
                <div className='icons-cale'>
                  <Calendar size="28" color="#004274" />
                </div>
              </div>
            </div>

            <div className='flightsDateGoing_date'>
              <div className='flightsDateGoing_date_'>
                <div>
                  <h2>{t("fligth.search_fligth.return")}</h2>
                  <p style={{ color: 'black' }}>{dateReturn.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                </div>
                <div className='icons-cale'>
                  <Calendar size="28" color="#004274" variant="Bold" />
                </div>
              </div>
            </div>

            <div className={position === "fligths" ? 'flightsHeaderFind_checkBox-c' : 'flightsHeaderFind_checkBox'}>
              <div className='flightsHeaderFind_checkBoxOne'>
                <div onClick={changeRoundTrip}>
                  {roundTrip ?
                    <RecordCircle style={{ cursor: 'pointer' }} size="28" color="#004274" />
                    :
                    <Record style={{ cursor: 'pointer' }} size="28" color="#004274" />
                  }
                </div>
                <p>{t("fligth.search_fligth.round_trip")}</p>
              </div>
              <div className='flightsHeaderFind_checkBoxOne'>
                <div onClick={changeOneWay}>
                  {oneWay ?
                    <RecordCircle style={{ cursor: 'pointer' }} size="28" color="#004274" />
                    :
                    <Record style={{ cursor: 'pointer' }} size="28" color="#004274" />
                  }
                </div>
                <p>{t("fligth.search_fligth.one_way")}</p>
              </div>
            </div>
          </div>
          <div className='dateRange'>
            {/* <DateRange minDate={date} maxDate={dateMax} editableDateInputs={true} onChange={handleSelect} moveRangeOnFirstSelection={false} ranges={[selectionRange]} months={2} direction="horizontal" /> */}
            <div className='dateRangeOnly'>
              {/* <CalendarNew maxDate={dateMax} locale={locales[locale]} onChange={onChangeOnlyDateOne} date={dateReturn} /> */}
              <DateRange
                editableDateInputs={true}
                onChange={item => setRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={range}
              />
            </div>
            {
              fecha > 0 ?
                ""
                :
                <div className='error-day-fligth'>
                  <p >La fecha de retorno debe ser mayor a la fecha de salida</p>
                </div>
            }

            <div className='contain-btn-select-f'>
              <button onClick={hiddenM}>{t("fligth.search_fligth.select")} {fecha} {t("fligth.search_fligth.days_fligth")}</button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default FlightsDateGoing