import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const useTours = () => {
  const [place, setPlace] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const condition = location.pathname;
  let counter = 0;
  const [dataAlways, setDataAlways] = useState([]);
  const [infoData, setInfoData] = useState({});

  function hacerLlamadaAPI() {
    if (condition === "/tours" && counter === 0) {
      const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/tours`
      axios
        .get(url, {
          params: {
            search: "colombia",
            pag: "15",
          },
        })
        .then((res) => {
          counter += 1;
          setInfoData(res.data);
          return setDataAlways(res.data.data);
        })
        .catch((error) => {
          counter += 1;
          console.log(error);
        });
    }
  }

  useEffect(() => {
    hacerLlamadaAPI();
  }, []);

  const findTours = (places) => {
    setLoading(true);
    if (places === "") {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
      const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/tours`
      axios
        .get(url, {
          params: {
            search: place,
            pag: "15",
          },
        })
        .then((res) => {
          setInfoData(res.data);
          setData(res.data.data);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        })
        .catch((error) => console.log(error));
    }
  };

  const nextPag = (url, identificador) => {
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        identificador === "DA"
          ? setDataAlways(res.data.data)
          : setData(res.data.data);
        setInfoData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((error) => console.log(error));
  };

  const prevPag = (url, identificador) => {
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        identificador === "DA"
          ? setDataAlways(res.data.data)
          : setData(res.data.data);
        setInfoData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((error) => console.log(error));
  };

  return {
    findTours,
    setPlace,
    place,
    error,
    data,
    loading,
    dataAlways,
    nextPag,
    infoData,
    prevPag,
  };
};

export default useTours;
