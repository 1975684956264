import React, { useEffect } from 'react'
import AnyQuestions from '../../../home/components/anyQuestion'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import BannerFlow from './bannerFlow/BannerFlow'
import SelectProcedures from './SelectProcedures/SelectProcedures'
import StateSteps from './stateSteps/StateSteps'
import "./styles.css"
import MotiveConsultation from './motiveConsultation/MotiveConsultation'
import TreatmentTime from './treatmentTime/TreatmentTime'
import TellYourself from './tellYourself/TellYourself'
import HowContact from './howContact/HowContact'
import UseBooking from '../../../../viewModels/tourismHealth/UseBooking'
import SuccessApointment from './successApointment/SuccessApointment'
import { CircularProgress } from '@mui/material'
import { useState } from 'react'




const BookingFlow = () => {
  const { state } = useLocation()
  const [t] = useTranslation("global")
  

  const {
    name,
    setName,
    email, 
    setEmail,
    description, 
    setDescription,
    age, 
    setAge,
    setTel,
    setReason,
    setTreatmentTime,
    setBudget,
    setOtherReviews,
    department,
    setDepartment,
    city,
    setCity,
    procedure,
    setProcedure,
    errCamp,
    code,
    setCode,
    sendInfo,
    dataApointment,
    successApointmentState, 
    steps,
    nextStep,
    prevStep,
    loading,
    campFull
  } =UseBooking()



useEffect(() => {
  setProcedure(state?.name)
}, [])

const [modal, setModal] = useState(true)


  return (
    <div>
      {
        successApointmentState === true ?
          <SuccessApointment name={dataApointment?.appointment?.name} doctor={dataApointment?.appointment?.user?.name} reason={dataApointment?.appointment?.reason} status={dataApointment?.appointment?.status}/>
        :
          <BannerFlow name={state?.name} stars={state?.stars} state={state?.categories} urlImage={state?.urlImage} modal={modal} setModal={setModal}/>
      }
      
      
      {
        steps === 1 &&
        <MotiveConsultation setReason={setReason} setDescription={setDescription}/>
      }
      {
        steps === 2 &&
        <TreatmentTime setBudget={setBudget} setOtherReviews={setOtherReviews} setTreatmentTime={setTreatmentTime}/>
      }
      {
        steps === 3 &&
        <TellYourself ages={age} setAges={setAge} city={city} setCity={setCity} deparment={department} setDeparment={setDepartment}/>
      }
      {
        steps === 4 &&
        <HowContact code={code} setCode={setCode} setEmail={setEmail} setName={setName} setTel={setTel}/>
      }
      {
        campFull &&
        <p className='form-incomplete-camp'>
          {t("specialist.booking_flow.complete_form_next")}
        </p>
      }
       {
        errCamp &&
        <p className='form-incomplete-camp'>
          {t("specialist.booking_flow.complete_form_send")}
        </p>
      }
      {
        steps > 1 && steps < 5 &&
        <div className='btn-double-booking-flow'>
          <button onClick={()=>prevStep()}>{t("specialist.booking_flow.back")}</button>
          {
            steps === 4 ?
            <button onClick={()=>{sendInfo(state?.id)}}>
              {loading  ?
              <CircularProgress size={20}/>
              :
              t("specialist.booking_flow.send")
            }
            </button>
            :
            <button onClick={()=>nextStep()}>{t("specialist.booking_flow.next")}</button>
          }
          
        </div>
      }

      {
        steps === 1 &&
        <div className='btn-one-booking-flow'>
          <button onClick={()=>nextStep()}>
          {t("specialist.booking_flow.continue")}
          </button>
        </div>
      }
      {
        steps === 5 ?
        ""
        :
        <StateSteps step={steps} />
      }
      
      <AnyQuestions title={t('browse_home.have_doubt')} />
    </div>
  )
}

export default BookingFlow