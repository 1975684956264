import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";


function useBrowseHome() {
    
    const navigate = useNavigate()
    const cardRefProcedure = useRef(null);
    const cardRefDestination = useRef(null);
    const [disableBtn, setDisableBtn] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [tabOpen, setTabOpen] = useState(0);
    const [isOpenproceduresList, setIsOpenproceduresList] = useState(false);
    const [proceduresSelected, setProceduresSelected] = useState(null);
    const [proceduresList, setProceduresList] = useState([]);;
    const [isOpenDestinationsList, setIsOpenDestinationsList] = useState(false);
    const [destinationsSelected, setDestinationsSelected] = useState(null);
    const [destinationsList, setDestinationsList] = useState([]);;

    function chooseProcedure(value) {
        setProceduresSelected(value?.name);
        setIsOpenproceduresList(false);
    }

    function chooseDestination(value) {
        setDestinationsSelected(value?.name);
        setIsOpenDestinationsList(false);
    }

    function goToWelezyShop() {
        console.log("goToWelezyShop");
    }

    function searchForProceduresOnSite() {
            navigate(`/TourismHealth/${proceduresSelected}`,{
                state:{
                    procedure:proceduresSelected,
                    city:destinationsSelected
                }
            })
    }

    useEffect(() => { 
        if(proceduresSelected && destinationsSelected){
            setDisableBtn(false);
        }
    }, [proceduresSelected, destinationsSelected])


    useEffect(() => {
        if (isOpenproceduresList) { setTabOpen(1); }
        else if (isOpenDestinationsList) { setTabOpen(2); }
        else {setTabOpen(0);}
    }, [isOpenproceduresList, isOpenDestinationsList])

    useEffect(() => {
        const urlSpecialist = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/speciality`
        const urlCities = `${process.env.REACT_APP_URL_API_AIOP_PRT}api/cities`
        axios.get(urlSpecialist,{
            params:{
                search:''
            }
        })
        .then(res=>{
            setProceduresList(res.data)
        })
        .catch(error=>{
            console.log(error)
        })
        axios.get(urlCities)
        .then(res=>{
            setDestinationsList(res.data.cities)
        })
        .catch(err=>{
            console.log(err)
        })
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        function handleClickOutside(event) {
            if (cardRefProcedure.current && !cardRefProcedure.current.contains(event.target)) {
                setIsOpenproceduresList(false);
            }
            if (cardRefDestination.current && !cardRefDestination.current.contains(event.target)) {
                setIsOpenDestinationsList(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    return {
        disableBtn,
        loading,
        tabOpen,
        cardRefProcedure,
        cardRefDestination,
        proceduresSelected,
        isOpenproceduresList,
        setIsOpenproceduresList,
        proceduresList,
        chooseProcedure,
        destinationsSelected,
        isOpenDestinationsList,
        setIsOpenDestinationsList,
        destinationsList,
        chooseDestination,
        searchForProceduresOnSite,
        goToWelezyShop,
        loadingSearch
    }
}
export default useBrowseHome;