import React from 'react'
import banner from "../../../assets/pictures/bannerTourismHealth.png"
import "./styles.css"
import { useTranslation } from "react-i18next"
import ActivityIndicator from '../../share/components/activityIndicator'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import { ArrowDown2, ArrowUp2, Heart } from 'iconsax-react';
import useBrowseHome from '../../../viewModels/home/browse.home.viewModel'
import { CircularProgress } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'


const BannerTourismHealth = () => {
  const {
    disableBtn,
    loading,
    tabOpen,
    cardRefProcedure,
    cardRefDestination,
    proceduresSelected,
    isOpenproceduresList,
    setIsOpenproceduresList,
    proceduresList,
    chooseProcedure,
    destinationsSelected,
    isOpenDestinationsList,
    setIsOpenDestinationsList,
    destinationsList,
    chooseDestination,
    searchForProceduresOnSite,
    goToWelezyShop,
    loadingSearch
  } = useBrowseHome();

  const navigate = useNavigate()
  const {pathname} = useLocation()
  const [t, i18n] = useTranslation("global")

  const gotoOption = ()=>{
    navigate(`/TourismHealthPack/${proceduresSelected}`, {
      state: {
          keyWork: ""
      }})
  } 

  return (
    <div className='banner-tourism-health'>
      <img src={banner} alt="" />
      <div className='mask-banner'>
        <div className='content-search-banner-tourism'>
        <div className='browseHomeColumnContainer-tourism'>
           
            <div className="browseHomeColumnSideLeftForm"
              style={{ backgroundColor: tabOpen === 0 ? '#FFFFFF' : '#EDEDED' }}
            >
               <p className="browseHomeColumnSideLeftTitle">{t("browse_home.need_procedures")}</p>
              <div className="browseHomeColumnSideLeftFormColumns">
                <div className={`browseHomeColumnSideLeftFormColumnsGroup
                ${tabOpen === 1 ? 'browseHomeTabOpen' : tabOpen === 2 ? 'browseHomeTabLeftClose' : 'tabBorder'}`}

                >
                  <div className="browseHomeColumnSideLeftFormColumnsText">
                    <p className="browseHomeColumnSideLeftFormColumnsTextTitle">{t("browse_home.what_procedures_looking")}</p>
                    {proceduresSelected
                      ? <p className="browseHomeColumnSideLeftFormColumnsTextValue">{proceduresSelected}</p>
                      : <p className="browseHomeColumnSideLeftFormColumnsTextValue">{t("browse_home.procedures")}</p>
                    }
                  </div>
                  {loading
                    ? <CircularProgress size={15} />
                    : <div
                      className="browseHomeColumnSideLeftFormColumnsBtn"
                      onClick={() => { setIsOpenproceduresList(!isOpenproceduresList) }}
                    >
                      {isOpenproceduresList
                        ? <ArrowUp2 size="25" color="#104172" variant="Bold" />
                        : <ArrowDown2 size="25" color="#104172" variant="Bold" />
                      }
                    </div>
                  }
                </div>

                <div className={`browseHomeColumnSideLeftFormColumnsGroup
                ${tabOpen === 2 ? 'browseHomeTabOpen' : tabOpen === 1 ? 'browseHomeTabRightClose' : ''}`}

                >
                  <div className="browseHomeColumnSideLeftFormColumnsText">
                    <p className="browseHomeColumnSideLeftFormColumnsTextTitle">{t("browse_home.where")}</p>
                    {destinationsSelected
                      ? <p className="browseHomeColumnSideLeftFormColumnsTextValue">{destinationsSelected}</p>
                      : <p className="browseHomeColumnSideLeftFormColumnsTextValue">Cartagena, Barranquilla...</p>
                    }
                  </div>
                  {loading
                    ? <CircularProgress size={15} />
                    : <div
                      className="browseHomeColumnSideLeftFormColumnsBtn"
                      onClick={() => { setIsOpenDestinationsList(!isOpenDestinationsList) }}
                    >
                      {isOpenDestinationsList
                        ? <ArrowUp2 size="25" color="#104172" variant="Bold" />
                        : <ArrowDown2 size="25" color="#104172" variant="Bold" />
                      }
                    </div>
                  }
                </div>
              </div>

              {isOpenproceduresList &&
                <div className="browseHomeColumnSideLeftFormListContainer" ref={cardRefProcedure}>
                  {
                    proceduresList.length > 0 && proceduresList.map((index, key) => {
                      return (
                        <div className="browseHomeColumnSideLeftFormListOption" onClick={() => chooseProcedure(index)} key={key}>
                          <p className="browseHomeColumnSideLeftFormListOptionText">{index?.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
              }

              {isOpenDestinationsList &&
                <div className="browseHomeColumnSideLeftFormListContainer" ref={cardRefDestination}>
                  {
                    destinationsList.length > 0 && destinationsList.map((index, key) => {
                      return (
                        <div className="browseHomeColumnSideLeftFormListOption" onClick={() => chooseDestination(index)} key={key}>
                          <p className="browseHomeColumnSideLeftFormListOptionText">{index?.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
              }

              {!(isOpenDestinationsList || isOpenproceduresList) &&
                 pathname !== "/" ?

                 <button style={{ opacity: loadingSearch ? 0.5 : 1 }} className={`${disableBtn ? 'browseHomeColumnSideLeftFormBtnDisabled' : 'browseHomeColumnSideLeftFormBtn'}`}
                     onClick={() => !loadingSearch && gotoOption()} disabled={disableBtn}>
                     {!loadingSearch &&
                       <p className="browseHomeColumnSideLeftFormBtnText">{t("browse_home.search")}</p>}
                     <div className="browseHomeColumnSideLeftFormBtnIcon">
                       {
                         loadingSearch ?
                           <ActivityIndicator size={25} colorFill="#ffffff" />
                           :
                           <SvgIcon name={iconList.IconSearch} size={25} color={"#FFFFFF"} />
                       }
   
                     </div>
                   </button>
   
                 :
   
                   <button style={{ opacity: loadingSearch ? 0.5 : 1 }} className={`${disableBtn ? 'browseHomeColumnSideLeftFormBtnDisabled' : 'browseHomeColumnSideLeftFormBtn'}`}
                     onClick={() => !loadingSearch && searchForProceduresOnSite()} disabled={disableBtn}>
                     {!loadingSearch &&
                       <p className="browseHomeColumnSideLeftFormBtnText">{t("browse_home.search")}</p>}
                     <div className="browseHomeColumnSideLeftFormBtnIcon">
                       {
                         loadingSearch ?
                           <ActivityIndicator size={25} colorFill="#ffffff" />
                           :
                           <SvgIcon name={iconList.IconSearch} size={25} color={"#FFFFFF"} />
                       }
   
                     </div>
                   </button>
              }
            </div>

          </div>
          </div>
          <div className='contain-letter-banner-tourism'>
              <h2>Turismo <span>En salud</span></h2>
          </div>
        </div>
      </div>
      )
}

      export default BannerTourismHealth