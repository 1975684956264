import './styles.css';
import usePassengerInformationForm from "../../../viewModels/flights/passenger.information.form.viewModel";
import ActivityIndicator from '../../share/components/activityIndicator';
import SvgIcon, { iconList } from '../../share/components/iconSVG';
import ModalContainer from '../../share/components/modalContainer';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import TabletPassengers from './components/tabletPassengers';
import TabletItinerary from './components/tabletItinerary';
import { useTranslation } from 'react-i18next';
import Loading from '../../share/components/loading';
import Find from '../../share/components/Find/Find';
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices';
import { ArrowLeft } from 'iconsax-react';
import SendInvoicesFlight from './components/SendInvoce/SendInvoices';
import DetailBuyFlight from './components/detailBuyFlight/DetailBuy';
import DetailPayFlight from './components/detailPayFlight/DetailPay';
import { useState } from 'react';
import usePnrFlight from '../../../viewModels/flights/usePnrFlight';
import PersonFormFlight from './components/PersonForm/PersonFormFlight';


function PassengerInformationForm() {
  const {
    step,
    setStep,
    loadingScreen,
    userDetail,
    userToken,
    stepsList,
    savePNR,
    isLoading,
    showModal,
    message,
    location,
    setShowModalClick,
    controlNumberPNR,
    globalPNR,
    setGlobalPNR,
    dataSavePNR,
    showListPassenger,
    modalListPassenger,
    getDataToEdit,
    dataToEdit,
    setModalListPassenger,
    createLinkPay,
    loadingLink
  } = usePassengerInformationForm();

  const {
    savePNRN,
    formatearCantidad,
    volverPaginaAnterior,
    state,
    persons,
    childs,
    babys,
    errorReservation,
    buildTravellers,
    setTravellers,
    travellers 
  } = usePnrFlight()

  const [t] = useTranslation("global")
  let indicator = 1;


  for (let index = 0; index < state?.passengersSelected?.adult; index++) {
    persons?.push(<PersonFormFlight index={index + 1} indicator={indicator} key={index} type={"adult"} setPaxes={setTravellers} />)
    indicator ++; 
  }

  for (let index = 0; index < state?.passengersSelected?.boy; index++) {
    persons?.push(<PersonFormFlight index={index + 1} indicator={indicator} key={index} type={"child"} setPaxes={setTravellers} />)
  }

  for (let index = 0; index < state?.passengersSelected?.baby; index++) {
    persons?.push(<PersonFormFlight index={index + 1} indicator={indicator} key={index} type={"baby"} setPaxes={setTravellers} />)
    indicator ++; 

  }

  return (
    <div>
      <Find />
      <CarouselSevices current={servicesTravel[0].title} />
      <div className='contain-hotel-pay'>
        <ModalContainer
          show={modalListPassenger}
          hidden={() => { }}
          sizeWidth={60}
          sizeHeight={90}
          hiddenButton={true}
        >
          <div className='TabletPassengersContainer'>
            <div style={{ height: '25px' }} />
            <TabletPassengers
              list={dataSavePNR?.travellers}
              toEdit={getDataToEdit}
              editing={true}
              title={t("fligth.form_passenger.verify_passengers")}
            />
            <TabletItinerary
              list={dataSavePNR?.itinerary}
              toEdit={getDataToEdit}
              editing={true}
              title={t("fligth.form_passenger.verify_itinerary")}
            />
            <div className='TabletPassengersContainerBtnContainer'>
              <div className='TabletPassengersContainerBtnClocet' onClick={() => setModalListPassenger(false)}>
                <p className='TabletPassengersContainerBtnText'>
                  {t("fligth.form_passenger.close")}
                </p>
              </div>
              <div className='TabletPassengersContainerBtn' onClick={() => savePNR()}>
                <p className='TabletPassengersContainerBtnText'>
                  {t("fligth.form_passenger.ok")}
                </p>
              </div>
            </div>
          </div>
        </ModalContainer>
        <p onClick={volverPaginaAnterior} className='return-url'><ArrowLeft size={20} color='#004274'></ArrowLeft>{t("packages.packages_pay.back_page")}</p>
        <div className='layout-hotel-pay'>
          <section className='main-hotel-pay'>
            {
              persons?.map((item, index) => {
                return (
                  item
                )
              })
            }
            
            <SendInvoicesFlight />
          </section>
          <section className='aside-hotel-pay'>
            <DetailPayFlight acomodation={1} total={state?.price} />
            <DetailBuyFlight hab={state?.listReserve} nom={state?.hotel} arrival={state?.arrival} departure={state?.departure} address={state?.dataHot?.direccion} checkIn={state?.dataHot?.checkin} checkOut={state?.dataHot?.checkout} />
            <div className='content-button-reservation-hotel'>
              <button onClick={savePNRN}>Reservar</button>
              <p>Total a pagar <span>{formatearCantidad(state?.price)}</span></p>
              {
                errorReservation &&
                <div className='err-hotel-form-person'>
                  <p style={{ color: "white", fontWeight: "bold" }}>Para poder reservar debes llenar todos los datos</p>
                </div>
              }
            </div>
          </section>
        </div>
      </div>

    </div>
    // <div className="pifs">


    //   {/* <ModalContainer
    //     show={needLogin}
    //     hidden={hiddenModalSesion}
    //     sizeWidth={60}
    //     sizeHeight={50}
    //   >
    //     <RequiredSession navigation={goToScreen} />
    //   </ModalContainer> */}

    //   <ModalContainer
    //     show={showModal}
    //     hidden={setShowModalClick}
    //     sizeWidth={40}
    //     sizeHeight={50}
    //   >
    //     {isLoading ?
    //       <ActivityIndicator
    //         colorFill='#104172'
    //         size={60}
    //       /> :
    //       <div className='pifsModal'>
    //         {message && message?.code === "ERROR"
    //           ? <SvgIcon color={'red'} name={iconList?.IconCloseCircleOutLine} size={120} />
    //           : <SvgIcon color={'green'} name={iconList?.IconCheckSucces} size={120} />
    //         }
    //         <p className='pifsModalText'>{message?.text}</p>
    //         <p className='pifsModalCode'>{message?.code}</p>
    //         {message?.code !== 'ERROR' &&
    //           <p className='pifsModalText'>{t("fligth.form_passenger.send_email_correct")}</p>
    //         }
    //         <div className='pifsModalBtn' onClick={() => setShowModalClick(false)}>
    //           <p className='pifsModalBtnText'>{t("fligth.form_passenger.ok")}</p>
    //         </div>
    //       </div>

    //     }
    //   </ModalContainer>
    //   <ModalContainer
    //     show={loadingLink}
    //   >
    //     <Loading size={95}/>
    //   </ModalContainer>

    //   <div className='pifsStepsList'>
    //     {stepsList.map((index, key) => {
    //       const isActive = index.id === step
    //       return (
    //         <div key={key}
    //           onClick={() => index.available && setStep(key)}
    //           className={`pifsStep ${isActive ? 'pifsStepOn' : 'pifsStepOff'}`}>
    //           <p className={`pifsStepText ${isActive ? 'pifsStepTextOn' : 'pifsStepTextOff'}`}>
    //             {index.name}
    //           </p>
    //         </div>
    //       )
    //     })
    //     }
    //   </div>
    //   {loadingScreen ?
    //     <div className='loadingScreen'>
    //       <ActivityIndicator
    //         colorFill='#104172'
    //         size={60}
    //       />
    //     </div>
    //     :
    //     <>
    //       {step === 1 &&
    //         <Step1
    //           savePNR={showListPassenger}
    //           userDetail={userDetail}
    //           location={location}
    //           dataToEdit={dataToEdit}
    //         />
    //       }
    //       {step === 2 &&
    //         <Step2
    //           setStep={setStep}
    //           controlNumberPNR={controlNumberPNR}
    //           setGlobalPNR={setGlobalPNR}
    //           dataSavePNR={dataSavePNR}
    //         />
    //       }
    //       {step === 3 &&
    //         <Step3
    //           userDetail={userDetail}
    //           userToken={userToken}
    //           location={location}
    //           PNR={globalPNR}
    //           dataSavePNR={dataSavePNR}
    //           createLinkPay={createLinkPay}
    //         />
    //       }
    //     </>
    //   }
    // </div>
  )
}

export default PassengerInformationForm;
