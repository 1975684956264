import React from 'react'
import "./styles.css"
import SvgIcon, { iconList } from '../../../share/components/iconSVG';


const SuccessPay = ({value}) => {
  

  function obtenerNumero(ticke) {

    const numero = ticke.split("/")[0];

    return numero;
  }
  function formatearFecha(fechaISO) {
    // Crear un objeto de fecha a partir de la cadena ISO
    const fecha = new Date(fechaISO);
  
    // Obtener los componentes de la fecha y hora
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear();
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
  
    // Formatear la fecha y hora según el formato deseado
    const fechaFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}`;
  
    return fechaFormateada;
  }

  return (
    <div className='content-pay-success-flight'>
      <h3 className='letter-green'><SvgIcon color={"rgb(17, 167, 17)"} name={iconList.IconCheckSucces} size={35} /> Compra Finalizada Tickete Emitido</h3>
      <p style={{
        lineHeight:"20px"
      }}>
        Su transacción {value?.pay?.request_id} fue aprobada por la entidad financiera. En caso de tener dudas sobre el vuelo, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a compras@wellezy.com
      </p>
      <table className='table-info-tickets'>
        <tr>
          <th>#</th>
          <th>Ticket</th>
        </tr>
        {
          value?.reserve?.tickets?.map((item, key) => {
            return (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{obtenerNumero(item?.et)}</td>
              </tr>
            )
          })
        }
      </table>
      <table className='table-info-tickets'>
        <tr>
          <th>Referencia</th>
          <th>Valor</th>
          <th>Dia y Hora</th>
          <th>Status</th>
        </tr>
        <tr>
          <td>{value?.pay?.reference}</td>
          <td>{value?.pay?.price}</td>
          <td>{formatearFecha(value?.pay?.date)}</td>
          <td>{value?.pay?.status}</td>
        </tr>
      </table>
    </div>
  )
}

export default SuccessPay