import React from "react";
import { Grid } from '@mui/material';
import './styles.css';
import useFlightsSearch from "../../../viewModels/flights/flights.search.viewModel";
import FlightDetailFilter from "../components/flightDetailResultFilter";
import FlightDetailResultsList from "../components/flightDetailResultsList";
import SearchFlights from "../search.flights.view";
import useSearchFlights from "../../../viewModels/flights/search.flights.viewModel";


function Flights() {
  const {
    loading,
    nameOrigin, 
    setNameOrigin,
    nameDestiny, setNameDestiny,
    getFlights,
    queryError,
    flightsList,
    airlinesList,
    allPassengersSelected,
    setAllPassengersSelected,
    isRoundTripB
  } = useFlightsSearch();

  const isIframe = false;

  const {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,

    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,

    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,

    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    ellipsisText,
  } = useSearchFlights(isIframe);

  return (
    <Grid container justifyContent='center'>
      <div className='flightSelectedDetail'>
        <div className="content-search-fligth-result">
          <SearchFlights
            buttonDisable={buttonDisable}
            cardRefDate={cardRefDate}
            cardRefPassenger={cardRefPassenger}
            cardRefPlace={cardRefPlace}
            dateGoingSelected={dateGoingSelected}
            dateReturnSelected={dateReturnSelected}
            ellipsisText={ellipsisText}
            getPassengersSelecteds={getPassengersSelecteds}
            getSelectedDestination={getSelectedDestination}
            getSelectedOrigin={getSelectedOrigin}
            isCardRefDate={isCardRefDate}
            isCardRefDestination={isCardRefDestination}
            isCardRefOrigin={isCardRefOrigin}
            isManagingPassengers={isManagingPassengers}
            isRoundTrip={isRoundTrip}
            passengersSelecteds={passengersSelecteds}
            searcherFlight={searcherFlight}
            selectedDestination={selectedDestination}
            selectedOrigin={selectedOrigin}
            setDateGoingSelected={setDateGoingSelected}
            setDateGoingSelectedApi={setDateGoingSelectedApi}
            setDateReturnSelected={setDateReturnSelected}
            setDateReturnSelectedApi={setDateReturnSelectedApi}
            setIsCardRefDate={setIsCardRefDate}
            setIsCardRefDestination={setIsCardRefDestination}
            setIsCardRefOrigin={setIsCardRefOrigin}
            setIsManagingPassengers={setIsManagingPassengers}
            setIsRoundTrip={setIsRoundTrip}
          />
        </div>


        <FlightDetailResultsList
          allPassengersSelected={allPassengersSelected}
          queryError={queryError}
          isRoundTrip={isRoundTripB}
          loading={loading}
          flightsList={flightsList}
          nameOrigin={nameOrigin}
          nameDestiny={nameDestiny}
        />
      </div>
    </Grid>
  )
}

export default Flights;