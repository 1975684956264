import React from 'react'
import Find from '../../../share/components/Find/Find'
import CarouselSevices, { servicesTravel } from '../../../share/components/CarouselServices/CarouselSevices'
import DetailPayHotel from './detailPayTransport/DetailPayTransport'
import connectMiles from "../../../../assets/logos/connectmiles.png"
import "./styles.css"
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import ModalContainer from '../../../share/components/modalContainer'
import Loading from '../../../share/components/loading'
import SuccessPay from './successPay/SuccessPay'
import PendingPay from './pendingPay/PendingPay'
import RejectPay from './rejectPay/RejectPay'
import usePayTransport from '../../../../viewModels/transport/usePayTransport'

const PayTransport = () => {

    const {
        dataEmitter,
        incompleteInformation,
        dataValue,
        loadinPay,
        setLoadinPay,
        statusTransaction
    } = usePayTransport()
    return (
        <div>
            <Find />
            <CarouselSevices current={servicesTravel[3].title} />
            <div className="contain-hotel-pay">
                <ModalContainer
                    show={loadinPay}
                    hidden={setLoadinPay}
                    sizeWidth={60}
                    sizeHeight={50}
                >
                    <Loading size={90} />
                </ModalContainer>
                <div className='layout-hotel-pay'>
                    <section className='main-hotel-pay'>
                        <h2 style={{ color: "#004274" }}>Estado de la transaccion</h2>
                        <p>El estatus de tu pago actualmente:</p>
                        <div className='content-state-pay' style={{ backgroundColor: `${statusTransaction?.status?.status === "APPROVED" ? "rgb(125, 171, 125)" : "rgb(218, 115, 115)"}`, borderColor: `${statusTransaction?.status?.status === "APPROVED" ? "rgb(13, 91, 13)" : "rgb(91, 13, 13)"}` }}>
                            <h3>Estado:</h3>
                            <p>
                                <div style={{ backgroundColor: `${statusTransaction?.status?.status === "APPROVED" ? "green" : "red"}` }}></div>
                                {statusTransaction?.status?.status}
                            </p>
                        </div>
                        {
                            dataEmitter?.response?.respuesta &&
                            <SuccessPay amount={dataEmitter?.dataBase?.price} email={dataEmitter?.dataBase?.email} name={dataEmitter?.dataBase?.name} order={dataEmitter?.dataBase?.localizator} phone={dataEmitter?.dataBase?.cel} agency={"Wellezy"}/>
                        }
                        {
                            statusTransaction?.status?.status === "PENDING" &&
                            <PendingPay description={statusTransaction?.status?.message} name={statusTransaction?.request?.payer?.name + " " + statusTransaction?.request?.payer?.surname} email={statusTransaction?.request?.payer?.email} phone={statusTransaction?.request?.payer?.mobile} />
                        }

                        {
                            statusTransaction?.status?.status === "REJECTED" &&
                            <RejectPay description={statusTransaction?.status?.message} name={statusTransaction?.request?.payer?.name + " " + statusTransaction?.request?.payer?.surname} email={statusTransaction?.request?.payer?.email} phone={statusTransaction?.request?.payer?.mobile} />
                        }


                        <div className='connect-miles'>
                            <img src={connectMiles} alt="connectMiles" />
                            <p>By paying with your ConnectMiles credit card, you will earn more miles
                                on you purchase</p>
                        </div>

                        {/* <PaymentCards selectItem={setPaymentCardSelected} selected={paymentCardSelected} setIdUser={setIdUser} setEmailOwner={setEmail}/> */}

                        {
                            incompleteInformation !== "" &&
                            <div className='err-hotel-form-person'>
                                <p style={{ color: "white", fontWeight: "bold" }}>{incompleteInformation}</p>
                            </div>
                        }

                    </section>
                    <section className='aside-hotel-pay'>
                        <DetailPayHotel nom={dataValue?.description} covid={dataValue?.service?.covidPrepared} passengers={dataValue?.baby + dataValue?.passengers} img={dataValue?.service?.vehicleHDImage} vehicle={dataValue?.service?.vehicle} price={dataValue?.price} meet={dataValue?.service?.meetAndGreet}/>
                        <div className='pay-secure-hotel'>
                            <SvgIcon color={"#004274"} name={iconList.IconKey} size={40} />
                            <div>
                                <p>Pago Seguro</p>
                                <span>Los niveles más altos para los pagos con tarjetas</span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>

    )
}

export default PayTransport