import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CarouselServices, {servicesTravel} from '../../share/components/CarouselServices/CarouselSevices'
import Banner from './Banner/Banner'
import InfoDetailClinic from './infoClinic/InfoDetailClinic'
import Find from '../../share/components/Find/Find'
import './styles.css'
import ModalQuo from './ModalQuo/ModalQuo'
import UseDetailClinics from '../../../viewModels/clinics/UseDetailClinics'

const Detailsclinics = () => {

  const {
    state
  } = useLocation()

const navigate = useNavigate()

  const {
    sendInformation,
    name,
    setName,
    email,
    setEmail,
    error,
    succesScreen,
    description,
    setDescription,
    tel,
    setTel,
    subject,
    setSubject,
    setModal,
    modal,
    loadingS
  } = UseDetailClinics()

  useEffect(() => {
    const body = document.querySelector('body');
  modal ? body.style.overflow = 'hidden' : body.style.overflow = '';
  }, [modal])

  console.log(state.urlImg)

  const goReservation = ()=>{
    navigate(`/Clinic/reservation/${state?.name}`, {state:{
      name:state?.name,
      categories:state?.subCategories,
      urlImage:state?.pathImage,
      stars: state?.stars,
      id: state?.id
    }})
  }


  return (
    <div>
        <Find/>
        <CarouselServices current={servicesTravel[8].title}/>
        <Banner name={state.name} address={state.address} urlImg={state.urlImg} pathImage={state.pathImage}/>
        <InfoDetailClinic address={state.address} city={state.city} description={state.description} img={state.pathImage} name={state.name} stars={state.stars} subCategories={state.subCategories} />
        <div className='contain-btn-make-cita-clinic'>
           <button onClick={()=>{
            goReservation()
           }} className='btn-make-cita-clinic'>Agendar Cita</button>
        </div>
        {
        modal &&
        <ModalQuo
          setModal={setModal}
          description={description}
          setDescription={setDescription}
          email={email}
          setEmail={setEmail}
          tel={tel}
          setTel={setTel}
          name={name}
          setName={setName}
          subject={subject}
          setSubject={setSubject}
          sendInformation={sendInformation}
          succesScreen={succesScreen}
          error={error}
          id={state?.id}
          loading={loadingS}
        />
      }
       
    </div>
  )
}

export default Detailsclinics