import React from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";

const InfoDetailClinic = ({
  description,
  subCategories,
  stars,
  city,
  img,
  address,
  name,
}) => {
  return (
    <div className="contain-info-detail-clinic">
      <section className="detalle-info-doctor-p">
        <h3>Informacion</h3>
        <p>{description}</p>
        <section className="first-info-clinic-rate">
          <ul className="list-star-first-info">
            <li>
              <SvgIcon name={iconList.IconStar} size={30} color={"#ffff00"} />
            </li>
            <li>
              <SvgIcon name={iconList.IconStar} size={30} color={"#ffff00"} />
            </li>
            <li>
              <SvgIcon name={iconList.IconStar} size={30} color={"#ffff00"} />
            </li>
            <li>
              <SvgIcon name={iconList.IconStar} size={30} color={"#ffff00"} />
            </li>
            <li>
              <SvgIcon name={iconList.IconStar} size={30} color={"#ffff00"} />
            </li>
          </ul>
          <div className="points-first-info">
            <div className="rate-detail-specialist">
              <p>{stars}</p>
            </div>
            <div>
              <p style={{ color: "#004274", fontWeight: "700" }}>
                {stars >= 5 ? "Excelente" : "Bueno"}
              </p>
              <p>Ver comentarios</p>
            </div>
          </div>
        </section>
      </section>
      <section className="list-detalle-especialidades">
        <h3>Especialidades</h3>
        <div className="list-detalle-especialidades-layout">
          <ul>
            {subCategories.map((item, key) => {
              return (
                <li key={key}>
                  <SvgIcon
                    name={iconList.IconCheckSucces}
                    size={30}
                    color={"#004274"}
                  />
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
        <h3>Oficinas</h3>

        <div className="office-work-doctor">
          <div className="office-work-doctor-header">
            <img src={`https://staging.aiop.com.co/storage/${img}`} alt="img" />
            <div>
              <h4>{name}</h4>
              <p>
                <span>Ciudad:</span> {city.name}
              </p>
              <p>
                <span>Direccion:</span> {address}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InfoDetailClinic;
