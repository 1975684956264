import './styles.css';
import SvgIcon, { iconList } from '../iconSVG';
import { useTranslation } from 'react-i18next';

function RequiredSession({ navigation }) {

  const [t,i18n] = useTranslation("global")

  return (
    <div className='requiredSession'>
      <div className='requiredSessionIcon'>
        <SvgIcon color={'#004274'} name={iconList.IconUserCircleOutLine} size={130} />
      </div>
      <p className='requiredSessionTitle'>{t("fligth.required_session.text")}</p>
      <div className='requiredSessionPannel'>
        <div onClick={() => navigation('/login')} className='requiredSessionBtn'>
          <p className='requiredSessionBtnText'>
          {t("fligth.required_session.login")}
          </p>
        </div>
        <div onClick={() => navigation('/register')} className='requiredSessionBtn'>
          <p className='requiredSessionBtnText'>
          {t("fligth.required_session.register")}
          </p>
        </div>
      </div>
    </div>
  )
}
export default RequiredSession;
