import "./assets/styles.css";

import banner from "./assets/img/banner.jpg";
import leters from "./assets/img/letters.png";
import Find from "../../ui/views/share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import personPhone2 from "../../ui/assets/pictures/girlFilgth.svg";
import personPhone from "../../ui/assets/pictures/menFligth.svg";
import SearchFlights from "../../ui/views/flights/search.flights.view";
import AnyQuestions from "../../ui/views/home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import useSearchFlights from "../../ui/viewModels/flights/search.flights.viewModel";

const FligthsNew = ({ isWidget = false }) => {
  const [t, i18n] = useTranslation("global");


  const isIframe = false;

  const {
    cardRefPlace,
    cardRefPassenger,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    buttonDisable,
    searcherFlight,

    //ciudades
    //ida
    isCardRefOrigin,
    setIsCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,

    //retorno
    isCardRefDestination,
    setIsCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    //fechas
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    isCardRefDate,
    setIsCardRefDate,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,

    //pasajeros
    isManagingPassengers,
    setIsManagingPassengers,
    passengersSelecteds,
    getPassengersSelecteds,
    ellipsisText,
  } = useSearchFlights(isIframe);

  return (
    <div>
      {!isWidget && <Find />}
      <CarouselSevices current={t("carousel_services.fligth")} />
      <header className="header-fligths">
        <div className="content-buscador-fli">
          <SearchFlights
            buttonDisable={buttonDisable}
            cardRefDate={cardRefDate}
            cardRefPassenger={cardRefPassenger}
            cardRefPlace={cardRefPlace}
            dateGoingSelected={dateGoingSelected}
            dateReturnSelected={dateReturnSelected}
            ellipsisText={ellipsisText}
            getPassengersSelecteds={getPassengersSelecteds}
            getSelectedDestination={getSelectedDestination}
            getSelectedOrigin={getSelectedOrigin}
            isCardRefDate={isCardRefDate}
            isCardRefDestination={isCardRefDestination}
            isCardRefOrigin={isCardRefOrigin}
            isManagingPassengers={isManagingPassengers}
            isRoundTrip={isRoundTrip}
            passengersSelecteds={passengersSelecteds}
            searcherFlight={searcherFlight}
            selectedDestination={selectedDestination}
            selectedOrigin={selectedOrigin}
            setDateGoingSelected={setDateGoingSelected}
            setDateGoingSelectedApi={setDateGoingSelectedApi}
            setDateReturnSelected={setDateReturnSelected}
            setDateReturnSelectedApi={setDateReturnSelectedApi}
            setIsCardRefDate={setIsCardRefDate}
            setIsCardRefDestination={setIsCardRefDestination}
            setIsCardRefOrigin={setIsCardRefOrigin}
            setIsManagingPassengers={setIsManagingPassengers}
            setIsRoundTrip={setIsRoundTrip}
          />
        </div>
        <div className="content-banner">
          <img src={banner} alt="" />
        </div>
      </header>

      {/* <section className="option-traveling">
        <div className="card-option-traveling">
          <div className="card-option-traveling-img">
            <img className="img1" src={personPhone2} alt="imagen1" />
          </div>
          <div className="card-option-traveling-info">
            <h3>{t("fligth.cards.travel_colombia")}</h3>
            <p>{t("fligth.cards.text_colombia")}</p>
            <button>{t("fligth.cards.see_offers")}</button>
          </div>
        </div>
        <div className="card-option-traveling">
          <div className="card-option-traveling-img">
            <img className="img2" src={personPhone} alt="imagen" />
          </div>
          <div className="card-option-traveling-info">
            <h3>{t("fligth.cards.travel_world")}</h3>
            <p>{t("fligth.cards.text_world")}</p>
            <button>{t("fligth.cards.see_offers")}</button>
          </div>
        </div>
      </section> */}
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default FligthsNew;
