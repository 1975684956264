import React from 'react'
import Visualizador from '../../Components/AlliesDetailsMain/Visualizador'
const RouteVisualizador = () => {
  return (
    <div>
        <Visualizador></Visualizador>
    </div>
  )
}

export default RouteVisualizador