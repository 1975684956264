
import React from 'react';
import './styles.css';


function CardRegisterNow({ type }) {
  return (
    <div className='cardRegisterNow'>
      
      <div className='cardRegisterNowSectionImg'>
        <img src={type.img} alt={type.title} className='cardRegisterNowSectionImgImage' />
      </div>

      <div className='cardRegisterNowSectionInfo'>
        <div className='cardRegisterNowSectionInfoContainer'>
          <div className='cardRegisterNowSectionInfoText'>
            <p className='cardRegisterNowSectionInfoTitle'>{type.title}</p>
            <p className='cardRegisterNowSectionInfoDescription'>{type.description}</p>
          </div>
          <a href={`${type.url}?type=${type.typeUser}`  }>
          <div className='cardRegisterNowSectionInfoBtn'>
            <p className='cardRegisterNowSectionInfoBtnText'>{type.register}</p>
          </div>
          </a>
        </div>
      </div>
      
    </div>
  )
};

export default CardRegisterNow;
