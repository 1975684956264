import axios from "axios";
import React, { useEffect, useState } from "react";
import AnyQuestions from "../../ui/views/home/components/anyQuestion";
import Find from "../../ui/views/share/components/Find/Find";
import CarouselSevices from "../../ui/views/share/components/CarouselServices/CarouselSevices";

import { ca } from "react-date-range/dist/locale";
import { CircularProgress } from "@mui/material";
import { ReactComponent as IconCheck } from './Assets/svg/IconCheck.svg'
import { ReactComponent as IconFail } from './Assets/svg/IconFail.svg'
import { ReactComponent as IconProcess } from './Assets/svg/IconProcess.svg'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PackageTourismVoucher = () => {

    const env = process.env.REACT_APP_URL_ENV;
    const requestId = localStorage.getItem("requestId");
    const price = localStorage.getItem("price");
    const currency = localStorage.getItem("currency");
    const namePackage = localStorage.getItem("namePackage");

    let urlWellezy;
    let url;

    const [dataVoucher, setDataVoucher] = useState({});
    const [loading, setLoading] = useState(true);
    const [statusPay, setStatusPay] = useState("")

    const [t] = useTranslation("global");

    const navigate = useNavigate();

    switch (env) {
        case "local":
            urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_LCH;
            url = process.env.REACT_APP_URL_API_AIOP_LCH;
            break;
        case "staging":
            urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_LCH;
            url = process.env.REACT_APP_URL_API_AIOP_STG;
            break;
        case "production":
            urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_PRT;
            url = process.env.REACT_APP_URL_API_AIOP_PRT;
            break;
        default:
            urlWellezy = process.env.REACT_APP_URL_API_WELLEZY_LCH;
            url = process.env.REACT_APP_URL_API_AIOP_LCH;
            break;
    }

    const goToBuyAgain = () => {
        navigate("/packages")
    }

    const generateVoucher = async () => {
        try{
            const response = await axios.post(`${url}api/reserve/pack`, {
                "request_id": requestId,
            })

            setDataVoucher(response.data);

            console.log(response)

            if(response.data.pay.status === "APPROVED"){
                setStatusPay("APPROVED")
                setLoading(false);
            }else if(response.data.pay.status === "REJECTED"){
                setStatusPay("REJECTED")
                setLoading(false);
            }else if(response.data.pay.status === "PENDING"){
                setStatusPay("PENDING")
                setLoading(false);
            }
        }catch(error){
            console.error("Error en la llamada a la API de vouchers", error);
        }finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        generateVoucher();
    }, []);

    return (
        <div>
            <Find />
            <CarouselSevices current={t("carousel_services.travel_secure")}/>
           {
            loading ? (
                <div className="container-loading-packages">
                    <CircularProgress/>
                </div>
            ):(
                <div className="container-voucher-terrawind">
                    <div className="voucher-terrawind">
                        {
                            statusPay === "APPROVED" ? (
                                <>
                                    <div className="title-generate-voucher">
                                        <p><span><IconCheck /></span> COMPRA FINALIZADA</p>
                                    </div>
                                    <div className="product-price-terrawind">
                                        <p>Gracias por tu compra. Tu transacción se ha completa con éxito</p>
                                        <p>Producto: <span>{namePackage}</span></p>
                                        <p>Cantidad pagada: <span>{parseInt(price).toLocaleString()} { currency }</span></p>
                                    </div>
                                </>
                            ): statusPay === "REJECTED" ? (
                                <>
                                    <div>
                                        <p className="title-p-pay-rejected-terrawind"><span><IconFail/></span> Tarjeta rechazada</p>
                                    </div>
                                    <div className="details-fail-pay">
                                        <p>Lo sentimos tu transacción no se ha completado con éxito</p>
                                        <p>Producto: <span>{namePackage}</span></p>
                                        <p>Cantidad intentada pagar: <span>{parseInt(price).toLocaleString()} { currency }</span></p>
                                    </div>
                                </>
                            ): statusPay === "PENDING" ? (
                                <>
                                    <div>
                                        <p className="title-p-pay-process-terrawind"><span><IconProcess/></span> Compra en proceso</p>
                                    </div>
                                    <div className="details-fail-pay">
                                        <p>Tu compra está en proceso. Gracias por tu paciencia. ¡Pronto tendrás noticias!</p>
                                        <p>Producto: <span>{namePackage}</span></p>
                                        <p>Cantidad a pagar: <span>{parseInt(price).toLocaleString()} { currency }</span></p>
                                    </div>
                                </>
                            ): null
                        }
                        {
                            statusPay === "APPROVED" ? (
                                <div className="container-table-terrawind">
                                    <table className="table-voucher-terrawind">
                                        <thead>
                                            <tr>
                                                <th>Pasajero</th>
                                                <th>Nombre</th>
                                                <th>Apellido</th>
                                                <th>Nacionalidad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Array.isArray(dataVoucher.reserve.people_packs) ? (
                                                    dataVoucher.reserve.people_packs.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.lastname}</td>
                                                            <td>{item.nacionality}</td>
                                                        </tr>
                                                    ))
                                                ):(
                                                    <tr>
                                                        <td>1</td>
                                                        <td>{dataVoucher.reserve.people_packs.name}</td>
                                                        <td>{dataVoucher.reserve.people_packs.lastname}</td>
                                                        <td>{dataVoucher.reserve.people_packs.nacionality}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ): null
                        }
                        <div className="btn-buy-again">
                            <button className="btn-download-terrawind" onClick={goToBuyAgain}>Volver a comprar</button>
                        </div>
                    </div>
                </div>
            )
           }
            <AnyQuestions title={t('browse_home.have_doubt')}/>
        </div>
    );
}

export default PackageTourismVoucher;