import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const usePnrFlight = () => {

    const [errorReservation, setErrorReservation] = useState(false)
    const [travellers, setTravellers] = useState([])
    const [travellersR, setTravellersR] = useState([])
    const persons = []
    const childs = []
    const babys = []

    const navigate = useNavigate();
    const { state } = useLocation()


    console.log(state)

    const volverPaginaAnterior = () => {
        navigate(-1);
    };




    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return cantidadFormateada;
    }


    const buildFree = (date, sex) => {
        // Separar la fecha en año, mes y día
        const [year, month, day] = date.split('-');
        
        // Array con los nombres de los meses abreviados
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        
        // Obtener la abreviatura del mes según el número de mes
        const monthAbbr = months[parseInt(month) - 1];
        
        // Obtener los últimos dos dígitos del año
        const yearAbbr = year.slice(-2);
        
        // Concatenar los componentes para formar el formato deseado
        const formattedDate = `----${day}${monthAbbr}${yearAbbr}-${sex}--`;
        
        return formattedDate;
    };

    const buildTravellers = () => {

        travellers.forEach((item, key) => {
                if (item.doc) {
                    const newData = [...travellersR];
                    newData.push({
                        number: key + 1,
                        free: buildFree(item?.birth, item?.sex),
                        typeDocument: item?.typeDoc,
                        name: item?.name,
                        lastName: item?.lastname,
                        adult: item?.adult,
                        baby: []
                    })
                    setTravellersR(newData);
                } else if (item.child) {
                    const newData = [...travellersR];
                    newData.push({
                        number: key + 1,
                        free: buildFree(item?.birth, item?.sex),
                        birth:item?.birth,
                        name: item?.name,
                        lastName: item?.lastname,
                        child: item?.child,
                        birth: item?.birth
                    })
                    setTravellersR(newData);
                } else if (item.offspring) {
                    // Clonar TravellersR
                    const newData = [...travellersR];
                    // Agregar el baby objeto al campo "baby" del adulto
                    newData[0].baby.push({
                        number: newData[0].number,
                        free: buildFree(item?.birth, item?.sex),
                        name: item?.name,
                        lastName: item?.lastname,
                        birth:item?.birth,
                        offspring: item?.offspring === "true" ? true: false
                    });
                    // Actualizar el estado
                    setTravellersR(newData);
                } else {
                    const newData = [...travellersR];
                    newData.push({
                        number: key + 1,
                        free: buildFree(item?.birth, item?.sex),
                        typeDocument: item?.typeDoc,
                        name: item?.name,
                        lastName: item?.lastname,
                        adult: item?.adult,
                        baby: []
                    })
                    setTravellersR(newData);
                }
        });
    };

    const savePNRN = () => {
        const requestPNR = {
            "company": state?.company,
            "bag": state?.bag,
            "fare": state?.fare,
            "tel": travellers[0]?.tel,
            "email": travellers[0]?.email?.replace("@","//"),
            "emailTwo": travellers[0]?.email,
            "travellers": travellersR,
            "itinerary":state?.itinerary
                
        }
        console.log(requestPNR)
    }

    useEffect(() => {
        buildTravellers()
    }, [travellers])

    useEffect(() => {
        console.log(travellersR)
    }, [travellersR])


    return {
        savePNRN,
        formatearCantidad,
        volverPaginaAnterior,
        state,
        persons,
        childs,
        babys,
        errorReservation,
        buildTravellers,
        travellers,
        setTravellers
    }
}

export default usePnrFlight