import React from 'react'
import Find from '../../share/components/Find/Find'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import "./styles.css"
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import ModalContainer from '../../share/components/modalContainer'
import Loading from '../../share/components/loading'
import SuccessPay from './successPay/SuccessPay'
import PendingPay from './pendingPay/PendingPay'
import RejectPay from './rejectPay/RejectPay'
import usePayFlights from '../../../viewModels/flights/usePayFlights'
import DetailPayFlight from './detailPayFlight/DetailPayFlight'


import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Skeleton } from '@mui/material'

const FlightsPay = () => {

  const {
    flightInfo,
    incompleteInformation,
    loading,
    statePay
  } = usePayFlights()

  return (
    <div>
      <Find />
      <CarouselSevices current={servicesTravel[1].title} />
      <div className="contain-hotel-pay">
        <ModalContainer
          show={false}
          hidden={() => { }}
          sizeWidth={80}
          sizeHeight={50}
        >
          <Loading size={90} />
        </ModalContainer>

        <div className='layout-hotel-pay'>
          <section className='main-hotel-pay'>
            {
              loading ?
                <Card sx={{ Width: "100%", m: 2 }}>
                  <CardHeader
                    title={
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={10} width="60%" />
                    }
                  />
                  <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
                  <CardContent>
                    <React.Fragment>
                      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>

                  </CardContent>
                </Card>
                :
                <>
                  {
                    statePay?.pay?.status === "APPROVED" &&
                    <SuccessPay value={statePay}/>
                  }
                  {
                    statePay?.pay?.status === "PENDING" &&
                    <PendingPay requestSuccess={statePay}/>
                  }
                  {
                    statePay?.pay?.status === "REJECTED" &&
                    <RejectPay requestSuccess={statePay}/>
                  }
                  {
                    incompleteInformation !== "" &&
                    <div className='err-hotel-form-person'>
                      <p style={{ color: "white", fontWeight: "bold" }}>{incompleteInformation}</p>
                    </div>
                  }
                </>
            }
          </section>
          <section className='aside-hotel-pay'>
            {
              loading ?
              <>
              <Card sx={{ Width: "100%", m: 2 }}>
              <CardHeader
                title={
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                }
                subheader={
                  <Skeleton animation="wave" height={10} width="0%" />
                }
              />
              <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
              <CardContent>
                <React.Fragment>
                  <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" height={10} width="80%" />
                </React.Fragment>
              </CardContent>
            </Card>
            <Card sx={{ Width: "100%", m: 2 }}>
            <CardContent>
                <React.Fragment>
                  <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" height={10} width="80%" />
                </React.Fragment>
              </CardContent>
            </Card>
            </>
              :
                <>
                  <DetailPayFlight info={flightInfo}/>
                  <div className='pay-secure-hotel'>
                    <SvgIcon color={"#004274"} name={iconList.IconKey} size={40} />
                    <div>
                      <p>Pago Seguro</p>
                      <span>Los niveles más altos para los pagos con tarjetas</span>
                    </div>
                  </div>
                </>
            }
          </section>
        </div>
      </div>

    </div>

  )
}

export default FlightsPay