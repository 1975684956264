import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Assets/styles.css";

function SelectDestination({destination, setDestination, validate, setValidate}){
    const env = process.env.REACT_APP_URL_ENV

    let url;

    const [destinations, setDestinations] = useState([])
    const [error, setError] = useState(null)

    switch (env) {
        case "local":
          url = process.env.REACT_APP_URL_API_AIOP_LCH;
          break;
        case "staging":
          url = process.env.REACT_APP_URL_API_AIOP_STG;
          break;
        case "production":
          url = process.env.REACT_APP_URL_API_AIOP_PRT;
          break;
      }

    const fetchDestinations = async () => {
        try {
            const response = await axios.get(`${url}api/terrawind/destinations`)
            setDestinations(response.data)
        } catch (error) {
            setError(error)
        }
    }

    useEffect(() => {
        fetchDestinations()
    }, []);

    const handleSelection = (event) => {
        setDestination(event.target.value);
        setValidate("");
    };

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return(
        <div className="container-select-destination">
            <label htmlFor="">Destino</label>
            <select name="" id="" onChange={handleSelection}
                className={
                    validate === "destination"
                        ? "place-select input-validate"
                        : "place-select"
                }
            >
                <option value="">Seleccione</option>
                {
                    destinations.map((destination, index) => (
                        <option value={destination.region_name} key={index}>{destination.region_name}</option>
                    ))
                }
            </select>
            { validate === "destination" ? (<p className="validate-p">El campo es requerido</p>): ""}
        </div>
    )
}

export default SelectDestination;