import React from 'react'
import Find from '../../../share/components/Find/Find'
import CarouselSevices, { servicesTravel } from '../../../share/components/CarouselServices/CarouselSevices'
import UsePayHotel from '../../../../viewModels/hotels/UsePayHotel'
import PaymentCards from '../../../profileUser/components/paymentCards'
import DetailPayHotel from './detailPayHotel/DetailPayHotel'
import connectMiles from "../../../../assets/logos/connectmiles.png"
import "./styles.css"
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import ModalContainer from '../../../share/components/modalContainer'
import Loading from '../../../share/components/loading'
import SuccessPay from './successPay/SuccessPay'
import PendingPay from './pendingPay/PendingPay'
import RejectPay from './rejectPay/RejectPay'

const PayHotel = () => {

    const {
        paymentCardSelected,
        setPaymentCardSelected,
        state,
        dataPay,
        responseApi,
        setResponseApi,
        loadinPay,
        incompleteInformation,
        setIdUser,
        setEmail,
        goToLinkPay,
        statusTransaction,
        stateValue,
        dataEmitter
    } = UsePayHotel()
    return (
        <div>
            <Find />
            <CarouselSevices current={servicesTravel[1].title} />
            <div className="contain-hotel-pay">
                <ModalContainer
                    show={responseApi}
                    hidden={setResponseApi}
                    sizeWidth={60}
                    sizeHeight={50}
                >

                    {
                        loadinPay &&
                        <Loading size={90}/>
                    }
                    {/* {
                        loadinPay ?

                            <Loading size={90} />

                            :

                            <div className='detail-reservation-result'>
                                <h2>Pago realizado exitosamente en el hotel: {dataPay?.reserve?.hotel?.hotel}</h2>
                                <h3>Datos del pago</h3>
                                <p>Se te enviara toda la información por el correo</p>
                                <ul>
                                    <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25} /> <span>Estado de la transaccion: </span> {dataPay?.pay?.pay?.transaction?.current_status}</li>
                                    <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25} /> <span>Tomador: </span> {dataPay?.reserve?.hotel?.name}</li>
                                    <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25} /> <span>Correo: </span> {dataPay?.reserve?.hotel?.email}</li>
                                    <li><SvgIcon color={"#004274"} name={iconList.IconListItem} size={25} /> <span>Precio de la reserva: </span> {dataPay?.pay?.pay?.transaction?.amount}</li>
                                </ul>
                            </div>
                    } */}

                </ModalContainer>
                <div className='layout-hotel-pay'>
                    <section className='main-hotel-pay'>
                        <h2 style={{ color: "#004274" }}>Estado de la transaccion</h2>
                        <p>El estatus de tu pago actualmente:</p>
                        <div className='content-state-pay' style={{backgroundColor:`${statusTransaction?.pay?.status === "APPROVED" ? "rgb(125, 171, 125)" : "rgb(218, 115, 115)"}`, borderColor:`${statusTransaction?.pay?.status === "APPROVED" ? "rgb(13, 91, 13)" : "rgb(91, 13, 13)"}`}}>
                            <h3>Estado:</h3>
                            <p>
                                <div style={{backgroundColor:`${statusTransaction?.pay?.status === "APPROVED" ? "green" :"red"}` }}></div>
                                {statusTransaction?.pay?.status}
                            </p>
                        </div>
                        {
                            dataEmitter?.reserve?.name &&
                            <SuccessPay amount={dataEmitter?.pay?.price} email={dataEmitter?.reserve?.email} name={dataEmitter?.reserve?.name} order={dataEmitter?.pay?.reference} phone={dataEmitter?.reserve?.cel} agency={"Wellezy"} arrival={dataEmitter?.reserve?.arrival} departure={dataEmitter?.reserve?.departure} hotel={dataEmitter?.reserve?.hotel}/>
                        }
                        {
                            statusTransaction?.pay?.status === "PENDING" &&
                            <PendingPay  name={statusTransaction?.reserve?.name}  email={statusTransaction?.reserve?.email} phone={statusTransaction?.reserve?.cel} />
                        }

{
                            statusTransaction?.pay?.status === "REJECTED" &&
                            <RejectPay  name={statusTransaction?.reserve?.name}  email={statusTransaction?.reserve?.email} phone={statusTransaction?.reserve?.cel} />
                        }
                        
                        
                        <div className='connect-miles'>
                            <img src={connectMiles} alt="connectMiles" />
                            <p>By paying with your ConnectMiles credit card, you will earn more miles
                                on you purchase</p>
                        </div>

                        {/* <PaymentCards selectItem={setPaymentCardSelected} selected={paymentCardSelected} setIdUser={setIdUser} setEmailOwner={setEmail}/> */}

                        {
                           incompleteInformation !== "" &&
                            <div className='err-hotel-form-person'>
                                <p style={{ color: "white", fontWeight: "bold" }}>{incompleteInformation}</p>
                            </div>
                        }

                    </section>
                    <section className='aside-hotel-pay'>
                        <DetailPayHotel listReserve={stateValue?.listReserve} nom={stateValue?.description} checkIn={stateValue?.checkIn} checkOut={stateValue?.checkOut} img={stateValue?.foto} address={stateValue?.address} price={stateValue?.price} />
                        <div className='pay-secure-hotel'>
                            <SvgIcon color={"#004274"} name={iconList.IconKey} size={40} />
                            <div>
                                <p>Pago Seguro</p>
                                <span>Los niveles más altos para los pagos con tarjetas</span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>

    )
}

export default PayHotel