import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Box from '@material-ui/core/Box';
import CardPlan from "./CardPlan/CardPlan";
import CarouselSevices from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import Container from '@material-ui/core/Container';
import Find from "../../ui/views/share/components/Find/Find";
import ActivityIndicator from "../../ui/views/share/components/activityIndicator";
import "./Assets/styles.css";

import {useTranslation} from "react-i18next"
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";


function TerrawindPlan(){
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(true)
    const [t] = useTranslation("global")

    const location = useLocation();
    const dataString = decodeURIComponent(location.pathname.split('/')[3]);
    const data = JSON.parse(dataString);
    const env = process.env.REACT_APP_URL_ENV

    let url;

    switch (env) {
        case "local":
          url = process.env.REACT_APP_URL_API_AIOP_LCH;
          break;
        case "staging":
          url = process.env.REACT_APP_URL_API_AIOP_STG;
          break;
        case "production":
          url = process.env.REACT_APP_URL_API_AIOP_PRT;
          break;
      }

    const getPlans = async () => {
        try{
            const response = await axios.post(`${url}api/terrawind/products`, data);
            const plansArray = Object.values(response.data);
            setPlans(plansArray);
        }catch(error){
            console.error("Error en la llamada a la API de planes", error)
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
       getPlans()
    }, [])

    const filteredPlans = useMemo(() => {
        return plans.filter(plan => !(data.passengers === 1 && plan.product_type !== "3") && plan.local_price !== "Price not available")
    }, [plans, data])

    return(
        <div>                    
            <Find/>
            <CarouselSevices current={t("carousel_services.travel_secure")}/>
            {loading ? (
                <div className="loading">
                    <ActivityIndicator colorFill='#0072B9' size={50} />
                </div>
            ):(
                <div>
                    <Container>
                        <Grid container spacing={3} className="card-plans">
                            {
                                filteredPlans.map((plan) => {
                                    const { product_name, local_price, product_id } = plan;
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={product_id} className="card-plan">
                                            <Box display="flex" justifyContent="center">
                                                <CardPlan 
                                                    name={product_name} 
                                                    price={local_price}
                                                    passengers={data.passengers}
                                                    dateOrigin={data.date_from}
                                                    dateDestination={data.date_to}
                                                    destination={data.destination}
                                                    origin={data.origin}
                                                    productId={product_id}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container>
                </div>
            )}
        </div>
    )
}

export default TerrawindPlan;