import './styles.css'
function JoinUs() {
  return (
    <div className='pqrsJoinUsContainer'>
      <p className='pqrsJoinUsContainerText'>Para una ayuda mas personalizada sobre resrevas, la cuenta y otros asuntos inicia sección</p>
      <div className='pqrsJoinUsContainerBtn' >
        <a href={'/login'} className='pqrsJoinUsContainerBtnText'>Iniciar sesión o registrarse</a>
      </div>
    </div>
  )
}

export default JoinUs;
