import React, { useState, useEffect, useRef, useContext } from "react";
import { iconList } from "../../views/share/components/iconSVG";
import Axios from "axios";
import { keys, set as setLocalStorage } from "../../localStorage";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../Context/user/userProvider";

function useRegisterUser() {
  const { setUserDetail, setUserToken } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [inputError, setInputError] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showValidationPassword, setShowValidationPassword] = useState(false);
  const [activeInput, setActiveInput] = useState(null);
  const [formRegister, setFormRegister] = useState({
    name: "",
    surname: "",
    email: "",
    phones: [],
    gender: 0,
    dateOfBirth: null,
    avatar: null,
    status: "active",
    type: "customer",
    address: [],
    password: "",
    confirmPassword: "",
  });

  const [errorFormatPasswor, setErrorFormatPasswor] = useState({
    number: false,
    letters: false,
    upperCaseLetters: false,
    specialCharacter: false,
    minimumAndMaximumNumberOfCharacters: false,
  });

  const errorPasswordStatus = [
    { comply: errorFormatPasswor.number, text: "Debe tener tener números" },
    { comply: errorFormatPasswor.letters, text: "Debe tener tener letras" },
    {
      comply: errorFormatPasswor.upperCaseLetters,
      text: "Debe tener tener al menos una mayúscula",
    },
    {
      comply: errorFormatPasswor.specialCharacter,
      text: "Debe tener tener un carácter especial",
    },
    {
      comply: errorFormatPasswor.minimumAndMaximumNumberOfCharacters,
      text: "Carácteres mínimos: 8, máximos: 16",
    },
  ];

  const regexList = {
    name: /^[A-Za-z\s']+$/,
    surname: /^[A-Za-z\s']+$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  };

  const screensList = {
    home: "/",
    loging: "/login",
  };

  const handleInputFocus = (event) => {
    const key = event.target.id;
    setActiveInput(key);
  };

  function getInputsForm(event) {
    const value = event.target.value;
    const key = event.target.id;
    const regex = regexList[key];
    const errorClear = inputError.filter((obj) => obj.key !== key);
    const newError = { key, message: event.target.name };
    if (regex) {
      const validation = regex.test(value);
      const errorExists = inputError.find((obj) => obj.key === key);
      if (!validation) {
        if (!errorExists) {
          if (errorExists && errorExists.length > 0) {
            setInputError([...inputError, newError]);
          } else {
            setInputError([newError]);
          }
        }
      } else if (errorExists) {
        setInputError([...errorClear]);
      }
    }
    if (value === "") {
      setInputError(errorClear);
    }
    setFormRegister({
      ...formRegister,
      [key]: value,
    });
    if (key === "password") {
      passwordValidation(event);
    }
    if (key === "confirmPassword") {
      if (formRegister.password !== value) {
        setInputError([...inputError, newError]);
      } else {
        setInputError(errorClear);
      }
    }
  }

  function setInputsForm(value, key) {
    setFormRegister({
      ...formRegister,
      [key]: value,
    });
  }

  const inputsStpe1 = [
    {
      label: "Nombres",
      focus: handleInputFocus,
      icono: iconList.IconPencilOutLine,
      type: "text",
      id: "name",
      value: formRegister.name,
      placeHolder: "agrega tu nombre",
      require: true,
      func: getInputsForm,
      name: "el nombre no valido",
    },
    {
      label: "Email",
      focus: handleInputFocus,
      icono: iconList.IconPencilOutLine,
      type: "text",
      id: "email",
      value: formRegister.email,
      placeHolder: "Agrega tu email",
      require: true,
      func: getInputsForm,
      name: "El email es invalido",
    },
    {
      label: "Intruduce una contraseña",
      focus: handleInputFocus,
      icono: iconList.IconPencilOutLine,
      type: showPassword ? "text" : "password",
      id: "password",
      value: formRegister.password,
      placeHolder: "Intruduce una contraseña",
      require: true,
      func: getInputsForm,
      name: "Contraseña no valida",
    },
    {
      label: "Confirma tu contraseña",
      focus: handleInputFocus,
      icono: iconList.IconPencilOutLine,
      type: showPassword ? "text" : "password",
      id: "confirmPassword",
      value: formRegister.confirmPassword,
      placeHolder: "Confirma tu contraseña",
      require: true,
      func: getInputsForm,
      name: "Contraseña no coincide",
    },
  ];

  const inputsList = inputsStpe1;

  function passwordValidation(event) {
    const value = event.target.value;
    const response = {
      number: /\d/.test(value),
      letters: /[a-zA-Z]/.test(value),
      upperCaseLetters: /[A-Z]/.test(value),
      specialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      minimumAndMaximumNumberOfCharacters:
        value.length > 7 && value.length < 16,
    };
    setErrorFormatPasswor(response);
  }

  function getInputError(input) {
    let res = false;
    if (inputError.length > 0) {
      for (const e in inputError) {
        const error = inputError[e];
        if (error.key === input?.id) {
          res = error;
          break;
        }
      }
    }
    return res;
  }

  async function onRegister() {
    if (loading) return;
    setLoading(true);
    setShowValidationPassword(false);
    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/register`
      const data = {
        name: formRegister.name,
        email: formRegister.email,
        password: formRegister.password,
        type: formRegister.type,
      };
      const response = await Axios.post(url, data);
      const userData = response?.data;
      const session = await setLocalStorage(keys.authUserSession, userData);
      if (session) {
        setUserDetail(userData?.user);
        setUserToken(userData?.token);
        if (location?.state?.routeMemory) {
          const screen = location?.state?.routeMemory;
          const dataProps = location?.state?.dataMemory;
          dataProps.refresh = true;
          navigate(`${screen}`, { state: dataProps });
        } else {
          window.location.href = "/";
        }
      }
    } catch (error) {
      if (error && error?.response?.data?.error) {
        const message = error?.response?.data?.error || "123";
        const newError = { key: "email", message: message };
        console.log("newError: ", newError);
        setInputError([newError]);
      }
    } finally {
      setLoading(false);
    }
  }

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const windowHeight = container.clientHeight;
      const duration = 500;
      const startTime = Date.now();
      const easeInOutQuad = (time) => {
        return time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time;
      };
      const animateScroll = () => {
        const elapsed = Date.now() - startTime;
        const progress = easeInOutQuad(Math.min(1, elapsed / duration));
        const targetScrollTop = scrollHeight - windowHeight;
        container.scrollTop =
          scrollTop + progress * (targetScrollTop - scrollTop);

        if (elapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };
      animateScroll();
    }
  };

  useEffect(() => {
    const showError = errorPasswordStatus.some((elem) => elem.comply === false);
    if (showError && ["password", "confirmPassword"].includes(activeInput)) {
      scrollToBottom();
    }
  }, [errorPasswordStatus]);

  useEffect(() => {
    const value = inputError.length > 0;
    setBtnDisable(value);
  }, [inputError]);

  useEffect(() => {
    let result = true;
    if (
      step === 1 &&
      inputError.length === 0 &&
      formRegister.name !== "" &&
      formRegister.email !== "" &&
      formRegister.password !== "" &&
      formRegister.confirmPassword !== "" &&
      formRegister.password === formRegister.confirmPassword &&
      errorPasswordStatus.every((elem) => elem.comply === true)
    ) {
      result = false;
    }

    const show = formRegister?.password !== "";
    setShowValidationPassword(show);
    setBtnDisable(result);
  }, [formRegister, step]);

  useEffect(() => {
    const url = window.location.search;
    const typeUser = url.split("=")[1];
    setInputsForm(typeUser, "type");
  }, []);

  return {
    screensList,
    step,
    setStep,
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    errorPasswordStatus,
    formRegister,
    loading,
    getInputError,
    containerRef,
    onRegister,
    showValidationPassword,
  };
}

export default useRegisterUser;

/*
  inputPhone,
  getInputPhone,
  addPhone,
  editPhone,
  deletePhone,
  setInputsForm,
  avataresList,
  addAddress,
  editAddress,
  deleteAddress,
  onRegister,
  isShowModal,
  setIsShowModal,

 {
   countryName: 'colombia',
   countryCode: '2',
   departmentName: 'antioquia',
   departmentCode: '4',
   cityName: 'sabaneta',
   cityCode: '6',
   postalCode: '40550',
   description: 'calle 77 sur #46D - 43, valparaiso pto 304',
 },


 const [inputPhone, setInputPhone] = useState({ codePhone: '', phoneNumber: '' });
 const [isShowModal, setIsShowModal] = useState(false);



 const avataresList=[
   {id:1, type:'avatar', img:'https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png'},
   {id:2, type:'avatar', img:'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png'},
   {id:3, type:'avatar', img:'https://cdn5.vectorstock.com/i/1000x1000/51/99/icon-of-user-avatar-for-web-site-or-mobile-app-vector-3125199.jpg'} 
 ]


 const inputsStpe2 = [
{
   label: 'Apellidos',
   icono: iconList.IconPencilOutLine,
   type: 'text',
   id: 'surname',
   value: formRegister.surname,
   placeHolder: 'Agrega tu apellido',
   require: true,
   func: getInputsForm,
   name: 'el apellido no es valido'
 },
    {
      id: 'codePhone',
      type: 'text',
      placeHolder: '+00',
      value: inputPhone.codePhone,
      className: 'formPhoneFormInputOne',
      func: getInputPhone
    },
    {
      id: 'phoneNumber',
      type: 'text',
      placeHolder: '0000000000',
      value: inputPhone.phoneNumber,
      className: 'formPhoneFormInputTwo',
      func: getInputPhone
    }
  ]
  const inputsStpe3 = []

  const inputsStpe4 = [
    {
      label: 'Intruduce una contraseña',
      icono: iconList.IconPencilOutLine,
      type: showPassword ? 'text' : 'password',
      id: 'password',
      value: formRegister.password,
      placeHolder: 'Intruduce una contraseña',
      require: true,
      func: getInputsForm,
      name: 'Contraseña no valida',
    },
    {
      label: 'Confirma tu contraseña',
      icono: iconList.IconPencilOutLine,
      type: showPassword ? 'text' : 'password',
      id: 'confirmPassword',
      value: formRegister.confirmPassword,
      placeHolder: 'Confirma tu contraseña',
      require: true,
      func: getInputsForm,
      name: 'Contraseña no coincide',
    },
  ]

  function addPhone() {
    if (inputPhone.codePhone === '' || inputPhone.phoneNumber === '') return;
    const allPhones = [...formRegister.phones, inputPhone]
    setFormRegister({
      ...formRegister,
      phones: allPhones
    });
    setInputPhone({
      codePhone: '',
      phoneNumber: ''
    });
  }

  function editPhone(value) {
    setInputPhone({
      codePhone: value.codePhone,
      phoneNumber: value.phoneNumber
    });
  }

  function deletePhone(value) {
    const allPhones = formRegister.phones.filter((elem) => elem?.phoneNumber !== value?.phoneNumber)
    setFormRegister({
      ...formRegister,
      phones: allPhones
    });
  }
    function addAddress() { }
  function editAddress() { }
  function deleteAddress(value) {
    const allAddress = formRegister.address.filter((elem) => elem?.phoneNumber !== value?.phoneNumber)
    setFormRegister({
      ...formRegister,
      address: allAddress
    });
  }

    function getListInputs() {
    return step === 1 ? : []
    // : step === 2 ? inputsStpe2 : step === 3 ? inputsStpe3 : inputsStpe4;
  }

  function getInputPhone(event) {
    const value = event.target.value;
    const key = event.target.id;
    setInputPhone({
      ...inputPhone,
      [key]: value
    })
  }
if (formRegister.password === formRegister.confirmPassword) {
  setInputError(null);
  if (errorPasswordStatus.every((element) => element?.comply === true)) {
    result = false;
  }
}
else {
  setInputError({ key: inputsStpe4[1].id, message: inputsStpe4[1].name });
}
      useEffect(() => {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (isMobile) {
          console.log('Acceso desde un dispositivo móvil');
        } else {
          console.log('Acceso desde un ordenador');
        }
      }, [])
 */
