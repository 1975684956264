import { useTranslation } from 'react-i18next';
import './styles.css';

function ComponentPassenger({ data, baby, toEdit, editing, type }) {

  const [t, i18n] = useTranslation("global")

  const routeType = data?.scale ? t("fligth.list_flights.scale") : t("fligth.list_flights.direct");

  return (
    <>
      <div className='tabletPassengersPassenger'>
        <div className='tabletPassengersPassengerL'>

          {type &&
            <p className='tabletPassengersGroupType'>{t("fligth.table_itinerary.fligth_of")} {type}</p>
          }
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_itinerary.origin")}</p>
            <p className='tabletPassengersGroupValue'>{data.from}</p>
          </div>
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_itinerary.destiny")}</p>
            <p className='tabletPassengersGroupValue'>{data.to}</p>
          </div>
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_itinerary.date")}</p>
            <p className='tabletPassengersGroupValue'>{data.dateDeparture.split('T')[0]}
            </p></div>
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_itinerary.hour")}</p>
            <p className='tabletPassengersGroupValue'>{data.dateDeparture.split('T')[1].split('+')[0]}</p>
          </div>
          <div className='tabletPassengersGroup'>
            <p className='tabletPassengersGroupKey'>{t("fligth.table_itinerary.type_route")}</p>
            <p className='tabletPassengersGroupValue'>{routeType}</p>
          </div>
        </div>
      </div>
      {data?.scale &&
        <div className='tabletPassengersContainerBaby'>
          <p className='tabletPassengersGroupType'>{t("fligth.table_itinerary.scales")}:</p>
          {
            data?.scale.map((index, key) => {
              return (
                <ComponentPassenger key={key} data={index} baby={true} toEdit={toEdit} editing={editing} />
              )
            })
          }
        </div>
      }
    </>
  )
}


function TabletItinerary({
  list,
  toEdit = () => { },
  editing = false,
  title
}) {

  const [t, i18n] = useTranslation("global")


  return (
    <div className='tabletPassengers'>
      <div className='tabletPassengersHead'>
        <p className='tabletPassengersHeadTitle'>{title}</p>
      </div>
      <div className='tabletPassengersBody'>
        {
          list && list.map((index, key) => {
            const type = key === 0 ? t("fligth.search_fligth.going") : t("fligth.search_fligth.return")
            return (
              <ComponentPassenger
                key={key}
                data={index}
                baby={false}
                toEdit={toEdit}
                editing={editing}

                type={type}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default TabletItinerary;
