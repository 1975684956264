import React from 'react'


import './styles.css'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import Loading from '../../../share/components/loading'

const ModalQuo = ({
    setModal,
    sendInformation,
    name,
    setName,
    email,
    setEmail,
    error,
    succesScreen,
    description,
    setDescription,
    tel,
    setTel,
    subject,
    setSubject,
    id,
    loading
}) => {


    return (
        <div className='modal-quo'>
            <div className='contain-close-modal'>
                <button onClick={() => setModal(false)}>x</button>
            </div>
           {
                loading === true ?
                    <Loading size={95}/>
                :
                (
                succesScreen === true ?
                <div className='success-screen-specialist'>
                    <SvgIcon name={iconList.IconCheckSucces} size={50} color={'#008000'}/>
                    <h2>
                        Informacion enviada correctamente
                    </h2>
                </div>
                :
                <form action="" onSubmit={(e) => { sendInformation(e, id) }} className='form-make-quo-specialist'>
                <legend>Pedir Cita</legend>
                <p>Llena el formulario te contactaremos lo mas pronto posible</p>
                <div className='campo-make-quo'>
                    <label htmlFor="">
                        Nombre
                    </label>
                    <input onChange={(e)=>setName(e.target.value)} type="text" />
                </div>
                <div className='campo-make-quo'>
                    <label htmlFor="">
                        Email
                    </label>
                    <input onChange={(e)=>setEmail(e.target.value)} type="text" />
                </div>
                <div className='campo-make-quo'>
                    <label htmlFor="">
                        Telefono
                    </label>
                    <input onChange={(e)=>setTel(e.target.value)} type="text" />
                </div>
                <div className='campo-make-quo'>
                    <label htmlFor="">
                        Asunto
                    </label>
                    <input onChange={(e)=>setSubject(e.target.value)} type="text" />
                </div>
                <div className='campo-make-quo'>
                    <label htmlFor="">
                        Descripcion
                    </label>
                    <input type='text' onChange={(e)=>setDescription(e.target.value)} />
                </div>
                {
                    error &&
                    <div className='error-message-specialist'>
                        <p>Todos los campos son obligatorios</p>
                    </div>
                }

                <button>
                    Enviar
                </button>
            </form>
                )
            
        }
            
        </div>
    )
}

export default ModalQuo