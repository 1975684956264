import React from 'react'
import banner from '../../../assets/pictures/bannerClinics.png'
import clinic from '../../../assets/images/clinicas.png'
import { useTranslation } from 'react-i18next'
import SearchClinics from '../SearchClinics/SearchClinics'

const BannerClinics = () => {
  const [t,i18n] = useTranslation("global")
  return (
    <header className='header-fligths'>
        <div className='content-buscador-fli'>
          <SearchClinics/>
        </div>
        <div className='content-banner-t'>
          <img src={banner} alt="" />
        </div>
      </header>
  )
}

export default BannerClinics